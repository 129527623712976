import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type MarketDataEntryType = {
  month: string;
  median_value?: number;
  median_value_per_sqft: number;
  median_days_on_market?: number;
  n_back_on_market?: number;
  n_canceled_listings?: number;
  n_new_listings?: number;
  n_pending_listings?: number;
  n_price_reductions?: number;
  n_sales?: number;
  sale_to_list_price_ratio?: number;
  structure_type?: string;
};

export type LocationPriceDataEntryType = MarketDataEntryType;

export type MarketDataType = {
  city?: MarketDataEntryType[];
  neighborhood?: MarketDataEntryType[];
  zip_code?: MarketDataEntryType[];
};

export const useMarket = (parcelId: number) => {
  const url = appConfig.apiEndpoint.parcelMarketData.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<MarketDataType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error ? {} : data;
};
