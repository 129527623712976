export { NWMLSNotice };

const NWMLSNotice = () => {
  return (
    <div className="flex w-full flex-col gap-2 text-pretty bg-white px-6 pb-10 pt-0 sm:pb-[5.5rem] text-center text-sm sm:max-w-7xl md:mx-auto">
      All data is obtained from various sources and may not have been verified by broker or MLS GRID. Supplied
      Open House Information is subject to change without notice. All information should be independently
      reviewed and verified for accuracy. Properties may or may not be listed by the office/agent presenting
      the information.
    </div>
  );
};
