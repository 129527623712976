import { appConfig } from "config";
import { fetcherWithCredentials, prettifyParcel } from "helpers";
import { useEffect } from "react";
import { selectorFamily, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { useCurrentStates } from "state";
import { customStructureAtom, FinancialsClass, useFinancials, useProforma } from "state/proformas";
import {
  buildRentalCompsFilters,
  buildSalesCompsFilters,
  processRentalComparables,
  processSalesComparables,
  useRentalComps,
  useSalesComps,
  useSalesCompsFilters,
} from "state/proformas/comparables";

/**
 * Return a listing object with an on market indicator.
 * @return {!Object} A formatted listing.
 */
const getListing = (parcel: any) => {
  const defaultListing = {
    listing_prices: [],
  };
  const originalListing = parcel.most_recent_listing;
  const listing = originalListing || defaultListing;
  const isNotSold = !listing.sale_price;
  const hasListingPrices = (listing.listing_prices || []).length > 0;

  listing._on_market = isNotSold && hasListingPrices && listing.listing_status === "Active";
  return listing;
};

const proformasByDataSelector = selectorFamily({
  key: "proformasByDataSelector",
  get: (data_and_proforma_type: any | undefined) => async () => {
    if (!data_and_proforma_type.data) return;
    const urlParams = new URLSearchParams({
      proforma_type: data_and_proforma_type.proforma_type || "",
    });
    if (data_and_proforma_type.share_token) {
      urlParams.append("share-token", data_and_proforma_type.share_token);
    }

    const response =
      data_and_proforma_type.data_type === "listingNumber"
        ? await fetcherWithCredentials(
            `${appConfig.apiEndpoint.proformaListingNumber.replace(
              ":listing_number",
              data_and_proforma_type.data,
            )}?${urlParams.toString()}`,
          )
        : await fetcherWithCredentials(
            `${appConfig.apiEndpoint.proformaAddress.replace(
              ":full_address",
              data_and_proforma_type.data,
            )}?${urlParams.toString()}`,
          );
    if (response.parcel) {
      response.listing = getListing(response.parcel);
      response.parcel = prettifyParcel(response.parcel);
    }
    return response;
  },
});

export const useProformasByData = (
  data: string | undefined,
  proforma_type: string | undefined,
  data_type: "address" | "listingNumber",
  share_token: string | null,
) => {
  const data_and_proforma_type = {
    data,
    proforma_type,
    data_type,
    share_token,
  };
  const loadable = useRecoilValueLoadable(proformasByDataSelector(data_and_proforma_type));
  const { setCurrentStates } = useCurrentStates();
  const { setProforma } = useProforma();
  const { setSalesComparables } = useSalesComps();
  const { setRentalComparables, setRentalCompsFilters } = useRentalComps();
  const setCustomStructure = useSetRecoilState(customStructureAtom);
  const { setSalesCompFilters, setSalesCompFiltersBackup } = useSalesCompsFilters();
  const { setFinancials } = useFinancials();

  useEffect(() => {
    if (loadable.state === "hasValue") {
      setCurrentStates([loadable.contents.parcel.state_id]);
      setProforma(loadable.contents);
      if (loadable.contents.comparables) {
        // const salesComps = processSalesComparables(loadable.contents.comparables);
        // Temporary filter to avoid showing sales comparables with most_recent_listing === null
        const salesComps = processSalesComparables(
          loadable.contents.comparables.filter((comp: any) => comp.parcel.most_recent_listing),
        );
        setSalesComparables(salesComps);
        const salesCompFilters = buildSalesCompsFilters(
          salesComps,
          loadable.contents.parcel._characteristics.structure_type,
        );
        setSalesCompFilters(salesCompFilters);
        setSalesCompFiltersBackup(salesCompFilters);
      }
      if (loadable.contents.rental_comparables) {
        const rentalComps = processRentalComparables(loadable.contents.rental_comparables);
        setRentalComparables(rentalComps);
        setRentalCompsFilters(
          buildRentalCompsFilters(rentalComps, loadable.contents.parcel._characteristics.structure_type),
        );
      }
      if (loadable.contents.custom_structure) {
        setCustomStructure(loadable.contents.custom_structure);
      }
      const newFinancials = new FinancialsClass(null, loadable.contents);
      setFinancials(newFinancials.data);
    }
  }, [
    loadable.state,
    loadable.contents,
    setSalesComparables,
    setRentalComparables,
    setCustomStructure,
    setSalesCompFilters,
    setSalesCompFiltersBackup,
    setRentalCompsFilters,
    setFinancials,
    setProforma,
    setCurrentStates,
  ]);

  return {
    proforma: loadable.contents,
    loading: loadable.state === "loading",
    error: loadable.state === "hasError",
  };
};
