import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Footer, WithSignIn } from "components";
import {
  AppreciationsMap,
  BrowseInsights,
  BrowseMap,
  mapBoundsAtom,
  NoAVMNotice,
  PropertiesGrid,
  PropertiesList,
} from "components/browse";
import { SearchPerformingMarkets } from "components/markets";
import { RegisterClientOrAgentModal } from "components/RegisterClientOrAgentModal";
import { appConfig } from "config";
import { cn } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactPaginate from "react-paginate";
import TimeAgo from "react-timeago";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useRecentSearches, useUserSession } from "state";
import { searchParamsAtom, searchResultsAtom, SearchResultType } from "state/browse";
import { useMarketDataSearchPerformers } from "state/market";
import { useUpdateDate } from "state/proformas";
import { useSelectedProperty } from "state/ui";

type Props = {
  loading: boolean;
  stateId: number;
  searchResults: SearchResultType[];
  emptyResult: boolean;
  onlyFavorites: boolean;
  setOnlyFavorites: (value: boolean) => void;
  sortedSearchResults: SearchResultType[];
};

export const ResultsPanel = ({
  loading,
  stateId,
  searchResults,
  emptyResult,
  onlyFavorites,
  setOnlyFavorites,
  sortedSearchResults,
}: Props) => {
  const currentUser = useUserSession();
  const [currentPage, setCurrentPage] = useState(0);
  const totalSearchResults = useRecoilValue(searchResultsAtom);
  // const [showCosts, setShowCosts] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { selectedProperty, setSelectedProperty } = useSelectedProperty();
  const [showMode, setShowMode] = useState<"grid" | "table" | "list" | "map">(isMobile ? "list" : "grid");
  const locationData = useRecoilValue(searchParamsAtom);
  const data = useMarketDataSearchPerformers(locationData?.locations!);
  const performersByMarketAvailable = data.bottom_performers.length > 0 && data.top_performers.length > 0;
  const setMapBounds = useSetRecoilState(mapBoundsAtom);

  useEffect(() => {
    if (isMobile && loading) {
      setMapBounds(undefined);
    }
  }, [loading, setMapBounds]);

  useRecentSearches({});

  useEffect(() => {
    setCurrentPage(0);
  }, [sortedSearchResults]);

  const resultsPerPage =
    appConfig.browse.showAd && appConfig.browse.statesWithAds.includes(stateId)
      ? appConfig.browse.defaultPageSize - 1
      : appConfig.browse.defaultPageSize;
  const numPages = Math.ceil(searchResults.length / resultsPerPage);

  const pagedResults = searchResults.slice(currentPage * resultsPerPage, (currentPage + 1) * resultsPerPage);
  const updateDateText = useUpdateDate(stateId);

  if (selectedProperty) {
    // find selected property in search results and determine the page number
    const idx = searchResults.findIndex((property) => property.parcel_id === selectedProperty.parcel_id);
    const selectedPropertyPage = Math.floor(idx / resultsPerPage);

    if (selectedPropertyPage !== currentPage) {
      setCurrentPage(selectedPropertyPage);
    }
  }

  const handlePageClick = (page: { selected: number }) => {
    setCurrentPage(page.selected);
    setSelectedProperty(undefined);
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex size-full flex-col">
      <div className="relative flex size-full flex-1 flex-col overflow-y-scroll" ref={scrollRef}>
        <div className="mt-4 flex w-full flex-row items-center justify-between">
          <div className="pl-4 text-sm sm:pl-6">
            <span className="font-bold">{searchResults.length}</span> out of{" "}
            <span className="font-bold">{totalSearchResults.length}</span> listings
            <span className="hidden xl:inline-block">&nbsp;match your filters</span>
            <span
              className="inline-block cursor-pointer"
              onClick={() => currentUser.isLogged && setOnlyFavorites(!onlyFavorites)}
            >
              <WithSignIn useChildren>
                {onlyFavorites ? (
                  <SolidStarIcon
                    className="ml-2 inline h-5 w-5 text-yellow-500 shadow-xl"
                    aria-hidden="true"
                  />
                ) : (
                  <StarIcon className="ml-2 inline h-5 w-5 shadow-xl" aria-hidden="true" />
                )}
              </WithSignIn>
            </span>
          </div>
          <div className="flex cursor-pointer flex-row items-center gap-1">
            {isMobile ? (
              <>
                <div
                  className={cn(
                    "mx-1 ml-4 whitespace-nowrap border-b-2 pb-1 text-xs text-gray-500",
                    { "border-b-transparent hover:border-b-gray-200": showMode === "list" },
                    { "border-b-[#7ba1bb]": showMode === "map" },
                  )}
                  onClick={() => setShowMode("map")}
                >
                  Map
                </div>
                <div
                  className={cn(
                    "mx-1 mr-4 whitespace-nowrap border-b-2 pb-1 text-xs text-gray-500 sm:mr-6",
                    { "border-b-transparent hover:border-b-gray-200": showMode === "map" },
                    { "border-b-[#7ba1bb]": showMode === "list" },
                  )}
                  onClick={() => setShowMode("list")}
                >
                  List
                </div>
              </>
            ) : (
              <>
                <div
                  className={cn(
                    "mx-1 ml-4 whitespace-nowrap border-b-2 pb-1 text-xs text-gray-500",
                    { "border-b-transparent hover:border-b-gray-200": showMode === "grid" },
                    { "border-b-[#7ba1bb]": showMode === "table" },
                  )}
                  onClick={() => setShowMode("table")}
                >
                  Table
                </div>
                <div
                  className={cn(
                    "mx-1 mr-4 whitespace-nowrap border-b-2 pb-1 text-xs text-gray-500 sm:mr-6",
                    { "border-b-transparent hover:border-b-gray-200": showMode === "table" },
                    { "border-b-[#7ba1bb]": showMode === "grid" },
                  )}
                  onClick={() => setShowMode("grid")}
                >
                  Grid
                </div>
              </>
            )}
          </div>
        </div>

        {(showMode === "grid" || showMode === "list") && (
          <PropertiesGrid stateId={stateId} properties={pagedResults} selectedProperty={selectedProperty} />
        )}
        {!isMobile && showMode === "table" && (
          <PropertiesList stateId={stateId} properties={pagedResults} selectedProperty={selectedProperty} />
        )}
        {showMode !== "map" && (
          <>
            {numPages > 0 && (
              <ReactPaginate
                pageCount={numPages}
                forcePage={currentPage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={currentPage < 3 || currentPage > numPages - 3 ? 4 : 3}
                marginPagesDisplayed={1}
                previousLabel={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
                containerClassName="mt-4 flex justify-center text-sm text-gray-500"
                previousClassName="mr-1"
                previousLinkClassName="py-1 hover:text-gray-900 cursor-pointer"
                nextClassName="ml-1"
                nextLinkClassName="py-1 hover:text-gray-900 cursor-pointer"
                pageClassName="mx-1"
                activeClassName="text-white "
                disabledClassName="text-gray-400"
                pageLinkClassName="px-2.5 py-1 cursor-pointer border border-[#6389c4] rounded-md"
                activeLinkClassName="px-2.5 py-1 bg-[#6389c4] cursor-default border border-[#6389c4] rounded-md"
                disabledLinkClassName="cursor-default"
                renderOnZeroPageCount={null}
              />
            )}

            <div className="mb-10 mt-20 flex w-full flex-col items-center justify-center px-4">
              <h1 className="mb-10 text-left text-xl font-medium sm:text-3xl">Market Insights</h1>
              {performersByMarketAvailable && (
                <div className="mb-6 flex w-full flex-row flex-wrap justify-around">
                  <SearchPerformingMarkets data={data} order="top" />
                  <SearchPerformingMarkets data={data} order="bottom" />
                </div>
              )}
              <BrowseInsights stateId={stateId} />
            </div>

            <div className="my-6 flex w-full items-center justify-center text-sm">
              Listings last updated&nbsp;
              <TimeAgo className="inline" date={new Date(updateDateText)} live={false} />
            </div>
          </>
        )}
        {showMode === "map" && (
          <div className="mt-4 h-[calc(100vh-7rem)] pb-1 w-full bg-transparent overflow-hidden">
            {!loading && emptyResult ? <AppreciationsMap /> : <BrowseMap />}
          </div>
        )}
        {showMode !== "map" && <Footer stateId={stateId} browsePage={true} />}
      </div>
      {showMode !== "map" && <NoAVMNotice />}
      <RegisterClientOrAgentModal />
    </div>
  );
};
