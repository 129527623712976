import { WASHINGTON_STATE_ID } from "config";
import { cn } from "helpers";
import NWMLSLogo from "images/NWMLS-logo.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useUserSession } from "state";
import { useUpdateDate } from "state/proformas";

type Props = {
  propertyParcel: any;
  className?: string;
};

export const ListedBy = ({ propertyParcel, className }: Props) => {
  const currentUser = useUserSession();
  const isNWMLS = propertyParcel.most_recent_listing?.listing_source === "NWMLS";
  const isSABOR = propertyParcel.most_recent_listing?.listing_source === "SABOR";
  const updateDate = useUpdateDate(propertyParcel.most_recent_listing?.state_id);
  return (
    <div
      className={cn(
        "w-full justify-center text-pretty px-2 pb-3 text-center text-xs font-light leading-5 text-gray-700 sm:leading-7",
        className && className,
      )}
    >
      {propertyParcel.most_recent_listing._on_market &&
        propertyParcel.most_recent_listing?.sale_type !== "Standard" && (
          <div className="inline font-bold">{propertyParcel.most_recent_listing?.sale_type}&nbsp;</div>
        )}
      {propertyParcel.most_recent_listing?.selling_company && (
        <div className="inline text-start">
          Sold by {propertyParcel.most_recent_listing?.selling_company}.&nbsp;
        </div>
      )}
      {isNWMLS && (
        <div className="inline items-center">
          {propertyParcel.most_recent_listing?.listing_source && (
            <>
              {propertyParcel.most_recent_listing?.selling_company ? <>Listing courtesy of&nbsp;</> : <>Courtesy of&nbsp;</>}
              {propertyParcel.most_recent_listing?.listing_source}
            </>
          )}
          {currentUser.isAgent &&
          propertyParcel.most_recent_listing?.parcel &&
          propertyParcel.most_recent_listing?.parcel.state_id === WASHINGTON_STATE_ID &&
          propertyParcel.most_recent_listing?.listing_number &&
          propertyParcel.most_recent_listing?.listing_number.length > 0 ? (
            <CopyToClipboard text={propertyParcel.most_recent_listing?.listing_number}>
              <a
                className="font-medium text-blue-700"
                href="http://www.matrix.nwmls.com/Matrix/Search/SingleFamily/MLS"
                target="_new"
              >
                {` (#${propertyParcel.most_recent_listing?.listing_number})`}
              </a>
            </CopyToClipboard>
          ) : (
            propertyParcel.most_recent_listing?.listing_number &&
            propertyParcel.most_recent_listing?.listing_number.length > 0 &&
            ` (#${propertyParcel.most_recent_listing?.listing_number})`
          )}
          {propertyParcel.most_recent_listing?.listing_company &&
            ` and ${propertyParcel.most_recent_listing?.listing_company} via MLS GRID as of ${updateDate}`}
          <img src={NWMLSLogo} alt="NWMLS Logo" className="ml-1 inline size-4" />
        </div>
      )} 
      
      {!isNWMLS && (
        <div className="inline">
          {propertyParcel.most_recent_listing?.listing_company && (
            <>Listed by {propertyParcel.most_recent_listing?.listing_company} </>
          )}
          {propertyParcel.most_recent_listing?.listing_source && (
            <>({propertyParcel.most_recent_listing?.listing_source}</>
          )}
          {propertyParcel.most_recent_listing?.listing_source &&
          currentUser.isAgent &&
          propertyParcel.most_recent_listing?.parcel &&
          propertyParcel.most_recent_listing?.parcel.state_id === WASHINGTON_STATE_ID &&
          propertyParcel.most_recent_listing?.listing_number &&
          propertyParcel.most_recent_listing?.listing_number.length > 0 ? (
            <CopyToClipboard text={propertyParcel.most_recent_listing?.listing_number}>
              <a
                className="font-medium text-blue-700"
                href="http://www.matrix.nwmls.com/Matrix/Search/SingleFamily/MLS"
                target="_new"
              >
                {` #${propertyParcel.most_recent_listing?.listing_number})`}
              </a>
            </CopyToClipboard>
          ) : (
            propertyParcel.most_recent_listing?.listing_number &&
            propertyParcel.most_recent_listing?.listing_number.length > 0 &&
            ` #${propertyParcel.most_recent_listing?.listing_number}) `
          )}
          {isSABOR && (<img src="https://cdn.davinci.pellego.com/static/images/sabor_logo.png" alt="SABOR Logo" className="ml-1 inline h-5" />)}
        </div>
      )}
    </div>
  );
};
