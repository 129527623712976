import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { FilterDropdownMenu } from "components/browse";
import { Fragment } from "react";
import { isDesktop } from "react-device-detect";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useFilters } from "state/browse";

type Props = {
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  handleChange: (e: any) => void;
  control: Control<FieldValues, unknown>;
  tag?: "new" | null;
};

const commonClasses = "flex h-6 p-0 w-full border-gray-500 items-center border px-2 py-4 text-sm";

const options = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export const FilterEquityMenu = ({ register, getValues, setValue, handleChange, control, tag }: Props) => {
  const variable = "equity";
  const { filters, setFilters } = useFilters();
  const anyValueSet =
    filters.hide_unknown_equity || filters.min_equity !== null || filters.max_equity !== null;

  const handleReset = () => {
    setFilters({ ...filters, min_equity: null, max_equity: null, hide_unknown_equity: false });
  };

  return (
    <>
      {isDesktop && (
        <Menu as="div" className={`relative ml-2 mr-1 hidden md:mx-2 filter-equity:flex`}>
          {({ open }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  anyValueSet ? "bg-black text-white" : "text-gray-600"
                }`}
              >
                <span className="flex items-center whitespace-nowrap">Equity</span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {anyValueSet ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReset();
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="ml-2 h-4 w-4 text-black" />
                    )}
                  </>
                )}
              </Menu.Button>

              {/* Tag. Another tags types can be added in the future */}
              {!anyValueSet && tag === "new" && (
                <div className="absolute -end-1 -top-2 inline-flex h-4 w-8 items-center justify-center rounded-sm border-red-500 bg-red-500 text-[10px] font-bold text-white">
                  NEW
                </div>
              )}

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="shadow-lg absolute top-8 mt-2 w-fit origin-top-left rounded-md bg-white p-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item
                    as="div"
                    className="mx-1 my-2 flex cursor-pointer flex-row items-center justify-start gap-2 bg-white p-1 text-sm text-gray-600 hover:bg-[#6389c4] hover:text-white"
                  >
                    <input
                      id="hide_unknown_equity"
                      className="flex cursor-pointer appearance-none border outline-0 indeterminate:bg-gray-300 hover:checked:bg-transparent focus:ring-0"
                      type="checkbox"
                      value="true"
                      {...register("hide_unknown_equity")}
                    />
                    <label
                      htmlFor="hide_unknown_equity"
                      className="inline-flex cursor-pointer whitespace-pre text-left"
                    >
                      Hide Unknown Equity
                    </label>
                  </Menu.Item>
                  <div className="mx-1 mb-3 mt-2 p-1">
                    <div className="flex flex-row content-start">
                      <Controller
                        control={control}
                        name={`min_${variable}`}
                        render={({ field: { onChange, name, value } }) => (
                          <FilterDropdownMenu
                            className={commonClasses}
                            placeholder="Min"
                            hidePlaceholderWithValue={true}
                            label=""
                            value={value}
                            options={
                              getValues(`max_${variable}`)
                                ? options.filter((o) => o < getValues(`max_${variable}`))
                                : options
                            }
                            onChange={(min_value: any) => {
                              onChange(min_value);
                              setValue(name, min_value);
                              handleChange(min_value);
                            }}
                          />
                        )}
                      />
                      <div className="mx-2">-</div>
                      <Controller
                        control={control}
                        name={`max_${variable}`}
                        render={({ field: { onChange, name, value } }) => (
                          <FilterDropdownMenu
                            className={commonClasses}
                            placeholder="Max"
                            hidePlaceholderWithValue={true}
                            label=""
                            value={value}
                            options={
                              getValues(`min_${variable}`)
                                ? options.filter((o) => o > getValues(`min_${variable}`))
                                : options
                            }
                            onChange={(max_value: any) => {
                              onChange(max_value);
                              setValue(name, max_value);
                              handleChange(max_value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
