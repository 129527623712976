import { atom, useRecoilState } from "recoil";

type AlertStateAtom = {
  message: string;
  type: string;
}

const alertAtom = atom<AlertStateAtom | null>({
  key: "alert",
  default: null,
});

class AlertState {
  atom: AlertStateAtom | null;
  setAtom: Function

  constructor(atom: AlertStateAtom | null, setAtom: Function) {
    this.atom = atom;
    this.setAtom = setAtom;
  }

  get type() { return this.atom?.type };
  get message() { return this.atom?.message; }

  success(message: string) {
    this.setAtom({ message, type: "success" });
  }

  error(message: string) {
    this.setAtom({ message, type: "danger" });
  }

  clear() {
    this.setAtom(null);
  }
}

export const useAlerts = () => {
  const [alert, setAlert] = useRecoilState(alertAtom);
  return new AlertState(alert, setAlert);
};
