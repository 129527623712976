import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js";

type Props = {
  label: string;
  onRemove: CallableFunction;
};

export const Label = ({ label, onRemove }: Props) => {

  const onClickOnRemove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  }

  return (
    <div className={`ml-1 flex h-8 cursor-pointer items-center whitespace-nowrap rounded-md border border-gray-300 bg-gray-100 px-3 text-sm text-gray-600 mb-1.5 mt-2"`}>
      <span className="truncate">{label}</span>
      <span onClick={onClickOnRemove} className="items-center flex">
        <Icon
          className="w-4 h-4 inline text-gray-400 ml-1 hover:bg-gray-300 rounded-lg"
          path={mdiClose}
        />
      </span>
    </div>
  )
}
