import { cn } from "helpers";
import NumberFormat from "react-number-format";

type Props = {
  property: any;
};

export const PhotosTableDisclosure = ({ property }: Props) => {
  const withLogoImage = property.listing_source === "RMLS" || property.listing_source === "SABOR";
  const imageLogo = property.listing_source === "RMLS" ? "rmls_logo.jpg" : "sabor_logo.png";

  return (
    <div className="absolute bottom-1.5 z-10 min-w-[320px] rounded-md bg-gradient-to-t from-black/50 to-transparent pb-2 pl-3 pr-2.5 pt-3 text-white">
      <div className="flex flex-row items-center justify-between">
        <div className={cn("flex flex-col", withLogoImage ? "w-56" : "w-full")}>
          <div className="flex text-xs">
            <div className="flex w-full">
              {property.beds}bd&nbsp;
              {property.baths}ba&nbsp;
              <NumberFormat value={property.sqft} displayType="text" thousandSeparator={true} />
              sqft&nbsp;
              {property.lot_square_feet > 0 && (
                <>
                  <NumberFormat
                    value={property.lot_square_feet}
                    displayType="text"
                    thousandSeparator={true}
                  />
                  &nbsp;lot
                </>
              )}
            </div>
          </div>
          {withLogoImage && (
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
              Listing provided by {property.listing_company}
            </div>
          )}
          {!withLogoImage && (
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
              Listing provided by {property.listing_source}
              {property.listing_company && " and " + property.listing_company}
            </div>
          )}
        </div>
        {withLogoImage && (
          <div className="ml-2 content-center justify-center rounded-sm bg-white">
            <img
              className="h-[28px] w-14 object-scale-down p-[3px]"
              src={`https://cdn.davinci.pellego.com/static/images/${imageLogo}`}
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};
