import { useEffect, useRef, memo } from "react";
import { CompMapService } from "components/proformas/comparables/";
import { useRecoilValue } from "recoil";
import { salesComparablesAtom } from "state/proformas/comparables";
import { useProforma } from "state/proformas";
import { atom, useSetRecoilState } from "recoil";

export const salesComphighlightHandlerAtom = atom<CallableFunction | null>({
  key: "salesComphighlightHandlerAtom",
  default: null,
});

export const CompMap = memo(() => {
  const { proforma } = useProforma();
  const salesComparables = useRecoilValue(salesComparablesAtom);
  const setHighlightHandler = useSetRecoilState(salesComphighlightHandlerAtom);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const compMapService = new CompMapService(salesComparables, proforma);
      compMapService.createMap(ref.current);
      setHighlightHandler(() => compMapService.highlightHandler.bind(compMapService));
    }
    return () => setHighlightHandler(null);
  }, [ref, salesComparables, proforma, setHighlightHandler]);

  return (
    <div ref={ref} className="w-full h-full" />
  );
});
