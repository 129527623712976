import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAlerts } from "state";

export const Alert = () => {
  const alert = useAlerts();
  const location = useLocation();

  useEffect(() => {
    // runs on location, i.e. route, change
    alert.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!alert.message) return null;

  return (
    <div className="container">
      <div className="m-0">
        <div className={`alert-dismissable px-3 py-1 rounded ${alert.type === 'danger' ? 'bg-red-300' : 'bg-green-300'}`}>
          {alert.message}
          <button className="text-lg float-right hover:bg-blue-200 text-gray-600 w-5 h-5 rounded-full mt-0.5 inline-flex items-center justify-center"
            onClick={() => alert.clear()}
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
}
