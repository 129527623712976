import { extendTailwindMerge } from "tailwind-merge";
import { withExtendedShadows } from "tailwind-extended-shadows-merge";

const twMerge = extendTailwindMerge(withExtendedShadows);

export const FormFieldError = ({ field, className }: { field: any; className?: string }) => {
  if (!field) return null;

  return <div className={twMerge("-mt-2 text-sm text-red-500", className)}>{`${field.message}`}</div>;
};
