import { appConfig } from "config";
import { ServiceAreaType, AgentPropertyType, fetchPolygons } from "state";
import { makeSVGIcon } from "components";
import { formatPropertyName } from "helpers";
import { atom } from "recoil";
import L from "leaflet";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";
import delay from "lodash/delay";

export const highlightAreaHandlerAtom = atom<CallableFunction | null>({
  key: "highlightAreaHandlerAtom",
  default: null,
});

const defaultStyle = {
  "color": "#61a28d",
  "weight": 2,
  "opacity": 0.7
};

const selectedStyle = {
  "color": "#008000",
  "weight": 3,
  "opacity": 0.8
};

const getPopupHtml = (property: AgentPropertyType) => {
  const html = `
    <b>${formatPropertyName(property.street_address_full)}</b>
    <img src="${property.photos[0]}">
    Status: ${property.listing_status} ${property.sale_date ? property.sale_date : ""}
  `;

  return html;
}

export class ServiceAreasMapService {
  serviceAreas: ServiceAreaType[];
  center: L.LatLngLiteral;
  sold: AgentPropertyType[];
  listed: AgentPropertyType[];
  map: L.Map | null = null;
  geoJSONLayer: L.GeoJSON | null = null;
  bounds: L.LatLngBounds | null = null;
  polygons: any[] = [];

  constructor(
    serviceAreas: ServiceAreaType[],
    center: L.LatLngLiteral,
    sold: AgentPropertyType[],
    listed: AgentPropertyType[]
  ) {
    this.serviceAreas = serviceAreas;
    this.center = center;
    this.sold = sold;
    this.listed = listed;

    if (serviceAreas && serviceAreas.length > 0) {
      fetchPolygons(this.serviceAreas).then((polygons) => {
        this.polygons = polygons;
        delay(() => this.addPolygons(), 1000);
      })
    }
  }

  createMap(domRef: HTMLDivElement) {
    this.map = L.map(domRef, {
      center: [this.center.lat, this.center.lng],
      zoom: 6,
      maxZoom: 18,
      attributionControl: true,
      zoomControl: false,
    });

    L.maplibreGL({
      style: `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${appConfig.mapTilerApiKey}`,
    }).addTo(this.map);

    L.control.zoom({
      position: 'bottomright'
    }).addTo(this.map);

    this.map.scrollWheelZoom.disable();

    this.addMarkers(this.sold, 'subject');
    this.addMarkers(this.listed, 'highlighted');
    if (this.polygons.length === 0 && this.bounds) {
      this.map.fitBounds(this.bounds);
    }
  }

  addPolygons() {
    if (this.polygons.length === 0) {
      return;
    }
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: 'FeatureCollection',
      features: this.polygons.map((polygon, idx) => {
        return {
          "type": "Feature",
          "properties": this.serviceAreas[idx],
          "geometry": polygon,
        }
      })
    };

    if (this.map) {
      this.geoJSONLayer = L.geoJSON(featureCollection, {
        style: defaultStyle
      }).addTo(this.map);
      this.bounds = this.geoJSONLayer.getBounds();
      this.map.flyToBounds(this.bounds);
    }
  }

  addMarkers(properties: AgentPropertyType[], type: string) {
    properties.forEach((property, idx) => {
      if (!property.latitude || !property.longitude) {
        return;
      }
      const marker = new L.Marker([property.latitude, property.longitude], {
        icon: makeSVGIcon(type, "•"),
        title: formatPropertyName(property.street_address_full),
      });
      marker.bindPopup(getPopupHtml(property), { minWidth: 150 }).openPopup();
      if (this.map) {
        marker.addTo(this.map);
        if (this.bounds) {
          this.bounds.extend(marker.getLatLng());
        } else {
          this.bounds = L.latLngBounds([marker.getLatLng()]);
        }
      }
    })
  }

  highlightHandler(serviceArea: ServiceAreaType | null) {
    if (serviceArea === null) {
      this.geoJSONLayer?.resetStyle();
      return;
    }
    this.geoJSONLayer?.eachLayer((layer: any) => {
      if (serviceArea.id === layer.feature.properties.id &&
        serviceArea.type === layer.feature.properties.type) {
        layer.setStyle(selectedStyle);
      }
    });
  }

  clearMap() {
    this.map?.off();
    this.map?.remove();
  }
}
