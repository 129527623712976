import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";

export const useAskAgent = () => {

  const sendEmailToAgent = (
    toEmail: string,
    fromEmail: string,
    fromName: string,
    phone: string,
    message: string,
    recaptchaToken: string,
    address: string
  ) => {

    return fetcherWithCredentials(appConfig.apiEndpoint.askAgent, "POST", {
      email: fromEmail,
      name: fromName,
      agent_email: toEmail,
      address: address,
      message,
      phone,
      "g-recaptcha-response": recaptchaToken,
    });

  }

  return { sendEmailToAgent };

}
