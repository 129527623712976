import { Tab } from '@headlessui/react';
import { CostEditModal, FlipTab, RehabTab, RentalTab } from "components/proformas/financials/";
import { useRef, useState } from "react";
import { useSetVisibleSection } from "state";


export const Financials = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "financials");

  return (
    <div className="w-full py-10 px-0 sm:py-[5.5rem] flex flex-col align-center justify-center items-center bg-[#FFF0E4] border border-[#f2dfce]" id="Financials" ref={ref}>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex p-0 space-x-0 w-80 sm:w-96 max-w-sm">
          <Tab className={({ selected }) => selected ? 'uppercase  border-[#38455b] border  text-white rounded-l-sm bg-[#38455b] w-full py-2.5 text-sm leading-5 font-medium' : 'w-full py-2.5 border-[#eac6b1] border text-sm leading-5 font-medium rounded-l-sm uppercase text-gray-600 bg-[#f2dfce]'} >  Rehab</Tab>
          <Tab className={({ selected }) => selected ? ' text-white border-[#38455b] border bg-[#38455b] w-full py-2.5 text-sm leading-5 font-medium uppercase' : 'w-full py-2.5 text-sm text-gray-600 border-l-0 border-r-0 border-[#eac6b1] border leading-5 font-medium uppercase focus:ring-white bg-[#f2dfce]'} >  Resell</Tab>
          <Tab className={({ selected }) => selected ? ' text-white border-[#f2dfce] border  rounded-r-sm bg-[#38455b] w-full py-2.5 text-sm leading-5 font-medium uppercase' : 'w-full py-2.5 text-sm text-gray-600 rounded-r-sm border-[#eac6b1] border leading-5 font-medium uppercase focus:ring-white  bg-[#f2dfce] '} >  Rental</Tab>
        </Tab.List>
        <Tab.Panels className="w-full justify-center flex bg-gradient-to-b">
          <div className="w-full max-w-7xl justify-center px-4 pt-4">
            {/* tab content */}
            <Tab.Panel>
              <RehabTab />
            </Tab.Panel>
            <Tab.Panel>
              <FlipTab setSelectedTab={setSelectedTab} />
            </Tab.Panel>
            <Tab.Panel>
              <RentalTab />
            </Tab.Panel>
          </div>
        </Tab.Panels>
      </Tab.Group>

      <CostEditModal />

    </div>
  )
}