import { ParcelHistory, RentalAppreciationGraph } from "components/proformas";
import { Loan } from "components/proformas/details";
import { useRef } from "react";
import { useSetVisibleSection } from "state";
import { useProforma } from "state/proformas";

export const PropertyData = () => {
  const { proforma } = useProforma();
  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "remarks");

  return (
    <div className="w-full max-w-7xl gap-4 px-4 sm:rounded-md xl:px-0" ref={ref}>
      <div className="justyfy-center flex w-full flex-col gap-6">
        {/*<KeyFinancials />*/}
        <div>
          <ParcelHistory history={proforma.parcel.listing_history} />
        </div>
        <div>
          <RentalAppreciationGraph />
        </div>
        <div>
          <Loan parcelId={proforma.parcel_id} />
        </div>
      </div>
    </div>
  );
};
