import { InformationCircleIcon } from "@heroicons/react/outline";
import { RehabInfoModal } from "components/proformas/financials/rehabtab/";
import { useState } from "react";
import NumberFormat from "react-number-format";
import ReactSlider from "react-slider";
import { useProforma } from "state/proformas";

const rehabGuideInfo = {
  titles: ["Maintenance", "Light Rehab", "Medium Rehab", "Heavy Rehab", "To the Studs"],
  descriptions: [
    "Get the property move-in ready with the essentials.",
    "Spruce up the interior with new floors, paint, and bathrooms.",
    "New kitchen, bathrooms, floors, doors and casings, and some minor exterior work.",
    "Replace all non-structural elements except roof, siding, drywall, and rough-in electrical/plumbing.",
    "Replace all non-structural elements.",
  ],
  lists: [
    ["Change locks", "Paint interior", "Replace carpets", "Clean house", "Replace appliances"],
    [
      "Replace electrical fixtures",
      "Paint interior and exterior",
      "Replace flooring",
      "Replace vanities ",
      "Replace appliances",
    ],
    [
      "Replace electrical fixtures",
      "Paint interior and exterior",
      "Replace doors and trim",
      "Replace flooring",
      "Recondition plumbing fixtures",
      "Replace cabinetry and countertops",
      "Replace appliances",
      "Light landscaping",
    ],
    [
      "Replace gutters and fascia",
      "Paint interior and exterior ",
      "Replace plumbing and electrical fixtures",
      "Replace doors and trim",
      "Replace windows and trim",
      "Replace flooring",
      "Replace cabinetry and countertops",
      "Replace appliances",
      "Heavy landscaping",
    ],
    [
      "Replace roofing and siding",
      "Paint interior and exterior",
      "Replace drywall and insulate",
      "Electrical and plumbing rough in",
      "Replace electrical and plumbing fixtures",
      "Replace doors and trim",
      "Replace windows, window fixtures and window trim",
      "Replace floors",
      "Replace cabinetry and countertops",
      "Replace appliances",
      "Heavy landscaping",
    ],
  ],
};

const sliderStepsPerIndex = 20;

export const RehabGuide = () => {
  const { proforma } = useProforma();
  const [guideIdx, setGuideIdx] = useState(4);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const numItems = rehabGuideInfo.titles.length;
  const ssf = proforma.parcel._characteristics.square_feet_finished;

  return (
    <div className="w-full">
      <div className="w-full pt-5 text-center text-sm font-bold uppercase">Rehab guides</div>
      <div className="py-3 text-sm">
        These guides show you potential scopes of work based on the property and area. The guides are just for
        reference. They do not affect the budgets in the yellow boxes.
      </div>
      <div className="w-full pb-4">
        <div className="w-full">
          <div className="text-md w-full pt-2 text-center font-bold">{rehabGuideInfo.titles[guideIdx]}</div>
          <div className="w-full py-1 text-center text-sm">
            {proforma.parcel._characteristics.year_built} build &bull; {proforma.structure.bathrooms} bath
            &bull;{" "}
            <NumberFormat
              value={proforma.structure.square_feet_finished}
              displayType="text"
              thousandSeparator={true}
            />{" "}
            sqft
            <InformationCircleIcon
              className="-mt-0.5 ml-2 inline h-5 w-5 cursor-pointer"
              aria-hidden="true"
              onClick={() => setShowInfoModal(true)}
            />
          </div>

          <ReactSlider
            className="mb-4 h-3 w-full"
            thumbClassName="w-6 h-6 rounded-full inline-block mr-3 cursor-pointer bg-white border-blue-500 border-4"
            trackClassName="h-1.5 mt-2 rounded-full rehab-guide-track"
            max={(numItems - 1) * sliderStepsPerIndex}
            defaultValue={guideIdx * sliderStepsPerIndex}
            onChange={(idx) => {
              setGuideIdx(Math.floor(idx / sliderStepsPerIndex));
            }}
          />

          <div className="text-md -mt-1 w-full text-center font-bold text-green-800">
            <NumberFormat
              value={proforma.package_amounts[guideIdx][0]}
              displayType="text"
              thousandSeparator={true}
              prefix="$ "
              decimalScale={0}
            />
            &nbsp;-&nbsp;
            <NumberFormat
              value={proforma.package_amounts[guideIdx][1]}
              displayType="text"
              thousandSeparator={true}
              prefix="$ "
              decimalScale={0}
            />
          </div>

          <div className="w-full py-1 text-center text-sm">
            <NumberFormat
              value={Math.floor(proforma.package_amounts[guideIdx][0] / ssf)}
              displayType="text"
              thousandSeparator={true}
              prefix="$ "
              decimalScale={2}
            />
            /sqft&nbsp;-&nbsp;
            <NumberFormat
              value={Math.ceil(proforma.package_amounts[guideIdx][1] / ssf)}
              displayType="text"
              thousandSeparator={true}
              prefix="$ "
              decimalScale={2}
            />
            /sqft
          </div>
        </div>
      </div>

      <div className="bg-[#f2dfce] p-4 px-4 text-left text-sm">
        <div className="pb-2">{rehabGuideInfo.descriptions[guideIdx]}</div>
        <ul className="list-outside list-disc pl-4">
          {rehabGuideInfo.lists[guideIdx].map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="font-gray-700 p-4 pb-0 text-center text-xs text-gray-600">
        These guides are computer generated. They should not substitute for getting bids from a contractor.
      </div>
      {showInfoModal && <RehabInfoModal closeModal={() => setShowInfoModal(false)} isOpen={showInfoModal} />}
    </div>
  );
};
