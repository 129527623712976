import { appConfig } from "config";
import { formatPropertyName } from "helpers";
import { ComparableBaseType } from "state/proformas/comparables";


export const makeCompMapIcon = (status: string) => {
  const green = "#70a08e",
    orange = "#F7931E",
    lightgray = "#eeeeee",
    lightblue = "#2196f3";

  let icon_color;

  switch (status) {
    case "highlighted":
      icon_color = lightblue;
      break;
    case "excluded":
      icon_color = lightgray;
      break;
    case "included":
      icon_color = green;
      break;
    case "subject":
      icon_color = orange;
      break;
    case "suggested":
      icon_color = green;
      break;
    default:
      icon_color = lightgray;
  }

  return {
    path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
        c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
        c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z`,
    fillColor: icon_color,
    fillOpacity: 1,
    strokeColor: "#808080",
    strokeWeight: 1,
    anchor: new google.maps.Point(15, 40),
    labelOrigin: new google.maps.Point(13.3, 13),
    scale: 0.8,
  };
};

export const makeCompMapLabel = (idx: number, status: string) => {
  if (status === "subject") {
    return {
      text: "•",
      color: "black",
      fontWeight: "800",
      fontSize: "16px",
    };
  }
  let textColor = "#A0A0A0";

  if (status === "included" || status === "suggested" || status === "highlighted") {
    textColor = "white";
  }

  return {
    text: String.fromCharCode("A".charCodeAt(0) + idx),
    color: textColor,
    fontWeight: "600",
    fontSize: "12px",
  };
};

export class CompMapService {
  comps: ComparableBaseType[];
  subjectProp: any;
  googleMap: google.maps.Map | null;
  markers: google.maps.Marker[] = [];

  constructor(comps: ComparableBaseType[], subjectProp: any) {
    this.comps = comps;
    this.subjectProp = subjectProp;
    this.googleMap = null;
  }

  createMap(domRef: HTMLDivElement) {
    let subjectPos = { lat: this.subjectProp.parcel.latitude, lng: this.subjectProp.parcel.longitude };

    const mapOptions = {
      scrollwheel: false,
      zoom: 12,
      center: subjectPos,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      rotateControl: true,

      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    };

    this.googleMap = new google.maps.Map(domRef, mapOptions);
    const bounds = new google.maps.LatLngBounds();

    this.makeMarkers(this.comps, bounds);

    if (!this.subjectProp.parcel.most_recent_listing?.dont_show_map_link) {
      const subjectMarker = new google.maps.Marker({
        position: subjectPos,
        icon: makeCompMapIcon("subject"),
        label: makeCompMapLabel(0, "subject"),
        title: formatPropertyName(this.subjectProp.parcel.street_address),
        map: this.googleMap,
        zIndex: 1000,
      });
      this.markers.push(subjectMarker);
    }
    bounds.extend(subjectPos);

    this.googleMap.fitBounds(bounds);
  }

  makeMarker(comp: ComparableBaseType, bounds: google.maps.LatLngBounds) {
    const position = new google.maps.LatLng(comp.parcel.latitude, comp.parcel.longitude);
    bounds.extend(position);

    const status = comp.status;
    const markerOptions = {
      position: position,
      title: formatPropertyName(comp.parcel.street_address),
      idx: comp._idx,
      parcel_id: comp.parcel._id,
      status: status,
      icon: makeCompMapIcon(status),
      label: makeCompMapLabel(comp._idx, status),
      map: this.googleMap,
    };
    const marker = new google.maps.Marker(markerOptions);
    marker.setVisible(!comp.parcel.most_recent_listing?.dont_show_map_link);
    return marker;
  }

  makeMarkers(comparables: ComparableBaseType[], bounds: google.maps.LatLngBounds) {
    comparables.forEach((comp) => {
      const marker = this.makeMarker(comp, bounds);
      this.markers.push(marker);
    });
  }

  highlightHandler(idx: number, highlighted: boolean) {
    this.markers[idx].setIcon(makeCompMapIcon(highlighted ? "highlighted" : this.comps[idx].status));
    this.markers[idx].setLabel(makeCompMapLabel(idx, highlighted ? "highlighted" : this.comps[idx].status));
    this.markers.forEach((marker, index) => {
      marker.setZIndex(idx === index ? 2000 : 1000 + index);
    });
  }

  createStaticMap(comp: ComparableBaseType) {
    const map = [
      "https://maps.googleapis.com/maps/api/staticmap?size=225x150",
      `key=${appConfig.googleMapsKey}`,
    ];
    const comp_icon =
      `http://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=${String.fromCharCode("A".charCodeAt(0) + comp._idx)}|5ec169|222222`
        .replace(/&/g, "%26")
        .replace(/\|/g, "%257C");

    const subj_icon = "http://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|F7931E|222222"
      .replace(/&/g, "%26")
      .replace(/\|/g, "%257C");

    map.push(`markers=icon:${comp_icon}%7C${comp.parcel.latitude},${comp.parcel.longitude}`);
    map.push(
      `markers=icon:${subj_icon}%7C${this.subjectProp.parcel.latitude},${this.subjectProp.parcel.longitude}`,
    );

    return map.join("&");
  }
}