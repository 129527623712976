import React from 'react';

type Props = {
  type: string;
  label: string;
  errMsg?: any;
  disabled?: boolean;
  value?: string;
};

export const LabeledInput = React.forwardRef((
  { type, label, errMsg, ...rest }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {

  return (
    <label className="block mt-3">
      <span className="text-gray-700 pl-3 text-sm">{label}</span>
      <input
        type={type}
        ref={ref}
        className={`mt-1 block w-full rounded-md
          border-gray-300
          shadow-sm
          focus:border-indigo-300
          focus:ring
          focus:ring-indigo-200
          focus:ring-opacity-50
          ${errMsg ? "border-red-500" : ""}
          `}
        {...rest}
      />
      {errMsg && <div className="text-red-500 text-sm mt-1">{errMsg}</div>}
    </label>
  );
});
