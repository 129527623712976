import { PhotographIcon } from "@heroicons/react/outline";
import { setShowPhotosModalIdxAtom } from "components/proformas";
import { StreetView } from "components/proformas/details";
import { useSetRecoilState } from "recoil";
import { EQUIVALENT_STRUCT_TYPES } from "state/browse/useFilters";
import { CustomStructureType, useCustomStructure, useProforma } from "state/proformas";

type Props = {
  compStats: any[];
  isRentalCompsViewAdjustEmpty: boolean;
};

export const RentalCompSubjectPropertyCard = ({ compStats, isRentalCompsViewAdjustEmpty }: Props) => {
  const { proforma } = useProforma();
  const { customStructure } = useCustomStructure(proforma);
  const isPhotosEmpty = proforma.photos.length === 0;
  const showPhotosModalIdx = useSetRecoilState(setShowPhotosModalIdxAtom);

  return (
    <div className="flex flex-row bg-blue-50 sm:shadow-lg">
      <div className="w-44 min-w-44 whitespace-nowrap p-3 sm:w-64 sm:min-w-64 sm:p-4">
        <div className="flex items-center whitespace-nowrap pb-[32.85px] text-sm font-bold">
          {" "}
          Subject Property
        </div>

        {proforma.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png" ? (
          <div>
            <StreetView
              otherClasses="flex cursor-pointer items-end rounded-sm bg-blue-300"
              sizeClasses="h-36 w-full"
              parcel={proforma.parcel}
              titles={false}
            />
          </div>
        ) : (
          <div
            className={`bg-[background-size: 100% 100%;] relative mt-2 flex h-36 w-full items-end rounded-sm bg-cover bg-center bg-no-repeat ${!isPhotosEmpty && "cursor-pointer"}`}
            style={{ backgroundImage: `url('${proforma.thumbnail_url}')` }}
            onClick={() => !isPhotosEmpty && showPhotosModalIdx(0)}
          >
            {!isPhotosEmpty && (
              <div className="absolute bottom-2 left-2 z-10 flex items-center text-xs text-white">
                <PhotographIcon className="mr-1 w-5" aria-hidden="true" />
                {proforma.photos.length}
              </div>
            )}
          </div>
        )}
        <div className="mt-14 text-xs leading-7">
          <div className="flex flex-row justify-items-stretch py-0">
            <div className="mt-2 w-1/2 text-left text-xs font-bold text-gray-700">Characteristics</div>
            <div className="mt-2 w-1/2 text-right text-xs font-bold text-gray-700">Subject</div>
          </div>

          {compStats.map((stat) =>
           (stat.key === "hoa_dues" &&
              (stat.subjectValue === "$0" || stat.subjectValue === 0 || stat.subjectValue === null)) ||
            (stat.key === "structure_style" &&
              !EQUIVALENT_STRUCT_TYPES.includes(proforma.parcel._characteristics.structure_type)) ||
            (stat.key === "view_type" && isRentalCompsViewAdjustEmpty) ? null : (
              <div
                key={stat.key}
                className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0"
              >
                <div className="w-1/2 whitespace-nowrap font-bold">{stat.name}</div>
                <div className="w-1/2 overflow-hidden text-ellipsis text-right">
                  {stat.subjectValue}
                  {customStructure[stat.key as keyof CustomStructureType] && (
                    <>
                      {stat.key === "view_type" ? (
                        <>
                          {customStructure.view_type && customStructure.view_type?.length > 0 && (
                            <> → {customStructure.view_type?.join(", ")}</>
                          )}
                        </>
                      ) : (
                        <> → {customStructure[stat.key as keyof CustomStructureType]}</>
                      )}
                    </>
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
