import { StarIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Spinner, WithSignIn } from "components";
import { PhotosTableDisclosure } from "components/browse";
import { buildProformasAddressUrl } from "helpers";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useSaveDeal } from "state/proformas";

const NUM_PHOTOS_TO_SHOW = 20;

type Props = {
  property: any;
};

export const PropertyPhotosTable = ({ property }: Props) => {
  const [saving, setSaving] = useState(false);
  const { isSavedDeal, saveDeal, deleteSavedDeal } = useSaveDeal();

  const propertyPhotos = property.photo_urls || [property.photo];

  const onSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    saveDeal(property._id, property.parcel_id).finally(() => setSaving(false));
  };

  const onUnSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    deleteSavedDeal(property.parcel_id).finally(() => setSaving(false));
  };

  return (
    <Link to={buildProformasAddressUrl(property.fullAddress)} target="_blank">
      <div className="flex min-w-full flex-col whitespace-nowrap rounded-lg border">
        <div className="flex h-56 flex-row">
          <div className="aspect-video py-4">
            <div className="relative w-full overflow-auto">
              {propertyPhotos.length > 0 && (
                <div className="styled-scrollbars relative flex w-full snap-mandatory snap-normal gap-4 overflow-x-auto">
                  {propertyPhotos.slice(0, NUM_PHOTOS_TO_SHOW).map((photo_url: string, idx: number) => (
                    <div
                      key={photo_url}
                      className="relative shrink-0 snap-center snap-normal first:pl-4 last:pr-8"
                    >
                      <LazyLoadImage
                        className="h-48 max-h-48 min-w-[320px] shrink-0 cursor-pointer rounded bg-white object-cover sm:h-96"
                        src={photo_url}
                        alt={`Property Photo ${idx + 1}`}
                      />
                      {idx === 0 && (
                        <>
                          <PhotosTableDisclosure property={property} />
                          <div className="absolute top-0 z-10 flex flex-col">
                            {property.sqftUnfinished > 0 && (
                              <div className="mx-2 mt-1 flex">
                                <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white opacity-60">
                                  Unfinished Sqft
                                </div>
                              </div>
                            )}
                            {property.listingType !== "Standard" && (
                              <div className="mx-2 mt-1 flex">
                                <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white opacity-60">
                                  {property.listingType}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="absolute right-0 top-0 z-10 mt-2 flex px-2 py-0">
                            <WithSignIn useChildren>
                              {saving ? (
                                <Spinner className="ml-2 h-5 w-5 text-white" />
                              ) : (
                                <>
                                  {isSavedDeal(property.parcel_id) ? (
                                    <SolidStarIcon
                                      onClick={onUnSaveProperty}
                                      className="align-right z-10 ml-2 h-5 w-5 text-white shadow-xl"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <StarIcon
                                      onClick={onSaveProperty}
                                      className="align-right z-10 ml-2 h-5 w-5 text-white shadow-xl"
                                      aria-hidden="true"
                                    />
                                  )}
                                </>
                              )}
                            </WithSignIn>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
