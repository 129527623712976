import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  registration: UseFormRegisterReturn<"hide_unknown_equity">;
};

export const HideUnknownEquityFilter = ({ registration }: Props) => {
  return (
    <div className="mt-2 flex w-full flex-row items-center justify-between gap-1 sm:gap-4">
      <div className="relative flex w-1/3 whitespace-nowrap"></div>
      <div className="flex w-2/3 flex-row items-center justify-start">
        <input
          id="hide_unknown_equity"
          className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
          type="checkbox"
          value="true"
          {...registration}
        />
        <label htmlFor="hide_unknown_equity" className="whitespace-pre text-left">
          Hide Unknown Equity
        </label>
      </div>
    </div>
  );
};
