import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type ParcelAgentType = {
  agent_page_url?: string;
  avatar: string;
  brokerage?: string;
  description?: string;
  email: string;
  name_first: string;
  name_last: string;
};

export const useParcelAgent = (parcelId?: number) => {
  const url = parcelId ? appConfig.apiEndpoint.parcelAgent.replace(":parcel_id", parcelId.toString()) : null;
  const { data, error } = useSWR<ParcelAgentType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error || Object.keys(data).length === 0 ? null : data;
};
