import { useEffect } from "react";
import { useFooter } from "state";
import { useUserSession } from "state";
import { useNavigate } from "react-router-dom";
import { appConfig } from "config";

export const Admin = () => {
  const { setShowFooter } = useFooter();
  const currentUser = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  useEffect(() => {
    if (!currentUser.isManagingAgent) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <iframe title="Admin" src={`${appConfig.apiBaseUrl}/admin/`} className="w-full">
      <p>Your browser does not support iframes!</p>
    </iframe>
  )
}
