import { Disclosure } from "@headlessui/react";
import {
  HamburguerButton,
  HamburguerPanel,
  Logo,
  NavBarAbout,
  NavBarSolutions,
  ProfileButton,
} from "components";
import { SearchBox } from "components/SearchBox";
import { cn } from "helpers";
import { useLocation } from "react-router-dom";

type Props = {
  notFixed?: boolean;
};

export const Nav = ({ notFixed }: Props) => {
  const location = useLocation();

  const isHome = location.pathname === "/";
  const isPropertyPage = location.pathname.includes("/proformas/");

  return (
    <Disclosure
      as="nav"
      className={cn(
        "z-40 h-14 w-full bg-[#f7faff] pt-1 text-gray-600",
        notFixed ? "bg-white" : "fixed top-0",
      )}
    >
      {({ open }) => (
        <>
          <div className="mx-auto pl-2 sm:px-4">
            <div className="relative flex h-12 items-center justify-between">
              {!(isHome || isPropertyPage) && <HamburguerButton open={open} />}
              <Logo />
              {!isHome && <SearchBox />}
              <div className="flex shrink-0 items-center justify-end">
                <div
                  className={cn(
                    isHome ? "flex" : "hidden md:flex",
                    "h-9 content-center pl-1 pr-1 pt-[1px] align-middle text-xs sm:pr-2",
                  )}
                >
                  <NavBarSolutions />
                  <NavBarAbout />
                </div>
                {(isHome || isPropertyPage) && <HamburguerButton open={open} />}
                <ProfileButton />
              </div>
            </div>
          </div>
          <HamburguerPanel />
        </>
      )}
    </Disclosure>
  );
};
