import { useEffect, useRef } from "react";
import { mdiHomeGroup, mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { MarketComparableType } from "components/markets";

type formattedResultType = {
  line1: string;
  line2: string;
  icon: string;
};

const formatOption = (item: MarketComparableType): formattedResultType => {
  const output: formattedResultType = {
    line1: "",
    line2: "",
    icon: "",
  };

  output.line1 = item.loc_name;

  switch (item.loc_type) {
    case "city":
      output.icon = mdiMapMarker;
      output.line2 = "City";
      break;
    case "neighborhood":
      output.line2 = "Neighborhood";
      output.icon = mdiHomeGroup;
      break;
    case "county":
      output.line2 = "County";
      output.icon = mdiMapMarker;
      break;
    case "zip_code":
      output.line2 = "ZIP code";
      output.icon = mdiMapMarker;
      break;
  }

  return output;
};

type Props = {
  item: MarketComparableType;
  onSelection: CallableFunction;
  selected: boolean;
};

export const SearchResultItem = ({ item, onSelection, selected }: Props) => {
  const option = formatOption(item);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  const handleClickOnItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelection(item);
  }

  return (
    <div
      className={`pl-2 pb-2 border-b cursor-pointer hover:bg-gray-100 first:border-t ${selected ? "bg-gray-200" : ""}`}
      onClick={handleClickOnItem}
      ref={ref}
    >
      <div className="flex">
        <div className="grow">
          <div className="font-bold pt-2">
            <Icon className="h-4 w-4 inline-block mr-2 text-slate-500" path={option.icon} />
            {option.line1}
          </div>
          <div className="text-xs ml-6 pb-1">
            {option.line2}
          </div>
        </div>
      </div>
    </div>
  )
}
