export { MainBody };

const MainBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <div id="main-wrapper" className="fixed w-full top-12 left-0 bottom-0 right-0">
      <div className="flex flex-wrap relative h-full">
        {children}
      </div>
    </div>
  );
};
