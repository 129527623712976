import { atom, useRecoilState } from "recoil";
import { RentalComparableType, structureTypes } from "state/proformas/comparables";

export const rentalCompFiltersMinMaxAttrs = [
  { label: "Bedrooms", key: "bedrooms", section: "structure" },
  { label: "Bathrooms", key: "bathrooms", section: "structure" },
  { label: "Finished Sqft", key: "square_feet_finished", section: "structure" },
  { label: "Lot Size Sqft", key: "lot_square_feet", section: "parcel" },
  { label: "Parking", key: "parking_count", section: "parcel" },
  { label: "Year Built", key: "year_built", section: "structure" },
  { label: "Rent Price", key: "rent", section: "monthly" },
  { label: "Price per Sqft", key: "price_per_square_foot", section: "sale" },
];

export type rentalCompsFiltersType = {
  structure_types: string[];
  min_bedrooms: number;
  max_bedrooms: number;
  min_bathrooms: number;
  max_bathrooms: number;
  min_square_feet_finished: number;
  max_square_feet_finished: number;
  min_lot_square_feet: number;
  max_lot_square_feet: number;
  min_parking_count: number;
  max_parking_count: number;
  min_year_built: number;
  max_year_built: number;
  min_monthly_rent: number;
  max_monthly_rent: number;
  min_price_per_square_foot: number;
  max_price_per_square_foot: number;
  distance: number;
};

export const buildRentalCompsFilters = (
  comparables: RentalComparableType[],
  subjectStructureType: string,
) => {
  const filters: any = {
    structure_types: structureTypes.filter((st) => st === subjectStructureType),
    distance: 0,
  };

  comparables.forEach((comp) => {
    rentalCompFiltersMinMaxAttrs.forEach((attr) => {
      if (attr.key !== "parking_count") {
        // parking_count is always empty in the old app
        const compValue = comp.parcel._characteristics[attr.key];
        const minKey = `min_${attr.key}`;
        if (filters[minKey] === undefined || filters[minKey] > compValue) {
          filters[minKey] = Math.floor(compValue);
        }
        const maxKey = `max_${attr.key}`;
        if (filters[maxKey] === undefined || filters[maxKey] < compValue) {
          filters[maxKey] = Math.ceil(compValue);
        }
      }
    });

    if (filters.distance < comp.distance) {
      // Pad the distance to defeat server-side rounding of included comps
      const distance = Math.floor((comp.distance + 0.01) * 100) / 100;
      filters.distance = distance;
    }
  });

  return filters;
};

export const rentalCompsFiltersAtom = atom<rentalCompsFiltersType | {}>({
  key: "rentalCompsFiltersAtom",
  default: {},
});

export const rentalCompsFiltersBackupAtom = atom<rentalCompsFiltersType | {}>({
  key: "rentalCompsFiltersBackupAtom",
  default: {},
});

export const useRentalCompsFilters = () => {
  const [rentalCompFilters, setRentalCompFilters] = useRecoilState(rentalCompsFiltersAtom);
  const [rentalCompFiltersBackup, setRentalCompFiltersBackup] = useRecoilState(rentalCompsFiltersBackupAtom);

  return {
    rentalCompFilters,
    setRentalCompFilters,
    rentalCompFiltersBackup,
    setRentalCompFiltersBackup,
  };
};
