import { useFinancials } from "state/proformas";
import NumberFormat from "react-number-format";
import sortBy from 'lodash/sortBy';
import { editCostModalAtom, setupCosts, getCostDetail, costNameDictionary } from "components/proformas/financials/";
import { useSetRecoilState } from 'recoil';

type Props = {
  costGroupName: "Expenses" | "Budgets",
};

export const RentalCostGroup = ({ costGroupName }: Props) => {
  const { financials } = useFinancials();

  const costGroup = financials?.calcsheet.is_brrr ?
    financials?.costgroups.rentalBrrr :
    financials?.costgroups.rentalHold;

  let costGroupData = sortBy(costGroup, '_display_order');

  const budgetsCGNames = ['maintenance_hold', 'maintenance_brrr', 'vacancy_hold', 'vacancy_brrr'];
  costGroupData = costGroupName === "Expenses" ?
    costGroupData.filter(cg => !budgetsCGNames.includes(cg.name)) :
    costGroupData.filter(cg => budgetsCGNames.includes(cg.name));

  const setEditCostModal = useSetRecoilState(editCostModalAtom);

  const totalCost = costGroupData.reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <div className="mb-8">
      <div className="flex flex-row justify-items-stretch gap-2 text-md leading-9 pb-2">
        <div className="whitespace-nowrap w-3/5 font-medium">
          {costGroupName}
        </div>
        <div className="whitespace-nowrap w-2/5 text-md text-right">
          <NumberFormat
            value={totalCost}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      {costGroupData?.map(cost => (
        <div key={cost.name} className="flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="whitespace-nowrap w-full">
            <div>
              {costNameDictionary[cost.name as keyof typeof costNameDictionary] || cost.name}
            </div>
            <div className="basis-3/5 text-xs text-gray-400">
              {getCostDetail(cost)}
            </div>
          </div>
          <div className="basis-2/5 text-right w-full">
            <div className="w-full p-1 items-center text-sm text-right text-black cursor-pointer  placeholder:text-gray-400 focus:border-blue-400 bg-stone-200  border outline-0 border-[#E4C442] focus:ring-0"
              onClick={() => setEditCostModal(setupCosts(cost, financials))}>
              <NumberFormat
                className="text-black cursor-pointer border-0 border-blue-700 placeholder:text-gray-400 focus:border-blue-400 pb-0.5"
                value={cost.amount}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
        </div>
      ))}

    </div>
  )
}