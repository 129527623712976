import { atom, useRecoilState } from "recoil";

const showRegisterClientModalAtom = atom({
  key: "showRegisterClientModalAtom",
  default: false,
});

export const useRegisterClientModal = () => {
  const [showRegisterClientModal, setShowRegisterClientModal] = useRecoilState(showRegisterClientModalAtom);

  return {
    openRegisterClientModal: () => setShowRegisterClientModal(true),
    closeRegisterClientModal: () => setShowRegisterClientModal(false),
    showRegisterClientModal,
  };
};
