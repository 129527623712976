import { atom, useRecoilState } from "recoil";

const showFooterAtom = atom({
  key: "showFooter",
  default: true,
});

export const useFooter = () => {
  const [showFooter, setShowFooter] = useRecoilState(showFooterAtom);
  return { showFooter, setShowFooter };
};

const showSelectedPropertyAtom = atom<any | null>({
  key: "showSelectedPropertyAtom",
  default: null,  // null | the whole property data
});

export const useSelectedProperty = () => {
  const [selectedProperty, setSelectedProperty] = useRecoilState(showSelectedPropertyAtom);
  return { selectedProperty, setSelectedProperty };
};
