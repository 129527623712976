import { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { MarketResearchMap } from "components/markets";
import { fetchMarketResearchData, useMarketResearchAvailability, fetchLocationPriceData } from "state/market";
import { NotFound } from "pages";
import {
  SearchMarketComparable,
  MarketResearchGraph,
  MarketComparableType,
  LocationPriceType,
  BestPerformingMarkets,
  WorstPerformingMarkets,
} from "components/markets";
import { CALIFORNIA_STATE_ID, FLORIDA_STATE_ID, WASHINGTON_STATE_ID } from "config";

const regions: Record<string, { name: string; stateId: number }> = {
  "southern-florida": {
    name: "Southern Florida",
    stateId: FLORIDA_STATE_ID,
  },
  seattle: {
    name: "Western Washington",
    stateId: WASHINGTON_STATE_ID,
  },
  "southern-california": {
    name: "Southern California",
    stateId: CALIFORNIA_STATE_ID,
  },
  "inland-empire": {
    name: "Inland Empire",
    stateId: CALIFORNIA_STATE_ID,
  },
  "san-diego": {
    name: "San Diego",
    stateId: CALIFORNIA_STATE_ID,
  },
};

export const fetchAllMarketResearchData = (locations: MarketComparableType[]) => {
  return Promise.all(
    locations.map((item) => fetchMarketResearchData(item.loc_type, item.loc_id, item.structureType!)),
  );
};

export const fetchAllLocationPriceData = (locations: LocationPriceType[]) => {
  return Promise.all(
    locations.map((item) => fetchLocationPriceData(item.loc_type, item.loc_id)),
  );
};

const getCompsFromParams = (params: URLSearchParams): MarketComparableType[] => {
  const comps = params.getAll("comps");
  if (!comps) {
    return [];
  }
  return comps.map((comp) => {
    const [loc_type, loc_id, structureType, loc_name] = comp.split("/");
    return {
      id: `${loc_type}/${loc_id}/${structureType}`,
      loc_type,
      loc_id: Number(loc_id),
      loc_name,
      structureType,
    };
  });
};

const getParamsFromComps = (comps: MarketComparableType[]) => {
  return {
    comps: comps.map((comp) => `${comp.loc_type}/${comp.loc_id}/${comp.structureType}/${comp.loc_name}`),
  };
};

export const MarketResearch = () => {
  const { region } = useParams<"region">();
  const [comparableItems, setComparableItems] = useState<MarketComparableType[]>([]);
  const locationAvailability = useMarketResearchAvailability(region || "seattle");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultCountiesMap: MarketComparableType[] = useMemo(
    () =>
      locationAvailability.counties_for_market.map((area) => ({
        id: `county/${area.id}/all`,
        loc_type: "county",
        loc_id: area.id,
        loc_name: `${area.name} County`,
        structureType: "all",
      })),
    [locationAvailability],
  );
  const defaultCounties = defaultCountiesMap.filter(function (item) {
    return false;
  });

  const compsFromParams = useMemo(() => getCompsFromParams(searchParams), [searchParams]);

  useEffect(() => {
    if (compsFromParams.length === 0 && defaultCounties.length > 0) {
      setSearchParams(getParamsFromComps(defaultCounties));
    }
  }, [compsFromParams.length, defaultCounties, setSearchParams]);

  useEffect(() => {
    if (compsFromParams.length > 0 && comparableItems.length === 0) {
      setLoading(true);
      fetchAllMarketResearchData(compsFromParams)
        .then((marketData) => {
          const newComparableItems = marketData.map((data, index) => ({
            ...compsFromParams[index],
            data,
          }));
          setComparableItems(newComparableItems);
          setSearchParams(getParamsFromComps(newComparableItems));
        })
        .finally(() => setLoading(false));
    }
  }, [compsFromParams, comparableItems.length, setSearchParams]);

  const onAddMarketComparable = (item: MarketComparableType) => {
    setLoading(true);
    fetchMarketResearchData(item.loc_type, item.loc_id, item.structureType!)
      .then((data) => {
        const newComparableItems = [...comparableItems, { ...item, data }];
        setComparableItems(newComparableItems);
        setSearchParams(getParamsFromComps(newComparableItems));
      })
      .finally(() => setLoading(false));
  };

  const onRemoveMarketComparable = (item: MarketComparableType) => {
    const newComparableItems = comparableItems.filter((i) => i.id !== item.id);
    setComparableItems(newComparableItems);
    setSearchParams(getParamsFromComps(newComparableItems));
  };

  if (!region || !regions[region]) return <NotFound />;

  return (
    <div className="align-center flex h-full w-full flex-col items-center p-4">
      <div className="w-full max-w-7xl bg-white sm:mt-10">
        <div className="flex w-full max-w-7xl flex-col gap-0 p-4 px-0 pt-4 sm:flex-row">
          <div className="basis-1/1 flex w-full flex-col pl-0 sm:basis-9/12 sm:pr-10">
            <div className="max-w-1/2 text-left font-serif text-2xl font-medium sm:text-4xl">
              {regions[region].name} Market Insights
            </div>

            <div className="mt-2 text-left font-light">
              <div className="text-md pt-0">
                Access the latest real estate activity and review trends from multi-year transaction data.{" "}
              </div>
            </div>

            <div className="my-8 w-full">
              <div className="text-md pb-2 font-bold ">Select locations to compare:</div>
              <SearchMarketComparable
                region={region}
                comparableItems={comparableItems}
                onAddMarketComparable={onAddMarketComparable}
                onRemoveMarketComparable={onRemoveMarketComparable}
                loading={loading}
              />
            </div>
            {comparableItems.length > 0 && (
              <div className="align-center mt-2 flex w-full flex-col items-center justify-center">
                <div className="my-4 w-full">
                  <MarketResearchGraph
                    marketResearchData={comparableItems}
                    valueName="median_value_per_sqft"
                    title="Price per Square Foot"
                  />
                </div>

                <div className="mt-4 flex w-full flex-col text-left  sm:flex-row sm:gap-20 sm:px-0">
                  <div className="basis-1/1 mb-4 sm:basis-1/2">
                    <MarketResearchGraph
                      marketResearchData={comparableItems}
                      valueName="n_price_reductions"
                      title="Price Reductions"
                    />
                  </div>

                  <div className="basis-1/1 mb-4 sm:basis-1/2">
                    <MarketResearchGraph
                      marketResearchData={comparableItems}
                      valueName="n_sales"
                      title="Sales"
                    />
                  </div>
                </div>

                <div className="flex w-full flex-col text-left sm:flex-row sm:gap-20 sm:px-0">
                  <div className="basis-1/1 mb-4 sm:basis-1/2">
                    <MarketResearchGraph
                      marketResearchData={comparableItems}
                      valueName="n_new_listings"
                      title="Listings"
                    />
                  </div>

                  <div className="basis-1/1 mb-4 sm:basis-1/2">
                    <MarketResearchGraph
                      marketResearchData={comparableItems}
                      valueName="sale_to_list_price_ratio"
                      title="Sale/List price ratio"
                    />
                  </div>
                </div>

                <div className="flex w-full flex-col text-left  sm:flex-row  sm:gap-20 sm:px-0">
                  <div className="basis-1/1 mb-4 sm:basis-1/2">
                    <MarketResearchGraph
                      marketResearchData={comparableItems}
                      valueName="median_days_on_market"
                      title="Median Days on Market"
                    />
                  </div>

                  {regions[region].stateId === 53 && (
                    <div className="basis-1/1 mb-4 sm:basis-1/2">
                      <MarketResearchGraph
                        marketResearchData={comparableItems}
                        valueName="n_canceled_listings"
                        title="Canceled Listings"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="basis-1/1 w-full rounded-lg  border border-stone-100 bg-stone-50 sm:basis-3/12">
            <div className="h-72 rounded-xl">
              <MarketResearchMap regions={defaultCountiesMap} defaultRegion={regions[region].stateId} />
            </div>

            <div className="p-4">
              <BestPerformingMarkets region={region} />

              <WorstPerformingMarkets region={region} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
