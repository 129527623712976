import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { useSearchParams } from "react-router-dom";
import { ProformaType } from "state/proformas";
import useSWR from "swr";

type SharingUserType = {
  avatar: string;
  email: string;
  name_first: string;
  name_last: string;
  phone_number: string;
  description: string;
  brokerage: string;
  roles: [{ name: string }];
};

export const useUserFromShareToken = (proforma: ProformaType) => {
  const [searchParams] = useSearchParams();
  const shareToken = searchParams.get("share");

  const url = shareToken ? appConfig.apiEndpoint.userFromToken.replace(":token", shareToken) : null;
  const { data: user, error } = useSWR<SharingUserType | null>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  let shareDate = null;
  if (proforma.created_on) shareDate = new Date(proforma.created_on).toLocaleDateString();
  if (proforma.updated_on) shareDate = new Date(proforma.updated_on).toLocaleDateString();

  const sharedByAgent = user && user.roles.some((role) => role.name === "agent");

  let arvEdited = false;
  if (proforma.calcsheet) arvEdited = proforma.calcsheet.pellego_arv !== proforma.calcsheet.resale_value;

  const sharingUser = !user || error ? null : user;

  return { sharingUser, shareDate, sharedByAgent, arvEdited };
};
