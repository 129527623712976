import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type CompAppreciationType = {
  appreciation: number[];
  geo_attribute_for_appreciation: string;
  structure_type_for_appreciation: string;
};

export const useCompAppreciation = (parcelId: number) => {
  const url = appConfig.apiEndpoint.compAppreciationGraph.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<CompAppreciationType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    data,
    error,
  };
};
