import {clsx, ClassValue} from 'clsx';
import { extendTailwindMerge } from "tailwind-merge";
import { withExtendedShadows } from "tailwind-extended-shadows-merge";

const twMerge = extendTailwindMerge(withExtendedShadows);

export const groupArray = (array: any[], groupSize: number, fillMissing: boolean = true) => {
  const groups: any[] = [];
  array.forEach((item, idx) => {
    const groupIdx = Math.floor(idx / groupSize);
    const rowIdx = idx % groupSize;
    if (rowIdx === 0) {
      groups[groupIdx] = Array(fillMissing ? groupSize : 0);
      if (fillMissing) groups[groupIdx].fill(undefined);
    }
    groups[groupIdx][rowIdx] = item;
  });
  return groups;
};

export const hasValue = (value: any) => {
  return value != null;
}

export function cn (...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}