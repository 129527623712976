import { Dot } from "components/icons";
import {
  formatPropertyName,
} from "helpers";
import Pluralize from "pluralize";
import TimeAgo from "react-timeago";

const customFormatter = (value: number, unit: string, suffix: string) => {
  return `${Pluralize(unit, value, true)}`;
};

const customFormatter2 = (value: number, unit: string, suffix: string) => {
  const unitMap: { [key: string]: string } = {
    second: "s",
    minute: "m",
    hour: "h",
    day: "d",
    week: "w",
    month: "m",
    year: "y",
  };
  return `${value}${unitMap[unit] || unit}`;
};

const colors: any = {
  active: "#34fd0f",
  pending: "#fce5cb",
  sold: "#999999",
  canceled: "#999999",
};

const buildAddress = (property: any) => {
  return (
    <>
      <span>{formatPropertyName(property.name)}</span>
      <span>
        , {property.city || property.county + " County"}
        <span className="mr-2">
          , {property.state_abbreviation} {property.zip_code}
        </span>
      </span>
    </>
  );
};

type Props = {
  property: any;
  selected: boolean;
  onChangeSelection: React.ChangeEventHandler<HTMLInputElement>;
};

export const SavedProperty = ({ property, selected, onChangeSelection }: Props) => {

  return (
    <>
      <td className="w-fit items-center py-2 px-1 sm:p-2">
        <input
          type="checkbox"
          className="border outline-0 indeterminate:bg-gray-300 focus:ring-0"
          checked={selected}
          onChange={onChangeSelection}
          onClick={(e) => e.stopPropagation()}
        />
      </td>
      <td className="w-fit whitespace-nowrap px-1 py-2 sm:p-2">
        <TimeAgo className="hidden sm:flex" date={new Date(property.last_updated)} live={false} formatter={customFormatter} />
        <TimeAgo className="flex sm:hidden pl-2" date={new Date(property.last_updated)} live={false} formatter={customFormatter2} />
      </td>
      <td className="max-w-[200px] sm:w-full grow px-1 py-2 sm:p-2">
        {property.name === "" ? "Unknown" : <div className="truncate">{buildAddress(property)}</div>}
      </td>
      <td className="flex w-fit min-w-3 shrink-0 flex-row items-center whitespace-nowrap px-1 py-2 sm:p-2">
        <Dot className={`size-2 text-[${colors[property.listing?.listing_status.toLowerCase()]}]`} />
        <span className="hidden pl-3 md:inline">{property.listing?.update_reason} (</span>
        <TimeAgo
          className="hidden md:inline"
          date={new Date(property.listing?.update_date)}
          live={false}
          formatter={customFormatter2}
        />
        <span className="hidden md:inline">)</span>
      </td>
    </>
  );
};
