import { appConfig } from "config";
import { MultiPolygon, Position } from "geojson";
import { fetcherWithCredentials } from "helpers";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchLocationAppreciations, fetchPolygons } from "state";
import { locationSearchItemsAtom } from "state/browse";

const fetchLocationAppreciationsFromSearchItems = async (locationSearchItems: any[]) => {
  let locationAppreciations = {};
  const locations: { type: string; id: number }[] = [];

  locationSearchItems.forEach((location) => {
    if (location.type === "city" || location.type === "county" || location.type === "metro_area") {
      locations.push({ type: location.type, id: Number(location.id!) });
    }
  });

  if (locations.length > 0) {
    const allLocationAppreciations = await fetchLocationAppreciations(locations);
    allLocationAppreciations.forEach((locationAppreciation) => {
      locationAppreciations = { ...locationAppreciations, ...locationAppreciation };
    });
  }

  return locationAppreciations;
};

export const useMapRequirements = (onlyAppreciation: boolean) => {
  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const [loading, setLoading] = useState(true);
  const [locationsPolygons, setLocationsPolygons] = useState<MultiPolygon[] | null>(null);
  const [locationAppreciations, setLocationAppreciations] = useState<Record<number, any> | null>(null);
  const [geoJsonPolygons, setGeoJsonPolygons] = useState<Position[][][] | null>(null);
  const [searchParams] = useSearchParams();
  const geoId = searchParams.get("geoId");
  const [loadingGeoJson, setLoadingGeoJson] = useState(!!geoId);

  useEffect(() => {
    if (locationSearchItems.length === 0) return;

    if (onlyAppreciation) {
      fetchLocationAppreciationsFromSearchItems(locationSearchItems).then((appreciationData) => {
        setLocationAppreciations(appreciationData);
        setLoading(false);
      });
    } else {
      const areas = locationSearchItems.map((item: any) => ({ type: item.type, id: item.id }));
      if (areas.length > 0) {
        fetchPolygons(areas)
          .then((polygons) => {
            setLocationsPolygons(polygons);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      if (geoId) {
        fetcherWithCredentials(`${appConfig.apiEndpoint.locationGeoJson}/${geoId}`)
          .then((polygons: MultiPolygon) => {
            setGeoJsonPolygons(polygons.coordinates);
          })
          .finally(() => {
            setLoadingGeoJson(false);
          });
      }
    }
  }, [locationSearchItems, onlyAppreciation, geoId]);

  return {
    loading: loading || loadingGeoJson,
    locationAppreciations,
    locationsPolygons,
    geoJsonPolygons,
    fetchLocationAppreciations: async () => fetchLocationAppreciationsFromSearchItems(locationSearchItems),
  };
};
