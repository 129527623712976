import useSWR from "swr";
import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";

type AgentType = {
  _id: number;
  agent_id: string;
  cell_phone_numbers?: string;
  current_brokerage: string;
  date_of_last_closing?: string;
  date_of_last_flip_sale?: string;
  date_of_last_rental_purchase?: string;
  email_addresses?: string;
  first_name: string;
  first_sale_date?: string;
  is_active: boolean;
  last_name: string;
  last_sale_date_at_prior_firm?: string;
  member_id: string;
  mls: string;
  n_active_listings?: number;
  n_brokerages?: number;
  n_pending_listings?: number;
  n_post_flip_listings?: number;
  n_post_flip_listings_last_year?: number;
  n_rental_purchases?: number;
  n_rental_purchases_last_year?: number;
  n_sales_buyside?: number;
  n_sales_sellside?: number;
  n_self_represented_listings?: number;
  n_transactions_historical?: number;
  n_transactions_last_year?: number;
  name: string;
  number_of_firms?: number;
  office_id: string;
  prior_firms: string[];
  state_license_id: string;
};

export type AgentPropertyType = {
  bedrooms: number;
  city?: string;
  county: string;
  latitude: number;
  listing_agent: string;
  listing_company: string;
  listing_price: number;
  listing_prices_dates: number[];
  listing_prices: number[];
  listing_source: string;
  listing_status: string;
  longitude: number;
  photos: string[];
  sale_date: string;
  sale_id: number;
  sale_price: number;
  selling_agent: string;
  selling_company: string;
  side: string;
  square_feet_finished: number;
  state_abbreviation: string;
  street_address_full: string;
  structure_type: string;
  update_date: string;
  update_reason: string;
};

export type ServiceAreaType = {
  id: number;
  type: string;
  name: string;
};

type AgentUserType = {
  avatar?: string;
  default_region?: number;
  description?: string;
  email: string;
  phone_number?: string;
  service_areas?: ServiceAreaType[];
  skills?: string[];
};

export type AgentDataType = {
  agent: AgentType;
  agent_user: AgentUserType;
  sold: AgentPropertyType[];
  listed: AgentPropertyType[];
};

export const useAgent = (agentSlug: string | undefined) => {
  const url = appConfig.apiEndpoint.agent.replace(":agent_slug", agentSlug || "");
  const { data, error } = useSWR<AgentDataType>(url, fetcherWithCredentials);

  return {
    loading: !data && !error,
    error,
    data,
  }
};
