import { mdiHomeGroup, mdiHomeOutline, mdiMapMarker } from "@mdi/js";
// import Icon from "@mdi/react";
import { stateIdToStateData } from "config";
import { cn, formatPropertyName, titleCase, TypeAheadItemType } from "helpers";
import { useEffect, useRef } from "react";

type formattedResultType = {
  line1: string;
  line2: string;
  icon: string;
};

const formatOption = (item: TypeAheadItemType): formattedResultType => {
  const output: formattedResultType = {
    line1: "",
    line2: "",
    icon: "",
  };

  if (item.name)
    output.line1 = `${titleCase(item.name)}, ${item.abbreviation ? `${item.abbreviation?.toUpperCase()}` : item.state_abbreviations ? `${item.state_abbreviations?.toUpperCase()}` : ""}`;
  if (item.state_name) output.line2 = `${titleCase(item.type)}`;

  switch (item.type) {
    case "city":
      output.line1 = `${titleCase(item.name)}, ${item.abbreviation?.toUpperCase()}`;
      output.icon = mdiMapMarker;
      break;
    case "neighborhood":
      output.line1 = `${titleCase(item.name)} (${titleCase(item.city_name)})`;
      output.icon = mdiHomeGroup;
      break;
    case "state":
      output.line2 = "State";
      output.icon = mdiMapMarker;
      break;
    case "county":
      output.icon = mdiMapMarker;
      break;
    case "zip_code":
      output.line2 = `ZIP code, ${titleCase(item.state_name)}`;
      output.icon = mdiMapMarker;
      break;
    case "metro_area":
      // check if output.line1 contains a '-' and if so, title case every dashed word
      if (output.line1.includes("-")) {
        const words = output.line1.split("-");
        output.line1 = words.map((word) => titleCase(word)).join("-");
      }
      // make to uppercase the last word of the line1
      const words = output.line1.split(" ");
      words[words.length - 1] = words[words.length - 1].toUpperCase();
      output.line1 = words.join(" ");
      output.line2 = `Metro Area`;
      output.icon = mdiMapMarker;
      break;
    case "parcel":
      output.line1 = formatPropertyName(titleCase(item.street_address));
      output.line2 = item.city_name
        ? `${titleCase(item.city_name)}`
        : `${titleCase(item.county_name)} County`;
      output.line2 += item.state_id
        ? `, ${stateIdToStateData[item.state_id as keyof typeof stateIdToStateData].code}`
        : (output.line2 += `, ${titleCase(item.state_name)}`);
      output.line2 += ` ${item.zip_code}`;
      output.icon = mdiHomeOutline;
      break;
  }

  return output;
};

type Props = {
  item: TypeAheadItemType;
  onSelection: CallableFunction;
  selected: boolean;
  currentStateId: number | null;
};

export const SearchResultItem = ({ item, onSelection, selected, currentStateId }: Props) => {
  const option = formatOption(item);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  const handleClickOnItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelection(item, currentStateId === item.state_id);
  };

  return (
    <div
      className={cn("group cursor-pointer pl-2 hover:bg-black", selected && "bg-black")}
      onClick={handleClickOnItem}
      ref={ref}
    >
      <div className="flex">
        <div className={cn("grow group-hover:text-white", selected && "text-white")}>
          <div className="pt-2">
            {/* <Icon className="mr-2 inline-block h-4 w-4 text-slate-500" path={option.icon} /> */}
            {option.line1}
          </div>
          <div className="pb-1">{option.line2}</div>
        </div>
      </div>
      {currentStateId !== null && currentStateId !== item.state_id && (
        <div className={cn("pb-1 text-xs text-blue-800 group-hover:text-blue-200", selected && "text-blue-200")}>
          Warning: region too far.
        </div>
      )}
    </div>
  );
};
