import { ModalWrapper } from "components";
import { XIcon } from '@heroicons/react/solid'
import { ChartBarIcon } from '@heroicons/react/outline'
import { GraphTypes } from "components/proformas/financials/rentaltab/";

const HelpText: Record<GraphTypes, { title: string, body: string }> = {
  "equity": {
    title: "EQUITY GRAPH",
    body: "This graph shows the property value of the deal over time. The net equity is also shown over time, and is a combined total of the cash in, selling costs, and loan balance. Hovering over any point on the graph will display a tooltip showing the specific values for that particular month.",
  },
  "monthlycashflow": {
    title: "MONTHLY CASHFLOWS GRAPH",
    body: "This graph shows a snapshot of the rent, costs, profit, and cash flow on cash rate for any month during the holding period of the property. Hovering over any point on the graph will display a tooltip showing the specific values for that particular month.",
  },
  "netprofit": {
    title: "CUMULATIVE PROFIT GRAPH",
    body: "This graph shows the net equity value in relation to the cashflow profits(loss) for the subject property during the holding time. The net equity includes the cash in, selling costs, and loan balance for the property that year. The cash flows represent all rent revenue, less any holding or loan costs. Hovering over any point on the graph will display a tooltip showing the specific values for that particular year.",
  },
  "rates": {
    title: "RATES OF RETURN GRAPH",
    body: "This graph shows various investment and rates of return ratios to review the subject property's investment potential. Hovering over any point on the graph will display a tooltip showing the specific values for that particular year.",
  },
}



type Props = {
  isOpen: boolean;
  closeModal: any;
  graphType: GraphTypes;
};

export const HelpModal = ({ isOpen, closeModal, graphType }: Props) => {

  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block w-full max-w-xl h-full max-h-fit px-0 pt-14 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-md mt-4">

        <div className="flex justify-center w-full h-full  max-h-[90%]">

          <div className="w-full max-w-5xl rounded-md shadow-lg bg-white">
            <div className="h-full flex flex-col">
              <header className=" flex flex-row w-full items-center">
                <div className="basis-4/5 pl-8 text-md font-medium text-gray-700 flex items-center">
                  <ChartBarIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
                  {HelpText[graphType].title}
                </div>
                <div className="basis-1/5 p-4 justify-right text-right pr-4">
                  <button
                    type="button"
                    className="pl-1 pb-1 text-sm font-medium text-gray-400 rounded-md hover:bg-gray-400 hover:text-white hover:border-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}>
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="bg-gray-50 h-64 p-8 flex flex-col flex-grow pt-4">
                {HelpText[graphType].body}
              </main>
              <footer className="p-4 text-center mb-2 font-bold bg-gray-50 ">
                <button
                  type="button"
                  className=" items-center sm:h-10 px-4 sm:px-8 py-2 font-normal text-sm rounded-sm text-white bg-[#61a28d] hover:bg-[#5bbc9c] transition ease-in-out duration-150 shadow-lg"
                  onClick={closeModal}
                >
                  CLOSE
                </button>
              </footer>
            </div>

          </div>

        </div>

      </div>
    </ModalWrapper>
  );
}