import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LabeledInput, SubmitButton } from "components/forms";
import { Alert } from "components";
import { useUserActions } from "actions";

export const ChangePassword = ({ onClose }: { onClose: CallableFunction }) => {
  const userActions = useUserActions();

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Your current password is required.")
      .min(6, "Password must be at least 6 characters."),
    new_password: Yup.string().required("Enter your new password")
      .min(6, "Password must be at least 6 characters."),
    new_password_confirm: Yup.string().required("Enter your new password again")
      .oneOf([Yup.ref("new_password")], "Passwords must match."),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors, isSubmitting, isSubmitSuccessful } = formState;

  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <div>
      <div className="grid grid-cols-1 m-6">
        <Alert />
        <form onSubmit={handleSubmit(userActions.changePassword)}>
          <LabeledInput type="password" label="Current password"
            {...register("password")} errMsg={errors.password?.message} />
          <LabeledInput type="password" label="New password"
            {...register("new_password")} errMsg={errors.new_password?.message} />
          <LabeledInput type="password" label="Re-enter new password"
            {...register("new_password_confirm")} errMsg={errors.new_password_confirm?.message} />

          <div className="w-full text-center">
            <SubmitButton
              isSubmitting={isSubmitting}
              isSuccessful={isSubmitSuccessful && !isSubmitting}
            >
              Change password
            </SubmitButton>
          </div>
        </form>
      </div>
    </div>
  )
}
