import { useFinancials } from "state/proformas";
import NumberFormat from "react-number-format";


export const RentalCard = () => {
  const { financials, updateCalcSheet } = useFinancials();

  if (!financials) return null;

  const expenses = financials.rental.monthlyCosts -
    financials.rental.monthlyLoanPmt -
    financials.rental.budget;

  const operatingIncome = financials.rental.monthlyRent * (1 - financials.rental.vacancyRate);

  const operatingCosts = financials.strategy_rental === "financed_hold" ?
    financials.costgroupTotals.operatingHold :
    financials.costgroupTotals.operatingBrrr;

  const onUpdateMonthlyRent = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ''));
    const fieldsToUpdate = financials.calcsheet.is_brrr ? { monthly_rent_brrr: val } : { monthly_rent_hold: val };
    updateCalcSheet(fieldsToUpdate);
  }

  return (
    <div className="border rounded-lg border-gray-300 bg-gray-50 p-6 shadow-lg">

      {/* card content */}
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9 pb-1">
        <div className="whitespace-nowrap basis-3/5 font-bold">Monthly Rent</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
            value={financials.rental.monthlyRent}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onBlur={onUpdateMonthlyRent}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                onUpdateMonthlyRent(e);
              }
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9 pt-1">
        <div className="whitespace-nowrap basis-3/5">
          Expenses
        </div>
        <div className="w-full basis-2/5 text-right pr-1 border-t border-gray-600">
          <NumberFormat
            value={expenses}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="whitespace-nowrap w-4/6">
          Budget
        </div>
        <div className="w-full text-right pr-1">
          <NumberFormat
            value={financials.rental.budget}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9 pb-4">
        <div className="whitespace-nowrap w-4/6">
          Mortgage
        </div>
        <div className="w-full basis-2/5 text-right pr-1 border-b border-gray-600 pb-1">
          <NumberFormat
            value={financials.rental.monthlyLoanPmt}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-3 pb-2">
        <div className="whitespace-nowrap basis-3/5 font-bold">
          Cash Flow:
        </div>
        <div className="w-full basis-2/5 text-right pr-1 font-bold">
          <NumberFormat
            value={financials.rental.monthlyProfit}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="border-b pb-4 mb-3"></div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="whitespace-nowrap w-4/6">
          Operating Income
        </div>
        <div className="w-full text-right pr-1">
          <NumberFormat
            value={12 * operatingIncome}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="whitespace-nowrap w-4/6">
          Operating Costs
        </div>
        <div className="w-full text-right pr-1  basis-2/5 border-b pb-2 border-gray-600">
          <NumberFormat
            value={operatingCosts * 12}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9 pt-2">
        <div className="whitespace-nowrap w-4/6 font-bold">
          Net Operating Income
        </div>
        <div className="w-full text-right pr-1 font-bold">
          <NumberFormat
            value={(operatingIncome - operatingCosts) * 12}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-6 pb-2">
        <div className="whitespace-nowrap w-4/6">
          Purchase Price:
        </div>
        <div className="w-full text-right pr-1">
          <NumberFormat
            value={financials.calcsheet.purchase_price}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm">
        <div className="whitespace-nowrap w-4/6">
          <div className="font-bold">
            Cap rate:
          </div>
        </div>
        <div className="w-full text-right font-bold items-centerpr-1">
          <NumberFormat
            value={financials.rental.capRate}
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            suffix="%"
          />
        </div>
      </div>

    </div>
  )
}
