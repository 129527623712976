import {
  AnnounceWidget,
  RegisterClientModal,
  RequireAuth,
  RequireNoAuth,
  SignInSignUpDialog,
} from "components";
import { Agent } from "components/agents";
import { LOTSIDE_TRACKING_ID, PathTracker } from "GoogleAnalytics";
import {
  Account,
  Admin,
  ConfirmEmail,
  Home,
  Layout,
  MarketResearch,
  NotFound,
  PasswordForgotten,
  PasswordReset,
  Proformas,
  RegisterClient,
  SignIn,
  SignUp,
} from "pages";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import "./App.css";
import { Browse } from "components/browse";

ReactGA.initialize(LOTSIDE_TRACKING_ID);

export const App = () => {

  const dumpRecoilState = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        for (const node of snapshot.getNodes_UNSTABLE() as Array<any>) {
          const value = await snapshot.getPromise(node);
          console.debug(`%c${node.key}:`, "font-weight: bold", value);
        }
      },
    [],
  );

  const [showAnnounceWidget, setShowAnnounceWidget] = useState(window.innerWidth > 768); // Assuming md breakpoint is 768px

  useEffect(() => {
    const handleResize = () => {
      setShowAnnounceWidget(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const onKeyDown = (e: any) => {
      if (e.key === "d" && e.ctrlKey) {
        e.preventDefault();
        dumpRecoilState();
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [dumpRecoilState]);

  return (
    <div className={"app-container h-screen w-full"}>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PathTracker />
                  <Layout />
                </>
              }
            >
              <Route
                path="/"
                element={
                  <>
                    <Home />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/market-research/:region"
                element={
                  <>
                    <MarketResearch />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/password-reset"
                element={
                  <>
                    <PasswordForgotten />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/reset/:token"
                element={
                  <>
                    <PasswordReset />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/confirm"
                element={
                  <>
                    <ConfirmEmail />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <SignIn />
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/ws-registration"
                element={
                  <>
                    <RequireAuth>
                      <RegisterClient />
                    </RequireAuth>
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/signup"
                element={
                  <>
                    <RequireNoAuth>
                      <SignUp />
                    </RequireNoAuth>
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/account"
                element={
                  <>
                    <RequireAuth>
                      <Account />
                    </RequireAuth>
                    <AnnounceWidget />
                  </>
                }
              />
              <Route
                path="/admin"
                element={
                  <RequireAuth>
                    <Admin />
                  </RequireAuth>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <NotFound />
                    <AnnounceWidget />
                  </>
                }
              />
            </Route>
            <Route
              path="/browse"
              element={
                <>
                  <PathTracker />
                  <Browse />
                  <SignInSignUpDialog />
                  <RegisterClientModal />
                </>
              }
            />
            <Route
              path="/proformas/address/:address"
              element={
                <>
                  <PathTracker />
                  <Proformas proforma_type="flip_rental" />
                  {showAnnounceWidget && <AnnounceWidget />}
                </>
              }
            />
            <Route
              path="/proformas/listing_number/:listing_number"
              element={
                <>
                  <PathTracker />
                  <Proformas proforma_type="flip_rental" />
                  {showAnnounceWidget && <AnnounceWidget />}
                </>
              }
            />
            <Route
              path="/proformas/address/new_construction/:address"
              element={
                <>
                  <PathTracker />
                  <Proformas proforma_type="new_construction" />
                  {showAnnounceWidget && <AnnounceWidget />}
                </>
              }
            />
            <Route
              path="/agents/:agentSlug"
              element={
                <>
                  <PathTracker />
                  <Agent />
                  <AnnounceWidget />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};
