import { useState } from "react";
import { Chart } from "react-google-charts";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { RentalGraphClass, GraphTypes, HelpModal } from "components/proformas/financials/rentaltab/";

type Props = {
  graphType: GraphTypes;
  name: string;
  mathService: any;
}

export const RentalGraph = ({ graphType, name, mathService }: Props) => {
  const rentalChart = new RentalGraphClass(mathService, graphType);
  const [showHelp, setShowHelp] = useState(false);

  const closeHelpModal = () => {
    setShowHelp(false);
  };

  return (
    <div className="py-0 w-full">
      <div className="text-md font-bold py-4 ">
        <div className="basis-1/1 flex w-full">
          <div className="basis-1/2 w-full">{name}</div>
          <div
            className="basis-1/2 w-full text-right justify-end flex pr-5"
            onClick={() => setShowHelp(true)}
          >
            <QuestionMarkCircleIcon className="w-4 text-gray-400 cursor-pointer" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="p-0 w-full">
        <Chart
          chartType={rentalChart.chartType}
          data={rentalChart.data}
          options={rentalChart.options}
        />
      </div>
      {showHelp && <HelpModal closeModal={closeHelpModal} isOpen={showHelp} graphType={graphType} />}
    </div>
  )
}
