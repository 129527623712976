import { buildCompDiffs } from "state/proformas/comparables";
import NumberFormat from "react-number-format";

type Props = {
  costDiffs: any;
  compChars: any;
  subjectChars: any;
};

export const AdjustedValues = ({ costDiffs, compChars, subjectChars }: Props) => {
  const comparableDiffs = buildCompDiffs(costDiffs, subjectChars, compChars);

  return (
    <div className="flex flex-row text-xs leading-6 w-full">
      <div className="flex flex-col text-xs leading-6 basis-2/5">
        {comparableDiffs.map((diff) => (
          <div key={`${diff.name}-a`} className="text-ellipsis overflow-hidden whitespace-nowrap  pr-2 text-gray-700 font-bold text-xs text-left border-b border-gray-200 py-1 capitalize w-full max-w-32 sm:max-w-48 ">{diff.name}</div>
        ))}
      </div>
      <div className="flex flex-col text-xs leading-6 basis-1/5">
        {comparableDiffs.map((diff) => (
          <div key={`${diff.name}-b`} className="pr-2 text-gray-700 text-xs text-left border-b border-gray-200 py-1">&nbsp;</div>
        ))}
      </div>

      <div className="flex flex-col text-xs text-gray-700 leading-6 basis-1/5 text-right">

        {comparableDiffs.map((diff) => (
          <div key={`${diff.name}-c`} className="flex flex-row w-full justify-items-stretch py-1 gap-2 border-b border-gray-200">
            <div className="whitespace-nowrap text-xs capitalize">
              {diff.delta} {diff.pct}
            </div>
          </div>
        ))}

      </div>
      <div className="flex flex-col text-xs text-gray-700 leading-6 basis-1/5 text-right">

        {comparableDiffs.map((diff) => (
          <div key={`${diff.name}-d`} className="flex text-right w-full flex-row justify-items-stretch py-1 gap-2 border-b border-gray-200">
            <div className="font-bold text-right w-full text-xs text-gray-900  whitespace-nowrap">
              <NumberFormat value={diff.amount} displayType="text" thousandSeparator={true} prefix="$ " decimalScale={0} />
            </div>
          </div>
        ))}

      </div>

    </div>
  );
}