import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { cn } from "helpers";
import { useEffect, useRef, useState } from "react";

type Props = {
  clampLines: number;
  className?: string;
  children: React.ReactNode;
  buttonClass?: string;
  iconSize?: number;
};

export const ShowMore = ({ clampLines, className, buttonClass, iconSize = 4, children }: Props) => {
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  let klass = className || "";
  if (!showAll) {
    klass += ` line-clamp-${clampLines}`;
  }

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      if (el.offsetHeight < el.scrollHeight) {
        setShowMoreEnabled(true);
      }
    }
  }, []);

  return (
    <>
      <div className={klass} ref={ref}>
        {children}
      </div>
      {showMoreEnabled && (
        <>
          {showAll && (
            <button
              className={cn("mt-1 flex items-center text-sm text-gray-400", buttonClass)}
              onClick={() => setShowAll(false)}
            >
              <ChevronUpIcon className="mr-1 inline size-4 text-gray-400" /> Show less{" "}
            </button>
          )}
          {!showAll && (
            <button
              className={cn("mt-1 flex items-center text-sm text-gray-400", buttonClass)}
              onClick={() => setShowAll(true)}
            >
              <ChevronDownIcon className={`mr-1 inline size-${iconSize} text-gray-400`} /> Continue reading{" "}
            </button>
          )}
        </>
      )}
    </>
  );
};
