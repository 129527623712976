import { Dialog, Transition } from "@headlessui/react";
import { cn } from "helpers";
import { Fragment, ReactNode } from "react";

type Props = {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  overlayOpacity?: number;
};

export const ModalWrapper = ({ className, isOpen, closeModal, children, overlayOpacity }: Props) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cn("fixed inset-0 z-[9990] overflow-y-auto", className)}
        onClose={closeModal}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={`fixed inset-0 bg-black opacity-${overlayOpacity || "50"}`} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
