import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { isDesktop } from "react-device-detect";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  value: boolean;
  registration: UseFormRegisterReturn<"assumable_loan">;
  handleReset: CallableFunction;
  tag?: "new" | null;
};

export const FilterLoanMenu = ({ value, registration, handleReset, tag }: Props) => {
  return (
    <>
      {isDesktop && (
        <Menu as="div" className={`relative ml-2 mr-1 hidden md:mx-2 filter-loan:flex`}>
          {({ open }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between gap-2 rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  value ? "bg-black text-white" : "text-gray-600"
                }`}
              >
                <span className="flex w-16 text-wrap text-center leading-none">Assumable Loan(s)</span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {value ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReset();
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="ml-2 h-4 w-4 text-black" />
                    )}
                  </>
                )}
              </Menu.Button>

              {/* Tag. Another tags types can be added in the future */}
              {!value && tag === "new" && (
                <div className="absolute -end-1 -top-2 inline-flex h-4 w-8 items-center justify-center rounded-sm border-red-500 bg-red-500 text-[10px] font-bold text-white">
                  NEW
                </div>
              )}

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute top-8 mt-2 w-fit origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item
                    as="div"
                    className={`m-1 my-2 flex cursor-pointer flex-row items-center justify-between gap-2 px-3 text-sm ${
                      value
                        ? "bg-[#6389c4] text-white"
                        : "bg-white text-gray-600 hover:bg-[#6389c4] hover:text-white"
                    }`}
                  >
                    <input
                      id="assumable_loan"
                      className="flex cursor-pointer appearance-none border outline-0 checked:bg-transparent checked:text-gray-700 indeterminate:bg-gray-300 hover:checked:bg-transparent focus:ring-0"
                      type="checkbox"
                      value="true"
                      {...registration}
                    />
                    <label
                      htmlFor="assumable_loan"
                      className="inline-flex cursor-pointer whitespace-pre text-left"
                    >
                      Has Assumable Loan(s)
                    </label>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
