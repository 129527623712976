import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  CompModal,
  compModalShowIdxAtom,
  CompPropertyCard,
  CompSubjectPropertyCard,
} from "components/proformas/comparables/";
import { formatCurrency } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useUserFromShareToken } from "state";
import { useProforma } from "state/proformas";
import { filteredCompStats, SalesComparableType } from "state/proformas/comparables";

type Props = {
  comparables: SalesComparableType[];
  toggleComparableStatus: CallableFunction;
};

const tallyTotalScore = (comparables: SalesComparableType[]) => {
  let totalScore = 0;
  // Total score is for included ones only
  comparables.forEach((comp) => {
    if (comp.is_included) {
      totalScore += comp.score;
    }
  });
  return totalScore;
};

const averageAdjustedValueIncludedOnly = (comparables: SalesComparableType[]) => {
  const includedComps = comparables.filter((comp) => comp.is_included);
  const numIncludedComps = includedComps.length;
  if (numIncludedComps === 0) return 0; // Avoid division by zero

  let totalAdjustedValue = includedComps.reduce((acc, comp) => acc + comp.projected_arv, 0);
  return totalAdjustedValue / numIncludedComps;
};

const medianAdjustedValueIncludedOnly = (comparables: SalesComparableType[]) => {
  const includedArvValues = comparables
    .filter((comp) => comp.is_included)
    .map((comp) => comp.projected_arv)
    .sort((a, b) => a - b);

  const numValues = includedArvValues.length;
  if (numValues === 0) return 0; // Handle case with no included comparables

  const middleIndex = Math.floor((numValues - 1) / 2);
  if (numValues % 2) {
    // If odd, return the middle value
    return includedArvValues[middleIndex];
  } else {
    // If even, return the average of the two middle values
    return (includedArvValues[middleIndex] + includedArvValues[middleIndex + 1]) / 2;
  }
};

const isCompsViewAdjustEmpty = (comparables: SalesComparableType[]) => {
  return comparables.every((comp) => !comp.cost_diffs.view_type || comp.cost_diffs.view_type === "");
};

export const CompProperties = ({ comparables, toggleComparableStatus }: Props) => {
  const { proforma } = useProforma();
  const { sharingUser, shareDate } = useUserFromShareToken(proforma);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 480;
  };

  const setCompModalShowIdx = useSetRecoilState(compModalShowIdxAtom);
  const compModalShowIdx = useRecoilValue(compModalShowIdxAtom);
  const compStats = filteredCompStats(proforma);
  const totalScore = tallyTotalScore(comparables);
  const isCompsViewAdjustmentEmpty = isCompsViewAdjustEmpty(comparables);

  const letterToIndex = (letter: string) => {
    return letter.charCodeAt(0) - "a".charCodeAt(0);
  };
  useEffect(() => {
    if (window.location.hash && window.location.hash.startsWith("#comp_")) {
      const compIndexLetter = window.location.hash.slice(-1);
      const compIndex = letterToIndex(compIndexLetter);
      if (compIndex >= 0 && compIndex < comparables.length) {
        setCompModalShowIdx(compIndex);
      }
    }
  }, [setCompModalShowIdx, comparables]);

  return (
    <div className="sm:mt-6 sm:basis-2/4">
      <div className="mb-4 text-center text-sm font-light text-black">
        {sharingUser && (
          <>
            Comps edited by {sharingUser.name_first} {sharingUser.name_last}
            {shareDate ? ` on ${shareDate}. ` : ". "}
          </>
        )}
        Value adjustments are computer generated by Lotside.
      </div>

      <div className="flex w-full flex-row">
        {/*  sale comps card  */}
        <CompSubjectPropertyCard
          compStats={compStats}
          isCompsViewAdjustmentEmpty={isCompsViewAdjustmentEmpty}
          average={averageAdjustedValueIncludedOnly(comparables)}
          median={medianAdjustedValueIncludedOnly(comparables)}
        />

        <div
          className="group relative flex w-screen overflow-x-auto"
          onScrollCapture={() => {
            comparables.length > 1 && handleScroll();
          }}
        >
          {!isScrolling && comparables.length > 1 && (
            <>
              {scrollContainerRef.current && scrollContainerRef.current.scrollLeft > 0 && (
                <ChevronLeftIcon
                  className="invisible absolute left-1 top-60 z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                  onClick={slideLeft}
                />
              )}
              {scrollContainerRef.current &&
                scrollContainerRef.current.scrollLeft <=
                  scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth - 1 && (
                  <ChevronRightIcon
                    className="invisible absolute right-1 top-60 z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                    onClick={slideRight}
                  />
                )}
            </>
          )}
          <div
            ref={scrollContainerRef}
            className="relative flex w-full snap-mandatory snap-normal gap-4 overflow-x-auto scroll-smooth"
          >
            {" "}
            {comparables.map((comp) => (
              <CompPropertyCard
                key={comp.parcel._id}
                comparable={comp}
                compStats={compStats}
                totalScore={totalScore}
                subjectProperty={proforma}
                isCompsViewAdjustmentEmpty={isCompsViewAdjustmentEmpty}
              />
            ))}
            {/*  additional comps card to balance spacing on scroll */}
            <div className="flex snap-start snap-normal flex-row first:pl-4">
              <div className="w-2 whitespace-nowrap p-3 pr-0 sm:p-4"></div>
            </div>
          </div>
        </div>
      </div>

      {compModalShowIdx !== null && (
        <CompModal
          isOpen={compModalShowIdx !== null}
          comparables={comparables}
          compStats={compStats}
          totalScore={totalScore}
          toggleComparableStatus={toggleComparableStatus}
        />
      )}
      <div className="p-4 pb-0 text-center text-sm font-light text-black">
        The selected comps have a median adjusted value of{" "}
        {formatCurrency(medianAdjustedValueIncludedOnly(comparables), 0)} and an average adjusted value of{" "}
        {formatCurrency(averageAdjustedValueIncludedOnly(comparables), 0)}.
      </div>
    </div>
  );
};
