import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { DetailsList } from "components/proformas/details";
import { formatCurrency, groupArray } from "helpers";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSetVisibleSection } from "state";
import { ProformaType, useCustomStructure, useProforma } from "state/proformas";

const cleanup = (value: string | null | undefined) => {
  if (!value) return "";
  return value.replace(/["{}]/g, "").replace(/,/g, ", ");
};

export const getUnitSections = (proforma: ProformaType) => {
  const unitSections = [];
  const multifamily = proforma?.structure?.multifamily_unit_information;
  if (multifamily && multifamily.length > 0) {
    var units = proforma.structure.multifamily_unit_information;
    for (var idx = 0; idx < units.length; idx++) {
      unitSections.push(`Unit ${idx + 1}`);
    }
  }
  return unitSections;
};

const getWaterType = (value: any) => {
  if (Array.isArray(value)) {
    return value.map((v) => cleanup(v)).join(", ");
  }
  return "--";
};

const getParcelCountyUrl = (parcel: any) => {
  let parcelCountyUrl = undefined;

  // Link to the parcel assessor's source
  // HEY THIS IS NOT SCALABLE! Once we leave Washington State, IS BORKED
  switch (parcel.county) {
    case "King":
      parcelCountyUrl =
        "http://blue.kingcounty.com/Assessor/eRealProperty/Dashboard.aspx?ParcelNbr=" +
        parcel.parcel_number.replaceAll("-", "");
      break;

    case "Kitsap":
      let regex = /(\d{6})(\d{1})(\d{3})(\d{4})/;
      if (parseInt(parcel.parcel_number.replaceAll("-", "")[0]) >= 4) {
        regex = /(\d{4})(\d{3})(\d{3})(\d{4})/;
      }
      const dashed_num = parcel.parcel_number.replaceAll("-", "").match(regex).slice(1).join("-");
      parcelCountyUrl = "https://psearch.kitsapgov.com/pdetails/Details.aspx?parcel=" + dashed_num;
      break;

    case "Pierce":
      parcelCountyUrl =
        "https://atip.piercecountywa.gov/#/app/propertyDetail/" +
        parcel.parcel_number.replaceAll("-", "") +
        "/summary";
      break;

    case "Skagit":
      parcelCountyUrl =
        "https://www.skagitcounty.net/Search/Property/?id=" + parcel.parcel_number.replaceAll("-", "");
      break;

    case "Snohomish":
      parcelCountyUrl =
        "https://www.snoco.org/proptax/search.aspx?parcel_number=" + parcel.parcel_number.replaceAll("-", "");
      break;

    case "Thurston":
      parcelCountyUrl =
        "https://tcproperty.co.thurston.wa.us/propsql/basic.asp?pn=" +
        parcel.parcel_number.replaceAll("-", "");
      break;
  }
  return parcelCountyUrl;
};

export const buildSummaryProperty = (proforma: any, customStructure: any) => {
  const parcel = proforma.parcel;
  // This is the original structure object,
  // as ._characteristics will update with custom changes
  const structure = proforma.structure;
  const units = structure.multifamily_unit_information;
  const multifamily = proforma?.structure?.multifamily_unit_information;
  const parcelCountyUrl = getParcelCountyUrl(parcel);

  const propertySpecs: any = [];
  const propertySpecsMore: any = [];

  if (structure.structure_type !== "House") {
    propertySpecs.push({
      name: "Type",
      value: structure.structure_type,
      section: "structure",
      customValue: customStructure.structure_type,
    });
  }

  if (!multifamily) {
    propertySpecs.push(
      {
        name: "Finished Sqft",
        value: structure.square_feet_finished?.toLocaleString(),
        section: "structure",
        customValue: customStructure.square_feet_finished,
      },
      {
        name: "Unfinished Sqft",
        value: structure.square_feet_basement_unfinished?.toLocaleString(),
        section: "structure",
        customValue: customStructure.square_feet_basement_unfinished,
      },
      {
        name: "Bedrooms",
        value: structure.bedrooms,
        section: "structure",
        customValue: customStructure.bedrooms,
      },
      {
        name: "Bathrooms",
        value: structure.bathrooms,
        section: "structure",
        customValue: customStructure.bathrooms,
      },
    );
  }

  propertySpecs.push({
    name: "Year Built",
    value: structure.year_built,
    section: "structure",
    customValue: customStructure.year_built,
  });

  propertySpecsMore.push(
    {
      name: "Year Improved",
      value: structure.year_built_effective,
      section: "structure",
    },
    {
      name: "Foundation",
      value: cleanup(structure.foundation_type),
      section: "structure",
    },
    {
      name: "Roof",
      value: cleanup(structure.roof_cover),
      section: "structure",
    },
    {
      name: "Siding",
      value: structure.siding_cover,
      section: "structure",
      customValue: customStructure.siding_cover,
    },
    {
      name: "Heating",
      value: structure.hvac_heating,
      section: "structure",
    },
    {
      name: "Cooling",
      value: structure.hvac_cooling,
      section: "structure",
    },
    {
      name: "Fireplaces",
      value: structure.fireplaces,
      section: "structure",
    },
    {
      name: "Garage Sqft",
      value: parcel.main_structure.square_feet_garage?.toLocaleString(),
      section: "structure",
      customValue: customStructure.square_feet_garage,
    },
  );

  // Units (i) - just multifamily
  if (units) {
    for (let idx = 0; idx < units.length; idx++) {
      const unit = units[idx];
      const section_name = `Unit ${idx + 1}`;
      propertySpecsMore.push({
        name: "Unit",
        value: unit["Unit Name"],
        section: section_name,
      });
      const keys = Object.keys(unit);
      for (const key of keys) {
        let val = unit[key];
        if (key === "Unit Name") continue;
        if (key === "Rent" && unit[key]) {
          val = formatCurrency(unit[key], 0);
        } else if (key === "Square Feet" && unit[key]) {
          val = unit[key].toFixed(0);
        }
        propertySpecsMore.push({
          name: key,
          value: val,
          section: section_name,
        });
      }
    }
  }

  propertySpecsMore.push(
    {
      name: "Parcel",
      value: parcel.parcel_number,
      url: parcelCountyUrl ? parcelCountyUrl : null,
      section: "parcel",
    },
    {
      name: "Lot Sqft",
      value: parcel.lot_square_feet?.toLocaleString(),
      section: "parcel",
    },
    {
      name: "Water",
      value: getWaterType(parcel.water_type),
      section: "parcel",
    },
    {
      name: "Sewer",
      value: cleanup(parcel.sewage_type),
      section: "parcel",
    },
    {
      name: "Zoning",
      value: parcel.zoning,
      section: "parcel",
    },
    {
      name: "Views",
      value: parcel.view_type ? cleanup(parcel.view_type.join(",")) : "",
      section: "parcel",
    },
  );
  parcel.taxes?.forEach((tax: any) => {
    propertySpecsMore.push({
      name: `Taxes (${tax["bill_year"].toString()})`,
      value: formatCurrency(tax["billed_amount"], 0),
      section: "parcel",
    });
  });
  propertySpecsMore.push(
    {
      name: "County",
      value: parcel.county,
      section: "parcel",
    },
    {
      name: "School District",
      value: parcel.school_district,
      section: "parcel",
    },
  );

  parcel.accessories?.forEach((accessory: any) => {
    propertySpecsMore.push({
      name: accessory["accessory_type"],
      value: accessory["accessory_description"],
      section: "accessories",
    });
  });

  return {
    propertySpecs: propertySpecs,
    propertySpecsMore: propertySpecsMore,
  };
};

export const PropertyDetails = ({ isNWMLS = false }: { isNWMLS?: boolean }) => {
  const { proforma } = useProforma();
  const [showMore, setShowMore] = useState(false);
  const unitSections = getUnitSections(proforma);
  const groupedUnits = groupArray(unitSections, 3);
  const { customStructure } = useCustomStructure(proforma) || undefined;
  const { propertySpecs: propertySummary, propertySpecsMore: propertySummaryMore } = buildSummaryProperty(
    proforma,
    customStructure,
  );
  const structureData = propertySummary.filter((item: any) => item.section === "structure");
  const structureDataMore = propertySummaryMore.filter((item: any) => item.section === "structure");
  const parcelDataMore = propertySummaryMore.filter((item: any) => item.section === "parcel");

  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "details");

  const handleClick = () => {
    if (isMobile) {
      if (!showMore) {ref.current?.scrollIntoView({ behavior: "smooth" })};
      setShowMore((prev) => !prev);
    }
  };

  return (
    <div className="w-full max-w-7xl justify-center gap-4 px-4 sm:rounded-md xl:px-0" ref={ref}>
      <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">
        Property details
      </div>
      <div className="flex w-full flex-col gap-6 pt-2">
        <div className="flex w-full flex-col">
          <DetailsList data={structureData} />
        </div>
        <div className="-mt-2" onClick={handleClick}>
          {(showMore || !isMobile) && (
            <div className="-mt-4 flex w-full flex-col gap-6 border-t border-gray-200">
              <div className="flex w-full flex-col">
                <DetailsList data={structureDataMore} />
              </div>
              {groupedUnits.map((group: any[], groupIdx: number) => {
                return (
                  <div key={`ugrp-${groupIdx}`} className="flex w-full flex-col gap-6">
                    {group.map((unitName: string, unitIdx: number) => {
                      return (
                        <div key={`u${groupIdx}${unitIdx + 1}`} className="w-full">
                          <DetailsList
                            data={propertySummaryMore.filter((item: any) => item.section === unitName)}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className="flex w-full flex-col pb-0">
                <DetailsList data={parcelDataMore} />
              </div>
            </div>
          )}
          {isMobile && (
            <>
              {showMore ? (
                <span className="mt-2 flex items-center text-left text-sm text-gray-400">
                  <ChevronUpIcon className="mr-2 inline size-4" />
                  Show less
                </span>
              ) : (
                <span className="-mt-3 flex items-center text-left text-sm text-gray-400">
                  <ChevronDownIcon className="mr-2 inline size-4" />
                  Show more
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
