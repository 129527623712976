import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper, RegisterClientOrAgentForm } from "components";
import { useRegisterClientOrAgentModal } from "state";

export const RegisterClientOrAgentModal = () => {
  const { closeRegisterClientOrAgentModal, showRegisterClientOrAgentModal } = useRegisterClientOrAgentModal();

  if (!showRegisterClientOrAgentModal) return null;

  return (
    <ModalWrapper
      isOpen={showRegisterClientOrAgentModal}
      closeModal={closeRegisterClientOrAgentModal}
    >
      <div className="inline-block w-full max-w-md transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
        <button className="float-right outline-none" onClick={closeRegisterClientOrAgentModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>
        <RegisterClientOrAgentForm />
      </div>
    </ModalWrapper>
  );
};
