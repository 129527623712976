import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

interface ILocationResponse {
  state: any[];
  county: any[];
  city: any[];
  metro_area: any[];
  neighborhood: any[];
  school_district: any[];
  zip_code: any[];
}

const buildLocationsLookup = (locations: ILocationResponse): ILocationResponse | undefined => {
  const geoIds = {} as ILocationResponse;

  if (!locations) return;

  ["state", "county", "city", "neighborhood", "metro_area"].forEach((geoType) => {
    const gType = geoType as keyof ILocationResponse;
    geoIds[gType] = {} as any;

    locations &&
      locations[geoType as keyof ILocationResponse].forEach((location) => {
        const newLocation = { ...location };
        newLocation._type = geoType;
        switch (geoType) {
          // Stash the county with the city
          case "city":
            newLocation.name = location.name.toLowerCase();
            break;
          case "county":
            // Name looks nice
            newLocation.name = `${location.name} County`;
            break;
        }
        geoIds[gType][newLocation.id] = newLocation;
      });
  });
  return geoIds;
};

const fetcherWithTransform = (url: string) => fetcherWithCredentials(url).then(buildLocationsLookup);

export const useLocationsApi = (urlParams: URLSearchParams) => {
  let url = appConfig.apiEndpoint.locations;
  const newSearchParams = new URLSearchParams();
  urlParams.forEach((value, key) => {
    if (["city", "county", "state", "neighborhood", "metro_area", "school_district"].includes(key)) {
      newSearchParams.append(key, value.toString());
    }
  });

  const apiParams = newSearchParams.toString();
  if (apiParams) {
    url += "?" + apiParams;
  }

  const { data, error } = useSWR<ILocationResponse | undefined>(url, fetcherWithTransform, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    error,
    data,
  };
};
