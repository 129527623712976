import { atom, useRecoilState } from "recoil";
import { TypeAheadItemType } from "helpers";

export const locationsFromItems = (items: any[]): any => {
  const last = items[items.length - 1];
  const stateId = last.value.type === "state" ? last.value.id : last.value.state_id;
  const multipleStates = items.some((item: any) => {
    const itemStateId = item.value.type === "state" ? item.value.id : item.value.state_id;
    return itemStateId !== stateId;
  });
  if (multipleStates) {
    items = [last];
  }
  return { stateId, newItems: items };
}

export interface ILocationSearchItem {
  label: string;
  value: any;
};

export const locationSearchItemsAtom = atom<TypeAheadItemType[]>({
  key: "locationSearchItemsAtom",
  default: [],
});


export const useLocationSearch = () => {
  const [locationSearchItems, setLocationSearchItems] = useRecoilState(locationSearchItemsAtom);

  return {
    locationSearchItems,
    setLocationSearchItems,
  };

};