import NumberFormat from "react-number-format";
import { useFinancials } from "state/proformas";

type Props = {
  loan: any;
}

export const LoanDetails = ({ loan }: Props) => {
  const { financials, updateLoan, updateCalcSheet } = useFinancials();
  const dpayPct = loan.name === 'brrr_mortgage' ? (1 - loan.dpay) * 100 : loan.dpay * 100;

  const toggleEnabledStatus = () => {
    updateLoan({
      ...loan, is_enabled: !loan.is_enabled
    });
  }

  const onMortgageLengthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateCalcSheet({ mortgage_length: Number(e.target.value) })
  }

  const toggleFinancedOrigination = () => {
    updateLoan({
      ...loan, is_financed_origination: !loan.is_financed_origination
    });
  }

  const onDownPaymentChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      let dpay = values.floatValue / 100;
      if (loan.name === 'brrr_mortgage') {
        dpay = 1 - dpay;
      }
      updateLoan({ ...loan, dpay });
    }
  }

  const onInterestChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan, interest: values.floatValue / 100
      });
    }
  }

  const onOriginationChange = (values: any) => {
    if (values.floatValue || values.floatValue === 0) {
      updateLoan({
        ...loan, origination: values.floatValue / 100
      });
    }
  }

  return (
    <>
      <div className="flex flex-row justify-items-stretch gap-2 mt-4 pb-4 items-center">
        <label className="whitespace-nowrap w-full font-medium cursor-pointer flex">
        <div className="w-full basis-3/5">
          <input
            className="text-blue-500 indeterminate:bg-gray-300 border outline-0 focus:ring-0 mr-1 cursor-pointer"
            type="checkbox"
            onChange={() => toggleEnabledStatus()}
            checked={loan.is_enabled}
          />
          {loan._display_name}
          </div>
          <div className="w-full basis-2/5 text-right">
          <NumberFormat
                    className="w-full"
                    value={loan._monthly_pmt}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$ "
                    decimalScale={0}
                  />
          </div>
        </label>
      </div>

      <div className={`${loan.is_enabled ? 'opacity-100' : 'opacity-20 cursor-not-allowed'}`}>
        <div className="w-full pl-6 text-gray-600">

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="whitespace-nowrap basis-3/5">
              Mortgage Period
            </div>
            <div className="flex  basis-2/5 w-full">
              <div className="text-center  w-full">
                <select
                  className="text-sm h-7 py-0 w-full bg-stone-200 border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                  value={financials?.calcsheet.mortgage_length}
                  onChange={onMortgageLengthChange}
                  disabled={!loan.is_enabled}
                >
                  <option value="15">15 yrs</option>
                  <option value="30">30 yrs</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="whitespace-nowrap basis-3/5">
              {loan.name === "brrr_mortgage" ? "Loan-to-Value" : "Down Payment"}
            </div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                defaultValue={dpayPct}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={0}
                onValueChange={onDownPaymentChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="whitespace-nowrap basis-3/5">Interest Rate</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                defaultValue={loan.interest * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onInterestChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-8">
            <div className="whitespace-nowrap basis-3/5">Origination Fee</div>
            <div className="w-full basis-2/5">
              <NumberFormat
                className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                defaultValue={loan.origination * 100}
                thousandSeparator={false}
                suffix=" %"
                decimalScale={2}
                onValueChange={onOriginationChange}
                disabled={!loan.is_enabled}
              />
            </div>
          </div>
          <div className="flex flex-row justify-items-stretch gap-2 text-xs mt-2 pb-4 items-center">
            <input
              className="text-blue-500 indeterminate:bg-gray-300 border outline-0 focus:ring-0"
              type="checkbox"
              onChange={() => toggleFinancedOrigination()}
              checked={loan.is_financed_origination}
              disabled={!loan.is_enabled}
            />
            Finance Origination Fee
          </div>

        </div>
      </div>
    </>
  )
}
