import { ArrowLongRight } from "components/icons";
import { SavedSearchesModal } from "components/proformas/modals";
import { cn } from "helpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { SaveSearchType } from "state/proformas";


type Props = {
  savedSearches: SaveSearchType[] | undefined;
};

export const SavedSearchesLinks = ({ savedSearches }: Props) => {
  const savedSearchesCount = savedSearches?.length || 0;
  const searchesToShow = savedSearchesCount <= 10 ? 10 : 8;
  const [showSavedSearchesModal, setShowSavedSearchesModal] = useState(false);

  return (
    <div
      className={cn(
        // "mb-4 mr-4 flex flex-col whitespace-nowrap rounded-sm border p-4 pt-0 shadow-lg shadow-x-2 shadow-y-2",
        "flex flex-col whitespace-nowrap p-4 pt-0",
        isMobile ? "min-w-full" : "h-76 w-80 min-w-80 shrink-0",
      )}
    >
      {savedSearchesCount === 0 && (
        <div className="flex flex-col gap-4 leading-7 sm:pt-3">
          <div className="font-bold">No saved searches!</div>
          <div className="text-pretty">
            To create a saved search, search an area, add some filters, and click save.
          </div>
          <div className="text-pretty">
            Pro tip: turn on alerts to get emails when new listings match your search filters.
          </div>
        </div>
      )}
      {savedSearchesCount > 0 && (
        <div className="flex flex-col text-sm">
          {savedSearches?.slice(0, searchesToShow).map((search, idx) => (
            <div className="inline" key={idx}>
              <Link
                className="leading-relaxed hover:text-blue-700 hover:font-medium"
                to={search.parameters?.url}
                key={search.name + search.id}
              >
                {search.name.length >= 42 ? <>{search.name.slice(0, 38)} ...</> : search.name}
                <ArrowLongRight className="ml-1 inline size-4" />
              </Link>
            </div>
          ))}
          {savedSearchesCount > 10 && (
            <div
              className="mt-3 cursor-pointer text-blue-700 hover:font-medium"
              onClick={() => setShowSavedSearchesModal(true)}
            >
              See All Saved Searches
            </div>
          )}
        </div>
      )}
      {showSavedSearchesModal && (
        <SavedSearchesModal
          isOpen={showSavedSearchesModal}
          closeModal={() => setShowSavedSearchesModal(false)}
        />
      )}
    </div>
  );
};