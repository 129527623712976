import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { atom, useRecoilState } from "recoil";

export type CustomStructureType = {
  bathrooms: number | null | undefined;
  bedrooms: number | null | undefined;
  floors: number | null | undefined;
  parking_count: number | null | undefined;
  square_feet_basement_finished: number | null | undefined;
  square_feet_basement_unfinished: number | null | undefined;
  square_feet_finished: number | null | undefined;
  square_feet_garage: number | null | undefined;
  siding_cover: number | null | undefined;
  structure_type: string | null | undefined;
  style: string | null | undefined;
  view_type?: string[] | null;
  year_built: number | null | undefined;
};

type CompsForCustomStructureType = {
  condition_score: number;
  cost_diffs: any[],
  normalized_score: number,
  parcel_id: number,
  projected_arv: number,
  score: number,
  similarity_score: number,
  status: string,
};

type CreateCustomStructureType = {
  comparables: CompsForCustomStructureType[];
  custom_structure: CustomStructureType;
};

export const customStructureAtom = atom<CustomStructureType>({
  key: "customStructureAtom",
  default: {
    bathrooms: null,
    bedrooms: null,
    floors: null,
    parking_count: null,
    square_feet_basement_finished: null,
    square_feet_basement_unfinished: null,
    square_feet_finished: null,
    square_feet_garage: null,
    siding_cover: null,
    structure_type: null,
    style: null,
    view_type: null,
    year_built: null,
  },
});


export const useCustomStructure = (proforma: any) => {
  const [customStructure, setCustomStructure] = useRecoilState(customStructureAtom);

  const createCustomStructure = (customStructure: CustomStructureType) => {
    const postData: CreateCustomStructureType = {
      custom_structure: customStructure,
      comparables: [],
    };
    proforma.comparables.forEach((comp: any) => {
      postData.comparables.push({
        condition_score: comp.condition_score,
        cost_diffs: comp.cost_diffs,
        normalized_score: comp.normalized_score,
        parcel_id: comp.parcel._id,
        projected_arv: comp.projected_arv,
        score: comp.score,
        similarity_score: comp.similarity_score,
        status: comp.status,
      });
    });

    const url = appConfig.apiEndpoint.customStructure.replace(":parcel_id", proforma.parcel._id);
    return fetcherWithCredentials(url, "POST", postData);
  }

  const hasCustomStructure = (): boolean => {
    return Object.keys(customStructure).some((key) => {
      return customStructure[key as keyof CustomStructureType] != null;
    });
  }

  return {
    createCustomStructure,
    customStructure,
    setCustomStructure,
    hasCustomStructure,
  }
};
