export const getRelativeDate = (deltaDays: number, startingDate: Date | null = null): Date => {
  const date = startingDate ? new Date(startingDate) : new Date();
  return new Date(date.setDate(date.getDate() + deltaDays));
}

export const dateFromDaysAgo = (daysAgo: number | undefined | null): string | null => {
  if (daysAgo == null) return null;
  return getRelativeDate(-daysAgo).toISOString().substring(0, 10);
}

export const formatDateShort = (date: string | Date, includeDay: boolean = true): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: includeDay ? 'numeric' : undefined };
  // Returns the date in the format "Jan 1, 2020" or "Jan 2020" if day is undefined
  return (new Intl.DateTimeFormat('en-US', options)).format(dateObj)
}

export const formatDate = (date: string | Date): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  // Returns the date in the format "Jan 1, 2020" or "Jan 2020" if day is undefined
  return (new Intl.DateTimeFormat('en-US', options)).format(dateObj)
}

export const formatNumber = (value: number | null | undefined, maxDecimals: number = 2): string => {
  if (value === null || value === undefined) return "";
  return value.toLocaleString('en-US', { maximumFractionDigits: maxDecimals }
  );
}

export const formatCurrency = (value: number | null | undefined, maxDecimals: number = 2): string => {
  if (value === null || value === undefined) return "";
  return value.toLocaleString('en-US',
    { style: 'currency', currency: 'USD', maximumFractionDigits: maxDecimals }
  );
}

export const formatCurrencyK1 = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return "";
  if (value < 1000) return formatCurrency(value, 0);
  value = value / 1000;
  if (value < 1000) {
    return value.toLocaleString('en-US',
      { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
    ) + 'K';
  }
  value = value / 1000;
  return value.toLocaleString('en-US',
    { style: 'currency', currency: 'USD', maximumFractionDigits: 1 }
  ) + 'M';
}

export const formatPrice = (price: number | undefined): string => {
  if (price === null || price === undefined) return "";
  if (price % 1000000 === 0) {
    return `$${(price / 1000000).toFixed(1)}m`;
  } else if (price % 100000 === 0) {
    return `$${(price / 1000000).toFixed(2)}m`;
  // } else if (price % 10000 === 0) {
  //   return `$${(price / 1000000).toFixed(2)}m`;
  } else if (price % 1000 === 0) {
    return `$${(price / 1000).toFixed(0)}k`;
  } else {
    return `${price.toLocaleString('en-US',
      { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}`;
  }
}

export const formatPercent = (value: number | null | undefined, maxDecimals: number = 2) => {
  if (value === null || value === undefined) return "";
  return (value * 100).toFixed(maxDecimals) + '%';
};

export const titleCase = (str: string | null | undefined): string => {
  if (!str) return "";
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const formatPropertyName = (name: string) => {
  if (!name) return "Unknown";
  return name.replace(/( [sn][ew][^a-z0-9])|( [sn][ew]$)/i, (a) => a.toUpperCase())
}
