import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import {
  costNameDictionary,
  editCostModalAtom,
  getCostDetail,
  setupCosts,
} from "components/proformas/financials/";
import { cn } from "helpers";
import sortBy from "lodash/sortBy";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { useSetRecoilState } from "recoil";
import { useFinancials } from "state/proformas";

type Props = {
  costGroup: string;
  costGroupName: string;
};

export const FlipCostGroup = ({ costGroup, costGroupName }: Props) => {
  const [showGroupData, setShowGroupData] = useState<boolean>(false);
  const { financials } = useFinancials();
  const costGroupData = sortBy(
    financials?.costgroups[costGroup as keyof typeof financials.costgroups],
    "_display_order",
  );
  const loans = financials?.calcsheet.loans.filter((l) => l._type === "carry" && l.is_enabled);
  const setEditCostModal = useSetRecoilState(editCostModalAtom);

  return (
    <div
      className={cn(
        "mb-8",
        isMobile && costGroup === "acquisition" && !showGroupData && "-mt-4",
        isMobile && !showGroupData && "mb-0",
        costGroup === "selling" && "mb-0",
      )}
    >
      <div className="text-md flex flex-row justify-items-stretch gap-2 pb-2 leading-9">
        <div className="w-3/5 whitespace-nowrap font-medium">{costGroupName}</div>
        <div className="flex w-full basis-2/5 items-center justify-end pr-1 text-right">
          <NumberFormat
            value={financials?.costgroupTotals[costGroup as keyof typeof financials.costgroupTotals]}
            displayType="text"
            thousandSeparator={true}
            prefix="$ "
            decimalScale={0}
          />
          {isMobile && (
            <>
              {showGroupData ? (
                <ChevronUpIcon className="ml-1 inline-block size-4" onClick={() => setShowGroupData(false)} />
              ) : (
                <ChevronDownIcon
                  className="ml-1 inline-block size-4"
                  onClick={() => setShowGroupData(true)}
                />
              )}
            </>
          )}
        </div>
      </div>

      {(!isMobile || showGroupData) && (
        <>
          {costGroupData?.map((cost) =>
            cost.name === "hoa_dues" && cost.amount === 0 ? null : (
              <div key={cost.name} className="flex flex-row justify-items-stretch gap-2 text-sm">
                <div className="w-full whitespace-nowrap">
                  <div>{costNameDictionary[cost.name as keyof typeof costNameDictionary] || cost.name}</div>
                  <div className="basis-3/5 text-xs text-gray-400">
                    {cost.name === "miscellaneous_acquisition" ? <>you decide!</> : getCostDetail(cost)}
                  </div>
                </div>
                <div className="w-full basis-2/5 text-right">
                  <div
                    className="w-full cursor-pointer items-center border border-[#E4C442] bg-stone-200 p-1 text-right text-sm text-blue-700 outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
                    onClick={() => setEditCostModal(setupCosts(cost, financials))}
                  >
                    <NumberFormat
                      className="cursor-pointer border-0 border-blue-700 pb-0.5 text-black placeholder:text-gray-400 focus:border-blue-400"
                      value={cost.amount}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  </div>
                </div>
              </div>
            ),
          )}

          {loans?.map((loan) => (
            <div key={loan.name}>
              {costGroup === "acquisition" && (
                <div className="flex flex-row justify-items-stretch gap-2 text-sm">
                  <div className="w-full basis-3/5 whitespace-nowrap">
                    <div>Origination Fee</div>
                    <div className="text-xs text-gray-400">for {loan._display_name}</div>
                  </div>
                  <div className="w-full basis-2/5 text-right leading-9">
                    <NumberFormat
                      className="w-full"
                      value={loan._origination_amt}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  </div>
                </div>
              )}
              {costGroup === "carrying" && !loan.is_financed_interest && (
                <div key={loan.name} className="flex flex-row justify-items-stretch gap-2 text-sm">
                  <div className="w-full basis-3/5 whitespace-nowrap">
                    <div>Interest</div>
                    <div className="text-xs text-gray-400">for {loan._display_name}</div>
                  </div>
                  <div className="w-full basis-2/5 text-right leading-9">
                    <NumberFormat
                      className="w-full"
                      value={loan._total_pmts}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
