import { formatPrice } from "helpers";
import NumberFormat from "react-number-format";
import { FinancialsType, ProformaType, useCustomStructure } from "state/proformas";

type Props = {
  financials: FinancialsType | null;
  proforma: ProformaType;
  mobileHeader?: boolean;
};

export const PellegoARV = ({ financials, proforma, mobileHeader }: Props) => {
  const { hasCustomStructure } = useCustomStructure(proforma);

  return (
    <>
      {mobileHeader ? (
        <>
          {formatPrice(financials?.calcsheet.pellego_arv)}
          {hasCustomStructure() &&
            financials?.calcsheet.pellego_arv !== financials?.calcsheet.resale_value && (
              <>{` → ${formatPrice(financials?.calcsheet.resale_value)}`}</>
            )}
        </>
      ) : (
        <>
          <NumberFormat
            value={financials?.calcsheet.pellego_arv}
            displayType="text"
            thousandSeparator={true}
            prefix="$"
            decimalScale={0}
          />
          {hasCustomStructure() &&
            financials?.calcsheet.pellego_arv !== financials?.calcsheet.resale_value && (
              <>
                {" → "}
                <NumberFormat
                  value={financials?.calcsheet.resale_value}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$"
                  decimalScale={0}
                />
              </>
            )}
        </>
      )}
    </>
  );
};
