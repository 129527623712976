import { useEffect, useRef } from "react";
import { MarketResearchMapService } from "./MarketResearchMapService";
import { stateIdToGeoCenter } from "config";
import { MarketComparableType } from "components/markets";

type Props = {
  regions: MarketComparableType[];
  defaultRegion: number;
};

export const MarketResearchMap = ({ regions, defaultRegion }: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const stateGeoCenter = stateIdToGeoCenter[defaultRegion as keyof typeof stateIdToGeoCenter];
  const mapSvcRef = useRef<MarketResearchMapService>(new MarketResearchMapService(stateGeoCenter));

  useEffect(() => {
    const mapService = mapSvcRef.current;
    if (mapRef.current) {
      mapSvcRef.current.createMap(mapRef.current);
    }
    return () => mapService.clearMap();
  }, [mapRef]);

  useEffect(() => {
    if (mapSvcRef.current) {
      mapSvcRef.current.setRegions(regions);
    }
  }, [regions]);

  return (
    <div ref={mapRef} className="w-full h-full" />
  );
}
