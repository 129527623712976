import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, FormFieldError } from "components";
import { Checkbox, SubmitButton, UnLabeledInput } from "components/forms";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAlerts, useRegisterWholesaleContact, useUserSession } from "state";
import * as Yup from "yup";

export const RegisterClientForm = () => {
  const [submitState, setSubmitState] = useState<"idle" | "sending" | "sent">("idle");
  const { registerWholesaleContact } = useRegisterWholesaleContact();
  const alertActions = useAlerts();
  const regions = [
    "Seattle",
    "Portland",
    "Bay Area",
    "Los Angeles",
    "San Diego",
    "Denver",
    "Austin",
    "Las Vegas",
    "Chicago",
    "Orlando",
    "Miami",
  ];
  const currentUser = useUserSession();

  // form validation rules
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Please enter your full name"),
    phone_number: Yup.string().required("Your phone number is required"),
    email: Yup.string().email().required("Your email address is required"),
    regions: Yup.array()
      .transform((value) => {
        return !Array.isArray(value) ? [] : value;
      })
      .min(1, "Please select at least one region.")
      .required("Please select at least one region."),
    has_agent_licence: Yup.boolean(),
    has_deals_to_post: Yup.boolean(),
    is_pre_approved: Yup.boolean(),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      full_name: `${currentUser?.name !== null ? currentUser?.name : ""}`,
      phone_number: `${
        currentUser?.session?.phone_number !== null ? currentUser?.session?.phone_number : ""
      }`,
      email: `${currentUser?.session?.email !== null ? currentUser?.session?.email : ""}`,
      regions: [],
      has_agent_licence: false,
      has_deals_to_post: false,
      is_pre_approved: false,
    },
  };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (formData: any) => {
    setSubmitState("sending");

    formData.regions = formData.regions.join(", ");

    registerWholesaleContact(formData)
      .then(() => {
        setSubmitState("sent");
      })
      .catch((err) => {
        setSubmitState("idle");
        alertActions.error(err.message);
      });
  };

  useEffect(() => {
    if (submitState !== "sent") {
      setFocus("full_name");
    }
  }, [setFocus, submitState]);

  return (
    <div className="mx-auto mt-4 max-w-2xl px-8 pb-4">
      {submitState === "sent" ? (
        <div className="mt-20 min-h-screen text-center text-lg">
          Thank you for registering.
          <br />
          We will be in touch soon.
        </div>
      ) : (
        <>
          <h3 className="mt-8 text-2xl">Register to see wholesale listings</h3>
          <div className="mt-4 max-w-md">
            <Alert />

            <div className="grid grid-cols-1">
              <form onSubmit={handleSubmit(onSubmit)}>
                <section id="personal-data">
                  <UnLabeledInput
                    type="text"
                    label="Full Name"
                    {...register("full_name")}
                    errMsg={errors.full_name?.message}
                  />
                  <UnLabeledInput
                    type="text"
                    label="Phone Number"
                    {...register("phone_number")}
                    errMsg={errors.phone_number?.message}
                  />
                  <UnLabeledInput
                    type="email"
                    label="Email Address"
                    {...register("email")}
                    errMsg={errors.email?.message}
                  />
                </section>
                <section id="regions">
                  <div className="my-5 flex-1">
                    <span className="text-gray-700">What are the regions you are interested in?</span>
                    <div className="mt-2 grid grid-cols-2 lg:grid-cols-3">
                      {regions.map((region, idx) => (
                        <Checkbox value={region} key={idx} {...register("regions")}>
                          {region}
                        </Checkbox>
                      ))}
                    </div>
                    <FormFieldError className="mt-1" field={errors.regions} />
                  </div>
                </section>
                <section id="extras-selected">
                  <div className="mt-4">
                    <Checkbox {...register("has_agent_licence")}>I have an agent license</Checkbox>
                    <Checkbox {...register("has_deals_to_post")}>I have deals I want to post</Checkbox>
                    <Checkbox {...register("is_pre_approved")}>I am pre-approved</Checkbox>
                  </div>
                </section>
                <section id="already-logged-data">
                  <UnLabeledInput
                    type="hidden"
                    label="User ID"
                    value={`${currentUser?.userId !== null ? currentUser?.userId : ""}`}
                    {...register("userId")}
                  />
                  <UnLabeledInput
                    type="hidden"
                    label="Default Region"
                    value={`${
                      currentUser?.session?.default_region !== null
                        ? currentUser?.session?.default_region
                        : ""
                    }`}
                    {...register("default_region")}
                  />
                  <UnLabeledInput
                    type="hidden"
                    label="Zip Code"
                    value={`${currentUser?.session?.zip_code !== null ? currentUser?.session?.zip_code : ""}`}
                    {...register("zip_code")}
                  />
                </section>
                <SubmitButton isSubmitting={submitState === "sending"}>Submit</SubmitButton>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
