import React, { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from 'react-dropzone';
import { SubmitButton } from "components/forms";
import { PhotographIcon } from "@heroicons/react/outline";
import { useUserActions } from "actions";

const MAX_AVATAR_WxH = 1024;
const MAX_IMAGE_SIZE = 20 * 1024 * 1024;

const getRezisedImage = (canvas: HTMLCanvasElement) => {
  if (canvas.width <= MAX_AVATAR_WxH) {
    return canvas;
  }
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = MAX_AVATAR_WxH;
  tmpCanvas.height = MAX_AVATAR_WxH;
  const ctx = tmpCanvas.getContext('2d');
  ctx?.drawImage(canvas,
    0, 0, canvas.width, canvas.height,
    0, 0, MAX_AVATAR_WxH, MAX_AVATAR_WxH
  );
  return tmpCanvas;
}

export const ProfilePictureUploader = ({ onUploaded }: { onUploaded: CallableFunction }) => {
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [zoonLevel, setZoomLevel] = useState(1);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editor = useRef<AvatarEditor>(null);
  const userActions = useUserActions();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    maxSize: MAX_IMAGE_SIZE,
    multiple: false,
    maxFiles: 1,
    onDropAccepted: acceptedFiles => {
      setImage(acceptedFiles[0]);
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
        setError(`${fileRejections[0].file.name}: ${fileRejections[0].errors[0].message}`);
      }
    },
    onFileDialogOpen: () => {
      setError(null);
    },
  });

  const handleUpload = () => {
    if (editor.current) {
      const canvas = getRezisedImage(editor.current.getImage());
      canvas.toBlob(blob => {
        if (blob) {
          setIsSubmitting(true);
          setError(null);
          userActions.uploadAvatar(blob)
            .then(() => {
              onUploaded();
            }).catch(err => {
              setError("Failed to upload image");
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      }, "image/jpeg");
    }
  }

  const handleZoom = (e: React.FormEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.currentTarget.value)
    setZoomLevel(scale);
  }

  const handleRotation = (e: React.FormEvent<HTMLInputElement>) => {
    const angle = parseFloat(e.currentTarget.value)
    setRotationAngle(angle);
  }

  return (
    <div className="flex justify-center w-full">
      {image == null ? (
        <div {...getRootProps({ className: "w-80 h-80 p-4 border-2 border-dotted mb-4 flex" })}>
          <input {...getInputProps()} />
          <div className="text-sm text-center justify-center flex flex-col items-center">
            <PhotographIcon className="mr-1 w-24 opacity-10" aria-hidden="true" />
            <div className="w-max-xs text-gray-400">
              Drag 'n' drop some files here, or click to select a new image
              {error && (
                <div className="text-sm text-center justify-center flex flex-col items-center">
                  <div className="text-red-500 text-sm pt-4 w-44">
                    {error}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-2">
          <AvatarEditor
            className="bg-gray-300"
            style={{ border: "1px solid #ccc", borderRadius: "8px" }}
            ref={editor}
            image={image || ""}
            width={300}
            height={300}
            border={10}
            borderRadius={10}
            color={[0, 255, 255, 0.7]}
            rotate={rotationAngle}
            scale={zoonLevel}
          />
          <div className="flex mt-4 text-sm">
            Zoom:
            <input
              className="w-48 ml-2"
              type="range"
              onChange={handleZoom}
              min="1"
              max="3"
              step="0.01"
              defaultValue="1"
            />
          </div>
          <div className="flex mt-4 text-sm">
            Rotation:
            <input
              className="w-48 mx-2"
              type="range"
              onChange={handleRotation}
              min="-180"
              max="180"
              step="1"
              defaultValue="0"
            />
            {rotationAngle}º
          </div>
          {error && (
            <p className="text-red-500 text-sm pt-4">
              {error}
            </p>
          )}
          <SubmitButton isSubmitting={isSubmitting} disabled={isSubmitting} onClick={handleUpload}>
            Update Profile Picture
          </SubmitButton>
        </div>
      )}
    </div>
  );
}