import { Disclosure } from "@headlessui/react";
import { ExternalLinkIcon, HomeIcon, LightBulbIcon, LoginIcon, LogoutIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { useUserActions } from "actions";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserSession } from "state";

export const HamburguerPanel = () => {
  const currentUser = useUserSession();
  const userActions = useUserActions();
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <Disclosure.Panel className="fixed h-screen min-h-fit w-full overflow-y-auto bg-white pt-2 md:hidden">
      {/* Menu options when user is not logged */}
      {!currentUser.isLogged && (
        <>
          <div className="xs:mt-4 relative ml-3 text-left text-sm uppercase text-gray-500">
            <ul className="mt-4">
              {!isHome && (
                <li className="px-4 pt-2">
                  <Disclosure.Button as={Fragment}>
                    <Link to="/" className="flex-nowrap text-sm font-medium uppercase text-gray-500">
                      <HomeIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                      Home
                    </Link>
                  </Disclosure.Button>
                </li>
              )}
              <li className="px-4 pt-4 font-medium">
                <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                <a href="https://www.lotside.com/about" target="_blank" rel="noreferrer">
                  About Lotside
                </a>
              </li>
              <li className="px-4 pt-4 font-medium">
                <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                <a href="https://www.lotside.com/plans/agents" target="_blank" rel="noreferrer">
                  Agent&nbsp;Plans
                </a>
              </li>

              <li className="px-4 pt-4 font-medium">
                <a href="https://www.lotside.com/buyers" target="_blank" rel="noreferrer">
                  <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                  Buyer&nbsp;Solutions
                </a>
              </li>

              <li className="mx-4 mb-4 mt-6 border-b-2 border-gray-100"></li>
              <li className="px-4 py-2">
                <Disclosure.Button as={Fragment}>
                  <Link
                    to="/login"
                    state={{ from: location }}
                    className="flex-nowrap text-sm font-medium uppercase"
                  >
                    <LoginIcon className="mr-4 inline h-6 w-6" aria-hidden="true" /> Log&nbsp;In
                  </Link>
                </Disclosure.Button>
              </li>
              <li className="px-4 py-4">
                <Disclosure.Button as={Fragment}>
                  <Link to="/signup" className="flex-nowrap py-2 text-sm font-medium uppercase text-gray-500">
                    <PencilAltIcon className="mr-4 inline h-6 w-6" aria-hidden="true" /> Free Account
                  </Link>
                </Disclosure.Button>
              </li>
            </ul>
          </div>
        </>
      )}

      {/* Menu options when user logged */}
      {currentUser.isLogged && (
        <div className="xs:mt-4 relative ml-3 text-left text-sm uppercase text-gray-500">
          <ul>
            <li className="px-4 py-2">
              <Disclosure.Button as={Fragment}>
                <Link to="/account">
                  <img
                    className="inline-block h-20 w-20 rounded-full object-cover"
                    src={currentUser.avatar}
                    alt="avatar"
                  />
                </Link>
              </Disclosure.Button>
            </li>
            <li className="px-4 pt-2 text-left">
              <div className="text-xs text-gray-600">Welcome back</div>
              {currentUser.name && (
                <div className="text-lg font-bold">
                  <Disclosure.Button as={Fragment}>
                    <Link to="/account">{currentUser.name}</Link>
                  </Disclosure.Button>
                </div>
              )}
            </li>
            <li className="px-4 pt-0 text-xs">
              <Disclosure.Button as={Fragment}>
                <Link to="/account">Edit your profile</Link>
              </Disclosure.Button>
            </li>
            <li className="mx-4 mb-3 mt-6 border-b-2 border-gray-700"></li>
            <li className="px-4 pt-2">
              <Disclosure.Button as={Fragment}>
                <Link to="/" className="flex-nowrap text-sm font-medium uppercase text-gray-500">
                  <HomeIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                  Home
                </Link>
              </Disclosure.Button>
            </li>
            <li className="px-4 pt-8 font-medium">
              <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
              <a href="https://www.lotside.com/about" target="_blank" rel="noreferrer">
                About Lotside
              </a>
            </li>
            <li className="px-4 pt-4 font-medium">
              <LightBulbIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
              <a href="https://www.lotside.com/software" target="_blank" rel="noreferrer">
                Software Solutions
              </a>
            </li>

            <li className="px-4 pt-4 font-medium">
              <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
              <a href="https://www.lotside.com/plans/agents" target="_blank" rel="noreferrer">
                Agent&nbsp;Plans
              </a>
            </li>

            <li className="px-4 pt-4 font-medium">
              <a href="https://www.lotside.com/buyers" target="_blank" rel="noreferrer">
                <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                Buyer&nbsp;Solutions
              </a>
            </li>
            <li className="mx-4 mb-2 mt-6 border-b-2 border-gray-700"></li>
            <li className="px-4 py-4">
              <Disclosure.Button as={Fragment}>
                <Link
                  to="/"
                  className="flex-nowrap text-sm font-medium uppercase text-gray-500"
                  onClick={userActions.logout}
                >
                  <LogoutIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                  Sign out
                </Link>
              </Disclosure.Button>
            </li>
          </ul>
        </div>
      )}
    </Disclosure.Panel>
  );
};
