import { useUserSession } from "state";
import ReactGA from 'react-ga4';

export const BrowseAd = () => {
  const currentUser = useUserSession();

  return (
    <div>
      {currentUser.isAgent ? (

        <a href="https://www.lotside.com/plans/agents" target="_blank" rel="noreferrer" onClick={()=>
        ReactGA.event({
          category: 'Browse',
          action: 'Click Ad',
          label: 'isAgent',
        })}

        >
          <div className="flex justify-center flex-col text-center shadow-lg w-full items-center h-full text-black rounded-md bg-white py-4 border-[#61a28d] border">
            <div className="flex items-center flex-col justify-center">
              <div className="text-2xl font-bold px-4 pt-4">
                Hang your license with Lotside
              </div>
              <div className="text-md font-extralight px-12 py-4 pb-8">
                Leverage your time.<br />Keep your commission.
              </div>

              <div className="flex flex-col w-full justify-center text-center items-center">
                <div className="items-center  px-4 sm:px-8 py-2 font-normal text-sm rounded-sm bg-[#61a28d] hover:bg-[#5bbc9c]  text-white hover:text-white transition ease-in-out duration-150 shadow-lg flex flex-col text-center justify-center ">
                  Learn More
                </div>
              </div>

            </div>
          </div>
        </a>

      ) : (

        <a href="https://www.lotside.com/buyers" target="_blank" rel="noreferrer" onClick={()=>
        ReactGA.event({
          category: 'Browse',
          action: 'Click Ad',
          label: 'NotAgent',
        })}>
          <div className="flex justify-center flex-col text-center shadow-lg w-full items-center h-full text-black rounded-md bg-white py-4 border-gray-200 border">
            <div className="flex items-center flex-col justify-center">
              <div className="text-2xl font-bold px-8 pt-4">
                Work with an investor agent
              </div>
              <div className="text-md font-extralight px-12 py-4 pb-8">
                Interview the top agents for your location and strategy.
              </div>

              <div className="flex flex-col w-full justify-center text-center items-center">
                <div className="items-center  px-4 sm:px-8 py-2 font-normal text-sm rounded-sm bg-[#61a28d] hover:bg-[#5bbc9c]  text-white hover:text-white transition ease-in-out duration-150 shadow-lg flex flex-col text-center justify-center ">
                  Learn More
                </div>
              </div>

            </div>
          </div>
        </a>
      )
      }

    </div >
  )
}
