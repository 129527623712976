export const PeriodSelector = ({ period, value, setPeriod }: { period: number, value: number, setPeriod: (p: number) => void }) => {
  return (
    <span
      className={`text-center w-7 ml-2 border-b-4 ${period === value ?
        "border-[#7AA0BA]" :
        "cursor-pointer"}`
      }
      onClick={() => setPeriod(value)}
    >
      {value === 0 ? "Max" : `${Math.floor(value/12)}Y`}
    </span>
  )
}
