import { AdjustmentsIcon } from "@heroicons/react/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AllFiltersSavedSearches,
  FilterEquityMenu,
  FilterLoanMenu,
  FilterMinMaxMenu,
  FilterPriceMenu,
  FilterStructureMenu,
  HideUnknownEquityFilter,
  ListingRemarkItemType,
  ListingRemarksSearchBox,
  LoanFilter,
  MinMaxFilterDropdowns,
  MinMaxFilterInputs,
  MinMaxPriceDropdowns,
  PropertiesPriceSlider,
  SavedSearchesMenu,
  SlidingPanel,
  StructureFilters,
} from "components/browse";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import {
  listingEventOptions,
  listingTypeOptions,
  resetedFilterValues,
  searchResultsAtom,
  SearchResultType,
  useFilters,
} from "state/browse";
import * as Yup from "yup";

const numberNullable = Yup.number()
  .nullable(true)
  .transform((val) => (val === Number(val) ? val : null));

// Filters fields transformation rules
const validationSchema = Yup.object().shape({
  assumable_loan: Yup.boolean(),
  hide_unknown_equity: Yup.boolean(),
  listing_event: Yup.array().of(Yup.string()),
  listing_type: Yup.array().of(Yup.string()),
  max_bathrooms: numberNullable,
  max_bedrooms: numberNullable,
  max_days_on_market: numberNullable,
  max_equity: numberNullable,
  max_gross_gain: numberNullable,
  max_gross_income: numberNullable,
  max_listing_price: numberNullable,
  max_lot_square_feet: numberNullable,
  max_square_feet_finished: numberNullable,
  max_unfinished_basement: numberNullable,
  max_updated: numberNullable,
  max_year_built: numberNullable,
  min_bathrooms: numberNullable,
  min_bedrooms: numberNullable,
  min_days_on_market: numberNullable,
  min_equity: numberNullable,
  min_gross_gain: numberNullable,
  min_gross_income: numberNullable,
  min_listing_price: numberNullable,
  min_lot_square_feet: numberNullable,
  min_square_feet_finished: numberNullable,
  min_unfinished_basement: numberNullable,
  min_updated: numberNullable,
  min_year_built: numberNullable,
  structure_type: Yup.array().of(Yup.string()),
});

type Props = {
  filteredProperties: SearchResultType[];
};

export const Filters = ({ filteredProperties }: Props) => {
  const { filters, setFilters, numActiveFilters } = useFilters();
  const [listingRemarks, setListingRemarks] = useState<string[] | null>(filters.listing_remarks);
  const [panelOpen, setPanelOpen] = useState(false);
  const totalSearchResults = useRecoilValue(searchResultsAtom);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: filters,
  };
  const { register, getValues, reset, setValue, control } = useForm(formOptions);

  const handleChange = useCallback(() => {
    const newFilters = validationSchema.cast(getValues());
    setFilters({ ...newFilters, listing_remarks: listingRemarks });
  }, [setFilters, getValues, listingRemarks]);

  register("structure_type", { onChange: handleChange });
  const structureTypeValues = getValues("structure_type");

  // See: https://dmitripavlutin.com/react-throttle-debounce/
  const debHandleChange = useMemo(() => debounce(handleChange, 600), [handleChange]);
  useEffect(() => {
    return () => {
      debHandleChange.cancel();
    };
  }, [debHandleChange]);

  useEffect(() => {
    reset(filters);
  }, [filters, reset]);

  useEffect(() => {
    setListingRemarks(filters.listing_remarks);
  }, [filters.listing_remarks, setListingRemarks]);

  const onListingRemarksChange = (items: ListingRemarkItemType[]) => {
    const filters = validationSchema.cast(getValues());
    setListingRemarks(items.map((item) => item.value));
    setFilters({ ...filters, listing_remarks: items.map((item) => item.value) });
  };

  const onStructureTypeClick = (value: string, isActive: boolean) => {
    let currentValues = getValues("structure_type");
    if (isActive) {
      currentValues = [...currentValues, value];
    } else {
      currentValues = currentValues.filter((v: string) => v !== value);
    }
    setValue("structure_type", currentValues);
    handleChange();
  };

  const onResetStructureTypeClick = () => {
    if (structureTypeValues.length > 0) {
      setValue("structure_type", []);
      handleChange();
    }
  };

  const onResetLoanTypeClick = () => {
    setValue("assumable_loan", false);
    handleChange();
  };

  const priceRangeSet = (min: number | null, max: number | null) => {
    setFilters({ ...filters, min_listing_price: min, max_listing_price: max });
  };

  const grossGainRangeSet = (min: number | null, max: number | null) => {
    setFilters({ ...filters, min_gross_gain: min, max_gross_gain: max });
  };

  const grossIncomeRangeSet = (min: number | null, max: number | null) => {
    setFilters({ ...filters, min_gross_income: min, max_gross_income: max });
  };

  const onResetAllFiltersClick = () => {
    setFilters(resetedFilterValues());
  };

  const assumableLoadRegistration = register("assumable_loan", { onChange: handleChange });
  const hideUnknownEquityRegistration = register("hide_unknown_equity", { onChange: handleChange });

  return (
    <>
      {!isMobile && (
        <div className="z-40 flex min-w-fit flex-row border-b bg-white py-3 pr-2 md:pr-3">
          <FilterPriceMenu
            priceMin={filters.min_listing_price}
            priceMax={filters.max_listing_price}
            priceRangeSet={priceRangeSet}
          />

          <FilterStructureMenu
            structureTypeValues={structureTypeValues}
            handleClick={onStructureTypeClick}
            handleReset={() => onResetStructureTypeClick()}
          />

          <FilterMinMaxMenu
            label="Gross Gain"
            variable="gross_gain"
            filters={filters}
            options={[0, 10, 20, 30, 40, 50]}
            equityRangeSet={grossGainRangeSet}
          />

          <FilterMinMaxMenu
            label="Income"
            variable="gross_income"
            filters={filters}
            options={[-10, -5, 0, 5, 10]}
            equityRangeSet={grossIncomeRangeSet}
          />

          <FilterEquityMenu
            register={register}
            getValues={getValues}
            setValue={setValue}
            control={control}
            handleChange={debHandleChange}
            tag={"new"}
          />

          <FilterLoanMenu
            registration={assumableLoadRegistration}
            value={getValues("assumable_loan")}
            handleReset={() => onResetLoanTypeClick()}
            tag={"new"}
          />

          <button
            className={`text-md mx-2 flex items-center justify-between rounded-md border border-gray-300 px-1 py-2 text-left text-sm font-medium sm:pl-3 sm:pr-4 ${
              numActiveFilters > 0 ? "bg-black text-white" : "text-gray-600"
            }`}
            onClick={() => {
              setPanelOpen(true);
            }}
          >
            <span className="flex items-center whitespace-nowrap">
              <AdjustmentsIcon className="mr-2 block size-5" aria-hidden="true" />
              All Filters{numActiveFilters > 0 ? ` • ${numActiveFilters}` : ""}
            </span>
          </button>
          <SavedSearchesMenu />
        </div>
      )}

      {isMobile && (
        <div className="z-40 flex min-w-fit flex-row bg-white pr-2 md:pr-3">
          <button
            className={`text-md mx-2 flex items-center justify-between rounded-md px-2 py-2 text-left text-sm font-medium sm:pl-3 sm:pr-4 ${
              numActiveFilters > 0 ? "bg-black text-white" : "text-gray-600"
            }`}
            onClick={() => {
              setPanelOpen(true);
            }}
          >
            <span className="flex items-center whitespace-nowrap">
              <AdjustmentsIcon className="block size-5" aria-hidden="true" />
              {numActiveFilters > 0 ? ` • ${numActiveFilters}` : ""}
            </span>
          </button>
        </div>
      )}

      <SlidingPanel
        isPaneOpen={panelOpen}
        onClose={() => {
          setPanelOpen(false);
        }}
        numActiveFilters={numActiveFilters}
        onResetAllFilters={onResetAllFiltersClick}
        numFilteredProperties={filteredProperties?.length}
      >
        <div className="text-md w-full bg-white text-gray-500">
          <div className="w-full px-4">
            <div className="p-2">
              <div className="border-b">
                {isMobile ? (
                  <AllFiltersSavedSearches />
                ) : (
                  <PropertiesPriceSlider
                    priceMin={filters.min_listing_price}
                    priceMax={filters.max_listing_price}
                    priceRangeSet={priceRangeSet}
                    autoApply={true}
                  />
                )}
              </div>
              <div className="mt-4 pb-4 pt-2">
                {isMobile && (
                  <MinMaxPriceDropdowns
                    label="Price"
                    variable="listing_price"
                    control={control}
                    handleChange={debHandleChange}
                    setValue={setValue}
                    getValues={getValues}
                    isCurrency={true}
                  />
                )}
                <MinMaxFilterDropdowns
                  label="Gross Gain"
                  variable="gross_gain"
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  getValues={getValues}
                  options={[0, 10, 20, 30, 40, 50]}
                />

                <MinMaxFilterDropdowns
                  label="Income"
                  variable="gross_income"
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  getValues={getValues}
                  options={[-10, -5, 0, 5, 10]}
                />

                <LoanFilter registration={assumableLoadRegistration} />

                <MinMaxFilterDropdowns
                  label="Estimated Equity"
                  variable="equity"
                  control={control}
                  handleChange={debHandleChange}
                  setValue={setValue}
                  getValues={getValues}
                  options={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                />

                <HideUnknownEquityFilter registration={hideUnknownEquityRegistration} />
              </div>

              <div className="mt-4 border-t pt-6">
                <StructureFilters
                  structureTypeValues={structureTypeValues}
                  handleClick={onStructureTypeClick}
                />
              </div>

              <div className="mt-2 gap-4">
                <div className="text-md">
                  <div className="flex w-full flex-col gap-2 py-4">
                    <MinMaxFilterInputs
                      label="Finished Sqft"
                      variable="square_feet_finished"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <MinMaxFilterInputs
                      label="Unfinished Sqft"
                      variable="unfinished_basement"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <MinMaxFilterInputs
                      label="Year Built"
                      variable="year_built"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <MinMaxFilterInputs
                      label="Bedrooms"
                      variable="bedrooms"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <MinMaxFilterInputs
                      label="Bathrooms"
                      variable="bathrooms"
                      control={control}
                      handleChange={debHandleChange}
                      setValue={setValue}
                    />

                    <div className="mt-4 border-t pt-4">
                      <MinMaxFilterInputs
                        label="Lot Sqft"
                        variable="lot_square_feet"
                        control={control}
                        handleChange={debHandleChange}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Property filters */}
              <div className="mt-4 border-t border-gray-200 pt-6">
                <div className="mb-2 text-sm font-bold text-gray-700">Last status update</div>
                <div className="flex flex-col flex-wrap gap-2">
                  {listingEventOptions.map((listingEventOption) => (
                    <label key={`lt-${listingEventOption}`} className="mr-4">
                      <input
                        type="checkbox"
                        value={listingEventOption}
                        className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                        {...register("listing_event", { onChange: handleChange })}
                      />
                      {listingEventOption}
                    </label>
                  ))}
                </div>
                <div className="flex w-full flex-col gap-2 py-4">
                  <MinMaxFilterInputs
                    label="Days Since Update"
                    variable="updated"
                    control={control}
                    handleChange={debHandleChange}
                    setValue={setValue}
                  />

                  <MinMaxFilterInputs
                    label="Days on Market"
                    variable="days_on_market"
                    control={control}
                    handleChange={debHandleChange}
                    setValue={setValue}
                  />
                </div>
              </div>

              <div className="mt-6 border-t border-gray-200 pt-5">
                <div className="mb-2 text-sm font-bold text-gray-700">Listing Type</div>
                <div className="flex flex-col flex-wrap gap-2">
                  {listingTypeOptions.map((listingTypeOption) => (
                    <label key={`lt-${listingTypeOption}`} className="mr-4">
                      <input
                        type="checkbox"
                        value={listingTypeOption}
                        className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
                        {...register("listing_type", { onChange: handleChange })}
                      />
                      {listingTypeOption}
                    </label>
                  ))}
                </div>
              </div>

              {/* Listing remarks search box */}
              <div className="mt-6 border-t border-gray-200 pt-5">
                <div className="mb-2 text-sm font-bold text-gray-700">Listing Remarks</div>
                <ListingRemarksSearchBox value={listingRemarks} onChange={onListingRemarksChange} />
              </div>
            </div>

            {isMobile && (
              <div className="mt-6 w-full justify-end pt-1 pr-2 text-black text-right text-xs">
                <span className="font-bold">{filteredProperties?.length} matches</span> out of
                <span className="font-bold"> {totalSearchResults.length}</span> listings
              </div>
            )}

            {/* <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-3 ">
              <div>
                <FlipRentalTabs register={register} handleChange={debHandleChange} />
              </div>
            </div> */}
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};
