import { ListItemDisclosure } from "components/browse";
import { useEffect, useRef } from "react";
import TimeAgo from "react-timeago";

type Props = {
  property: any;
  selected?: boolean;
};

export const PropertyListItemWholesale = ({ property, selected = false }: Props) => {
  const selectedPropertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedPropertyRef.current && selected) {
      selectedPropertyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);

  return (
    <>
      <td className="flex-1 flex-col py-2">
        <div className="flex flex-col" ref={selectedPropertyRef}>
          <div className="flex flex-row items-center text-xs">
            <img
              src={`https://s3-us-west-2.amazonaws.com/cdn.davinci.pellego.com/static/images/black/${property.structureType}.svg`}
              className="mx-2 h-3"
              alt={property.structureType}
            />
            Register to view
          </div>
        </div>
        <ListItemDisclosure property={property} />
      </td>
      <td className="hidden whitespace-nowrap p-2 text-xs xl:table-cell">
        <div className="flex flex-col">
          <TimeAgo
            date={new Date(property.listingEventDate)}
            live={false}
            formatter={(value, unit, suffix) => {
              return `${value}${unit === "month" ? "mo" : unit.slice(0, 1)} ${suffix}`;
            }}
          />
          <div className="whitespace-nowrap pt-1 text-xs tracking-tighter text-gray-400">
            {property.listingEvent}
          </div>
        </div>
      </td>

      <td className="whitespace-nowrap p-2 text-xs">Register</td>
      <td className="whitespace-nowrap p-2 text-xs">Register</td>

      <td className="hidden whitespace-nowrap p-2 text-xs xl:table-cell">Register</td>
      <td className="hidden whitespace-nowrap py-2 text-xs xl:table-cell">Register</td>
    </>
  );
};
