import React from "react";

type Props = {
  errMsg?: any;
  children?: React.ReactNode;
  value?: string;
};

export { Checkbox };

const Checkbox = React.forwardRef(
  ({ errMsg, children, value, ...rest }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <label className="flex items-center">
        <input
          type="checkbox"
          value={value ? value : "true"}
          ref={ref}
          className={`${errMsg ? "border-red-500" : ""} `}
          {...rest}
        />
        <span className="ml-3">{children}</span>
        {errMsg && <div className="text-red-500">{errMsg}</div>}
      </label>
    );
  },
);
