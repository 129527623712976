import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper, RegisterClientForm } from "components";
import { useRegisterClientModal } from "state";

export const RegisterClientModal = () => {
  const { closeRegisterClientModal, showRegisterClientModal } =
    useRegisterClientModal();

  const closeModal = () => {
    closeRegisterClientModal();
  };

  return (
    <ModalWrapper isOpen={showRegisterClientModal} closeModal={closeModal}>
      <div
        className={`${
          !showRegisterClientModal && "lg:max-w-2xl"
        } inline-block w-full max-w-md transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all`}
      >
        <button className="float-right outline-none" onClick={closeModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>
        {showRegisterClientModal && <RegisterClientForm />}
      </div>
    </ModalWrapper>
  );
};
