import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/react/outline";
import { cn, formatCurrencyK1 } from "helpers";
import { Fragment, useRef, useState } from "react";

type Props = {
  className?: string;
  placeholder?: string;
  label: string;
  value: number | null;
  options: number[];
  onChange: CallableFunction;
  hidePlaceholderWithValue?: boolean;
};

export const FilterPriceDropdownMenu = ({
  className,
  placeholder,
  label,
  value,
  options,
  onChange,
  hidePlaceholderWithValue,
}: Props) => {
  const inputRef = useRef<any>();

  const [customValue, setCustomValue] = useState<number | null>(null);
  const [inputPlaceholder, setInputPlaceholder] = useState("Enter value");

  const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : null;
    setCustomValue(newValue);
    if (newValue !== null && newValue > options[0] && newValue < options[options.length - 1]) {
      onChange(newValue);
    }
  };

  return (
    <Menu as="div" className="relative flex w-full">
      {({ open }) => (
        <>
          <Menu.Button className={cn(className, "flex w-full justify-end py-2 pl-2 pr-1 sm:pl-3 sm:pr-2")}>
            <span className="flex items-center whitespace-nowrap text-gray-600">
              {value !== null ? (value === +Infinity ? "Any Price" : formatCurrencyK1(value)) : placeholder}
            </span>
            {open ? (
              <>
                {inputRef?.current?.focus()}
                <ChevronUpIcon className="ml-2 size-4" />
              </>
            ) : (
              <>
                {value !== null ? (
                  <XIcon
                    className="ml-2 size-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(null);
                    }}
                  />
                ) : (
                  <ChevronDownIcon className="ml-2 size-4" />
                )}
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 flex w-full origin-top-left justify-end rounded-md shadow-lg">
              <div>
                <input
                  ref={inputRef}
                  type="number"
                  className="h-9 w-full items-center border border-dotted border-gray-400 p-1 text-right ring-1 ring-black ring-opacity-5 focus:border-gray-400 focus:outline-none focus:ring-black"
                  placeholder={inputPlaceholder}
                  value={customValue !== null ? customValue : ""}
                  onChange={handleCustomValueChange}
                  onFocus={() => setInputPlaceholder("")}
                  onBlur={() => setInputPlaceholder("Enter value")}
                />
                <div className="h-fit max-h-56 overflow-y-scroll rounded-md">
                  {options.map((option) => (
                    <Menu.Item key={option}>
                      <button
                        onClick={() => {
                          setCustomValue(null);
                          setInputPlaceholder("Enter value");
                          onChange(option);
                        }}
                        className={cn(
                          "block w-full px-4 py-2 text-right text-sm",
                          value === option
                            ? "bg-[#6389c4] text-white"
                            : "bg-white text-gray-600 hover:bg-[#6389c4] hover:text-white",
                        )}
                      >
                        {option === +Infinity ? "Any Price" : formatCurrencyK1(option)}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
