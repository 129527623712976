import { XIcon } from "@heroicons/react/outline";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "components/forms";
import debounce from "lodash/debounce";
import Pluralize from "pluralize";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { atom, useRecoilState } from "recoil";
import { useProforma } from "state/proformas";
import {
  rentalCompFiltersMinMaxAttrs,
  structureTypes,
  useMoreRentalComparables,
  useRentalCompsFilters,
} from "state/proformas/comparables";
import * as Yup from "yup";


export const rentalCompsFormIsDirtyAtom = atom<boolean>({
  key: "rentalCompsFormIsDirtyAtom",
  default: false,
});

const numberNullable = Yup.number()
  .nullable(true)
  .transform((val) => (val === Number(val) ? val : null));

// Filters fields transformation rules
const validationSchema = Yup.object().shape({
  structure_types: Yup.array().of(Yup.string()),
  min_bedrooms: numberNullable,
  max_bedrooms: numberNullable,
  min_bathrooms: numberNullable,
  max_bathrooms: numberNullable,
  min_square_feet_finished: numberNullable,
  max_square_feet_finished: numberNullable,
  min_lot_square_feet: numberNullable,
  max_lot_square_feet: numberNullable,
  min_parking_count: numberNullable,
  max_parking_count: numberNullable,
  min_year_built: numberNullable,
  max_year_built: numberNullable,
  min_monthly_rent: numberNullable,
  max_monthly_rent: numberNullable,
  min_price_per_square_foot: numberNullable,
  max_price_per_square_foot: numberNullable,
  distance: numberNullable,
});

type Props = {
  allFilters: boolean;
  setShowModal: CallableFunction;
};

export const RentalCompFiltersForm = ({ allFilters, setShowModal }: Props) => {
  const { proforma } = useProforma();
  const { rentalCompFilters, setRentalCompFilters, rentalCompFiltersBackup } = useRentalCompsFilters();
  const { searchMoreRentalComps, loading, numMoreComps } = useMoreRentalComparables(proforma);
  const [formIsDirty, setFormIsDirty] = useRecoilState(rentalCompsFormIsDirtyAtom);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: rentalCompFilters,
  };

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm(formOptions);

  const submitForm = async (formData: any) => {
    const newFilters = validationSchema.cast(getValues());
    setRentalCompFilters(newFilters);
    searchMoreRentalComps(newFilters);
    reset(newFilters);
    setFormIsDirty(false);
  };

  const handleChange = useCallback(() => {
    const newFilters = validationSchema.cast(getValues());
    setRentalCompFilters(newFilters);
    setFormIsDirty(true);
  }, [setRentalCompFilters, getValues, setFormIsDirty]);

  // See: https://dmitripavlutin.com/react-throttle-debounce/
  const debHandleChange = useMemo(() => debounce(handleChange, 100), [handleChange]);
  useEffect(() => {
    return () => {
      debHandleChange.cancel();
    };
  }, [debHandleChange]);

  const clearMinMax = (attr: string) => {
    const filters = validationSchema.cast(getValues());
    let newFilters;
    if (filters[attr]) {
      newFilters = { ...filters, [attr]: null };
    } else {
      newFilters = { ...filters, [`min_${attr}`]: null, [`max_${attr}`]: null };
    }
    setRentalCompFilters(newFilters);
    setFormIsDirty(true);
  };

  const resetFilters = () => {
    setRentalCompFilters(rentalCompFiltersBackup);
    reset(rentalCompFiltersBackup);
    setFormIsDirty(true);
  };

  const openAllFilters = () => {
    const newFilters = validationSchema.cast(getValues());
    setRentalCompFilters(newFilters);
    setShowModal(true);
  };

  useEffect(() => {
    reset(rentalCompFilters);
  }, [rentalCompFilters, reset]);

  useEffect(() => {
    if (isDirty) setFormIsDirty(true);
  }, [setFormIsDirty, isDirty]);

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        {allFilters ? (
          <>
            {/* Property type buttons */}
            <div className="mt-2 border-t border-gray-200 pt-3">
              <div className="mb-1 mt-2 text-xs font-bold text-gray-700">Structure Type</div>
              <div className="flex flex-row flex-wrap gap-2">
                {structureTypes.map((structureType) => (
                  <label key={`t-${structureType}`} className="flex items-center">
                    <input
                      type="checkbox"
                      value={structureType}
                      className="mr-2 border text-blue-500 outline-0 indeterminate:bg-gray-300 focus:ring-0"
                      {...register("structure_types", { onChange: debHandleChange })}
                    />
                    {structureType}
                  </label>
                ))}
              </div>
            </div>

            {/* Property filters */}
            <div className="mt-4 gap-4 border-t border-gray-200 pt-4">
              <div className="grid w-full grid-cols-1 gap-10">
                <div>
                  <div className="mb-2 flex flex-row justify-items-stretch gap-2">
                    <div className="w-3/12 whitespace-nowrap text-xs font-bold text-gray-700">
                      Characteristic
                    </div>
                    <div className="w-3/12 whitespace-nowrap text-right text-xs font-bold text-gray-700">
                      Subject
                    </div>
                    <div className="w-1/12 text-center text-xs font-bold"></div>
                    <div className="w-2/12 text-center text-xs font-bold">Min</div>
                    <div className="w-2/12 text-center text-xs font-bold">Max</div>
                    <div className="w-1/12 text-center text-xs font-bold"></div>
                  </div>

                  {rentalCompFiltersMinMaxAttrs.map((attr) => (
                    <div key={attr.key} className="mb-1 flex flex-row justify-items-stretch gap-2">
                      <div className="w-3/12 whitespace-nowrap">{attr.label}</div>
                      <div className="w-3/12 whitespace-nowrap text-right text-gray-500">
                        {attr.key === "price_per_square_foot" && (
                          <NumberFormat
                            value={proforma.parcel._characteristics.ppsf}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$ "
                            decimalScale={0}
                          />
                        )}
                        {attr.key !== "monthly_rent" && attr.key !== "price_per_square_foot" && (
                          <NumberFormat
                            value={proforma.parcel._characteristics[attr.key]}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={proforma.parcel._characteristics[attr.key] % 1 === 0 ? 0 : 1}
                          />
                        )}
                      </div>
                      <div className="w-1/12 text-center text-xs font-bold"></div>
                      <div className="w-2/12 text-xs">
                        {attr.key.includes("price") ? <>$</> : <>&nbsp;&nbsp;</>}
                        <input
                          className="h-5 w-[90%] border border-gray-300 p-0 px-2 py-2.5 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0"
                          type="number"
                          min={0}
                          step={attr.key.endsWith("rooms") ? 0.25 : 1}
                          {...register(`min_${attr.key}`, { onChange: debHandleChange })}
                        />
                      </div>
                      <div className="w-2/12 text-xs">
                        {attr.key.includes("price") ? <>$</> : <>&nbsp;&nbsp;</>}
                        <input
                          className="h-5 w-[90%] border border-gray-300 p-0 px-2 py-2.5 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0"
                          type="number"
                          step={attr.key.endsWith("rooms") ? 0.25 : 1}
                          min={0}
                          {...register(`max_${attr.key}`, { onChange: debHandleChange })}
                        />
                      </div>
                      <div className="w-1/12">
                        <button
                          type="button"
                          className="cursor-pointer"
                          onClick={() => clearMinMax(attr.key)}
                          aria-label="Clear"
                        >
                          <XIcon className="h-2 w-2" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="mb-1 mt-2 flex flex-row justify-items-stretch gap-2">
                    <div className="w-6/12 whitespace-nowrap">Distance</div>
                    <div className="w-3/12 text-right text-xs">
                      <input
                        className="h-5 w-[55%] border border-gray-300 p-0 px-2 py-2.5 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0"
                        type="number"
                        step={0.01}
                        min={0}
                        {...register("distance", { onChange: debHandleChange })}
                      />
                    </div>
                    <div className="w-2/12 whitespace-nowrap text-gray-400">Miles</div>
                    <div className="w-1/12">
                      <button
                        type="button"
                        className="cursor-pointer"
                        onClick={() => clearMinMax("distance")}
                        aria-label="Clear"
                      >
                        <XIcon className="h-2 w-2" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row justify-around">
                    <div className="flex h-14 items-center">
                      <button
                        type="button"
                        className="focus:shadow-outline items-center text-gray-400 focus:outline-none"
                        onClick={resetFilters}
                        aria-label="Reset"
                      >
                        Reset Filters
                      </button>
                    </div>
                    <div className="flex h-14 items-center">
                      {(formIsDirty || loading) && (
                        <SubmitButton
                          isSubmitting={loading}
                          disabled={loading}
                          buttonClassName="min-w-36 py-1 px-6 my-2 text-white text-sm bg-[#7AA0BA] hover:bg-[#4f7b98]"
                          spinnerClassName="mr-2 size-4"
                        >
                          {loading ? <>Updating...</> : <>Update Search</>}
                        </SubmitButton>
                      )}
                      {!formIsDirty && !loading && numMoreComps !== null && (
                        <span
                          className="mr-4 flex cursor-pointer text-sm font-medium text-blue-700"
                          onClick={() => setShowModal(false)}
                        >
                          {Pluralize("matching comp", numMoreComps, true)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Comparables status filters */}

            {/* Comparables characteristics filters */}
            <div className="mt-4 text-sm leading-7">
              <div className="flex flex-row justify-items-stretch gap-2">
                <div className="w-5/12 whitespace-nowrap text-xs font-bold text-gray-700">
                  Characteristics{" "}
                </div>
                <div className="w-3/12 text-center text-xs font-bold">Min</div>
                <div className="w-3/12 text-center text-xs font-bold">Max</div>
                <div className="w-1/12 text-center text-xs font-bold"></div>
              </div>

              {rentalCompFiltersMinMaxAttrs
                .filter((attr) =>
                  ["bedrooms", "bathrooms", "square_feet_finished", "lot_square_feet"].includes(attr.key),
                )
                .map((attr) => (
                  <div key={attr.key} className="mt-1 flex flex-row justify-items-stretch gap-2">
                    <div className="w-5/12 whitespace-nowrap">{attr.label}</div>
                    <div className="w-3/12 text-center">
                      <input
                        className="h-5 w-[90%] border border-gray-300 px-2 py-2.5 text-center text-sm outline-0 placeholder:text-gray-400 focus:border-b focus:border-orange-400 focus:border-transparent focus:ring-0"
                        type="number"
                        step={attr.key.endsWith("rooms") ? 0.25 : 1}
                        min={0}
                        {...register(`min_${attr.key}`, { onChange: debHandleChange })}
                      />
                    </div>
                    <div className="w-3/12 text-center">
                      <input
                        className="h-5 w-[90%] border border-gray-300 px-2 py-2.5 text-center text-sm outline-0 placeholder:text-gray-400 focus:border-b focus:border-gray-400 focus:ring-0"
                        type="number"
                        step={attr.key.endsWith("rooms") ? 0.25 : 1}
                        min={0}
                        {...register(`max_${attr.key}`, { onChange: debHandleChange })}
                      />
                    </div>
                    <div className="w-1/12">
                      <button
                        type="button"
                        className="cursor-pointer"
                        onClick={() => clearMinMax(attr.key)}
                        aria-label="Clear"
                      >
                        <XIcon className="h-2 w-2" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                ))}

              <div className="flex flex-row justify-between">
                <div className="flex h-14 items-center">
                  <button
                    type="button"
                    onClick={() => openAllFilters()}
                    className="ml-auto flex text-sm text-blue-700"
                  >
                    <AdjustmentsIcon className="mr-2 h-4 w-4 text-blue-700" />
                    Edit all filters
                  </button>
                </div>
                <div className="flex h-14 items-center">
                  {(formIsDirty || loading) && (
                    <SubmitButton
                      isSubmitting={loading}
                      disabled={loading}
                      buttonClassName="min-w-36 py-1 px-6 my-4 text-white text-sm bg-[#7AA0BA] hover:bg-[#4f7b98]"
                      spinnerClassName="mr-2 size-4"
                    >
                      {loading ? <>Updating...</> : <>Update Search</>}
                    </SubmitButton>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};