import { appConfig } from "config";

const fetchLocationAppreciation = async (type: string, id: number) => {
  return fetch(
    appConfig.apiEndpoint.locationAppreciation.replace(":type", type).replace(":id", id.toString()),
  ).then((res) => res.json());
};

export const fetchLocationAppreciations = (locations: any[]) => {
  return Promise.all(locations.map((location) => fetchLocationAppreciation(location.type, location.id)));
};
