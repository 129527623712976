import { RegisterClientForm } from "components/RegisterClientForm";
// import { useUserSession } from "state";

export const RegisterClient = () => {
  // const currentUser = useUserSession();
  return (
    // <>
    //   {currentUser.isClient ? (
    //     <div className="mx-auto min-h-screen mt-20 text-lg max-w-2xl px-8 pb-4 text-center">
    //       Your are already registered as a wholesale client
    //     </div>
    //   ) : (
        <div>
          <RegisterClientForm />
        </div>
    //   )}
    // </>
  );
};
