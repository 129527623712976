import { yupResolver } from "@hookform/resolvers/yup";
import { useUserActions } from "actions";
import { Alert } from "components";
import { LabeledInput, SubmitButton } from "components/forms";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAlerts, useSignInSignUpModal } from "state";
import * as Yup from "yup";

type Props = {
  asModal?: boolean;
};

export const SignIn = ({ asModal }: Props) => {
  const userActions = useUserActions();
  const alert = useAlerts();
  const notEmailConfirmed = alert?.message?.includes("Please confirm your email");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors } = formState;
  const { openSignUpModal, openPasswordResetModal } = useSignInSignUpModal();

  const onSubmit = (userData: any) => {
    setIsSubmitting(true);
    if (notEmailConfirmed) {
      userActions
        .resendConfirmationEmail(userData.email, false)
        .finally(() => setIsSubmitting(false));
    } else {
      userActions.login(userData, asModal).finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div className="mx-auto mt-2 max-w-2xl px-8 pb-4">
      <h3 className="mt-8 text-3xl">{!notEmailConfirmed ? <>Sign In</> : <>Confirm Your Email</>}</h3>
      <div className="mt-4 max-w-md">
        <Alert />

        <div className="grid grid-cols-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!notEmailConfirmed && (
              <>
                <LabeledInput
                  type="email"
                  label="Email"
                  {...register("email")}
                  errMsg={errors.email?.message}
                />
                <LabeledInput
                  type="password"
                  label="Password"
                  {...register("password")}
                  errMsg={errors.password?.message}
                />
              </>
            )}
            <SubmitButton isSubmitting={isSubmitting}>
              {!notEmailConfirmed ? <>Sign In</> : <>Resend Confirmation Email</>}
            </SubmitButton>
            {!notEmailConfirmed && (
              <div className="mt-2 text-sm">
                Forgot Password?{" "}
                <Link
                  to="/password-reset"
                  className="text-blue-700"
                  onClick={(e) => {
                    if (asModal) {
                      e.preventDefault();
                      openPasswordResetModal();
                    }
                  }}
                >
                  Reset Password
                </Link>
                <br />
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  className="text-blue-700"
                  onClick={(e) => {
                    if (asModal) {
                      e.preventDefault();
                      openSignUpModal();
                    }
                  }}
                >
                  Create Free Account
                </Link>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
