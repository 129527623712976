import { GoogleChartWrapperChartType } from "react-google-charts";
import { formatNumber } from "helpers";
import { reduceToYearly } from "state/proformas";

export type GraphTypes = "equity" | "monthlycashflow" | "netprofit" | "rates";

const graph_lightblue = '#64B5F6';
const graph_darkblue = '#1976d2';
const graph_purple = '#9C27B0';
const graph_pink = '#d8476f';

export class RentalGraphClass {
  mathService: any;
  options: any;
  data: any[] = [];
  graphType: GraphTypes;
  chartType?: GoogleChartWrapperChartType;
  minProfit: number = 0;
  infiniteROI: boolean = false;
  availroi: boolean = false;
  availirr: boolean = false;

  constructor(mathSercice: any, graphType: GraphTypes) {
    this.mathService = mathSercice;
    this.graphType = graphType;
    this.data = this.buildGraphData();
    this.options = this.buildGraphOptions();
  }

  buildGraphData() {
    switch (this.graphType) {
      case "equity":
        return this.buildEquityData();
      case "monthlycashflow":
        return this.buildCashFlowData();
      case "netprofit":
        return this.buildTotalProfitData();
      case "rates":
        return this.buildRatesData();
    }
    return [];
  }

  buildGraphOptions() {
    switch (this.graphType) {
      case "equity":
        return this.buildEquityOptions();
      case "monthlycashflow":
        return this.buildCashFlowOptions();
      case "netprofit":
        return this.buildTotalProfitOptions();
      case "rates":
        return this.buildRatesOptions();
    }
    return {}
  }

  zipMonths(annualize: boolean, startFrom: 0 | 1, ...args: any[]) {
    const matrix = [];
    // The first boolean passed is whether to "annualize" the months

    for (let i = 0; i < args[0].length; i++) {
      const row = [i + startFrom];

      // Specify each X-value as a fraction of a year
      if (annualize) {
        row[0] /= 12;
      }

      args.forEach((arg) => {
        if (arg.length) {
          row.push(arg[i]);
        }
      });
      matrix.push(row);
    }

    return matrix;
  }

  generateTimestampTooltip(k: number, initial: number = 0) {
    const k_year = Math.floor((k - initial) / 12) + 1;
    let k_month;

    if (k === 0 && initial !== 0) {
      return '<div class="rentgraph-table-row">' +
        '<div class="rentgraph-table-label"><strong>' +
        'Initial Values</strong></div></div>';
    }
    else {
      k_month = ((k - initial) % 12) + 1;
      return '<div class="rentgraph-table-row">' +
        '<div class="rentgraph-table-label"><strong>' +
        'Year ' + k_year + ' Month ' + k_month +
        '</strong></div></div>';
    }
  }

  getCommonOptions(): any {
    const gridlines = [];
    const holdingTime = this.mathService.values.holding_time
    var i, mult, hPadding;
    if (holdingTime <= 120) {
      mult = 1;
      hPadding = 0;
    }
    else if (holdingTime > 600) {
      mult = 10;
      hPadding = 1;
    }
    else {
      mult = 5;
      hPadding = 1;
    }
    for (i = 0; i <= Math.ceil(holdingTime / 12 / mult); i++) {
      gridlines.push(i * mult);
    }

    return {
      height: 250,
      chartArea: {
        top: 0,
        height: '80%',
        width: '90%',
      },
      backgroundColor: 'transparent',
      curveType: 'function',
      focusTarget: 'category',
      legend: { position: 'none' },
      hAxis: {
        format: undefined,
        ticks: gridlines,
        title: 'Year',
        viewWindow: {
          min: -hPadding,
          max: Math.ceil(holdingTime / 12) + hPadding,
        },
        baselineColor: 'none',
      },
      isStacked: '',
      tooltip: {
        isHtml: true,
      },
      vAxis: {
        format: 'decimal',
        textPosition: 'in',
      },
    };
  }

  ///////////////// Net Equity /////////////////

  buildEquityData() {
    const columns = [
      { type: 'number', label: 'Month' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'number', label: 'Cash In' },
      { type: 'number', label: 'Selling Costs' },
      { type: 'number', label: 'Loan Balance' },
      { type: 'number', label: 'Net Equity' },
    ];

    const rows = this.zipMonths(
      true,
      1,
      this.generateEquityTooltip(),
      this.mathService.netCashIn(),
      this.mathService.netSellingCosts(),
      this.mathService.netPrincipalBalance(),
      this.mathService.netTotalEquity()
    );

    return [columns, ...rows];
  }

  generateEquityTooltip() {
    var eqTooltip = [];

    var propvalue = this.mathService.netPropertyValue();
    var loanbalance = this.mathService.netPrincipalBalance();
    var sellcosts = this.mathService.netSellingCosts();
    var cashin = this.mathService.netCashIn();
    var netequity = this.mathService.netTotalEquity();

    // How many times? The minimum number of times!
    var iters = Math.min(netequity.length, loanbalance.length, propvalue.length);

    for (var k = 0; k < iters; k++) {

      var p = propvalue[k].toLocaleString();
      var l = loanbalance[k].toLocaleString();
      var s = sellcosts[k].toLocaleString();
      var c = cashin[k].toLocaleString();
      var e = netequity[k].toLocaleString();

      var m = this.generateTimestampTooltip(k, 1);
      var pv = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Property Value: </td>' +
        '<td class="rentgraph-table-amount">$ ' + p + '</td></tr>';
      var lb = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Loan Balance: </td>' +
        '<td class="rentgraph-table-amount">' + l + '</td></tr>';
      var sc = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Selling Costs: </td>' +
        '<td class="rentgraph-table-amount">' + s + '</td></tr>';
      var ci = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Cash In: </td>' +
        '<td class="rentgraph-table-amount">' + c + '</td></tr>';
      var eq = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'Net Equity: </strong></td>' +
        '<td class="rentgraph-table-amount"><strong>$ ' +
        e + '</strong></td></tr>';

      var content = '<div class="rentgraph-tooltip">' +
        m + '<table class="rentgraph-table tt-table">' +
        pv +
        '<tr><td colspan="2"><hr></td></tr>' +
        eq +
        lb + sc + ci +
        '</table></div>';

      eqTooltip.push(content);
    }
    return eqTooltip;
  }

  buildEquityOptions() {
    const options = this.getCommonOptions();
    options.isStacked = true;
    options.vAxis.minValue = 0;
    options.vAxis.title = 'Property Value ($)';

    options.series = {
      0: { color: graph_pink, },
      1: { color: graph_darkblue, },
      2: { color: graph_lightblue, },
      3: { color: graph_purple, },
    };

    this.chartType = "AreaChart";

    return options;
  }

  ///////////////// Cash Flow /////////////////

  buildCashFlowData() {
    const columns = [
      { type: 'number', label: 'Month' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'number', label: 'Loan Payment' },
      { type: 'number', label: 'Monthly Costs' },
      { type: 'number', label: 'Profit/Loss' },
    ];

    const rows = this.zipMonths(
      true,
      0,
      this.generateCashFlowTooltip(),
      this.mathService.monthlyLoanPayment(),
      this.mathService.monthlyHoldingCosts(),
      this.mathService.monthlyProfit(),
    );

    return [columns, ...rows];
  }

  generateCashFlowTooltip() {
    var cfTooltip = [];

    var revenue = this.mathService.monthlyRent();
    var costs = this.mathService.monthlyHoldingCosts();
    var loanpmt = this.mathService.monthlyLoanPayment();
    var profits = this.mathService.monthlyProfit();
    var roi = this.mathService.netROI();
    var showLoan = (this.mathService.values.strategy === 'financed_hold' && this.mathService.values.holdLoanEnabled) || (this.mathService.values.strategy === 'financed_brrr' && this.mathService.values.brrrLoanEnabled);
    for (var k = 0; k < costs.length; k++) {
      var r = revenue[k].toLocaleString();
      var l = loanpmt[k] ? '-' + loanpmt[k].toLocaleString() : '0';
      var p = profits[k].toLocaleString();
      var ret = formatNumber(roi[k], 1);
      var c;
      if (showLoan) {
        c = costs[k].toLocaleString();
      } else {
        c = (revenue[k] - profits[k]).toLocaleString();
      }

      var m = this.generateTimestampTooltip(k);

      var rev = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Revenue: </td>' +
        '<td class="rentgraph-table-amount">$ ' + r + '</td></tr>';
      var lp = '<tr class="rentgraph-table-costrow">' +
        '<td class="rentgraph-table-label">' +
        'Loan Pmt: </td>' +
        '<td class="rentgraph-table-amount">' + l + '</td></tr>';
      var cost = '<tr class="rentgraph-table-costrow">' +
        '<td class="rentgraph-table-label">' +
        'Other Costs: </td>' +
        '<td class="rentgraph-table-amount">-' + c + '</td></tr>';
      var prof = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'Month\'s Profit: </strong></td>' +
        '<td class="rentgraph-table-amount"><strong>$ ' +
        p + '</strong></td></tr>';

      var rate = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'Cash Flow on Cash: </strong></td>' +
        '<td class="rentgraph-table-amount"><strong> ' +
        (ret ? ret + '%' : 'None') + '</strong></td></tr>';

      var content = '<div class="rentgraph-tooltip">' +
        m + '<table class="rentgraph-table tt-table">' +
        rev
      if (showLoan) {
        content = content + lp + cost + '<tr><td colspan="2"><hr></td></tr>' +
        prof + rate + '</table></div>';
      } else {
        content = content + cost + '<tr><td colspan="2"><hr></td></tr>' +
        prof + rate + '</table></div>';
      }

      cfTooltip.push(content);
    }
    return cfTooltip;
  }

  buildCashFlowOptions() {
    const options = this.getCommonOptions();
    options.isStacked = true;
    options.vAxis.title = 'Gross Revenue ($)';
    options.vAxis.minValue = 0;

    options.series = {
      0: { color: graph_darkblue, },
      1: { color: graph_lightblue },
      2: { color: graph_purple, },
    };

    this.chartType = "AreaChart";

    return options;
  }

  ///////////////// Total Profit /////////////////

  buildTotalProfitData() {
    const columns = [
      { type: 'number', label: 'Year' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'number', label: 'Net Equity' },
      { type: 'number', label: 'Comp. Cash Flow' },
    ];

    const netEquity = reduceToYearly(this.mathService.netTotalEquity(), true);
    const netProfit = reduceToYearly(this.mathService.netProfit(), true);

    this.minProfit = Math.min(...netEquity, ...netProfit);

    const rows = this.zipMonths(
      false,
      1,
      this.generateTotalProfitTooltip(),
      netEquity,
      netProfit,
    );

    return [columns, ...rows];
  }

  generateTotalProfitTooltip() {
    var profTooltip = [];
    var lequity = this.mathService.netTotalEquity();
    var profit = this.mathService.netProfit();

    // Consolidate the equity and profits to yearly amounts
    var evals = reduceToYearly(lequity, true);
    var cvals = reduceToYearly(profit, true);


    for (var k = 0; k < evals.length; k++) {
      var e = evals[k].toLocaleString();
      var c = cvals[k].toLocaleString();
      var t = (evals[k] + cvals[k]).toLocaleString();

      var m = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'End of Year </strong></td>' +
        '<td class="rentgraph-table-amount"><strong> ' +
        (k + 1) + '</strong></td></tr>';

      var eq = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Net Equity: </td>' +
        '<td class="rentgraph-table-amount">$ ' + e + '</td></tr>';
      var nc = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Cash Flow: </td>' +
        '<td class="rentgraph-table-amount">$ ' + c + '</td></tr>';
      var ttl = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'Total: </strong></td>' +
        '<td class="rentgraph-table-amount"><strong>$ ' +
        t + '</strong></td></tr>';

      var content = '<div class="rentgraph-tooltip">' +
        m + '<table class="rentgraph-table tt-table">' +
        nc + eq + '<tr><td colspan="2"><hr></td></tr>' +
        ttl + '</table></div>';

      profTooltip.push(content);
    }
    return profTooltip;
  }

  buildTotalProfitOptions() {
    const options = this.getCommonOptions();
    options.isStacked = true;
    options.vAxis.title = 'Cumulative Profit ($)';

    // Go one past on the right so we can see the whole bar
    options.hAxis.viewWindow.min = 0;
    if (this.mathService.values.holding_time <= 120) {
      options.hAxis.viewWindow.max += 1;
    }

    options.vAxis.viewWindow = { min: this.minProfit * 2 };

    options.series = {
      0: { color: graph_purple },
      1: { color: graph_lightblue },
    };

    this.chartType = "ColumnChart";

    return options;
  }

  ///////////////// Rates of Return /////////////////

  buildRatesData() {
    const columns = [
      { type: 'number', label: 'Year' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'number', label: 'IRR' },
      { type: 'number', label: 'Compound Annual Growth Rate' },
    ];

    const rows = this.zipMonths(
      false,
      1,
      this.generateRatesTooltip(),
      this.mathService.netIRR(),
      this.mathService.netReteOfReturn(),
    );

    return [columns, ...rows];
  }

  generateRatesTooltip() {
    var rTooltip = [];

    var roi = reduceToYearly(this.mathService.netROI(), true);
    var ror = this.mathService.netReteOfReturn();
    var irr = this.mathService.netIRR();

    for (var k = 0; k < ror.length; k++) {
      var troi;
      var iret;

      // checking if we have all rates from this.MathService_
      // if not, we'll want the tooltips to display 'none' for that rate,
      // and the legend to be hidden from the map
      // for roi and ror we also have a warning blurb if we do not have those rates
      if (roi[k] === 0) {
        // currently used to set hide/show for legend key for roi and ror
        this.availroi = false;
        // currently used to display warning for roi and ror in html
        this.infiniteROI = true;
      } else {
        this.availroi = true;
      }

      if (!ror[k]) {
        troi = 'None';
      } else {
        troi = ror[k].toFixed(2) + ' %';
      }

      if (!irr[k]) {
        iret = 'None';
        // sets legend key to hide if we do not have an irr
        this.availirr = false;
      } else {
        iret = irr[k].toFixed(2) + ' %';
        this.availirr = true;
      }

      var m = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label"><strong>' +
        'Year </strong></td>' +
        '<td class="rentgraph-table-amount"><strong> ' +
        (k + 1) + '</strong></td></tr>';

      var trr = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'Comp. Growth Rate: </td>' +
        '<td class="rentgraph-table-amount"> ' +
        troi + '</td></tr>';

      var ir = '<tr class="rentgraph-table-row">' +
        '<td class="rentgraph-table-label">' +
        'IRR: </td>' +
        '<td class="rentgraph-table-amount"> ' +
        iret + ' </td></tr>';

      var content = '<div class="rentgraph-tooltip">' +
        m + '<table class="rentgraph-table tt-table">' +
        ir + trr + '</table></div>';

      rTooltip.push(content);
    }
    return rTooltip;
  }

  buildRatesOptions() {
    const options = this.getCommonOptions();
    options.isStacked = false;
    options.series = {
      0: { color: graph_lightblue, },
      1: { color: graph_purple, },
    };

    options.vAxis.minValue = 0;
    options.vAxis.title = 'Percent (%)';

    this.chartType = "LineChart";

    return options;
  }

}