
export const getAllUrlParams = (searchParams) => {
  const allUrlParams = {};
  for (const key of searchParams.keys()) {
    const value = searchParams.getAll(key);
    allUrlParams[key] = value;
    if (value.length === 1) {
      allUrlParams[key] = value[0];
    }
  }
  return allUrlParams;
};

export const updateUrlParams = (currentUrlParams, newUrlParams, setUrlParams) => {
  const finalUrlParams = getAllUrlParams(currentUrlParams);

  Object.keys(newUrlParams).forEach(key => {
    if (newUrlParams[key] != null) {
      finalUrlParams[key] = newUrlParams[key];
    } else {
      delete finalUrlParams[key];
    }
  });
  setUrlParams(finalUrlParams);
};
