import { SaveProforma } from "components/proformas";
import { OptionsMenu, PellegoARV } from "components/proformas/header/";
import { OREGON_STATE_ID } from "config";
import { cn, formatDateShort, formatPrice, formatPropertyName } from "helpers";
import NumberFormat from "react-number-format";
import { usePopperTooltip } from "react-popper-tooltip";
import { useUserFromShareToken, useUserSession } from "state";
import { useFinancials, useProforma } from "state/proformas";

export const Header = () => {
  const { proforma } = useProforma();
  const { financials } = useFinancials();
  const lastSoldEvent = proforma.parcel.listing_history.find((h: any) => h.event === "Sold");
  const showAddress = !(proforma.parcel.most_recent_listing.dont_show_address === true);
  const { arvEdited } = useUserFromShareToken(proforma);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: toolTipVisible,
  } = usePopperTooltip({ placement: "auto" });
  const currentUser = useUserSession();
  const isClientorAgent = currentUser.isClient || currentUser.isAgent;

  return (
    <div className="top-0 z-10 flex w-full flex-col items-center justify-center bg-[#f7faff]">
      <div className="flex w-full max-w-7xl items-center px-4 py-0 pt-2 xl:px-0">
        {/* Property head */}
        <div className="flex w-full flex-row gap-2 sm:gap-4">
          <div className="flex basis-5/6 flex-col truncate sm:grow sm:basis-3/4 sm:flex-row sm:items-center sm:pb-3">
            {/* Property address */}
            <div className="mr-2 truncate text-sm font-medium leading-5 sm:text-lg">
              {showAddress ? (
                <>
                  {formatPropertyName(proforma.name)},{" "}
                  {proforma.parcel._characteristics.city_for_address || proforma.parcel.county + " County"},{" "}
                  {proforma.parcel.state_obj.abbreviation} {proforma.parcel.zip_code}
                </>
              ) : (
                <>Address Hidden by Owner</>
              )}
            </div>

            <div className="font-lignt mb-2 mt-0 whitespace-nowrap text-xs text-gray-600 sm:mb-0">
              {/* Mobile view prices */}
              <div className="flex flex-row flex-wrap pt-1 text-sm font-light text-gray-600 sm:hidden">
                {proforma.listing?._on_market && (
                  <div>
                    <span>
                      {proforma.parcel.most_recent_listing.sale_type === ("Bank Owned" || "Short Sale") && (
                        <>{proforma.parcel.most_recent_listing.sale_type} / </>
                      )}
                    </span>
                    <span>Active at </span>
                    {formatPrice(proforma.parcel.listing_history[0]?.price)}
                  </div>
                )}

                {!proforma.listing?._on_market && (
                  <>
                    {proforma.wholesale && (
                      <div>
                        <span>Assignable Contract </span>
                        {formatPrice(proforma.wholesale?.price)}
                      </div>
                    )}
                    {!proforma.wholesale && (
                      <div>
                        {proforma.parcel.listing_history.length > 0 &&
                        proforma.parcel.listing_history[0]?.price ? (
                          <>
                            {proforma.parcel.listing_history[0].event === "Pending"
                              ? lastSoldEvent
                                ? "Pending from "
                                : "Pending"
                              : lastSoldEvent
                                ? `Sold ${formatDateShort(proforma.parcel.listing_history[0]?.date, false)} for `
                                : "Sold"}
                            {lastSoldEvent && formatPrice(proforma.parcel.listing_history[0]?.price)}
                          </>
                        ) : (
                          <div>No sales on record</div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* ARV Value */}
                {(!proforma.parcel.most_recent_listing.no_avm_display || isClientorAgent) && !arvEdited && (
                  <div className="pl-1">
                    (~ARV <PellegoARV financials={financials} proforma={proforma} mobileHeader />)
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-shrink-0 basis-1/6 flex-row items-start sm:basis-1/4 sm:items-center">
            {/* Property price and arv */}
            <div className="mr-4 hidden p-2 sm:mr-6 sm:flex sm:basis-2/3">
              <div className="justify-items-strech flex w-full flex-row gap-6 align-middle">
                {proforma.listing?._on_market && (
                  <div
                    className={cn(
                      !arvEdited ? "basis-1/2" : "basis-full",
                      "whitespace-nowrap text-sm font-light text-gray-500 sm:text-center",
                    )}
                  >
                    <div className="text-lg font-bold leading-5 text-black">
                      <NumberFormat
                        value={proforma.parcel.listing_history[0]?.price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalScale={0}
                      />
                    </div>
                    <span>
                      {proforma.parcel.most_recent_listing.sale_type === ("Bank Owned" || "Short Sale") && (
                        <>{proforma.parcel.most_recent_listing.sale_type} /</>
                      )}
                    </span>
                    <span> Active Listing</span>
                  </div>
                )}
                {!proforma.listing?._on_market && (
                  <>
                    {proforma.wholesale && (
                      <div className="basis-1/2 whitespace-nowrap text-xs font-light text-gray-500 sm:text-center">
                        <div className="text-lg font-bold leading-5 text-black">
                          <NumberFormat
                            value={proforma.wholesale?.price}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={0}
                          />
                        </div>
                        Assignable Contract
                      </div>
                    )}

                    {proforma.parcel.listing_history.length > 0 ? (
                      <>
                        {proforma.parcel.listing_history[0].event === "Pending" ? (
                          <div className="basis-1/2 whitespace-nowrap text-sm font-light text-gray-500 sm:text-center">
                            <div className="text-lg font-bold leading-5 text-black">
                              <NumberFormat
                                value={proforma.parcel.listing_history[0]?.price}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="$"
                                decimalScale={0}
                              />
                            </div>
                            <div className="leading-1 text-sm">Pending</div>
                          </div>
                        ) : (
                          <div className="basis-1/2 whitespace-nowrap text-sm font-light text-gray-500 sm:text-center">
                            {lastSoldEvent ? (
                              <>
                                <div className="text-lg font-bold leading-5 text-black">
                                  <NumberFormat
                                    value={lastSoldEvent?.price}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="$"
                                    decimalScale={0}
                                  />
                                </div>
                                <div className="leading-1 text-sm">Off Market, last sold for</div>
                              </>
                            ) : (
                              <div className="leading-1 text-sm">Off Market</div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="leading-1 whitespace-nowrap text-sm font-light text-gray-500">
                        <br />
                        No sales on record
                      </div>
                    )}
                  </>
                )}
                {(!proforma.parcel.most_recent_listing.no_avm_display || isClientorAgent) && !arvEdited && (
                  <div ref={setTriggerRef}>
                    {toolTipVisible && proforma.parcel.state_id === OREGON_STATE_ID && (
                      <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
                        <div {...getArrowProps({ className: "tooltip-arrow" })}></div>
                        ARV is estimated by Lotside's Algorithms.
                      </div>
                    )}
                    <div className="basis-1/2 border-l border-gray-300 pl-6 text-sm font-light text-gray-500 sm:text-center">
                      <div className="text-lg font-bold leading-5 text-black">
                        <PellegoARV financials={financials} proforma={proforma} />
                      </div>
                      <div className="whitespace-nowrap">Lotside ARV</div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full items-center justify-end text-right align-top sm:flex sm:basis-1/3 sm:pb-2 sm:align-middle">
              {/* Property save */}
              <SaveProforma
                text="SAVE"
                className={`hidden items-center rounded-sm px-4 py-2 text-sm font-normal shadow-sm transition duration-150 ease-in-out lg:inline-flex lg:h-9 ${
                  proforma.is_mine
                    ? "bg-black text-[12px] text-white"
                    : "border border-black bg-white text-black hover:border-black hover:bg-black hover:text-white"
                }`}
              />

              {/* Property options menu */}
              <OptionsMenu openDir="left" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
