import { getRelativeDate } from "helpers";
import { Chart } from "react-google-charts";
import { CompAppreciationType, useCompAppreciation, useProforma } from "state/proformas";
import { SalesComparableType } from "state/proformas/comparables";

const numMonthsToShow = 13;

class SalesCompsGraphClass {
  validGraph: boolean = false;
  comparables: SalesComparableType[];
  options: any;
  data: any[] = [];
  minPpsqft: number = 0;
  maxPpsqft: number = 0;
  formatters: any;
  subjectPpsf: number = 0;

  constructor(
    data: CompAppreciationType | undefined,
    comparables: SalesComparableType[],
    zipCode: string,
    subjectPpsf: number,
  ) {
    this.comparables = comparables;

    const sqFtPrices = data?.appreciation || [];

    this.minPpsqft = Math.min.apply(null, sqFtPrices);
    this.maxPpsqft = Math.max.apply(null, sqFtPrices);

    const rows = this.buildDataMatrix(sqFtPrices, subjectPpsf);
    const columns = this.buildGraph(zipCode);
    this.data = [columns, ...rows];
  }

  addMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  setMonths() {
    const dates = [];
    for (let i = 0; i < numMonthsToShow; i++) {
      const month = this.addMonths(new Date(), i - (numMonthsToShow - 1));
      month.setDate(1);
      dates.push(month);
    }
    return dates;
  }

  getEntryFromMatrix(matrix: any[][], yearMonth: string) {
    for (let i = 0; i < matrix.length; i++) {
      const entryYearMonth = matrix[i][0].toISOString().substr(0, 7);
      if (entryYearMonth === yearMonth) {
        return matrix[i];
      }
    }
    return null;
  }

  buildDataMatrix(prices: number[], subjectPpsf: number) {
    const matrix: any[][] = [];
    const dates = this.setMonths();
    let point;
    for (let i = 0; i < dates.length; i++) {
      point = new Array(11).fill(null);
      point[0] = dates[i];
      point[1] = prices[i] ? prices[i] : null;
      point[2] = prices[i] ? "$" + Math.round(prices[i]) : "N/A";
      if (i === dates.length - 1) {
        point[3] = subjectPpsf;
        point[4] = "$" + Math.round(subjectPpsf);
        point[5] = "Subject";
        point[6] = "point {fill-color: #FFA500; }";
      }
      matrix.push(point);
    }

    this.comparables.forEach((comp, idx) => {
      if (comp.parcel._characteristics.ppsf < this.minPpsqft) {
        this.minPpsqft = comp.parcel._characteristics.ppsf;
      }
      if (comp.parcel._characteristics.ppsf > this.maxPpsqft) {
        this.maxPpsqft = comp.parcel._characteristics.ppsf;
      }

      const entry = this.getEntryFromMatrix(matrix, comp.parcel._characteristics.last_date.substr(0, 7));

      if (entry) {
        point = [entry[0], entry[1], entry[2], null, null, null, null, null, null, null, null];
        if (comp.is_included) {
          point[3] = comp.parcel._characteristics.ppsf;
          point[4] = "$" + Math.round(comp.parcel._characteristics.ppsf);
          point[5] = String.fromCharCode("A".charCodeAt(0) + idx);
          point[6] = "point {fill-color: #70a08e; }";
        } else {
          point[7] = comp.parcel._characteristics.ppsf;
          point[8] = "$" + Math.round(comp.parcel._characteristics.ppsf);
          point[9] = String.fromCharCode("A".charCodeAt(0) + idx);
          point[10] = "point {fill-color: #d3d3d3; }";
        }
        matrix.push(point);
      }
    });

    matrix.sort((a, b) => {
      if (a[0].getTime() > b[0].getTime()) return 1;
      if (a[0].getTime() < b[0].getTime()) return -1;
      return 0;
    });

    return matrix;
  }

  buildGraph(zipCode: string) {
    const max = this.maxPpsqft + 60;
    const min = this.minPpsqft - 60;
    const xmin = this.addMonths(new Date(), -13);
    const xmax = getRelativeDate(5);
    this.options = {
      height: 250,
      chartArea: { left: "40", top: "5", right: "14", bottom: "60", width: "100%", height: "100%" },
      curveType: "function",
      focusTarget: "category",
      backgroundColor: "transparent",
      hAxis: {
        format: "MMM YY",
        viewWindow: {
          min: xmin,
          max: xmax,
          mode: "maximized",
        },
        gridlines: { color: "#eee" },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
      },
      legend: {
        position: "bottom",
        alignment: "center",
        textStyle: { color: "#858585", fontName: "Roboto", fontSize: "11" },
      },
      series: {
        0: { color: "#0E6BA0", type: "line", pointSize: "1" },
        1: { color: "#70a08e" },
        2: { color: "#d3d3d3" },
      },
      seriesType: "scatter",
      vAxis: {
        textPosition: "out",
        format: "$#",
        viewWindow: {
          min: min,
          max: max,
          mode: "maximized",
        },
        gridlines: { color: "#ebebeb", count: 4 },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
      },
    };

    const columns = [
      { type: "date", label: "Month" },
      { type: "number", label: `${zipCode}` },
      { type: "string", role: "tooltip" },
      { type: "number", label: "Selected Comp" },
      { type: "string", role: "tooltip" },
      { type: "string", role: "annotation" },
      { type: "string", role: "style" },
      { type: "number", label: "Unselected Comp" },
      { type: "string", role: "tooltip" },
      { type: "string", role: "annotation" },
      { type: "string", role: "style" },
    ];

    this.validGraph = true;

    return columns;
  }
}

type Props = {
  comparables: SalesComparableType[];
};

export const CompGraph = ({ comparables }: Props) => {
  const { proforma } = useProforma();
  const { data, loading, error } = useCompAppreciation(proforma.parcel_id);

  if (loading || error) return null;

  const scChart = new SalesCompsGraphClass(
    data,
    comparables,
    proforma.parcel.zip_code.toString(),
    proforma.parcel._characteristics.ppsf,
  );

  if (!scChart.validGraph) return null;
  if (!data) return null;

  // let structureTypePlural;
  // if (data && data.structure_type_for_appreciation === "all") {
  //   structureTypePlural = "Properties";
  // } else {
  //   structureTypePlural = data.structure_type_for_appreciation + "s";
  // }
  // let graphTitle;
  // if (data && data.geo_attribute_for_appreciation === "neighborhood" && proforma.parcel.neighborhood) {
  //   graphTitle = structureTypePlural + " in " + proforma.parcel.neighborhood;
  // } else if (data && data.geo_attribute_for_appreciation === "zip_code" && proforma.parcel.zip_code) {
  //   graphTitle = structureTypePlural + " in " + proforma.parcel.zip_code.toString();
  // } else if (data && data.geo_attribute_for_appreciation === "city" && proforma.parcel.city) {
  //   graphTitle = structureTypePlural + " in " + proforma.parcel.city;
  // } else {
  //   graphTitle = structureTypePlural + " in " + proforma.parcel.county + " County";
  // }

  const graphTitle = "Median Sale";

  return (
    <div className="mb-4 w-full rounded-lg border border-stone-100 bg-stone-50 p-3 pb-1">
      <div className="pb-0 text-sm font-bold text-gray-900">{graphTitle}</div>
      <div className="py-1 text-sm font-light text-gray-700">Price per Square Foot</div>
      <div className="pt-0">
        <Chart
          chartType="ComboChart"
          data={scChart.data}
          options={scChart.options}
          formatters={scChart.formatters}
        />
      </div>
    </div>
  );
};
