import { useNavigate, useLocation } from "react-router-dom";
import { appConfig } from "config";
import { useUserSession, useAlerts, useSignInSignUpModal } from "state";
import { useFetchWrapper } from "helpers";

export function useUserActions() {
  const fetchWrapper = useFetchWrapper();
  const currentUser = useUserSession();
  const alertActions = useAlerts();
  const navigate = useNavigate();
  const location = useLocation();
  const { closeSignInSignUpModal } = useSignInSignUpModal();

  return {
    login,
    logout,
    register,
    resendConfirmationEmail,
    forgotPassword,
    resetPassword,
    changePassword,
    updateUser,
    uploadAvatar,
  };

  function login(userData: any, dontRedirect: boolean | undefined) {
    return fetchWrapper.post(appConfig.apiEndpoint.login, userData).then((user) => {
      currentUser.refresh(user);

      if (dontRedirect) {
        closeSignInSignUpModal();
      } else {
        // get return url from location state or default to home page
        const state = location.state as { from: { pathname: string; search: string } };
        const from = state?.from?.pathname + state?.from?.search || "/";
        navigate(from, { replace: true });
      }
    });
  }

  function logout() {
    return fetchWrapper.post(appConfig.apiEndpoint.logout).then(() => {
      currentUser.refresh();
      navigate("/");
    });
  }

  function register(userData: any) {
    return fetchWrapper.post(appConfig.apiEndpoint.register, userData);
  }

  function resendConfirmationEmail(email: any, dontRedirect: boolean | undefined) {
    return fetchWrapper.get(appConfig.apiEndpoint.requestEmailConfirmation.replace(":email", email)).then(() => {
      alertActions.success("Confirmation email resent. Please check your email.");
      if (dontRedirect) {
        closeSignInSignUpModal();
      } else {
        // get return url from location state or default to home page
        const state = location.state as { from: { pathname: string; search: string } };
        const from = state?.from?.pathname + state?.from?.search || "/";
        navigate(from, { replace: true });
      }
    });
  }

  function forgotPassword(userData: any, dontRedirect: boolean | undefined) {
    return fetchWrapper.post(appConfig.apiEndpoint.forgotPassword, userData).then((data) => {
      alertActions.success("Reset successful. Please check your email for instructions.");
      if (!dontRedirect) {
        setTimeout(() => navigate("/"), 5000);
      }
    });
  }

  function resetPassword(token: string, userData: any) {
    return fetchWrapper.post(`${appConfig.apiEndpoint.resetPassword}${token}`, userData).then((user) => {
      currentUser.refresh(user);

      alertActions.success("Reset successful. Redirecting to Home...");

      setTimeout(() => navigate("/"), 3000);
    });
  }

  function changePassword(formData: any) {
    return fetchWrapper.post(appConfig.apiEndpoint.changePassword, formData).then((user) => {
      alertActions.success("Password changed successfully.");
    });
  }

  function updateUser(userData: any) {
    return fetchWrapper.post(appConfig.apiEndpoint.user, userData).then((user) => {
      currentUser.refresh({
        name_first: user.name_first,
        name_last: user.name_last,
        phone_number: user.phone_number,
        default_region: user.default_region,
        description: userData.description,
        skils: userData.skills,
        service_areas: userData.service_areas,
      });
    });
  }

  function uploadAvatar(blob: Blob) {
    const formData = new FormData();
    formData.append("file", blob, "profile.jpg");
    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      body: formData,
    };
    return fetch(appConfig.apiEndpoint.uploadAvatar, requestOptions)
      .then((res) => res.json())
      .then((avatar) => {
        currentUser.refresh({ avatar });
      });
  }
}
