import {
  Footer,
  ListedBy,
  LoadingPhrases,
  Nav,
  NotificationBar,
  NWMLSNotice,
  SignInSignUpDialog,
} from "components";
import { AskAboutNotice } from "components/browse";
import {
  AskAbout,
  AskAboutWithAgent,
  Comparables,
  Financials,
  Markets,
  Photos,
  PropertyData,
  PropertyDetails,
  PropertyNotFound,
  PropertyRemarks,
} from "components/proformas";
import { Header, HeaderSticky } from "components/proformas/header/";
import { OREGON_STATE_ID } from "config";
import {
  buildProformasAddressUrl,
  buildProformasListingNumberUrl,
  cn,
  formatPropertyName,
  titleCase,
} from "helpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useCurrentStates,
  useRecentSearches,
  useSignInSignUpModal,
  useSiteConfig,
  useUserFromShareToken,
  useUserSession,
} from "state";
import {
  ParcelAgentType,
  useParcelAgent,
  useProforma,
  useProformasByData,
  useUpdateDate,
} from "state/proformas";

type Props = {
  proforma_type: "flip_rental" | "new_construction";
};

export const Proformas = ({ proforma_type }: Props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const currentUser = useUserSession();
  const { openNonMLSSignUpModal } = useSignInSignUpModal();
  const navigate = useNavigate();

  let address = params.address?.replace(/ /g, "-");
  let listingNumber = params.listing_number;

  const data = address ? address : listingNumber;
  const dataType = address ? "address" : "listingNumber";

  const { loading, error } = useProformasByData(data, proforma_type, dataType, searchParams.get("share"));
  const { proforma } = useProforma();

  const showAddress = !(proforma?.parcel?.most_recent_listing?.dont_show_address === true);
  if (!loading && address && !showAddress) {
    listingNumber = proforma?.listing_number;
    navigate(buildProformasListingNumberUrl(listingNumber), {
      replace: true,
    });
  }
  if (!loading && listingNumber && showAddress) {
    address = proforma?.parcel?.full_address;
    navigate(buildProformasAddressUrl(address), { replace: true });
  }

  useRecentSearches({ loading, error });

  const { sharingUser, sharedByAgent } = useUserFromShareToken(proforma);
  let parcelAgent = useParcelAgent(proforma.parcel_id);
  if (currentUser.isAgent) parcelAgent = currentUser.session as ParcelAgentType;

  const hasMLS = !(proforma.has_mls === false);
  const isNWMLS = proforma.listing?.listing_source === "NWMLS";
  const updateDate = useUpdateDate(proforma?.parcel?.state_id);

  const [showSmallHeader, setShowSmallHeader] = useState(false);

  const siteConfig = useSiteConfig();
  const { currentStates } = useCurrentStates();
  const isPartnerState = currentStates?.some((state) => siteConfig.partner_states?.includes(state));
  const isBrokerageState = currentStates?.some((state) => siteConfig.brokerage_states?.includes(state));
  window.Pellego.proforma = proforma;

  const handleScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    if (scrollTop < (hasMLS ? 90 : 138) && showSmallHeader) {
      setShowSmallHeader(false);
    }
    if (scrollTop > (hasMLS ? 90 : 138) && !showSmallHeader) {
      setShowSmallHeader(true);
    }
  };

  // Loading Phrases
  const isSourceLookup = searchParams.get("source") === "lookup";
  const loadingPhrases = isSourceLookup
    ? [
        "Pulling County Data",
        "Picking Sale Comps",
        "Picking Rental Comps",
        "Estimating Comp Adjustments",
        "Estimating ARV",
        "Estimating Rent",
        "Formatting Everything Else..",
      ]
    : ["Pulling Data", "Picking Comps", "Estimating ARV", "Estimating Rent"];

  if ((loading || !proforma.parcel_id) && !error) {
    return <LoadingPhrases phrases={loadingPhrases} isSourceLookup={isSourceLookup} />;
  }

  return (
    <div className="h-screen w-full font-sans">
      <div id="main-wrapper" className="fixed bottom-0 left-0 right-0 top-0 w-full">
        <div className="relative flex h-full flex-wrap">
          {error ? (
            <PropertyNotFound />
          ) : (
            <div
              id="main-content"
              className="relative h-full flex-1 overflow-y-scroll"
              onScroll={handleScroll}
            >
              <div className="flex w-full flex-col items-center">
                <Helmet>
                  {showAddress ? (
                    <title>
                      {formatPropertyName(titleCase(proforma.full_address || proforma.parcel.full_address))}
                    </title>
                  ) : (
                    <title>List. #{listingNumber}</title>
                  )}
                </Helmet>
                <div id="Top"></div>
                <Nav notFixed={true} />
                {!hasMLS && (
                  <NotificationBar type="warning" sticky={showSmallHeader}>
                    <div className="flex flex-col sm:flex-row sm:gap-2">
                      We don't have MLS data for this region yet.
                      <div>
                        <span
                          className="cursor-pointer text-blue-700 underline"
                          onClick={openNonMLSSignUpModal}
                        >
                          Check here
                        </span>{" "}
                        to be updated when we do.
                      </div>
                    </div>
                  </NotificationBar>
                )}
                {!isMobile && showSmallHeader ? <HeaderSticky /> : <Header />}
                <div id="Remarks"></div>
                <Photos />
                <ListedBy
                  propertyParcel={proforma.parcel}
                  className={cn("bg-[#f7faff] pt-1", isMobile ? "pb-1" : "pb-4")}
                />
                <div
                  className="align-center flex w-full flex-col items-center justify-center bg-[#f7faff] pb-10 pt-4 sm:pb-[5.5rem]"
                  id="PropertyData"
                >
                  <div className="flex w-full max-w-7xl flex-row items-start align-top xl:gap-10">
                    <div className="flex w-full flex-col gap-4 sm:flex-row md:w-2/3 md:flex-col lg:w-2/3 lg:flex-row xl:gap-10">
                      {hasMLS && (
                        <div className="order-2 w-full sm:w-1/2 md:w-full lg:order-1 lg:w-1/2">
                          <PropertyData />
                        </div>
                      )}
                      <div className="order-1 w-full sm:w-1/2 md:w-full lg:order-2 lg:w-1/2">
                        <PropertyRemarks withPrivateRemarks={isPartnerState || isBrokerageState} />
                        <PropertyDetails isNWMLS={isNWMLS} />
                      </div>
                    </div>
                    <div className="sticky top-16 hidden justify-center px-4 md:flex md:w-1/3 xl:px-0">
                      {parcelAgent && !isNWMLS ? (
                        <AskAboutWithAgent
                          title="Ask about this property"
                          agent={sharedByAgent ? sharingUser : parcelAgent}
                          currentUserIsAgent={currentUser.isAgent}
                        />
                      ) : (
                        <AskAbout title="Ask about this property" />
                      )}
                    </div>
                  </div>
                </div>

                <Comparables />

                <Financials />

                <Markets agent={sharedByAgent ? sharingUser : parcelAgent} />

                <div className={`${isNWMLS ? "pb-4" : "pb-10 sm:pb-[5.5rem]"} text-sm leading-7`}>
                  Content last updated on {updateDate}.
                </div>

                {isNWMLS && <NWMLSNotice />}

                {proforma.parcel.state_id === OREGON_STATE_ID && (
                  <img
                    src={`https://s3-us-west-2.amazonaws.com/cdn.davinci.pellego.com/static/images/rmls_logo.jpg`}
                    alt=""
                  />
                )}
              </div>
              <Footer stateId={proforma.parcel.state_id} />
            </div>
          )}
          <SignInSignUpDialog />
        </div>
        <div className="fixed bottom-0 w-full md:hidden">
          <AskAboutNotice agent={sharedByAgent ? sharingUser : parcelAgent} />
        </div>
      </div>
    </div>
  );
};
