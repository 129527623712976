import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type RentalCompAppreciationType = {
  appreciation: number[];
  geo_attribute_for_appreciation: string;
};

export const useRentalCompAppreciation = (parcelId: number) => {
  const url = appConfig.apiEndpoint.rentalCompAppreciationGraph.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<RentalCompAppreciationType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    data,
    error,
  };
};
