import { atom, useRecoilState } from "recoil";

const showRegisterClientOrAgentModalAtom = atom({
  key: "showRegisterClientOrAgentModalAtom",
  default: false,
});

export const useRegisterClientOrAgentModal = () => {
  const [showRegisterClientOrAgentModal, setShowRegisterClientOrAgentModal] = useRecoilState(
    showRegisterClientOrAgentModalAtom,
  );

  return {
    openRegisterClientOrAgentModal: () => setShowRegisterClientOrAgentModal(true),
    closeRegisterClientOrAgentModal: () => setShowRegisterClientOrAgentModal(false),
    showRegisterClientOrAgentModal,
  };
};
