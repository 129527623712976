import { useEffect, useState, useRef } from "react";
import { mdiMagnify, mdiClose } from "@mdi/js";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import {
  useMarketResearchAvailability,
  MarketResearchAvailabilityType,
  MarketDataEntryType,
} from "state/market";
import { Labels } from "./Labels";
import { SearchResultItem } from "./SearchResultItem";
import Icon from "@mdi/react";
import { Spinner } from "components";

export type MarketComparableType = {
  id: string;
  loc_type: string;
  loc_id: number;
  loc_name: string;
  structureType?: string;
  data?: MarketDataEntryType[];
};

export type LocationPriceType = MarketComparableType;

export const marketStructureTypes = {
  "all": "All",
  "condominium": "Condos",
  "house": "Houses",
  "multifamily": "Multifamily",
  "townhouse": "Townhomes"
};

const LOC_TYPES = ["county", "city", "neighborhood", "zip_code"];

const getTypeAheadResults = (
  searchText: string,
  availability: MarketResearchAvailabilityType,
): MarketComparableType[] => {
  const retVal: MarketComparableType[] = [];

  if (!searchText) return retVal;

  const searchLower = searchText.toLocaleLowerCase();

  LOC_TYPES.forEach((locType) => {
    const typeAvailability: any[] = availability[locType as keyof typeof availability];
    const filteredResults = typeAvailability.filter((item) => {
      if (locType === "zip_code") {
        return item.includes(searchLower);
      }
      const lcName = item.name.toLocaleLowerCase();
      if (locType === "county") {
        return `${lcName} county`.includes(searchLower);
      }
      return lcName.includes(searchLower);
    });

    filteredResults.forEach((item) => {
      retVal.push({
        id: item.id ?? Number(item),
        loc_type: locType,
        loc_id: item.id ?? Number(item),
        loc_name: locType === 'county' ? `${item.name} County` : item.name ?? item,
        structureType: "",
      });
    });

  });

  return retVal;
};

type Props = {
  region: string;
  comparableItems: MarketComparableType[];
  onAddMarketComparable: (item: MarketComparableType) => void;
  onRemoveMarketComparable: (item: MarketComparableType) => void;
  loading: boolean;
};

export const SearchMarketComparable = ({ region, comparableItems,
  onAddMarketComparable, onRemoveMarketComparable, loading }: Props
) => {
  const locationAvailability = useMarketResearchAvailability(region);
  const [inputText, setInputText] = useState("");
  const debouncedText = useDebounce(inputText, 300);
  const [typeaheadResult, setTypeaheadResult] = useState<MarketComparableType[]>([]);
  const [editableItem, setEditableItem] = useState<MarketComparableType | null>(null);
  const [selectedStructureType, setSelectedStructureType] = useState("all");
  const [showInputPanel, setShowInputPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
  const ref = useRef(null);

  useEffect(() => {
    if (debouncedText.length > 1) {
      const newTypeaheadResults = getTypeAheadResults(debouncedText, locationAvailability);
      setTypeaheadResult(newTypeaheadResults);
    }
  }, [debouncedText, locationAvailability]);

  const resetStatus = (closePanel: boolean = true) => {
    setInputText("");
    setTypeaheadResult([]);
    setSelectedItem(undefined);
    if (closePanel) {
      setShowInputPanel(false);
      setEditableItem(null);
    }
  }

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus(false);
    }
  }

  const selectItem = (item: MarketComparableType,) => {
    setEditableItem(item);
    resetStatus(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        resetStatus();
        break;
      case "ArrowUp":
        e.preventDefault();
        if (typeaheadResult.length > 0 && selectedItem !== undefined && selectedItem > 0) {
          setSelectedItem(selectedItem - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextIdx = selectedItem == null ? 0 : selectedItem + 1;
        if (typeaheadResult.length > 0 && nextIdx < typeaheadResult.length) {
          setSelectedItem(nextIdx);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (selectedItem != null) {
          selectItem(typeaheadResult[selectedItem]);
        }
        break;
    }
  }

  const onRemoveItem = (item: MarketComparableType) => {
    resetStatus();
    onRemoveMarketComparable(item);
  }

  const onAddItem = () => {
    if (!editableItem) {
      return;
    }
    const newItem = {
      ...editableItem,
      id: `${editableItem.loc_type}/${editableItem.id}/${selectedStructureType}`,
      structureType: selectedStructureType,
    };
    if (comparableItems.some((location: MarketComparableType) => location.id === newItem.id)) {
      return;
    }
    onAddMarketComparable(newItem);
    resetStatus();
  }

  if (!locationAvailability) {
    return null;
  }

  return (
    <div
      className="w-full relative text-black text-base"
      onKeyDown={handleKeyDown}
      ref={ref}
    >
      <div
        className="flex w-full flex-row rounded-lg border border-gray-400 bg-white justify-between"
        onClick={() => setShowInputPanel(true)}
      >
        <div className="flex flex-col w-full"><Labels items={comparableItems} onRemoveItem={onRemoveItem} /></div>
        <div className="w-10 flex-none cursor-pointer rounded-r-lg border-l bg-white p-2.5 right-0">
          {loading ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <Icon path={mdiMagnify} className="h-5 w-5" />
          )}
        </div>

      </div>

      {showInputPanel && (
        <div className="absolute top-0 w-full bg-white z-10 border border-gray-300 rounded-b-lg items-center p-2 min-w-[290px]">
          <Labels items={comparableItems} onRemoveItem={onRemoveItem} />
          <input
            type="text"
            className="border border-gray-400 h-9 w-full my-2"
            value={inputText}
            onChange={onInputTextChange}
            autoComplete="off"
            placeholder={comparableItems.length === 0 ? "Enter a county, city, neighborhood or zip code" : "Enter another location"}
            autoFocus
          />

          <button
            className="absolute right-2 top-2 font-bold"
            onClick={() => resetStatus()}
          >
            <Icon path={mdiClose} className="w-4 h-4 ml-1 inline text-gray-400 hover:bg-gray-300 rounded-lg" />
          </button>

          {editableItem && (
            <div className="text-sm text-gray-700 mt-1 mb-3 px-1">
              <div className="my-2">
                <span className="capitalize mr-2 font-bold">Selected {editableItem.loc_type === "zip_code" ? "zip code" : editableItem.loc_type}:</span>
                {editableItem.loc_name}
              </div>

              <span className="mr-2 font-bold">Structure type:</span>
              {Object.entries(marketStructureTypes).map(([structureType, structureName]) => (
                <label key={structureType} className="mr-5">
                  <input type="radio" name="structype"
                    className="text-blue-500 indeterminate:bg-gray-300 border outline-0 focus:ring-0 mr-1"
                    checked={selectedStructureType === structureType}
                    onChange={(e) => { setSelectedStructureType(structureType) }}
                  />
                  {structureName}
                </label>
              ))}
              <button
                className="mt-4 sm:mt-0 inline-flex items-center px-6 py-2 rounded-sm text-white bg-[#61a28d] hover:bg-[#5bbc9c] transition ease-in-out duration-150"
                onClick={onAddItem}
              >
                + Add
              </button>
            </div>
          )}

          {typeaheadResult.length > 0 &&
            <div className="w-full max-h-96 overflow-y-scroll z-10 items-center">
              {typeaheadResult.map((item, idx) => (
                <SearchResultItem
                  key={item.id}
                  item={item}
                  onSelection={selectItem}
                  selected={selectedItem === idx}
                />
              ))}
            </div>
          }

        </div>
      )}

    </div>
  )
}
