import { updateUrlParams } from "helpers";
import orderBy from "lodash/orderBy";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { useUserSession } from "state";
import { filteredSearchResultsSelector, SearchResultType } from "state/browse";

export type SortingOptionsType = {
  name: string;
  value: string;
  forAdvancedRole: boolean;
  sortByObjProperty: string;
};

export const sortingOptions: SortingOptionsType[] = [
  {
    name: "Last Update (Newest)",
    value: "-listing_updated_on",
    forAdvancedRole: false,
    sortByObjProperty: "-listingEventDate",
  },
  {
    name: "Last Update (Oldest)",
    value: "listing_updated_on",
    forAdvancedRole: false,
    sortByObjProperty: "listingEventDate",
  },
  {
    name: "Days on Market (Oldest)",
    value: "-days_on_market",
    forAdvancedRole: false,
    sortByObjProperty: "-days_on_market",
  },
  // { name: "Profit (Highest)", value: "-profit", forAdvancedRole: true, sortByObjProperty: "-profit" },
  // { name: "Profit (Lowest)", value: "profit", forAdvancedRole: true, sortByObjProperty: "profit" },
  // {
  //   name: "ROI (Highest)",
  //   value: "-roi",
  //   forAdvancedRole: true,
  //   sortByObjProperty: "-flip_financed_total_roi",
  // },
  // { name: "ROI (Lowest)", value: "roi", forAdvancedRole: true, sortByObjProperty: "flip_financed_total_roi" },
  // { name: "Cash Flow (Highest)", value: "-cashflow", forAdvancedRole: true, sortByObjProperty: "-cashflow" },
  // { name: "Cash Flow (Lowest)", value: "cashflow", forAdvancedRole: true, sortByObjProperty: "cashflow" },
  // { name: "Cap Rate (Highest)", value: "-cap_rate", forAdvancedRole: true, sortByObjProperty: "-cap_rate" },
  // { name: "Cap Rate (Lowest)", value: "cap_rate", forAdvancedRole: true, sortByObjProperty: "cap_rate" },
  {
    name: "Relative Cap (Highest)",
    value: "-capRateMultiplier",
    forAdvancedRole: true,
    sortByObjProperty: "-capRateMultiplier",
  },
  {
    name: "Relative Cap  (Lowest)",
    value: "capRateMultiplier",
    forAdvancedRole: true,
    sortByObjProperty: "capRateMultiplier",
  },
];

const paramToSortByOption = (urlParams: URLSearchParams): SortingOptionsType => {
  const sortByParam = urlParams.get("sort_by") || sortingOptions[0].value;
  const selectedOption = sortingOptions.find((option) => option.value === sortByParam);
  return selectedOption || sortingOptions[0];
};

const sortSearchResult = (
  searchResult: SearchResultType[],
  sortOption: SortingOptionsType,
): SearchResultType[] => {
  let sortField = sortOption.sortByObjProperty;
  let sortDirection: "asc" | "desc" = "asc";
  if (sortField.startsWith("-")) {
    sortField = sortField.substring(1);
    sortDirection = "desc";
  }
  return orderBy(searchResult, ["is_wholesale", sortField], ["desc", sortDirection]);
};

const searchResultsSortingOptionAtom = atom<SortingOptionsType>({
  key: "searchResultsSortCriteriaAtom",
  default: sortingOptions[0],
});

export const sortedSearchResultsSelector = selector({
  key: "sortedSearchResultsSelector",
  get: ({ get }) => {
    const searchResults = get(filteredSearchResultsSelector);
    const sortOption = get(searchResultsSortingOptionAtom);
    return sortSearchResult(searchResults, sortOption);
  },
});

export const useSort = () => {
  const currentUser = useUserSession();
  const [sortOption, setSortOption] = useRecoilState(searchResultsSortingOptionAtom);
  const sortedSearchResults = useRecoilValue(sortedSearchResultsSelector);
  const [urlParams, setUrlParams] = useSearchParams();

  useEffect(() => {
    setSortOption(paramToSortByOption(urlParams));
  }, [urlParams, setSortOption]);

  const updateSort = (newSortOption: SortingOptionsType) => {
    updateUrlParams(urlParams, { sort_by: newSortOption.value }, setUrlParams);
  };

  return {
    sortOption,
    setSortOption: updateSort,
    sortedSearchResults,
    hasAdvancedRole: currentUser?.hasAdvancedRole,
  };
};
