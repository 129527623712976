import useSWR from "swr";
import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";

export const useSavedPropertiesList = (limit: number = 300, page: number = 1, sort: string = "created_on") => {
  const { data, error, mutate } = useSWR(appConfig.apiEndpoint.proformaAll + `?limit=${limit}&page=${page}&sort=${sort}`,
    fetcherWithCredentials);

  const deleteSavedProperties = (ids: number[]) => {
    const idsParam = "?proforma_ids=" + ids.join("&proforma_ids=");
    return fetch(appConfig.apiEndpoint.proformaAll + idsParam, { method: "DELETE", credentials: "include" })
      .then(() => mutate());
  }

  return {
    loading: !data && !error,
    total_properties: data?.total_count,
    properties: data?.proformas,
    deleteSavedProperties,
    error,
  }
};
