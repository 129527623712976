import React from "react";
import { useRegisterClientOrAgentModal } from "state";

export const Restricted = () => {
  const { openRegisterClientOrAgentModal } = useRegisterClientOrAgentModal();

  return (
    <>
      <div
        className="text-blue-800"
        onClick={(e) => {
          e.preventDefault();
          openRegisterClientOrAgentModal();
        }}
      >
        Restricted
      </div>
    </>
  );
};
