import { Map, Property } from "components/browse";
import { isMobile } from "react-device-detect";
import { useSelectedProperty } from "state";

export const BrowseMap = () => {
  const { selectedProperty } = useSelectedProperty();

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto bg-[#aadaff] ">
      <div className="z-10 h-full w-full overflow-y-auto bg-[#aadaff]">
        <Map emptyResult={false} />
        {isMobile && selectedProperty && (
          <div className="fixed bottom-0 z-[1000] mb-16 h-fit w-full px-1">
            <Property property={selectedProperty} showCosts={false} selected={true} />
          </div>
        )}
      </div>
    </div>
  );
};
