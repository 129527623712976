import { atom, useRecoilState } from "recoil";
import { formatNumber, formatCurrency, prettifyParcel } from "helpers";
// import { useFinancials } from "state/proformas";

type CompStatusType = "suggested" | "included" | "excluded" | "rejected" | "unselected";

export type ComparableBaseType = {
  _characteristics?: any;
  _idx: number;
  distance: number;
  parcel_id: number;
  parcel: any;
  status: CompStatusType;
};

export type SalesComparableType = ComparableBaseType & {
  condition_category?: number;        // Not used?
  condition_score: number;
  constraints_missed: any[];
  constraints_passed: number;
  cost_diffs: any;
  is_flip: boolean;
  is_rental: boolean;
  normalized_score: number;
  projected_arv: number;
  score: number;
  similarity_score: number;
  is_included?: boolean;
}

export type CompStatsType = {
  name: string;
  key: string;
  fallback?: string;
  format?: string;
  tooltip?: string;
  compsKey?: string;
  subjectValue?: string;
};

const compStats: CompStatsType[] = [
  {
    name: "Bedrooms",
    key: "bedrooms",
    format: "number",
    fallback: "0",
  },
  {
    name: "Bathrooms",
    key: "bathrooms",
  },
  {
    name: "Finished Sqft",
    key: "square_feet_finished",
    format: "number",
    tooltip: "Interior finished living space",
  },
  {
    name: "Unfinished Sqft",
    key: "square_feet_basement_unfinished",
    format: "number",
    fallback: "Unspecified",
    tooltip: "Interior unfinished space, typically an unfinished basement",
  },
  {
    name: "Lot Sqft",
    key: "lot_square_feet",
    format: "number",
    fallback: "Unknown",
  },
  // {
  //   name: "Assessed Value",
  //   key: "assessed_value",
  //   format: "currency",
  // },
  {
    name: "Views",
    key: "view_type",
    fallback: "Unspecified",
    compsKey: "view",
  },
  {
    name: "Garage Sqft",
    key: "square_feet_garage",
    format: "number",
    fallback: "Unspecified",
    compsKey: "garage",
  },
  {
    name: "Year Built",
    key: "year_built",
    fallback: "Unknown",
  },
  {
    name: "Waterfront Ft",
    key: "waterfront_footage",
    format: "number",
    fallback: "None",
  },
  {
    name: "Restrictions",
    key: "restrictions",
    fallback: "None",
    compsKey: "environmental_restrictions",
  },
  {
    name: "Number of Units",
    key: "number_of_units_in_building",
    compsKey: "number_of_units"
  },
  {
    name: "HOA Dues",
    key: "hoa_dues",
    format: "currency",
    tooltip: "The HOA Dues",
    fallback: "Unspecified",
  },
  {
    name: "Style",
    key: "structure_style",
    fallback: "Unspecified",
    compsKey: "structure_style"
  }
];

export const filteredCompStats = (subjectProperty: any) => {
  const filteredCompStats: CompStatsType[] = [];

  compStats.forEach(compStat => {
    const newStat = { ...compStat };
    let value = subjectProperty.parcel._characteristics[compStat.key];
    if (value !== undefined && value !== null) {
      if (compStat.format === "number") {
        value = formatNumber(value, value % 1 === 0 ? 0 : 2);
      } else if (compStat.format === "currency") {
        value = formatCurrency(value, 0);
      } else if (compStat.format === "array") {
        value = value.map((v: string) => v.replace(/[{}]/g, '')).join(', ');
      } else {
        value = value.toString();
      }
      newStat.subjectValue = value;
      filteredCompStats.push(newStat);
    }
  });
  return filteredCompStats;
}

export const salesComparablesAtom = atom<SalesComparableType[]>({
  key: "salesComparablesAtom",
  default: [],
});

const statusToRank: Record<string, number> = {
  "suggested": 5,
  "included": 4,
  "excluded": 1,
};

const sortSalesComparables = (comparables: SalesComparableType[]) => {
  const sortedComps = [...comparables].sort((a, b) => {
    if (a.status === b.status)
      return a.score < b.score ? 1 : a.score >= b.score ? -1 : 0;
    const status_a = statusToRank[a.status] || 3;
    const status_b = statusToRank[b.status] || 3;
    return status_a < status_b ? 1 : status_a >= status_b ? -1 : 0;
  });
  return sortedComps;
}

export const processSalesComparables = (comparables: SalesComparableType[]) => {
  const sortedComps = sortSalesComparables(comparables);

  const prettifiedComps = sortedComps.map((comp, idx) => {
    return {
      ...comp,
      parcel: prettifyParcel(comp.parcel),
      _idx: idx,
      is_included: comp.status === "suggested" || comp.status === "included",
    }
  });

  return prettifiedComps
};

type CostDiffType = {
  key: string;
  name: string;
  amount: number;
  pct: string | number,
  delta: string | number,
  warv: string | number,
};

export const buildCompDiffs = (costDiffs: any, subject_chars: any, comp_chars: any) => {
  // Convert keys to nice words
  const diff_keys: Record<string, string> = {
    market_change: "Market Change",
    bathrooms: "bath",
    location: "location",
    lot_square_feet: "lot sqft",
    street_noise: "street noise",
    street_size: "street size",
    square_feet_garage: "garage sqft",
    square_feet_basement_unfinished: "unfinished basement sqft",
    square_feet_finished: "finished sqft",
    view: "view",
    bedrooms: "bedroom",
    quality: "character",
    year_built: "",
    garage: "garage",
    pole: "pole",
    structure_style: "style",
    patio: "patio",
    "gas available": "gas available",
    moorage: "moorage",
    "sprinkler system": "landscaping",
    "boat house": "boat house",
    "steam room / sauna": "steam room / sauna",
    greenhouse: "greenhouse",
    "cable tv": "cable tv",
    dock: "dock",
    shed: "shed",
    "dog run": "dog run",
    "gated entry": "gated entry",
    "utility building": "utility building",
    "green house": "green house",
    cabin: "cabin",
    "disabled access": "disabled access",
    workshop: "workshop",
    "storage building": "storage building",
    stable: "stable",
    "high speed internet": "high speed internet",
    outbuildings: "outbuildings",
    "ev hookup": "ev hookup",
    barn: "barn",
    "well or pump house": "well or pump house",
    cabana: "cabana",
    shop: "shop",
    deck: "deck",
    fence: "fence",
    pool: "pool",
    arena: "arena",
    "rv parking": "rv parking",
    propane: "propane",
    "athletic court": "athletic court",
    gazebo: "gazebo",
    spa: "spa",
    "poultry house": "poultry house",
    "hot tub/spa": "hot tub/spa",
    "lean-to": "lean-to",
    n_units: "units",
    condo_floor_of_building: "floor",
    number_of_units_in_building: "number_of_units",
    number_of_floors_in_building: "stories",
  };

  // Nice ways of describing the delta relative to the subject
  const delta_descriptions: Record<string, string[]> = {
    location: ["+ better", "- lesser"],
    market_change: ["+ appreciation", "- depreciation"],
    quality: ["+ higher", "- lower"],
    street_noise: ["+ less", "- more"],
    street_size: ["+ smaller", "- larger"],
    view: ["+ better", "- lesser"],
    garage: ["+ bigger", "- smaller"],
    "gas available": ["+ has ", "- no "],
    moorage: ["+ has ", "- no "],
    "sprinkler system": ["+ has ", "- no "],
    "boat house": ["+ has ", "- no "],
    "steam room / sauna": ["+ has ", "- no "],
    greenhouse: ["+ has ", "- no "],
    "cable tv": ["+ has ", "- no "],
    dock: ["+ has ", "- no "],
    shed: ["+ has ", "- no "],
    "dog run": ["+ has ", "- no "],
    "gated entry": ["+ has ", "- no "],
    "utility building": ["+ has ", "- no "],
    "green house": ["+ has ", "- no "],
    cabin: ["+ has ", "- no "],
    "disabled access": ["+ has ", "- no "],
    workshop: ["+ has ", "- no "],
    "storage building": ["+ has ", "- no "],
    stable: ["+ has ", "- no "],
    "high speed internet": ["+ has ", "- no "],
    outbuildings: ["+ has ", "- no "],
    "ev hookup": ["+ has ", "- no "],
    barn: ["+ has ", "- no "],
    "well or pump house": ["+ has ", "- no "],
    cabana: ["+ has ", "- no "],
    shop: ["+ has ", "- no "],
    fence: ["+ has ", "- no "],
    deck: ["+ has ", "- no "],
    pool: ["+ has ", "- no "],
    arena: ["+ has ", "- no "],
    "rv parking": ["+ has ", "- no "],
    propane: ["+ has ", "- no "],
    "athletic court": ["+ has ", "- no "],
    gazebo: ["+ has ", "- no "],
    spa: ["+ has ", "- no "],
    "poultry house": ["+ has ", "- no "],
    "hot tub/spa": ["+ has ", "- no "],
    n_units: ["+ more ", "- fewer "],
    condo_floor_of_building: ["+ higher", "- lower"],
    number_of_floors_in_building: ["+ more", "- fewer"],
  };

  const excludedKeysToDisplay = [
    'bedrooms',
    'bathrooms',
    'square_feet_finished',
    'square_feet_basement_unfinished',
    'lot_square_feet',
    'structure_style',
    'year_built',
    'market_change',
    'number_of_units',
  ];

  const diffs: CostDiffType[] = [];

  let diff_total = 0;
  Object.values(costDiffs).forEach(value => {
    // For simplicity, the weights will be absolute (not polar)
    diff_total += Math.abs(value as number);
  });

  // Look through the cost differentials for this comparable
  Object.entries(costDiffs).forEach(([key, value]) => {
    // We only care about differentials that are different
    if (value && !excludedKeysToDisplay.includes(key)) {
      // Normalize all non-values to zero (for mathing)
      const sval = subject_chars[key] || 0;
      const cval = comp_chars[key] || 0;

      // Store a 0 for positive, 1 for negative
      const posneg = +(value as number < 0);
      const diff: CostDiffType = {
        key: key,
        name: diff_keys[key] || key,
        amount: value as number,
        pct: "",
        delta: "",
        warv: Math.round((costDiffs[key as keyof typeof costDiffs] / diff_total) * 100),
      };

      // Handle different ways of describing the delta
      if (key === "year_built") {
        const ysign = ["+ ", "- "][posneg];
        const age = Math.round(Math.abs(cval - sval));
        const measure = age === 1 ? " year " : " years ";
        const suffix = ["newer", "older"][posneg];
        diff.delta = ysign + age + measure + suffix;
      } else if (delta_descriptions[key]) {
        diff.delta = delta_descriptions[key][posneg];
      } else {
        const sign = ["+", "-"][posneg];
        // Get the difference between the two values
        let dval = Math.abs(cval - sval);
        // Round everything except bathrooms
        if (key !== "bathrooms") {
          dval = Math.round(dval);
        }
        diff.delta = sign + " " + (dval === 0 ? "" : dval);

        // Proper grammar!
        if (["bedrooms", "bathrooms", "parking_count"].indexOf(key) !== -1 && dval !== 1) {
          diff.name += "s";
        }

        const pctd = Math.abs(Math.round(((cval - sval) / cval) * 100));
        // Watch out for Buzz Lightyear
        if (pctd === Infinity || pctd === -Infinity || isNaN(pctd)) {
          diff.pct = "";
        } else {
          // The percentage is what percent bigger/smaller
          // the SUBJECT is in terms of the COMPARABLE
          diff.pct = "(" + sign + pctd + "%)";
        }
      }
      if (isNaN(diff.warv as number)) {
        diff.warv = "N/A";
      } else {
        diff.warv = Math.abs(diff.warv as number) + "%";
      }

      diffs.push(diff);
    }
  });

  return diffs.sort((a, b) => {
    if (a.amount > b.amount) return 1;
    if (a.amount < b.amount) return -1;
    return 0;
  });
}

export const useSalesComps = () => {
  const [salesComparables, setSalesComparables] = useRecoilState(salesComparablesAtom);
  // const { recalculateArv } = useFinancials();

  const toggleComparableStatus = (compIndex: number) => {
    const position = salesComparables.findIndex(comp => comp._idx === compIndex);
    const newComparable = { ...salesComparables[position] };
    if (newComparable.is_included) {
      newComparable.status = "excluded";
      newComparable.is_included = false;
    } else {
      newComparable.status = "included";
      newComparable.is_included = true;
    }
    const newComparables = [
      ...salesComparables.slice(0, position),
      ...salesComparables.slice(position + 1),
      newComparable,
    ];

    const numSelected = newComparables.filter(comp => comp.is_included).length;
    if (numSelected === 0) {
      // Don't allow to unselect all
      return;
    }

    const sortedNewComparables = sortSalesComparables(newComparables);
    setSalesComparables(sortedNewComparables);
    // recalculateArv(sortedNewComparables);
  }

  return {
    salesComparables,
    setSalesComparables,
    toggleComparableStatus,
  };

}