import { appConfig } from "config";
import { fetchPolygons } from "state";
import { MarketComparableType } from "components/markets";
import L from "leaflet";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";

const defaultStyle = {
  "color": "#61a28d",
  "weight": 2,
  "opacity": 0.7
};

export class MarketResearchMapService {
  center: L.LatLngLiteral;
  map: L.Map | null = null;
  geoJSONLayer: L.GeoJSON | null = null;
  bounds: L.LatLngBounds | null = null;
  polygons: any[] = [];
  regions: MarketComparableType[] = [];

  constructor(
    center: L.LatLngLiteral,
  ) {
    this.center = center;
  }

  createMap(domRef: HTMLDivElement) {
    this.map = L.map(domRef, {
      center: [this.center.lat, this.center.lng],
      zoom: 6,
      maxZoom: 18,
      attributionControl: true,
      zoomControl: false,
    });

    L.maplibreGL({
      style: `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${appConfig.mapTilerApiKey}`,
    }).addTo(this.map);

    L.control.zoom({
      position: 'bottomright'
    }).addTo(this.map);

    this.map.scrollWheelZoom.disable();

    if (this.polygons.length === 0 && this.bounds) {
      this.map.fitBounds(this.bounds);
    }
  }

  setRegions(regions: MarketComparableType[]) {
    this.regions = regions;
    if (regions && regions.length > 0) {
      const areas = regions.map((region) => ({ type: region.loc_type, id: region.loc_id }));
      fetchPolygons(areas).then((polygons) => {
        this.addPolygons(polygons);
      });
    }
  }

  addPolygons(polygons: any[]) {
    this.polygons = polygons;
    if (this.polygons.length === 0) {
      return;
    }
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: 'FeatureCollection',
      features: this.polygons.map((polygon, idx) => {
        return {
          "type": "Feature",
          "properties": { id: idx, name: this.regions[idx].loc_name },
          "geometry": polygon,
        }
      })
    };

    if (this.map) {
      if (this.geoJSONLayer) {
        this.geoJSONLayer.clearLayers();
      }
      this.geoJSONLayer = L.geoJSON(featureCollection, {
        style: defaultStyle
      })
        .bindTooltip((layer: any) => layer.feature.properties.name)
        .addTo(this.map);
      this.bounds = this.geoJSONLayer.getBounds();
      this.map.flyToBounds(this.bounds);
    }
  }

  clearMap() {
    this.map?.off();
    this.map?.remove();
  }
}
