import { Wrapper } from "@googlemaps/react-wrapper";
import { Tab } from "@headlessui/react";
import { AdjustmentsIcon, ChartBarIcon, MapIcon } from "@heroicons/react/outline";
import WarningIcon from "components/icons/WarningIcon";
import {
  CompFilters,
  CompGraph,
  CompMap,
  CompProperties,
  RentalCompFilters,
  RentalCompGraph,
  RentalCompMap,
  RentalCompProperties,
} from "components/proformas/comparables/";
import { appConfig } from "config";
import NWMLSLogo from "images/NWMLS-logo.png";
import Pluralize from "pluralize";
import React, { useRef, useState } from "react";
import { useSetVisibleSection, useSignInSignUpModal } from "state";
import { useProforma } from "state/proformas";
import { useRentalComps, useSalesComps } from "state/proformas/comparables";

type TabToShowType = "filters" | "map" | "prices";

export const Comparables = () => {
  const { proforma } = useProforma();
  const { salesComparables, toggleComparableStatus } = useSalesComps();
  const { rentalComparables } = useRentalComps();

  const [salesCompActiveTab, setSalesCompActiveTab] = useState<TabToShowType>("map");
  const [rentalCompActiveTab, setRentalCompActiveTab] = useState<TabToShowType>("map");

  const ref = useRef<HTMLDivElement>(null);
  useSetVisibleSection(ref, "comps");

  const { openSignUpModal } = useSignInSignUpModal();
  const hasMLS = !(proforma.has_mls === false);

  return (
    <div
      className="align-center flex w-full flex-col items-center justify-center px-0 py-10 sm:py-[5.5rem]"
      id="Comps"
      ref={ref}
    >
      {!hasMLS && (
        <div className="text-md m-2 flex flex-col items-center rounded-lg bg-yellow-100 p-2 text-center text-gray-800 sm:flex-row sm:justify-center sm:gap-2">
          <div className="mr-2 hidden sm:block">
            <WarningIcon />
          </div>
          <div className="flex">We don't have MLS data for this region yet.</div>
          <div>
            <span className="cursor-pointer text-blue-700 underline" onClick={openSignUpModal}>
              Click here
            </span>{" "}
            to get updates when we do.
          </div>
        </div>
      )}
      <Tab.Group>
        <Tab.List
          className={`${(!hasMLS || !rentalComparables.length) && "hidden"} flex w-80 max-w-sm space-x-0 p-0 sm:w-96`}
        >
          <Tab
            className={({ selected }) =>
              selected
                ? `${
                    !hasMLS || !rentalComparables.length
                      ? "pointer-events-none text-gray-600"
                      : "bg-[#38455b] text-white"
                  } w-full rounded-l-sm border border-[#38455b] py-2.5 text-sm font-medium uppercase leading-5`
                : "w-full rounded-l-sm border border-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-gray-600"
            }
          >
            Sale Comps
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "w-full rounded-r-sm border border-[#38455b] bg-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-white"
                : "w-full rounded-r-sm border border-[#38455b] py-2.5 text-sm font-medium uppercase leading-5 text-gray-600 focus:ring-white"
            }
          >
            Rental Comps
          </Tab>
        </Tab.List>
        <Tab.Panels className="flex w-full justify-center bg-gradient-to-b from-white to-white">
          <div className="w-full max-w-7xl justify-center pt-6">
            {/* Sale comps tab content */}
            <Tab.Panel className="">
              {/* Comparables top 3 columns with max width */}
              <div className="text-center text-lg text-black sm:text-xl">
                We found <strong>{Pluralize("sale", salesComparables.length, true)}</strong> that match your
                search
              </div>
              {hasMLS && proforma.listing.listing_source && (
                <div className="text-center text-sm font-light text-black">
                  Listings provided by {proforma.listing.listing_source}
                  {proforma.listing.listing_source === "NWMLS" ? (
                    <img src={NWMLSLogo} alt="NWMLS Logo" className="ml-1 inline size-4" />
                  ) : (
                    "."
                  )}
                </div>
              )}
              <div className="mb-4"></div>
              <div className="inline-flex w-full justify-center rounded-md md:hidden" role="group">
                <button
                  type="button"
                  onClick={() => {
                    setSalesCompActiveTab("filters");
                  }}
                  className={`${
                    salesCompActiveTab === "filters" ? "bg-gray-200 text-blue-700" : "bg-white text-gray-900"
                  } flex rounded-l-md border border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                >
                  <AdjustmentsIcon className="mr-2 h-4 w-4 text-blue-700" /> Filters
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setSalesCompActiveTab("map");
                  }}
                  className={`${
                    salesCompActiveTab === "map" ? "bg-gray-200 text-blue-700" : "bg-white text-gray-900"
                  } flex border border-l-0 border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                >
                  <MapIcon className="mr-2 h-4 w-4 text-blue-700" /> Map
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setSalesCompActiveTab("prices");
                  }}
                  className={`${
                    salesCompActiveTab === "prices" ? "bg-gray-200 text-blue-700" : "bg-white text-gray-900"
                  } flex rounded-r-md border border-l-0 border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                >
                  <ChartBarIcon className="mr-2 h-4 w-4 text-blue-700" /> Prices
                </button>
              </div>

              <div className="flex w-full flex-col md:flex-row">
                <div className="flex w-full flex-col p-4 md:ml-6 md:hidden">
                  {salesCompActiveTab === "filters" && (
                    <div className="relative flex h-full w-full">
                      <CompFilters />
                    </div>
                  )}
                  {salesCompActiveTab === "map" && (
                    <div className="relative flex h-96 w-full">
                      <Wrapper apiKey={appConfig.googleMapsKey}>
                        <CompMap />
                      </Wrapper>
                    </div>
                  )}
                  {salesCompActiveTab === "prices" && (
                    <div className="relative flex h-96 w-full">
                      <CompGraph comparables={salesComparables} />
                    </div>
                  )}
                </div>

                <div className="hidden w-full flex-row gap-6 md:flex">
                  <div className="h-80 w-1/3">
                    <CompFilters />
                  </div>
                  <div className="z-0 h-80 w-1/3 md:mt-0 md:px-0">
                    <Wrapper apiKey={appConfig.googleMapsKey}>
                      <CompMap />
                    </Wrapper>
                  </div>
                  <div className="h-80 w-1/3">
                    <CompGraph comparables={salesComparables} />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <CompProperties
                  comparables={salesComparables}
                  toggleComparableStatus={toggleComparableStatus}
                />
              </div>
            </Tab.Panel>

            {/* Rental Comps tab content */}
            {hasMLS && (
              <Tab.Panel className="">
                {/* Comparables top 3 columns with max width */}
                <div className="text-center text-lg text-black sm:text-xl">
                  We found <strong>{Pluralize("rental", rentalComparables.length, true)}</strong> that match
                  your search
                </div>
                <div className="mb-4 text-center text-sm font-light text-black">
                  Listings provided by {proforma.listing.listing_source}.
                </div>

                <div className="inline-flex w-full justify-center rounded-md md:hidden" role="group">
                  <button
                    type="button"
                    onClick={() => {
                      setRentalCompActiveTab("filters");
                    }}
                    className={`${
                      rentalCompActiveTab === "filters"
                        ? "bg-gray-200 text-blue-700"
                        : "bg-white text-gray-900"
                    } flex rounded-l-md border border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                  >
                    <AdjustmentsIcon className="mr-2 h-4 w-4 text-blue-700" /> Filters
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setRentalCompActiveTab("map");
                    }}
                    className={`${
                      rentalCompActiveTab === "map" ? "bg-gray-200 text-blue-700" : "bg-white text-gray-900"
                    } flex border border-l-0 border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                  >
                    <MapIcon className="mr-2 h-4 w-4 text-blue-700" /> Map
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setRentalCompActiveTab("prices");
                    }}
                    className={`${
                      rentalCompActiveTab === "prices"
                        ? "bg-gray-200 text-blue-700"
                        : "bg-white text-gray-900"
                    } flex rounded-r-md border border-l-0 border-gray-200 px-4 py-2 align-middle text-xs font-medium shadow-sm`}
                  >
                    <ChartBarIcon className="mr-2 h-4 w-4 text-blue-700" /> Prices
                  </button>
                </div>

                <div className="flex w-full flex-col gap-2 md:flex-row md:gap-4">
                  <div className="flex w-full flex-col md:ml-6 md:hidden">
                    {rentalCompActiveTab === "filters" && (
                      <div className="relative flex h-full w-full gap-4 p-4">
                        <RentalCompFilters />
                      </div>
                    )}
                    {rentalCompActiveTab === "map" && (
                      <div className="relative flex h-96 w-full gap-4 p-4">
                        <Wrapper apiKey={appConfig.googleMapsKey}>
                          <RentalCompMap />
                        </Wrapper>
                      </div>
                    )}
                    {rentalCompActiveTab === "prices" && (
                      <div className="relative flex h-96 w-full gap-4 p-4">
                        <RentalCompGraph comparables={rentalComparables} />
                      </div>
                    )}
                  </div>

                  <div className="hidden w-full flex-row gap-4 md:flex">
                    <div className="w-1/3">
                      <RentalCompFilters />
                    </div>
                    <div className="h-80 w-1/3 sm:mt-0 sm:px-0">
                      <Wrapper apiKey={appConfig.googleMapsKey}>
                        <RentalCompMap />
                      </Wrapper>
                    </div>
                    <div className="h-full w-1/3 gap-4">
                      <RentalCompGraph comparables={rentalComparables} />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <RentalCompProperties comparables={rentalComparables} />
                </div>
              </Tab.Panel>
            )}
          </div>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
