import { Spinner } from "components";
import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSignInSignUpModal, useUserSession } from "state";
import { useSaveProforma } from "state/proformas";

type Props = {
  className?: string;
  text: string;
};

export const SaveProforma = ({ className, text }: Props) => {
  const { proforma, saveProforma, autoSaveProforma, setAutoSaveProforma } = useSaveProforma();
  const currentUser = useUserSession();
  const { openSignInModal } = useSignInSignUpModal();
  const [saving, setSaving] = useState(false);
  const [buttonText, setButtonText] = useState(proforma.is_mine ? "SAVED" : text);

  const save = useCallback(() => {
    setSaving(true);
    setButtonText("SAVING");
    saveProforma()
      .then(() => {
        setButtonText("SAVED");
      })
      .finally(() => setSaving(false));
  }, [saveProforma, setSaving]);

  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!currentUser?.isLogged) {
      openSignInModal();
      return;
    }

    ReactGA.event({
      category: "Proforma",
      action: "Save Deal",
      label: (proforma._id || "").toString(),
    });

    save();
  };

  useEffect(() => {
    if (autoSaveProforma) {
      setAutoSaveProforma(false);
      save();
    }
  }, [autoSaveProforma, setAutoSaveProforma, save]);

  return (
    <button className={className} onClick={clickHandler} disabled={saving}>
      {saving && (
        <Spinner className={`h-4 w-4 -ml-2 mr-3`} />
      )}
      {buttonText}
    </button>
  );
};
