import useSWR, { KeyedMutator } from "swr";
import { appConfig, WASHINGTON_STATE_ID } from "config";
import { fetcherWithCredentials, jsonParse } from "helpers";
import { ServiceAreaType } from "state";

interface ISession {
  _fresh: boolean;
  logged: boolean;
  email: string;
  name_first: string;
  name_last: string;
  roles: { name: string }[];
  _id?: number;
  avatar?: string;
  agent?: object;
  agent_slug?: string;
  default_region?: number;
  is_confirmed?: boolean;
  occupations?: string[];
  phone_number?: string;
  user_id?: string;
  zip_code?: string;
  description?: string;
  skills?: string[];
  service_areas?: ServiceAreaType[];
}

export class UserSession {
  error: string | undefined;
  session: ISession | undefined;
  mutate: KeyedMutator<any>;

  constructor(data: any, error: any, mutate: KeyedMutator<any>) {
    this.session = data;
    this.error = error;
    this.mutate = mutate;

    if (this.session) {
      localStorage.setItem("current_user", JSON.stringify(this.session));
    } else {
      // get initial state from local storage to enable user to stay logged in
      this.session = jsonParse(localStorage.getItem("current_user") || "");
    }
  }

  get userId() {
    return this.session?.user_id;
  }

  get loading(): boolean {
    return !this.session && !this.error;
  }

  get isLogged() {
    return this.session && this.session.logged;
  }

  get hasAdvancedRole() {
    return this.session && this.session.logged;
  }

  get isClient() {
    return this.session?.roles.some(role => role.name === "client");
  }

  get isAgent() {
    return this.session?.roles.some(role => role.name === "agent");
  }

  get isExporter() {
    return this.session?.roles.some(role => role.name === "exporter");
  }

  get isWashington () {
    return this.session?.default_region === WASHINGTON_STATE_ID;
  }

  get isManagingAgent() {
    return this.session?.roles.some(role => role.name === "managing_agent");
  }

  get avatar() {
    return this.session?.avatar
      || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  }

  get name() {
    if (this.session?.name_first && this.session?.name_last) {
      return `${this.session?.name_first} ${this.session?.name_last}`}
    if (this.session?.name_first) {
      return `${this.session?.name_first}`}
    if (this.session?.name_last) {
      return `${this.session?.name_last}`}
    return null
  }

  refresh(userData?: any) {
    if (userData) {
      return this.mutate({ ...this.session, ...userData }, false);
    }
    else {
      return this.mutate();
    }
  }

}

export const useUserSession = () => {
  const { data, error, mutate } = useSWR(appConfig.apiEndpoint.session,
    fetcherWithCredentials, { revalidateIfStale: false });

  const session = new UserSession(data, error, mutate);

  return session;
}
