import { Map } from "components/browse";

export const AppreciationsMap = () => {
  return (
    <div className="flex h-full w-full flex-col overflow-y-auto bg-[#aadaff] ">
      <div className="z-10 h-full w-full overflow-y-auto bg-[#aadaff]">
        <Map emptyResult={true} />
      </div>
    </div>
  );
};
