import { FinancialsType } from "state/proformas/";

type ValuesType = {
  // NOT USED acquisition_costs: number;
  after_repair_value: number;
  // NOT USED carrying_costs: number;
  cost_appreciation: number;
  holding_time: number;
  initial_monthly_costs: number;
  // NOT USED initial_monthly_profit: number;
  initial_monthly_rent: number;
  initial_selling_costs: number;
  loan_amount: number;
  monthly_interest_rate: number;
  monthly_payment: number;
  mortgage_length: number;
  property_appreciation: number;
  purchase_price: number;
  rent_appreciation: number;
  rent_appreciation_frequency: number;
  // NOT USED carry_cashin: number;
  rental_cashin: number;
  // NOT USED flip_rehab_costs: number;
  hold_rehab_costs: number;
  // NOT USED cstrat: string;
  rstrat: string;
  flip_profit: number;
  holdLoanEnabled: boolean;
  brrrLoanEnabled: boolean;
  strategy: string;
};

const buildValues = (financials: FinancialsType): ValuesType => {
  const loan = financials.strategy_rental === "financed_hold" ?
    financials.loans.hold : financials.loans.brrr;

  return {
    // acquisition_costs: financials.costgroupTotals.acquisition,
    after_repair_value: financials.calcsheet.resale_value,
    // carrying_costs: financials.costgroupTotals.carrying,

    // until individual costs can be broken out and increased
    // at their own pace, we will match cost appr to rent appr
    cost_appreciation: financials.calcsheet.rent_appreciation,
    // stored in years
    holding_time: financials.calcsheet.holding_time * 12,
    initial_monthly_costs: financials.rental.monthlyCosts,
    initial_monthly_rent: financials.rental.monthlyRent,
    // initial_monthly_profit: financials.rental.monthlyProfit,
    initial_selling_costs: financials.strategy_rental === "financed_hold" ?
      financials.costgroupTotals.sellingHold :
      financials.costgroupTotals.selling,
    loan_amount: loan._principal_amt,
    monthly_interest_rate: loan.interest / 12,
    monthly_payment: loan._monthly_pmt,
    // This will be different from the holding time, and also it comes
    // in years so we have to multiply by 12. Ugh that's so annoying.
    mortgage_length: (financials.calcsheet.mortgage_length || 0) * 12,
    property_appreciation: financials.calcsheet.property_appreciation,
    purchase_price: financials.calcsheet.purchase_price,
    rent_appreciation: financials.calcsheet.rent_appreciation,
    rent_appreciation_frequency: financials.calcsheet.rent_period * 12,
    // carry_cashin: financials.carry.cashin,
    rental_cashin: financials.rental.cashin,
    // flip_rehab_costs: financials.calcsheet.rehab_costs_flip,
    hold_rehab_costs: financials.calcsheet.rehab_costs_hold,
    // cstrat: financials.strategy_carry,
    rstrat: financials.strategy_rental,
    flip_profit: financials.carry.profit,
    holdLoanEnabled: financials.loans.hold.is_enabled,
    brrrLoanEnabled: financials.loans.brrr.is_enabled,
    strategy: financials.strategy_rental,
  };
}

const _compound = (rate: number, period: number): number => {
  if (rate > 1) {
    rate /= 100;
  }
  return Math.pow((1 + rate), period);
};

export const reduceToYearly = (array: any[], cumulative: boolean = false) => {

  // This method takes an array of values and aggregates sets of twelve
  // values into singles. If the "cumululative" boolean is passed, then
  // the method assumes the existing array is a cumulative summation and
  // only returns every 12th value. If "cumulative" is falsey, this method
  // will sum each group of twelve and return an array of those sums.

  // Watch out for mismatches
  if (array.length % 12 === 1) {
    // console.log('reduceToYearly found array length ' + array.length +
    //   ', stripping first value. This is not an error, but needs' +
    //   ' to be consistent one way or the other.');
    array = [...array];
    array.splice(0, 1);
  }
  else if (array.length % 12 !== 0) {
    console.log('reduceToYearly requires an array length multiple ' +
      ' of 12 (or 12+1), received ' + array.length);
    return array;
  }

  var output = new Array(Math.ceil(array.length / 12));

  for (var i = 0; i < array.length; i++) {
    // What year is it?
    var y = Math.floor(i / 12);

    if (!output[y]) {
      output[y] = 0;
    }

    // If cumulative is passed, only take the LAST value
    if (!cumulative || (i + 1) % 12 === 0) {

      // Display values for END of year, not beginning
      output[y] += array[i];
    }
  }
  return output;
}

type NetValuesType = {
  propertyvalue?: number[];
  principalbalance?: number[];
  sellingcosts?: number[];
  rent?: number[];
  holdingcosts?: number[];
  loanpayment?: number[];
  profit?: number[];
  cashin?: number[];
  totalequity?: number[];
  roi?: (number | null)[];
  rateofreturn?: (number | null)[];
  valuecap?: number[];
  irr?: (number | null)[];
};

type MonthlyValuesType = {
  holdingcosts?: number[];
  loanpayment?: number[];
  profit?: number[];
  rent?: number[];
};

export class MathServiceClass {
  values: ValuesType;
  net: NetValuesType;
  monthly: MonthlyValuesType;

  constructor(financials: FinancialsType) {
    this.values = buildValues(financials);
    this.net = {};
    this.monthly = {};

    this.calcAll();
  }

  // Returns 0 + h values of ARV/purchase price + appreciation
  generatePropertyValue() {
    var arv = this.values.after_repair_value;
    var pprice = this.values.purchase_price;
    var V = [];
    // Include initial value, so h += 1
    var h = this.values.holding_time + 1;
    var a = this.values.property_appreciation;
    var r = this.values.hold_rehab_costs;

    var prop = (this.values.rstrat === 'financed_hold') ? (pprice + r) : arv;

    for (var t = 0; t < h; t++) {
      // Due to the nature of compound, the 0 iter will = arv
      var value = prop * _compound(a, t / 12);
      V.push(Math.round(value));
    }
    return V;
  }

  // Returns 0 + h values of remaining loan balance
  generatePrincipalBalance() {
    var loanamount = this.values.loan_amount;
    var n = this.values.mortgage_length;
    var i = this.values.monthly_interest_rate;
    // Include initial value, so h += 1
    var h = this.values.holding_time + 1;
    var M = this.values.monthly_payment;
    var P = [];

    if (loanamount === 0) {
      // skip the first for loop and do all zero's (hits second loop)
      n = 0;
    }

    for (var t = 0; t < n; t++) {
      var c, principal, pmts;
      if (i === 0) {
        principal = loanamount;
        pmts = M * t;
      } else {
        c = _compound(i, t);
        principal = loanamount * c;
        pmts = M * ((c - 1) / i);
      }

      P.push(Math.round(principal - pmts));
    }
    // Add in zeros after payoff
    for (t = n; t < h; t++) {
      P.push(0);
    }

    return P;
  }

  // Returns 0 + h values of selling costs
  generateSellingCosts() {
    var SCo = this.values.initial_selling_costs;
    var SC = [SCo];
    var h = this.values.holding_time;
    var a = this.values.property_appreciation;

    for (var t = 1; t <= h; t++) {
      // BLACK MAGICK: this assumes the selling costs never change and
      // are all dependent on the arv
      // for now, calculating all sub selling costs at an equal increase,
      // and at an equal rate.
      var amt = (SCo) * _compound(a, t / 12);

      SC.push(Math.round(amt));
    }
    return SC;
  }

  generateCashIn() {
    var pr = this.monthly.profit || [];
    // Include an initial value, so h += 1
    var h = this.values.holding_time;
    // TODO: setting this as rental cash in for now
    var ytdcashin = this.values.rental_cashin;
    var X = [ytdcashin];

    for (var t = 0; t < h; t++) {
      if (pr[t] < 0) {
        ytdcashin += Math.abs(pr[t]);
      }
      X.push(ytdcashin);
    }
    return X;
  }

  generateRent() {
    var Ro = this.values.initial_monthly_rent;
    var h = this.values.holding_time;
    var rfr = this.values.rent_appreciation_frequency;
    var ar = this.values.rent_appreciation;

    if (!Ro) {
      Ro = 0;
    }

    var R = [Ro];

    // We start at one but do one fewer, so we can start with "initial" val
    for (var t = 1; t < h; t++) {
      // How many complete cycles have we made in frequency?
      // Multiply that by how many we WOULD have had every year
      var increases = Math.floor(t / rfr) * (rfr / 12);
      // Get the compound value of that many increases
      var amount = Ro * _compound(ar, increases);

      R.push(Math.round(amount));
    }
    return R;
  }

  generateHoldingCosts() {
    var C = [];
    var NC: number[] = [];
    var Co = this.values.initial_monthly_costs;
    var h = this.values.holding_time;
    var M = this.values.monthly_payment;
    var ac = this.values.cost_appreciation;
    var rfr = this.values.rent_appreciation_frequency;

    // note: includes holding costs only - no loan pmts
    for (var t = 0; t < h; t++) {
      var inc = Math.floor(t / rfr) * (rfr / 12);
      var amt = (Co - M) * _compound(ac, inc);

      C.push(Math.round(amt));

      if (t === 0) {
        NC.push(amt);
      } else {
        NC.push(NC[t - 1] + amt);
      }
    }
    return { monthly: C, net: NC };
  }

  generateLoanPayment() {
    var LC = [];
    var NLC: number[] = [];
    var loan = this.values.loan_amount;
    var n = this.values.mortgage_length;
    var h = this.values.holding_time;
    var M = this.values.monthly_payment;

    // what if there is no loan?
    if (loan === 0) {
      n = 1;
    }

    // Set for the length of the loan
    for (var k = 0; k < n; k++) {
      LC.push(Math.round(M));

      if (k === 0) {
        NLC.push(Math.round(M));
      } else {
        NLC.push(NLC[k - 1] + Math.round(M));
      }
    }
    // Set for any time after the loan
    for (k = n; k < h; k++) {
      LC.push(0);
    }
    return { monthly: LC, net: NLC };
  }

  generateProfit() {
    var R = this.monthly.rent || [];
    var C = this.monthly.holdingcosts || [];
    var LC = this.monthly.loanpayment || [];
    var h = this.values.holding_time;
    // cumulative profit of cashflows (no equity)
    var NP: number[] = [];
    var Pr = [];

    for (var t = 0; t < h; t++) {
      // profits include any loan costs
      var profits = R[t] - C[t] - LC[t];
      Pr.push(Math.round(profits));

      if (t === 0) {
        NP.push(profits);
      } else {
        NP.push(NP[t - 1] + profits);
      }
    }
    return { monthly: Pr, net: NP };
  }

  generateIRR() {
    var cashflows = reduceToYearly(this.monthly.profit || []);
    var prop_values = reduceToYearly(this.netPropertyValue(), true);
    // If we liquidate before the loan is paid off, that's important
    var loan_balance = reduceToYearly(this.netPrincipalBalance(), true);
    var selling_costs = reduceToYearly(this.netSellingCosts(), true);

    // TODO: setting this as rental cash in for now
    var initial_outlay = this.values.rental_cashin;

    if (this.values.rstrat === 'financed_brrr') {
      initial_outlay += this.values.flip_profit;
    }

    // FROM FINANCE.JS
    // seekZero seeks the zero point of the function fn(x), accurate to within x \pm 0.01. fn(x) must be decreasing with x.
    function seekZero(fn: any) {
      var x = 1;
      var timesrun = 0;
      var timesmax = 1000;
      while (fn(x) > 0 && timesrun < timesmax) {
        x += 1;
        timesrun++;
      }
      while (fn(x) < 0 && timesrun < timesmax) {
        x -= 0.01;
        timesrun++;
      }
      return x + 0.01;
    }

    // Internal Rate of Return (IRR)
    function IRR(...args: any) {
      function npv(rate: number) {
        var rrate = (1 + rate / 100);
        var npv = args[0];
        for (var i = 1; i < args.length; i++) {
          npv += (args[i] / Math.pow(rrate, i));
        }
        return npv;
      }
      return Math.round(seekZero(npv) * 100) / 100;
    }

    // Store our own set of cash flows for the monthly
    var cashflow_progress = [];

    // Loop through the periods and create an IRR for each of them
    var irr_values = [];

    // negative cash in, or a very small value will throw the irr formula
    // we'll start at 10% as the threshold for a large enough cash in value
    if (initial_outlay < (0.1 * this.values.after_repair_value)) {
      // if we don't have a large enough cashin value, we'll fill the array with nulls
      for (var k = 0; k < cashflows.length; k++) {
        irr_values.push(null);
      }
      return irr_values;

    } else {
      // The first IRR value is the initial outlay, so.... negative cash in?
      cashflow_progress.push(initial_outlay * -1);
      // We need a last value to keep the splice aligned,
      // so we'll leave it intentionally blank
      cashflow_progress.push(null);

      // Start with the zero element, which is basically a flip
      // irr_values.push( IRR.apply(null, cashflow_progress) );

      // Use cashflows length rather than h, because it's a yearly not monthly
      for (var i = 0; i < cashflows.length; i++) {
        // Remove the old liquidation value
        cashflow_progress.pop();
        // Add the new cash flows
        cashflow_progress.push(cashflows[i]);
        // And the new liquidation value
        cashflow_progress.push(
          prop_values[i] - loan_balance[i] - selling_costs[i]);
        // Calculate the IRR
        irr_values.push(IRR.apply(null, cashflow_progress));
      }
      return irr_values;
    }

  }

  generateTotalEquity() {
    var ARV = this.values.after_repair_value;
    var A = this.values.loan_amount;
    // Include an initial value, so h += 1
    var h = this.values.holding_time + 1;
    var V = this.netPropertyValue();
    var P = this.netPrincipalBalance();
    var SC = this.netSellingCosts();
    //note: includes loan pmt
    var X = this.netCashIn();
    var NE;
    var r = this.values.hold_rehab_costs;

    var prop = (this.values.rstrat === 'financed_hold') ?
      (this.values.purchase_price + r) : ARV;

    var initial_netequity = prop - A - SC[0] - X[0];
    NE = [initial_netequity];

    for (var t = 1; t < h; t++) {
      var netequity = V[t] - P[t] - SC[t] - X[t];

      NE.push(Math.round(netequity));
    }
    return NE;
  }


  generateValueCapRate() {
    var VCR = [];
    var Pv = reduceToYearly(this.netPropertyValue(), true);
    // use monthly values for profit and loan pmt so we can snapshot one 12 mo. period
    // using reduceToYearly fn instead of multiplying profit by 12 every iteration
    // seems more accurate to store array of only the year end values?
    var P = reduceToYearly(this.monthly.profit || []);
    var LC = reduceToYearly(this.monthly.loanpayment || []);

    for (var t = 0; t < P.length; t++) {

      if (!LC[t]) {
        LC[t] = 0;
      }

      var prof = P[t] + LC[t];
      var rate = (prof / Pv[t]) * 100;
      VCR.push(rate);
    }
    return VCR;
  }

  // Total cash on cash / annual rate of return
  // 'compound annual growth rate'
  generateRateOfReturn() {
    // this is the cumulative net profit (total for all time)
    var NP = reduceToYearly(this.netProfit(), true);
    var E = reduceToYearly(this.netTotalEquity(), true);
    var X = reduceToYearly(this.netCashIn(), true);
    var RR = [];
    // [(1 + (Cumulative profit / Net Cash In)) ^ (1 / year)] - 1
    // console.log(NP[0], E[0], X[0])

    for (var t = 0; t < NP.length; t++) {
      // Only an accurate % if cash in is positive
      if (X[t] <= 0) {
        RR.push(null);
      }
      else {
        // profit include the property value (if you were to cash out this yr),
        // as well as loan pmts(incl. in NP), cash in, and selling costs
        var profit = NP[t] + E[t];

        var netcashin = X[t];
        if (this.values.rstrat === 'financed_brrr') {
          netcashin += this.values.flip_profit;
        }

        // annualror is the cumulitve profit (for the year) over net cash in,
        var annualror = profit / netcashin;
        var power = 1 / (t + 1);
        var result = Math.pow(1 + annualror, power) - 1;


        RR.push(result * 100);
      }
    }

    return RR;
  }

  generateROI() {
    var h = this.values.holding_time;
    // note: includes loan pmt
    var Pr = this.monthly.profit || [];
    // note: includes loan pmt
    var X = this.netCashIn();

    var ROI = [];

    for (var t = 0; t < h; t++) {
      // If the cash flow is positive and cash in is negative,
      // cash on cash is infinity (technically)
      // But we'll also go null if cash flow is negative, cause that makes
      // a positive ROI and we don't want that
      if (X[t] <= 0) {
        ROI.push(null);
      }
      else {
        // annual cash on cash is the month profits x 12, divided by cash in
        var annual_cash_on_cash = (Pr[t] * 12) / X[t];
        ROI.push(annual_cash_on_cash * 100);
      }

    }
    return ROI;
  }

  calcAll() {
    const HC = this.generateHoldingCosts();
    this.net.holdingcosts = HC.net;
    this.monthly.holdingcosts = HC.monthly;

    const LP = this.generateLoanPayment();
    this.net.loanpayment = LP.net;
    this.monthly.loanpayment = LP.monthly;

    this.monthly.rent = this.generateRent();
    this.net.propertyvalue = this.generatePropertyValue();
    this.net.principalbalance = this.generatePrincipalBalance();
    this.net.sellingcosts = this.generateSellingCosts();

    const profit = this.generateProfit();     // Needs monthly.rent, monthly.holdingcosts, monthly.loanpayment
    this.net.profit = profit.net;
    this.monthly.profit = profit.monthly;

    this.net.cashin = this.generateCashIn();  // Needs monthly.profit

    this.net.irr = this.generateIRR();  // Needs monthly.profit, monthly.profit, monthly.profit, .sellingcosts

    this.net.totalequity = this.generateTotalEquity(); // Needs net.propertyvalue, net.principalbalance, net.sellingcosts, net.cashin

    this.net.valuecap = this.generateValueCapRate(); // Needs net.propertyvalue, monthly.profit, monthly.loanpayment

    this.net.rateofreturn = this.generateRateOfReturn(); // Needs net.profit, net.totalequity, net.cashin

    this.net.roi = this.generateROI(); // Needs monthly.profit, net.cashin
  }

  netPropertyValue() {
    return this.net.propertyvalue || [];
  }

  netPrincipalBalance() {
    return this.net.principalbalance || [];
  }

  netSellingCosts() {
    return this.net.sellingcosts || [];
  }

  netCashIn() {
    return this.net.cashin || [];
  }

  netTotalEquity() {
    return this.net.totalequity || [];
  }

  netProfit() {
    return this.net.profit || [];
  }

  netROI() {
    return this.net.roi || [];
  }

  netIRR() {
    return this.net.irr || [];
  }

  netReteOfReturn() {
    return this.net.rateofreturn || [];
  }

  monthlyLoanPayment() {
    return this.monthly.loanpayment || [];
  }

  monthlyHoldingCosts() {
    return this.monthly.holdingcosts || [];
  }

  monthlyProfit() {
    return this.monthly.profit || [];
  }

  monthlyRent() {
    return this.monthly.rent || [];
  }

}
