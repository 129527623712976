import { useEffect } from "react";
import { useUserSession } from "state";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

export const LOTSIDE_TRACKING_ID = "G-S32Q1T0Z97";

export const PathTracker = () => {
  const location = useLocation();
  const session = useUserSession();

  useEffect(() => {
    if (session.userId) {
      ReactGA.set({ uid: session.userId });
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location, session]);

  return null;
};
