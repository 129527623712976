import { appConfig, stopWords } from "config";
import { fetcherWithCredentials } from "helpers";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { atom, selector, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";


export interface ISearchParamLocations {
  state: any[];
  county: any[];
  city: any[];
  neighborhood: any[];
  school_district: any[];
  zip_code: any[];
  metro_area: any[];
}

export interface ISearchParams {
  locations: ISearchParamLocations;
}

export type ListingRemarkTrackLetterType = Record<string, string[]>;

const buildListingRemarksTypeahead = (deals: any[]): ListingRemarkTrackLetterType => {
  const allWords: string[] = [];
  deals.forEach((deal) => {
    if (deal.listing_remarks) {
      deal.listing_remarks
        .toLowerCase()
        .split(" ")
        .forEach((word: string) => {
          word = word.replace(/[^a-z0-9]/gi, "");
          if (
            word.length > 0 &&
            word[0].match(/[a-z]/i) &&
            !stopWords.includes(word) &&
            !allWords.includes(word)
          ) {
            allWords.push(word);
          }
        });
    }
  });

  const listingRemarksTerms: ListingRemarkTrackLetterType = {};
  allWords.forEach((word) => {
    // Is this the first time we find this letter?
    const trackletter = word[0];
    if (!listingRemarksTerms[trackletter]) {
      listingRemarksTerms[trackletter] = [];
    }
    listingRemarksTerms[trackletter].push(word);
  });
  return listingRemarksTerms;
};

export type SearchResultType = {
  _id: number;
  assumable_loan: boolean | null;
  hide_unknown_equity: boolean | null;
  baths: number;
  beds: number;
  days_on_market: number;
  dont_show_address: boolean;
  dont_show_map_link: boolean;
  equity: number | null;
  fullAddress: string;
  is_wholesale: boolean;
  latitude: number;
  listing_company: string;
  listing_remarks: string | null;
  listing_source: string;
  listingEvent: string;
  listingEventDate: string;
  listingNumber: string;
  listingPrice: number;
  listingType: string;
  location: string;
  longitude: number;
  lot_square_feet: number;
  monthly_rent_hold: number;
  name: string;
  no_avm_display: boolean;
  neighborhood_id?: number;
  parcel_id: number;
  pellegoArv: number;
  photo_urls?: (string | null)[] | null;
  piti: number;
  sqft: number;
  sqftUnfinished: number;
  state_id: number;
  structureType: string;
  year_built: number | null;
  zip_code: string;
};

export const searchResultsAtom = atom<SearchResultType[]>({
  key: "searchResultsAtom",
  default: [],
});

export const searchParamsAtom = atom<ISearchParams | undefined>({
  key: "searchParamsAtom",
  default: undefined,
});

const searchSelector = selector({
  key: "searchSelector",
  get: async ({ get }) => {
    const searchParams = get(searchParamsAtom);

    if (!searchParams) return;

    const params = new URLSearchParams({
      locations: JSON.stringify(searchParams.locations),
    });

    const response = await fetcherWithCredentials(
      `${appConfig.apiEndpoint.proformaSearch}?${params.toString()}`,
    );

    const listingRemarksTerms = buildListingRemarksTypeahead(response);

    return { deals: response, listingRemarksTerms };
  },
});

export const useSearchApiParams = () => {
  const [searchParams, setSearchParams] = useRecoilState(searchParamsAtom);
  const setSearchResults = useSetRecoilState(searchResultsAtom);

  const update = (newSearchParams: ISearchParams | undefined) => {
    if (isEqual(newSearchParams, searchParams)) return;
    setSearchParams(newSearchParams);
    setSearchResults([]);
  };

  return { update };
};

export const useSearchApiResult = () => {
  const loadable = useRecoilValueLoadable(searchSelector);
  const [searchResults, setSearchResults] = useRecoilState(searchResultsAtom);

  useEffect(() => {
    if (loadable.contents?.deals && loadable.contents?.deals.length > 0 && searchResults.length === 0) {
      setSearchResults(loadable.contents?.deals);
    }
  }, [loadable, setSearchResults, searchResults]);

  return {
    deals: loadable.contents?.deals,
    listingRemarksTerms: loadable.contents?.listingRemarksTerms,
    loading: loadable.state === "loading" || loadable.contents?.deals === undefined,
    error: loadable.state === "hasError",
  };
};