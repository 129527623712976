import { cn } from "helpers";

type Props = {
  property: any;
};

export const ListItemDisclosure = ({ property }: Props) => {
  const withLogoImage = property.listing_source === "RMLS" || property.listing_source === "SABOR";
  const imageLogo = property.listing_source === "RMLS" ? "rmls_logo.jpg" : "sabor_logo.png";

  return (
    <div className="flex flex-row items-center justify-start">
      {withLogoImage && (
        <div className="ml-2 shrink-0 content-center items-center justify-center rounded-sm bg-white">
          <img
            className="h-4 w-8 object-scale-down p-[2px] xl:h-6 xl:w-14 xl:p-[3px]"
            src={`https://cdn.davinci.pellego.com/static/images/${imageLogo}`}
            alt=""
          />
        </div>
      )}
      <div
        className={cn(
          "flex flex-col text-[10px] tracking-tighter text-gray-400 xl:text-xs",
          withLogoImage
            ? "w-[136px] pl-0.5 pt-0 xl:w-56 xl:pl-1 3xl:w-full"
            : "w-44 pl-0.5 pt-0 xl:w-72 xl:pl-2 xl:pt-1 3xl:w-full",
        )}
      >
        {withLogoImage && <div className="truncate">Listing provided by {property.listing_company}</div>}
        {!withLogoImage && (
          <div className="truncate">
            Listing provided by {property.listing_source}
            {/* {property.listing_company && " and " + property.listing_company} */}
          </div>
        )}
      </div>
    </div>
  );
};
