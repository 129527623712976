import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import L from "leaflet";
import { useSearchParams } from "react-router-dom";

const polygonsToCoordinates = (polygons: L.Polygon[]) => {
  return polygons.map((polygon) => {
    const latLngArray = polygon.getLatLngs().flat() as L.LatLng[];
    return [latLngArray.map((ll) => [ll.lng, ll.lat])];
  });
};

const getAllCurrentParams = (searchParams: URLSearchParams) => {
  const allUrlParams: Record<string, any> = {};

  searchParams.forEach((value, key) => {
    if (allUrlParams[key]) {
      if (Array.isArray(allUrlParams[key])) {
        allUrlParams[key].push(value);
      } else {
        allUrlParams[key] = [allUrlParams[key], value];
      }
    } else {
      allUrlParams[key] = value;
    }
  });
  return allUrlParams;
};

export const useMultiPolygons = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const geoId = searchParams.get("geoId");

  const setSearchParamsValues = (newValues: Record<string, any>) => {
    const currentParams = getAllCurrentParams(searchParams);
    for (const key in newValues) {
      if (Object.prototype.hasOwnProperty.call(newValues, key)) {
        const value = newValues[key];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          delete currentParams[key];
          delete newValues[key];
        }
      }
    }
    setSearchParams({ ...currentParams, ...newValues });
  };

  const upsertGeoJson = (polygons: L.Polygon[], geoId: string | null) => {
    if (geoId && polygons.length === 0) {
      return fetcherWithCredentials(appConfig.apiEndpoint.locationGeoJson + `/${geoId}`, "DELETE").finally(
        () => {
          setSearchParamsValues({ geoId: null });
        },
      );
    }

    const data = {
      coordinates: polygonsToCoordinates(polygons),
      type: "MultiPolygon",
    };

    const url = appConfig.apiEndpoint.locationGeoJson + (geoId ? `/${geoId}` : "");
    return fetcherWithCredentials(url, geoId ? "PUT" : "POST", data)
      .then((result) => {
        setSearchParamsValues({ geoId: result.id });
      })
      .catch(() => {
        console.error(`upsertGeoJson ERROR for geoId: ${geoId}`);
        upsertGeoJson(polygons, null);
      });
  };

  const setMultiPolygons = (polygons: L.Polygon[]) => {
    upsertGeoJson(polygons, geoId);
  };

  return setMultiPolygons;
};
