import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import React, { useEffect, useRef } from "react";
import { SnapItem, SnapList, useScroll, useVisibleElements } from "react-snaplist-carousel";
import { useProforma } from "state/proformas";

type Props = {
  photos: any[];
  isOpen: boolean;
  closeModal: any;
  showIdx?: number;
};

export const PhotosModal = ({ photos, isOpen, closeModal, showIdx }: Props) => {
  const snapList = useRef(null);
  const { proforma } = useProforma();
  var showListingSource = false;
  var listingSource = "";
  if (proforma && proforma.listing && proforma.listing.listing_source) {
    listingSource = proforma.listing.listing_source;
    showListingSource = true;
  }
  const goToPhoto = useScroll({ ref: snapList });
  const visible = useVisibleElements({ debounce: 100, ref: snapList }, ([element]) => element);

  useEffect(() => {
    if (showIdx != null) {
      goToPhoto(showIdx, { animationEnabled: false });
    }
  }, [showIdx, goToPhoto]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "ArrowRight":
        goToPhoto(visible + 1);
        break;
      case "ArrowLeft":
        goToPhoto(visible - 1);
        break;
    }
  };

  return (
    <ModalWrapper
      className="z-[2147483001] sm:pt-1"
      isOpen={isOpen}
      closeModal={closeModal}
      overlayOpacity={90}
    >
      <div
        className="inline-block h-screen max-h-fit w-screen transform overflow-hidden bg-white px-0 text-left align-middle shadow-xl transition-all sm:rounded-md sm:bg-black"
        onKeyDown={handleKeyDown}
      >
        <div className="mb-3 mt-1 h-6 align-middle text-sm font-medium leading-6 text-black sm:text-white">
          <button className="float-right outline-none" onClick={closeModal}>
            <XIcon className="mr-1 w-7" aria-hidden="true" />
          </button>
        </div>

        <div className="flex h-screen max-h-[90%] flex-row">
          <div className="relative h-full overflow-auto ">
            <div className="relative flex h-full snap-x snap-mandatory gap-4 overflow-x-auto text-center">
              <SnapList ref={snapList} direction="horizontal">
                {photos.map((photo: any, index) => (
                  <SnapItem width="100%" snapAlign="start" key={`snampitem-${photo._id}`}>
                    <div className={`relative flex h-full w-full items-center justify-center pb-1 sm:pb-0`}>
                      {visible === index && index > 0 && (
                        <span
                          className="absolute left-1 h-10 w-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                          onClick={() => goToPhoto(index - 1)}
                        >
                          <ChevronLeftIcon />
                        </span>
                      )}
                      <img
                        className="size-full object-contain rounded bg-white sm:bg-black align-middle shadow-xl"
                        src={photo.photo_url}
                        alt={`Sale: ${photo.sale_id} Parcel: ${photo.parcel_id}`}
                      />

                      {photo.description && (
                        <div className="absolute bottom-1 mx-4 mb-1 rounded-xl bg-black p-2 py-1 text-xs text-white opacity-50 sm:bottom-12 sm:mx-0 sm:px-4 sm:opacity-80">
                          {photo.description}
                        </div>
                      )}

                      {visible === index && index < photos.length - 1 && (
                        <span
                          className="absolute right-1 h-10 w-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                          onClick={() => goToPhoto(index + 1)}
                        >
                          <ChevronRightIcon />
                        </span>
                      )}
                    </div>
                  </SnapItem>
                ))}
              </SnapList>
            </div>
          </div>
        </div>

        {showListingSource && (
          <div className="mt-2 text-center text-xs text-gray-400">
            Property photos and listing data provided by {listingSource}.
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
