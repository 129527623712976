import { ChevronDownIcon, ChevronUpIcon, PhotographIcon } from "@heroicons/react/outline";
import { SaleHistoryPhotosModal } from "components/proformas/modals";
import { formatDateShort } from "helpers";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
// import TimeAgo from "react-timeago";
import { ParcelHistoryEntryType } from "state/proformas";

type Props = {
  history: ParcelHistoryEntryType[];
  sinceListed?: boolean;
  disablePhotosModal?: boolean;
};

const showMoreThreshold = 5;

export const ParcelHistory = ({ history, sinceListed, disablePhotosModal }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const [showParcelHistoryEntry, setShowParcelHistoryEntry] = useState<undefined | ParcelHistoryEntryType>(
    undefined,
  );

  if (!history.length) {
    return <div className="flex w-full px-1 py-2 text-sm">No sales history record for this property.</div>;
  }

  let lastListedIndex = history.findIndex((listing) => listing.event.toLowerCase() === "listed");
  if (lastListedIndex === -1) lastListedIndex = 0;

  return (
    <div>
      <div className="mt-2 pb-1 text-xs font-bold uppercase text-green-900">LISTING & SALE HISTORY</div>
      {history.map((listing, idx) => (
        <React.Fragment key={`${listing.date}-${idx}`}>
          {((sinceListed && idx <= lastListedIndex) || !sinceListed) && (
            <div className={`${idx >= showMoreThreshold && !showAll ? "hidden" : ""}`}>
              <div
                className={`flex w-full gap-2 whitespace-nowrap border-b border-gray-200 px-1.5 py-2 text-sm sm:gap-4 ${idx > lastListedIndex ? "text-gray-400" : ""}`}
              >
                <div className="flex grow basis-1/3 items-center align-middle font-medium sm:basis-2/4">
                  {listing.event}
                  {!disablePhotosModal && listing.has_metadata && (
                    <button
                      aria-label="View historical data"
                      onClick={() => setShowParcelHistoryEntry(listing)}
                      className="ml-2 flex cursor-pointer items-center whitespace-nowrap rounded-full border border-blue-400 bg-white p-1 px-2 text-center text-xs text-blue-400 hover:bg-blue-400 hover:text-white"
                    >
                      <PhotographIcon className="mr-1 w-3" aria-hidden="true" />
                      {listing.photos?.length || 0}
                    </button>
                  )}
                </div>
                <div className="basis-1/3 text-right sm:basis-1/4">
                  <NumberFormat
                    value={listing.price}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$ "
                    decimalScale={0}
                  />
                </div>
                {/* <div className="hidden text-right sm:block sm:basis-1/4">
                  <TimeAgo date={new Date(listing.date)} live={false} />
                </div> */}
                <div className="basis-1/3 text-right sm:basis-1/4">{formatDateShort(listing.date)}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      {history.length > showMoreThreshold && showAll && (
        <button
          className="flex flex-row items-center pt-2 text-sm text-gray-400"
          onClick={() => setShowAll(false)}
        >
          <ChevronUpIcon className="mr-1 inline h-4 w-4 text-gray-400" /> Show less history
        </button>
      )}
      {history.length > showMoreThreshold && !showAll && (
        <button className="pt-2 text-sm text-gray-400" onClick={() => setShowAll(true)}>
          <ChevronDownIcon className="mr-1 inline h-4 w-4 text-gray-400" /> Show more history
        </button>
      )}
      {showParcelHistoryEntry != null && (
        <SaleHistoryPhotosModal
          history={showParcelHistoryEntry}
          isOpen={showParcelHistoryEntry != null}
          closeModal={() => setShowParcelHistoryEntry(undefined)}
        />
      )}
    </div>
  );
};
