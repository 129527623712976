import { useEffect, useState, useRef } from "react";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import { typeAheadApi, TypeAheadItemType } from "helpers";
import { SearchResultItem } from "components/SearchBox/SearchResultItem";
import Icon from "@mdi/react"
import { mdiAutorenew, mdiClose } from "@mdi/js";

type Props = {
  serviceAreas: any[];
  updateServiceAreas: (serviceAreas: any[]) => void;
};

export const EditAreasOfService = ({ serviceAreas, updateServiceAreas }: Props) => {
  const [inputText, setInputText] = useState("");
  const debouncedText = useDebounce(inputText, 700);
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const resetStatus = () => {
    setInputText("");
    setTypeaheadResult([]);
  }

  useEffect(() => {
    if (debouncedText.length > 1) {
      setLoading(true);
      typeAheadApi(debouncedText, ["city", "county", "neighborhood", "zip_code"])
        .then((result) => setTypeaheadResult(result))
        .finally(() => setLoading(false));
    }
  }, [debouncedText])

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus();
    }
  }

  const removeServiceArea = (area: any) => {
    const newSerciceAreas = serviceAreas.filter((sa) => {
      return sa.type !== area.type || sa.id !== area.id;
    });
    updateServiceAreas(newSerciceAreas);
  }

  const selectItem = (item: TypeAheadItemType) => {
    const newSerciceAreas = serviceAreas.filter((sa) => {
      return sa.type !== item.type || sa.id !== item.id;
    });
    newSerciceAreas.push({ id: item.id, type: item.type, name: item.name });
    updateServiceAreas(newSerciceAreas);
    resetStatus();
  };

  return (
    <div className="w-full font-light">
      <div className="relative">
        <input
          type="text"
          className="border border-gray-200 h-9 w-full my-2 pr-6 text-sm"
          value={inputText}
          onChange={onInputTextChange}
          autoComplete="off"
          placeholder="Enter city, county, neighborhood or zip code"
          autoFocus
        />
        <button
          className="absolute right-1 top-3.5 font-bold"
          onClick={() => resetStatus()}
        >
          {!loading && inputText.length > 0 &&
            <Icon path={mdiClose} className="w-4 h-4 ml-1 inline text-gray-400 hover:bg-gray-300 rounded-lg" />
          }
          {loading && <Icon path={mdiAutorenew} className="w-4 h-4 ml-1 mt-1 text-blue-600" spin />}
        </button>

        {typeaheadResult.length > 0 &&
          <div
            ref={ref}
            className="absolute top-12 left-0 bg-gray-100 w-full max-h-80 overflow-y-scroll z-10  items-center border border-blue-400 p-1"
          >
            {typeaheadResult.map((item, idx) => (
              <SearchResultItem
                key={item.id || item.parcel_id || idx}
                item={item}
                onSelection={selectItem}
                selected={false}
                currentStateId={null}
              />
            ))}
          </div>
        }
      </div>
      <div className="flex flex-wrap">
        {serviceAreas.length === 0 &&
          <div className="text-gray-600 text-sm">
            No service areas selected
          </div>
        }
        {serviceAreas.map((area) => (
          <div key={`${area.type}-${area.id}`}
            className="relative capitalize py-1 pl-2 pr-6 mb-1 bg-gray-300 border rounded mr-1 whitespace-nowrap leading-4"
          >
            <span className="font-thin">{area.type.replace('_', ' ')}: </span>
            <span className="font-normal">{area.name}</span>
            <button
              className="absolute right-1 top-0.5"
              onClick={() => removeServiceArea(area)}
            >
              <Icon path={mdiClose} className="w-4 h-4 ml-1 inline text-gray-400 hover:bg-gray-200 rounded-lg" />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
