import { useFooter } from 'state';

import { Nav, MainBody, Footer, SignInSignUpDialog } from "components";
import { Outlet } from "react-router";

export const Layout = () => {
  const { showFooter } = useFooter();

  return (
    <div className="h-screen w-full font-sans">
      <Nav />
      <MainBody>
        {showFooter && (
          <>
            <div id="main-content" className="relative h-full flex-1 overflow-y-scroll">
              <div id="main-body">
                <Outlet />
              </div>
              <Footer />
            </div>
          </>
        )}
        {!showFooter && <Outlet />}
        <SignInSignUpDialog />
      </MainBody>
    </div>
  );
};
