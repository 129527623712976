import { PhotosModalMobile } from "components/proformas/modals";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useProforma } from "state/proformas";


export const Photos = () => {
  const { proforma } = useProforma();
  const [showPhotosModalIdx, setShowPhotosModalIdx] = useState<number | null>(null);

  if (proforma.photos.length === 0) return null;

  return (
    <>
      <div className="group relative w-full overflow-auto">
        <div className="flex w-full snap-x snap-mandatory overflow-x-auto scroll-smooth">
          {proforma.photos.map((photo: any, idx: number) => (
            <div
              key={photo._id}
              className={`${
                proforma.photos.length === 1 && "w-full"
              } shrink-0 snap-start snap-normal px-1`}
            >
              <LazyLoadImage
                className={`${
                  proforma.photos.length === 1 && "w-full"
                } h-60 min-w-[320px] shrink-0 cursor-pointer rounded bg-white object-cover shadow-xl sm:h-96`}
                onClick={() => setShowPhotosModalIdx(idx)}
                src={photo.photo_url}
                alt={`Sale: ${photo.sale_id} Parcel: ${photo.parcel_id}`}
              />
            </div>
          ))}
        </div>
      </div>
      {showPhotosModalIdx !== null && (
        <PhotosModalMobile
          photos={proforma.photos}
          isOpen={showPhotosModalIdx !== null}
          closeModal={() => setShowPhotosModalIdx(null)}
          showIdx={showPhotosModalIdx}
        />
      )}
    </>
  );
};