import { CalcSheetType, FinancialsType } from "state/proformas";

const _getFoundationAmount = (loan: any, calcsheet: CalcSheetType) => {
  // loan._foundation_amt = 0;
  loan.time = 0;
  loan._display_name = 'Loan';

  if (!calcsheet.mortgage_length) {
    calcsheet.mortgage_length = calcsheet.holding_time;
  }

  // assign foundation amt, as well as other attributes
  // we may need while using the loans
  switch (loan.name) {
    case 'rehab_hardmoney':
      // this loan is for the rehab costs only
      loan._foundation_amt = calcsheet.rehab_costs_flip;
      // comes from newton set in months
      loan.time = calcsheet.carrying_time;
      loan._display_name = 'Rehab Loan';
      loan._type = 'carry';
      break;
    case 'pp_hardmoney':
      // this loan is for the purchase price only (no rehab)
      loan._foundation_amt = calcsheet.purchase_price;
      // comes from newton set in months
      loan.time = calcsheet.carrying_time;
      loan._display_name = 'Purchase Loan';
      loan._type = 'carry';
      break;
    case 'hold_mortgage':
      loan._foundation_amt = calcsheet.purchase_price;
      // comes from newton set in yrs
      loan.time = calcsheet.mortgage_length * 12;
      loan._display_name = 'Mortgage';
      loan._type = 'rental';
      loan._closing_pct = 0.02;
      break;
    case 'brrr_mortgage':
      loan._foundation_amt = calcsheet.resale_value;
      // comes from newton set in yrs
      loan.time = calcsheet.mortgage_length * 12;
      loan._display_name = 'Refinance Loan';
      loan._type = 'rental';
      break;
  }
}

const _calculatePrincipalAmt = (loan: any) => {
  loan._origination_amt = 0;
  loan._fin_interest_amt = 0;
  // includes any financed origination and/or financed interest
  loan._principal_amt = 0;

  const monthly_interest_pct = loan.interest / 12;

  // Find the amount of the down payment
  if (loan.dpay) {
    loan._dpay_amt = loan._foundation_amt * loan.dpay;
  } else {
    loan._dpay_amt = 0;
  }

  // Principal starts as foundation minus down payment
  loan._principal_amt = loan._foundation_amt - loan._dpay_amt;

  loan._origination_amt =
    Math.round(loan._principal_amt * loan.origination);

  // Add the origination fee, if it's financed
  if (loan.is_financed_origination) {
    loan._principal_amt += loan._origination_amt;
  }

  // Factor in the cost of financing interest
  if (loan.is_financed_interest && loan._type === 'carry') {
    // Compound the interest over the life of the loan
    loan._principal_amt +=
      (Math.pow((1 + monthly_interest_pct), loan.time) *
        loan._principal_amt) - loan._principal_amt;
  }

  loan._principal_amt = Math.round(loan._principal_amt);

  return loan._principal_amt;
}

export const calculateLoans = (calcsheet: CalcSheetType) => {
  const loans = calcsheet.loans;

  loans.forEach((loan: any) => {

    _getFoundationAmount(loan, calcsheet);
    _calculatePrincipalAmt(loan);

    const monthly_interest_pct = loan.interest / 12;
    const compound = Math.pow((1 + monthly_interest_pct), loan.time);

    if (loan._type === 'rental') {

      if (monthly_interest_pct === 0) {
        loan._monthly_pmt = loan._principal_amt / loan.time;
      } else {
        const num = loan._principal_amt *
          (monthly_interest_pct * compound);
        const denom = compound - 1;
        loan._monthly_pmt = num / denom;
      }

      // The interest is the total monthly payments
      // minus the borrowed amount
      loan._interest_amt = Math.round(
        (loan._monthly_pmt * loan.time) - loan._principal_amt);
    }
    else if (loan._type === 'carry') {
      loan._monthly_pmt = monthly_interest_pct * loan._principal_amt;
      loan._interest_amt = loan._monthly_pmt * loan.time;
    }

    loan._total_pmts = Math.round(loan._monthly_pmt * loan.time);
    loan._topline = loan._origination_amt + loan._interest_amt;

    // NOW we can round the monthly payment
    loan._monthly_pmt = Math.round(loan._monthly_pmt);
  });

  return loans;
}

/**
* Retrieve a cost from cost groups by name
* @param {string} costName The name of a cost.
* @param {!Object} costgroups Collection of costsgroups.
* @return {!Object} The last cost with that name.
*/
export const costByName = (costName: string, costgroups: any) => {
  let rv: any = null;
  Object.values(costgroups).forEach((costgroup: any) => {
    costgroup.forEach((cost: any) => {
      if (cost.name === costName) {
        rv = cost;
      }
    });
  });
  return rv;
}

/**
* Return true if costgroup contains an object with name and
*     _costrgoup.
* @param {string} costName The name of a cost.
* @param {string} groupName The name of a costgroup.
* @param {!Object} costgroups Collection of costsgroups.
* @return {boolean} Whether or not the costgroup has a cost with that name
*     and group name.
*/
export const isCostInGroup = (costName: string, groupName: string, costgroup: any[]) => {
  return costgroup.some((element: any) => {
    const sameName = element.name === costName;
    const sameGroup = element._costgroup === groupName;
    return sameName && sameGroup;
  })
}

export const getFoundationAmount = (foundation: string, financials: FinancialsType) => {
  let amount = 0;
  const calcsheet = financials.calcsheet;

  switch (foundation) {
    case 'holding_time':
      // we'll want the total (per mo) taxes
      // for the carrying time
      amount = (calcsheet.annual_taxes / 12) * calcsheet.carrying_time;
      break;
    case 'square_feet_finished':
      amount = financials.square_feet_finished;
      break;
    default:
      amount = calcsheet[foundation as keyof CalcSheetType] as number || amount;
      break;
  }
  return amount;
}

const getCostsDependentOn = (foundation: string, calcsheet: CalcSheetType) => {
  return calcsheet.costs.filter(cost => cost.foundation === 'purchase_price')
}

export const calculatePurchaseFromProfit = (
  financials: FinancialsType,
  targetprofit: number | undefined,
  targetpprice: number | undefined
) => {
  const cstrat = financials.strategy_carry;

  const purchase_profit: { target_profit: null | number, target_pprice: null | number } = {
    target_profit: null,
    target_pprice: null,
  };

  let resale_value = financials.calcsheet.resale_value;

  if (financials.calcsheet.my_arv)
    resale_value = financials.calcsheet.my_arv;

  const current_profit = financials.carry.profit;
  const current_pprice = financials.calcsheet.purchase_price;

  // We need to account for zero values
  const target_profit = targetprofit !== undefined ? targetprofit : current_profit;
  const target_pprice = targetpprice !== undefined ? targetpprice : current_pprice;

  // Step one, find out the total of all costs DEPENDENT on the purchase price
  let total_related_to_pprice = 0;

  getCostsDependentOn('purchase_price', financials.calcsheet).forEach((cost) => {
    // Subcosts we calculate by finding the total amount
    if (!cost.loan_type)
      total_related_to_pprice += (cost.multiplier * current_pprice);
  });

  // If it's a loan that applies to the carrying period
  if (cstrat === 'financed_both' || cstrat === 'financed_pp') {
    financials.calcsheet.loans.filter(loan => loan.name === 'pp_hardmoney').forEach((loan) => {
      total_related_to_pprice += loan._origination_amt + loan._interest_amt;
    });
  }

  // All those costs are a percent of the purchase price!
  const pct_related_to_pprice = total_related_to_pprice / current_pprice;
  // console.log('Found $' + Math.round(total_related_to_pprice) + ' in costs depend on the purchase price (' + Math.round(pct_related_to_pprice * 100) + '%)');

  // So how much of our costs are NOT dependent on purchase price?
  const total_costs = resale_value - current_pprice - current_profit;
  const total_unrelated_to_pprice = total_costs - total_related_to_pprice;

  // console.log('Found $' + Math.round(total_unrelated_to_pprice) + ' in costs independent of the purchase price (of $' + total_costs + ' total costs)');
  // console.log('By that logic, actual profit ' + current_profit + ' should equal ' + (resale_value - current_pprice - total_related_to_pprice - total_unrelated_to_pprice));

  // So let's find the profit from a new purchase price
  if (targetpprice !== undefined) {
    const newcosts = target_pprice * pct_related_to_pprice;
    // console.log('Under a ' + target_pprice + ' pprice, there would be ' + newcosts + ' in dependent costs');
    const profit = resale_value - target_pprice - newcosts -
      total_unrelated_to_pprice;
    // console.log('  New profit estimate is ' + profit);
    purchase_profit.target_profit = Math.round(profit);
  }
  // Or let's find the purchase price from the profit
  else if (targetprofit !== undefined) {
    // new pprice and related costs
    const pprice_and_related_costs = resale_value - target_profit - total_unrelated_to_pprice;

    // console.log('Under a ' + target_profit + ' profit, there would be ' + pprice_and_related_costs + ' in purchase price and dependent costs');
    let pprice = pprice_and_related_costs / (1 + pct_related_to_pprice);

    // console.log('New pprice estimate is ' + pprice + ' plus ' + Math.round(pprice * pct_related_to_pprice) + ' in dependent costs (total ' + (pprice * (1 + pct_related_to_pprice)) + ')');

    // Should we round up or down?
    // const roundup = Math.round(resale_value - Math.ceil(pprice) * (1 + pct_related_to_pprice) - total_unrelated_to_pprice);
    const rounddown = Math.round(resale_value - Math.floor(pprice) * (1 + pct_related_to_pprice) - total_unrelated_to_pprice);

    // console.log('Rounding pprice up -> rounded profit ' + roundup);
    // console.log('Rounding pprice down -> rounded profit ' + rounddown);

    if (Math.round(rounddown) === targetprofit) {
      // console.log('  - Rounding DOWN');
      pprice = Math.floor(pprice);
    }
    else {
      // console.log('  - Rounding UP');
      pprice = Math.ceil(pprice);
    }

    // const actual_profit = resale_value - (pprice * (1 + pct_related_to_pprice)) - total_unrelated_to_pprice;
    // console.log('  This will result in an estimated profit of ' + actual_profit + ', versus the input profit of ' + target_profit);

    purchase_profit.target_pprice = pprice;
  }

  // console.log('**  Purchase: ' + purchase_profit.target_pprice + ', Profit: ' + purchase_profit.target_profit + '  **');

  return purchase_profit;
}

export const calculateROIs = (
  financials: FinancialsType,
  target_pprice: number,
  target_profit: number,
) => {
  const costgroupTotals = financials.costgroupTotals;
  const ROIs = { totalROI: 0, cashROI: 0 };

  const spentCosts = costgroupTotals['acquisition'] + costgroupTotals['carrying'] + financials.calcsheet.rehab_costs_flip + target_pprice;
  ROIs.totalROI = target_profit / spentCosts;

  let purchaseCost = target_pprice;
  let rehabCosts = financials.calcsheet.rehab_costs_flip;
  if (financials.calcsheet.loans[0].is_enabled) {
    purchaseCost = purchaseCost * financials.calcsheet.loans[0].dpay
  }
  if (financials.calcsheet.loans[1].is_enabled) {
    rehabCosts = rehabCosts * financials.calcsheet.loans[1].dpay
  }
  const financedSpentCosts = costgroupTotals['acquisition'] + costgroupTotals['carrying'] + purchaseCost + rehabCosts;
  ROIs.cashROI = target_profit / financedSpentCosts;

  return ROIs;
}

export const calcBudgets = (costgroups: any[]) => {
  let budgets = 0;

  costgroups.forEach(cost => {
    if (
      cost.name === "maintenance_hold" ||
      cost.name === "maintenance_brrr" ||
      cost.name === "vacancy_hold" ||
      cost.name === "vacancy_brrr"
    ) {
      budgets += cost.amount;
    }
  });
  return budgets;
}
