import { useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceAreaType, useAgent } from "state";
import { useRecoilValue } from "recoil";
import { ModalWrapper } from "components";
import { highlightAreaHandlerAtom, AgentProperties, ContactForm, ServiceAreasMap } from "components/agents";
import { NotFound } from "pages";
import { WASHINGTON_STATE_ID } from "config";
import { XIcon } from "@heroicons/react/solid";
import { ChatIcon } from '@heroicons/react/outline'
import delay from "lodash/delay";

export const Agent = () => {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const [selectedTab, setSelectedTab] = useState<"sold" | "listed">("sold");
  const highlightHandler = useRecoilValue(highlightAreaHandlerAtom);
  const params = useParams();
  let agentSlug = params.agentSlug;
  const { error, data } = useAgent(agentSlug);

  const onMouseHover = (serviceArea: ServiceAreaType | null) => {
    if (highlightHandler) {
      highlightHandler(serviceArea);
    }
  }

  if (error) return <NotFound />;

  return (
    <div className="w-full flex flex-col">

      {data && (
        <>
          <div className="bg-[#3F464A] w-full flex flex-col items-center px-4 pt-10">


            <div className="w-full max-w-5xl flex flex-col sm:flex-row gap-8 pb-8 sm:pb-14">
              <div className="flex flex-col w-full h-full order-last sm:order-first basis-2/3 px-4 sm:px-0">
                <div className="text-4xl sm:text-7xl text-white h-full">

                  {data.agent && (
                    <h1 className="font-serif tracking-tight">
                      {data.agent.name}
                    </h1>
                  )}

                </div>
                <div className="text-2xl text-[#B9B9B9] h-full pt-5 font-extralight">
                  Real Estate Agent
                </div>
                <div className="text-md text-[#B9B9B9] h-full font-thin">
                  State License Number {data.agent.state_license_id}
                </div>
                <div className="text-md text-gray-100 h-full pt-5 font-extralight   max-w-md">
                  {data.agent_user.description}
                </div>

                {data.agent_user.service_areas && (
                  <h2 className="font-medium mb-2 text-white mt-4 text-sm">Specialties</h2>
                )}
                <div className="flex flex-wrap max-w-sm">
                  {data.agent_user?.skills?.map((skill) => (
                    <span key={skill}
                      className="border border-gray-200 py-1 px-2 text-gray-300 rounded-2xl text-xs font-medium mr-2 my-1 whitespace-nowrap capitalize"
                    >
                      {skill}
                    </span>
                  ))}
                </div>

              </div>
              <div className="flex flex-col w-full basis-1/1 sm:basis-1/2">
                <div className="text-xl text-gray-600 w-full justify-center sm:justify-end flex">
                  {data.agent_user.avatar && (
                    <div className="w-full sm:max-w-sm items-center justify-center shadow-lg ">
                      {data.agent_user.avatar && (
                        <img className="w-full  rounded-sm" src={data.agent_user.avatar} alt="avatar" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 w-full flex flex-col items-center px-4">
            <div className="flex flex-col sm:flex-row w-full gap-6 max-w-5xl relative">
              <div className="w-full flex flex-col basis-1/1 md:basis-2/3">
                <div className="w-full flex flex-col md:flex-row gap-6">
                  <div className="w-full basis-1/1  pb-2 md:pb-0">
                    <h2 className="font-medium pb-2">Areas of service</h2>


                    <div className="w-full h-80 lg:h-[520px]">
                      <ServiceAreasMap
                        serviceAreas={data.agent_user.service_areas || []}
                        soldProperties={data.sold}
                        listedProperties={data.listed}
                        defaultRegion={data.agent_user.default_region || WASHINGTON_STATE_ID} />
                    </div>

                    <div className="w-full basis-1/1">
                      {data.agent_user.service_areas && (
                        <h2 className="font-medium mb-2 mt-4">Locations</h2>
                      )}
                      <div className="flex flex-wrap">
                        {data.agent_user?.service_areas?.map((service_area) => (
                          <span key={`${service_area.name}-${service_area.id}`}
                            className="border border-blue-500 py-1 px-2 text-blue-800 rounded-2xl text-xs font-medium mr-2 my-1 whitespace-nowrap capitalize hover:bg-blue-200"
                            onMouseEnter={() => onMouseHover(service_area)}
                            onMouseLeave={() => onMouseHover(null)}
                          >
                            {service_area.name} {service_area.type === "county" && "County"}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* propiedades */}
                {(data.listed.length > 0 || data.sold.length > 0) && (
                  <div className="flex w-full flex-col items-center mt-10 border-t border-gray-300 ">
                    <div className="text-xl font-bold text-center pt-10">
                      Experience
                    </div>
                    <div className="text-md text-center leading-9 text-gray-600 mb-2">
                      See my current listings and past sales
                    </div>

                    {data.listed.length > 0 && (
                      <div className="flex flex-row w-full max-w-md pb-4">
                        <button
                          className={`uppercase w-full border-[#38455b] border py-2.5 text-sm leading-5 font-medium rounded-l-sm ${selectedTab === "sold" ? "text-white bg-[#38455b]" : "text-gray-600"}`}
                          onClick={() => setSelectedTab("sold")}
                        >
                          Sold
                        </button>
                        <button
                          className={`uppercase w-full border-[#38455b] border py-2.5 text-sm leading-5 font-medium rounded-r-sm ${selectedTab === "listed" ? "text-white bg-[#38455b]" : "text-gray-600"}`}
                          onClick={() => setSelectedTab("listed")}
                        >
                          Listed
                        </button>
                      </div>
                    )}

                    <AgentProperties properties={data[selectedTab]} />

                  </div>
                )}


                <div className="flex w-full justify-center sm:justify-end py-4 bottom-0 sticky sm:hidden  z-[9990]">
                  <div className="flex w-full justify-end pr-3">

                    <div
                      className="flex text-md text-center cursor-pointer h-full bg-[#61a28d] hover:bg-[#5bbc9c] p-4 rounded-md text-white font-medium tracking-wide shadow-xl"
                      onClick={openModal}
                    >
                      <ChatIcon className="w-6" aria-hidden="true" />
                    </div>

                  </div>
                </div>


              </div>

              <div className="w-full basis-1/1 sm:basis-1/3 hidden sm:inline">
                <div className="sticky top-10 border p-6 flex flex-col shadow-lg bg-white" id="contact">
                  <h2 className="font-medium">
                    How can I help?
                  </h2>

                  <div className="w-full justify-center items-center pt-2 ">
                    <ContactForm toEmail={data.agent_user.email} />
                  </div>

                </div>
              </div>
            </div>
          </div>




          <div className="bg-[#3F464A] w-full flex flex-col items-center px-4 pt-10 sm:mt-10">


            <div className="w-full max-w-5xl flex flex-row gap-8 pb-8 sm:pb-14 items-center">
              <div className="flex flex-col w-full h-full basis-2/3 px-4 sm:px-0">
                <div className="text-4xl sm:text-7xl text-white h-full">

                  <img src="/images/core/pgo-logo-trans.png" width="150" alt="" />


                </div>

              </div>

              <div className="flex flex-col w-full basis-1/1 sm:basis-1/2 justify-end">
                <div className="text-sm text-gray-400 w-full text-right flex  justify-end">
                  Copyright Lotside 2022
                </div>
              </div>
            </div>
          </div>





          <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
            <div className="inline-block w-full max-w-sm overflow-hidden text-left align-middle transition-all transform bg-white border border-gray-200 shadow-xl rounded-lg p-2 z-[999]">
              <h3 className="text-lg font-medium leading-6 text-gray-900 w-full flex border-b p-2">
                <div className="basis-4/5 text-md font-medium text-gray-700 flex items-center">
                  How can I help?
                </div>
                <div className="basis-1/5 justify-right text-right ">
                  <button
                    type="button"
                    className="pl-1 pb-1 text-sm font-medium text-gray-400 rounded-md hover:bg-gray-400 hover:text-white hover:border-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}>
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </h3>

              <div className="top-10 p-2 flex flex-col  bg-white" id="contact">
                <div className="text-sm text-gray-600">
                  Feel out this form and I will get in touch with you soon:

                  {/* add city and zip code no listing */}
                </div>

                <div className="w-full justify-center items-center pt-2">
                  <ContactForm toEmail={data.agent_user.email} onSubmit={() => { delay(closeModal, 1000) }} />
                </div>

              </div>
            </div>
          </ModalWrapper>

        </>
      )}
    </div>
  )
}
