import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { PasswordForgotten, SignIn, SignUp } from "pages";
import { forwardRef, ReactNode } from "react";
import { useSignInSignUpModal, useUserSession } from "state";

type Props = {
  children: ReactNode;
  asButton?: boolean;
  text?: string;
  useChildren?: boolean;
  asAgent?: boolean;
  asClient?: boolean;
  disabled?: boolean;
};

export const WithSignIn = forwardRef(
  ({ children, asButton, text, useChildren, asAgent, asClient, disabled }: Props) => {
    const { openSignInModal } = useSignInSignUpModal();
    const currentUser = useUserSession();

    const openModal = (e: React.MouseEvent) => {
      e.preventDefault();
      openSignInModal();
    };

    if (
      (!asAgent && !asClient && currentUser?.isLogged) ||
      (asAgent && currentUser.isAgent) ||
      (asClient && currentUser.isClient) ||
      disabled
    ) {
      return <>{children}</>;
    }

    if (useChildren) {
      return <div onClick={openModal}>{children}</div>;
    }

    return (
      <button
        className={
          asButton
            ? `cursor-pointer whitespace-nowrap rounded-lg border border-blue-400 bg-white px-4 py-2 text-center text-sm text-blue-600 shadow-sm transition-all duration-150 hover:bg-blue-400 hover:text-white`
            : "flex-nowrap text-left leading-4 text-blue-800"
        }
        onClick={openModal}
      >
        {text || "Sign\u00a0In"}
      </button>
    );
  },
);

export const SignInSignUpDialog = () => {
  const {
    closeSignInSignUpModal,
    showSignInSignUpModal,
    showSignInModal,
    showSignUpModal,
    showNonMLSSignUpModal,
    showPasswordResetModal,
  } = useSignInSignUpModal();

  if (!showSignInSignUpModal) return null;

  const closeModal = () => {
    closeSignInSignUpModal();
  };

  return (
    <ModalWrapper isOpen={showSignInSignUpModal} closeModal={closeModal}>
      <div
        className={`${
          showSignInModal || showPasswordResetModal ? "" : "lg:max-w-2xl"
        } inline-block w-full max-w-md transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all`}
      >
        <button className="float-right outline-none" onClick={closeModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>
        {showSignInModal && <SignIn asModal={true} />}
        {showSignUpModal && <SignUp asModal={true} />}
        {showNonMLSSignUpModal && <SignUp asModal={true} nonMLS={true} />}
        {showPasswordResetModal && <PasswordForgotten asModal={true} />}
      </div>
    </ModalWrapper>
  );
};
