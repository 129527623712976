import { useEffect, useRef } from "react";
import { ServiceAreaType, AgentPropertyType } from "state";
import { ServiceAreasMapService } from "components/agents";
import { stateIdToGeoCenter, WASHINGTON_STATE_ID } from "config";
import { useSetRecoilState } from "recoil";
import { highlightAreaHandlerAtom } from "components/agents";

type Props = {
  serviceAreas: ServiceAreaType[];
  soldProperties: AgentPropertyType[];
  listedProperties: AgentPropertyType[];
  defaultRegion?: number;
};

export const ServiceAreasMap = ({ serviceAreas, defaultRegion, soldProperties, listedProperties }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const setHighlightHandler = useSetRecoilState(highlightAreaHandlerAtom);

  const stateGeoCenter = stateIdToGeoCenter[(defaultRegion || WASHINGTON_STATE_ID) as keyof typeof stateIdToGeoCenter];

  useEffect(() => {
    let mapService: ServiceAreasMapService | null = null;
    if (ref.current) {
      mapService = new ServiceAreasMapService(serviceAreas, stateGeoCenter, soldProperties, listedProperties);
      mapService.createMap(ref.current);
      setHighlightHandler(() => mapService?.highlightHandler.bind(mapService));
    }
    return () => mapService?.clearMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, serviceAreas, defaultRegion]);

  return (
    <div ref={ref} className="w-full h-full" />
  );
}
