import { formatDateShort, formatNumber } from "helpers";
import { Chart } from "react-google-charts";
import NumberFormat from "react-number-format";
import { MarketDataEntryType, MarketDataType } from "state/market";


class MarketGraphClass {
  valueName: keyof MarketDataEntryType;
  period: number;
  options: any;
  data: any[] = [];
  ticks: any[] = [];

  constructor(
    marketData: MarketDataEntryType[],
    valueName: keyof MarketDataEntryType,
    period: number
  ) {
    this.valueName = valueName;
    this.period = period;
    const rows = this.zipData(marketData, valueName, period);
    const columns = this.buildGraph();
    this.data = [columns, ...rows]
  }

  zipData(marketData: MarketDataEntryType[], valueName: keyof MarketDataEntryType, period: number) {
    const matrix: any = [];

    let firstMonth = period === 0 ? 0 : marketData.length - period;
    firstMonth = firstMonth < 0 ? 0 : firstMonth;

    for (let i = firstMonth; i < marketData.length; i++) {
      const date_ = new Date(Date.parse(marketData[i].month));
      matrix.push([
        date_,
        marketData[i][valueName],
        `${formatDateShort(date_, false)} <b>${formatNumber(Number(marketData[i][valueName]))}</b>`,
      ]);
      this.ticks.push(date_);
    }
    return matrix;
  }

  buildGraph() {
    this.options = {
      height: 200,
      chartArea: { left: "35", top: "5", right: "15", bottom: "50", width: "100%", height: "100%" },
      hAxis: {
        gridlines: { color: "transparent" },
        minorGridlines: { count:0 },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
        ticks: this.ticks,
        format: "MM/yy",
        // slantedText: true,
        // slantedTextAngle: 60,
      },
      pointSize: 2,
      areaOpacity: 0.24,
      lineWidth: 3,
      backgroundColor: "#fafaf9",
      series: {
        0: { color: "#000", pointSize: "1" },
      },
      vAxis: {
        // minValue: 0,
        textPosition: "out",
        gridlines: { color: "#ddd", type: "line", count: 4 },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
        viewWindowMode: "maximized",
      },
      tooltip: {
        isHtml: true,
      },
    };

    if (this.valueName !== 'sale_to_list_price_ratio') {
      this.options.vAxis.minValue = 0;
    }

    const columns = [
      { type: 'date' },
      { type: 'number' },
      { type: 'string', role: 'tooltip', p: { html: true } },
    ];

    return columns;
  }
}

type Props = {
  marketData: MarketDataType;
  dataset: keyof MarketDataType;
  valueName: keyof MarketDataEntryType;
  period: number;
  totals: any;
  title: string;
  isAverage?: boolean;
};

export const MarketGraph = ({ marketData, dataset, valueName, period, totals, title, isAverage }: Props) => {
  const marketChart = new MarketGraphClass(marketData[dataset] || [], valueName, period);
  const delta = totals[`delta_${valueName}`];

  return (

    <div className="w-full max-w-sm min-w-fit bg-stone-50 border border-stone-100 rounded-lg px-2 sm:px-4 py-4">
      <div className="font-bold text-sm text-gray-900 pb-0">{title}</div>
      <div className="font-light text-sm text-gray-700  pt-0 pb-3">
        <div className="h-3">
          {delta !== undefined && (
            <>
              {delta < 0 && "Down "}
              {delta > 0 && "Up "}
              <NumberFormat value={Math.abs(delta)} displayType="text" suffix="%" decimalScale={1} />
              {" compared to this time last year"}
            </>
          )}
        </div>
      </div>
      <div className="h-48 ">
        <div className="w-full h-full">
          <Chart
            chartType="LineChart"
            data={marketChart.data}
            options={marketChart.options}
          />
        </div>
      </div>
    </div>
  )
}