import { BrowseAd, Property, PropertyWholesale } from "components/browse";
import { appConfig } from "config";
import { cn } from "helpers";
import { Fragment } from "react";
import { isMobile } from "react-device-detect";
import { useUserSession } from "state";
import { SearchResultType } from "state/browse";

type Props = {
  stateId: number;
  properties: SearchResultType[];
  selectedProperty?: SearchResultType;
};

export const PropertiesGrid = ({ stateId, properties, selectedProperty }: Props) => {
  const currentUser = useUserSession();
  return (
    <>
      <div
        className={cn(
          "grid w-full gap-4 p-4",
          isMobile && "mt-0 grid-cols-1",
          !isMobile && "mt-1 grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3",
        )}
      >
        {properties.map((property, idx: number) => (
          <Fragment key={property.parcel_id}>
            {!currentUser.isClient && property.is_wholesale ? (
              <PropertyWholesale
                property={property}
                selected={property.parcel_id === selectedProperty?.parcel_id}
              />
            ) : (
              <Property
                property={property}
                showCosts={false}
                selected={property.parcel_id === selectedProperty?.parcel_id}
              />
            )}
          </Fragment>
        ))}
        {appConfig.browse.statesWithAds.includes(stateId) && appConfig.browse.showAd && <BrowseAd />}
      </div>
    </>
  );
};
