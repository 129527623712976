import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-storage-complete";
import { useProforma } from "state/proformas";
import { compareUrlLocations } from "helpers";

type Props = {
  loading?: boolean;
  error?: boolean;
};

export const useRecentSearches = ({ loading = false, error = false }: Props) => {
  const { proforma } = useProforma();
  const [lsSearches, setLSSearches] = useLocalStorage<any[]>("searches", [], {
    prefix: "lotside",
  });
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isSourceLookup = searchParams.get("source") === "lookup";
  const pageTitle = document.title;

  const saveRecentSearch = async () => {
    if (location.pathname.includes("/proformas") && !isSourceLookup) return;

    const currentSearches = lsSearches || [];
    let searchesAfterDelete = currentSearches;

    // Delete repeated browse searches
    if (location.pathname.includes("/browse")) {
      let searchesDoDelete: any[] = [];
      currentSearches.forEach((search) => {
        const searchUrl = search?.parameters?.url;
        const isSameLocations = compareUrlLocations(searchUrl, location.pathname + location.search);
        if (isSameLocations) {
          searchesDoDelete.push(search);
        }
      }
      )
      searchesAfterDelete = currentSearches.filter((s) => !searchesDoDelete.includes(s))
    }

    // Delete repeated proforma searches
    if (location.pathname.includes("/proformas")) {
      let searchesDoDelete: any[] = [];
      currentSearches.forEach((search) => {
        const searchUrl = search?.parameters?.url;
        if (location.pathname === searchUrl) {
          searchesDoDelete.push(search);
        }
      }
      )
      searchesAfterDelete = currentSearches.filter((s) => !searchesDoDelete.includes(s))
    }

    // Get propertyData
    const showAddress = !(proforma?.parcel?.most_recent_listing?.dont_show_address === true);
    const propertyData: any = {
      showAddress: showAddress,
      thumbnail_url: proforma.parcel?.thumbnail_url,
      parcel: {
        latitude: showAddress ? proforma.parcel?.latitude : undefined,
        longitude: showAddress ? proforma.parcel?.longitude : undefined,
      },
      name: showAddress ? proforma.name : undefined,
      city_for_address: showAddress ? proforma.parcel?._characteristics?.city_for_address : undefined,
      county: showAddress ? proforma.parcel?.county : undefined,
      state_abbreviation: showAddress ? proforma.parcel?.state_obj?.abbreviation : undefined,
      zip_code: showAddress ? proforma.parcel?.zip_code : undefined,
    };

    // Create a new search entry
    const newSearch: any = {
      id: new Date().getTime(),
      name: new Date().toLocaleString() + " Search",
      active: false,
      parameters: {
        texts: "",
        url: location.pathname.includes("/proforma") ? location.pathname : location.pathname + location.search,
        isRecent: true,
        propertyData: {
          showAddress: propertyData?.showAddress,
          thumbnail_url: propertyData?.thumbnail_url,
          parcel: propertyData?.parcel,
          name: propertyData?.name,
          city_for_address: propertyData?.city_for_address,
          county: propertyData?.county,
          state_abbreviation: propertyData?.state_abbreviation,
          zip_code: propertyData?.zip_code,
        },
        browseData: {
          pageTitle: pageTitle,
        },
      },
    };

    // Add the new search entry to the beginning of the array in LS and limit to 10
    let updatedSearches: any[] = [
      newSearch,
      ...searchesAfterDelete,
    ].sort((a, b) => b.id - a.id)
      .slice(0, 10);
    setLSSearches(updatedSearches);
  };

  useEffect(() => {
    if ((pageTitle || proforma.name) && (!loading && !error)) {
      saveRecentSearch();
    }
    //eslint-disable-next-line
  }, [location.pathname, location.search, proforma.name, error, pageTitle]);
};
