import { cn, formatCurrency, formatDate } from "helpers";
import { useLoans, useProforma } from "state/proformas";

const buildLoans = (data: any[]) => {
  const allLoanSpecs: any[] = [];
  for (let i = data.length - 1; i >= 0; i--) {
    var loan = data[i];
    if (loan.loan_date) {
      const loanSpecs = {
        "Loan Type": loan.loan_type?.toUpperCase().includes("CONV")
          ? "Conv."
          : loan.loan_type?.toUpperCase().includes("FHA")
            ? "FHA"
            : loan.loan_type?.toUpperCase().includes("VA")
              ? "VA"
              : loan.loan_type
                ? loan.loan_type
                : "Loan",
        // Before interest rate was only calculated as a percentage when > 100
        "Interest Rate": loan.loan_interest_rate / 100 + "%",
        Amount: formatCurrency(loan.loan_amount, 0),
        Date: loan.loan_date,
      };
      allLoanSpecs.push(loanSpecs);
    }
  }
  return allLoanSpecs;
};

type Props = {
  parcelId: number;
};

export const Loan = ({ parcelId }: Props) => {
  const { proforma } = useProforma();
  const { loans, loading, error } = useLoans(parcelId);
  if (loading || error) return null;

  const lastSaleDate = proforma.parcel.listing_history.filter(
    (listing_history: any) => listing_history.event === "Sold",
  )[0]?.date;

  const loansSpecs = buildLoans(loans);

  return (
    <div className="w-full">
      <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">
        Loan history from public records
      </div>
      {loansSpecs && loansSpecs.length > 0 ? (
        <div className="mt-2 w-full">
          {loansSpecs.map((loanSpec: any, index: number) => (
            <div
              key={`spec-${index}`}
              className={cn(
                "flex w-full items-center whitespace-nowrap border-b border-gray-200 px-1.5 py-2 text-sm last:border-b-0 sm:gap-4",
                !lastSaleDate || loanSpec.Date >= lastSaleDate.substr(0, 10) ? "" : "text-gray-400",
              )}
            >
              <div className="w-full truncate text-left">{loanSpec["Loan Type"]}</div>
              <div className="w-full text-right">{loanSpec["Interest Rate"]}</div>
              <div className="w-full text-right">{loanSpec.Amount}</div>
              <div className="w-full text-right">{formatDate(loanSpec.Date)}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-2 w-full text-sm font-light">No records available.</div>
      )}
    </div>
  );
};
