import { useEffect, useState } from "react";
import { AgentPropertyType } from "state/useAgent";
import NumberFormat from "react-number-format";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

type Props = {
  properties: AgentPropertyType[];
};

export const AgentProperties = ({ properties }: Props) => {
  const [page, setPage] = useState(0);
  const PAGE_SIZE = 6;
  const totalPages = Math.ceil(properties.length / PAGE_SIZE);

  const sortedSales = properties.slice().sort((a, b) => {
    if (a.sale_date > b.sale_date) return -1;
    if (a.sale_date < b.sale_date) return 1;
    return 0;
  });

  useEffect(() => {
    setPage(0);
  }, [properties]);

  return (
    <div className="w-full">

      {properties.length === 0 && (
        <div className="w-full text-center items-center flex flex-col">
          <div className="w-full text-center border p-4 max-w-md text-xs text-gray-500">There are no active listings at this moment to display.</div>
        </div>
      )}

      <ul className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 sm:p-4 w-full">
        {sortedSales.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((property) => (
          <li key={`${property.sale_id}-${property.side}`} className="rounded-sm flex flex-col w-full border bg-gray-100 shadow-md hover:border hover:border-blue-500 border-gray-100">
            {property.photos && property.photos.length > 0 ? (
              <div className="relative w-full flex-shrink-0 h-52">

                {property.sale_date && (
                  <div className="text-white py-1 px-2 absolute z-10 top-0 bg-black rounded-md m-2 opacity-70 flex">
                    <div className="text-[10px]">
                      {property.sale_date}
                    </div>
                  </div>
                )}

                <div className="text-white py-1 px-2 absolute z-10 top-0 right-0 bg-black rounded-md m-2 opacity-70 flex">
                  <div className="text-[10px]">
                    {property.listing_status}
                  </div>
                </div>

                <div className="absolute w-full bg-gradient-to-t from-gray-900/80 to-transparent  bottom-0 p-2">
                  <div className=" w-full text-gray-800 flex-row flex ">
                    <div className="flex text-sm font-bold text-white items-center w-full basis-2/3">
                      {property?.city &&
                        <>
                          {property.city},
                        </>
                      }
                      {property?.county &&
                        <>
                          &nbsp;{property.county},
                        </>
                      }
                      &nbsp;{property.state_abbreviation}
                    </div>
                    <div className="basis-1/3 font-bold text-white text-right items-center text-sm">
                      <NumberFormat value={property.listing_price} displayType="text" thousandSeparator={true} prefix="$ " decimalScale={0} />
                    </div>
                  </div>

                  <div className="text-[8px] text-ellipsis overflow-hidden whitespace-nowrap tracking-tighter text-gray-300 ">
                    Listing provided by {property.listing_source}
                    {property.listing_company && (" and " + property.listing_company)}
                  </div>
                </div>

                <div className="rounded-t-sm flex items-end bg-cover w-full h-full bg-center bg-no-repeat bg-[background-size: 100% 100%;]"
                  style={{ backgroundImage: `url('${property.photos[0]}')` }}
                >
                </div>
              </div>
            ) : (
              <div className="relative w-full flex-shrink-0 h-52 bg-gradient-to-t from-gray-900/80 to-transparent">

                <div className="absolute w-full bg-gradient-to-t from-gray-900/80 to-transparent  bottom-0 p-2">
                  <div className=" w-full text-gray-800 flex-row flex ">
                    <div className="flex text-sm font-bold text-white items-center w-full basis-2/3">

                      {property?.city &&
                        <>
                          {property.city},
                        </>
                      }
                      {property?.county &&
                        <>
                          &nbsp;{property.county},
                        </>
                      }

                      &nbsp;{property.state_abbreviation}

                    </div>
                    <div className="basis-1/3 font-bold text-white text-right items-center text-sm">
                      <NumberFormat value={property.listing_price} displayType="text" thousandSeparator={true} prefix="$ " decimalScale={0} />
                    </div>
                  </div>

                  <div className="text-[8px] text-ellipsis overflow-hidden whitespace-nowrap tracking-tighter text-gray-300 ">
                    Listing provided by {property.listing_source}
                    {property.listing_company && (" and " + property.listing_company)}
                  </div>
                </div>

                <div className="rounded-t-sm flex items-end bg-cover w-full h-full bg-center bg-no-repeat bg-[background-size: 100% 100%;] border-gray-200 border bg-black opacity-10"
                  style={{ backgroundImage: `url('https://cdn.davinci.pellego.com/static/images/core/missing.png')` }}
                >
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>

      {/* Pagination */}
      {properties.length > 0 && totalPages > 1 && (
        <div className="flex  mx-4 py-1 rounded justify-center items-center">
          <button onClick={() => setPage(Math.max(0, page - 1))}>
            <ChevronLeftIcon className=" text-blue-500 mr-2 w-6 h-6 inline  hover:bg-gray-300 rounded-xl" />
          </button>

          <span className="text-blue-800 px-8">
            {page + 1} of {totalPages}
          </span>

          <button onClick={() => setPage(Math.min(page + 1, PAGE_SIZE - 1))}>
            <ChevronRightIcon className=" text-blue-500 mr-2 w-6 h-6 inline  hover:bg-gray-300 rounded-xl" />
          </button>
        </div>
      )}
    </div >
  )
}
