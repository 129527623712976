import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export type ParcelHistoryEntryType = {
  buyer?: string;
  buyer2?: string;
  date: string;
  event: string;
  price: number;
  sale_id: number;
  sale_type: string;
  seller?: string;
  seller2?: string;
  photos?: any[];
  sale_deed?: string;
  listing_remarks?: string;
  agent_listing_remarks?: string;
  has_metadata?: boolean;
  listing_events?: ParcelHistoryEntryType[];
  listing_agent?: string;
  selling_agent?: string;
  listing_company?: string;
  selling_company?: string;
};

export const useParcelHistory = (parcelId: number) => {
  const url = appConfig.apiEndpoint.parcelHistory.replace(":parcel_id", parcelId.toString());
  const { data, error } = useSWR<ParcelHistoryEntryType[]>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    loading: !data && !error,
    data,
    error,
  };
};
