import useSWR from "swr";
import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";

export type SaveSearchType = {
  id: number;
  active: boolean;
  name: string;
  parameters: any;
  user_id?: number;
};

export type NewSaveSearchType = {
  name: string;
  active: boolean;
  parameters: any;
};

const conditionalFetcher = (url: string) => {
  if (url.includes("users//search-filters")) {
    return [];  // don't fetch and return [] if the url doesn't contain userId
  }
  return fetcherWithCredentials(url)
};

export const useSavedSearchList = (userId: string | undefined) => {
  const { data, error, mutate } = useSWR(appConfig.apiEndpoint.userSearchFilters.replace(":user_id", userId || ""),
    conditionalFetcher, { revalidateIfStale: false });

  const deleteSavedSearch = (searchId: number) => {
    return fetch(appConfig.apiEndpoint.userSearchFilters.replace(":user_id", userId || "") + `/${searchId}`,
      { method: "DELETE", credentials: "include" })
      .then(() => mutate());
  }

  const updateSavedSearch = (data: SaveSearchType) => {
    const url = appConfig.apiEndpoint.userSearchFilters.replace(":user_id", userId?.toString() || "") + `/${data.id}`;
    return fetcherWithCredentials(url, "PUT", data)
      .then(() => mutate());
  }

  const createSavedSearch = (data: NewSaveSearchType) => {
    const url = appConfig.apiEndpoint.userSearchFilters.replace(":user_id", userId?.toString() || "");
    return fetcherWithCredentials(url, "POST", data)
      .then(() => mutate());
  }

  return {
    loading: !data && !error,
    savedSearchFilters: data?.sort((a: SaveSearchType, b: SaveSearchType) => b.id - a.id),
    deleteSavedSearch,
    updateSavedSearch,
    createSavedSearch,
    error,
  }
};
