import { appConfig } from "config";
import L, { ControlPosition } from "leaflet";
import { useEffect, useRef } from "react";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";


export const makeSVGIcon = (status: string, text: string) => {
  const green = "#70a08e",
    orange = "#ff9900",
    lightgray = "#eeeeee",
    lightblue = "#2196f3";

  let iconColor;
  let textColor = "#A0A0A0";

  if (status === "included" || status === "suggested" || status === "highlighted") {
    textColor = "white";
  }

  switch (status) {
    case "highlighted":
      iconColor = lightblue;
      break;
    case "excluded":
      iconColor = lightgray;
      break;
    case "included":
      iconColor = green;
      break;
    case "subject":
      iconColor = orange;
      textColor = "#000";
      break;
    case "suggested":
      iconColor = green;
      break;
    default:
      iconColor = lightgray;
  }

  return L.divIcon({
    className: "simple-marker",
    html: `
      <svg width="20" height="35" viewBox="0 0 27 42" version="1.1" preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
            c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
            c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z"
          fill="${iconColor}" stroke="black" stroke-opacity="0.5">
        </path>
      </svg>
      <span class="text" style="color:${textColor};">${text}</span>
    `,
    iconSize: [20, 35],
    iconAnchor: [10, 35],
  });
};

const createMap = (
  domRef: HTMLDivElement,
  lat: number,
  lng: number,
  zoomLevel?: number,
  showMarker?: boolean,
  satellite?: boolean,
  zoomPosition?: ControlPosition,
) => {
  const map = L.map(domRef, {
    center: [lat, lng],
    zoom: zoomLevel || 11,
    maxZoom: 20,
    attributionControl: true,
    zoomControl: false,
  });

  const googleSatLayer = L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });

  const mapLibreLayer = L.maplibreGL({
    style: `https://api.maptiler.com/maps/22b68e49-6ea7-41cf-af9c-ed2c7af89192/style.json?key=${appConfig.mapTilerApiKey}`,
  });

  if (satellite) {
    googleSatLayer.addTo(map);
  } else {
    mapLibreLayer.addTo(map);
  }

  L.control
    .zoom({
      position: zoomPosition || "bottomright",
    })
    .addTo(map);

  map.scrollWheelZoom.disable();

  if (showMarker) {
    const marker = new L.Marker([lat, lng], {
      icon: makeSVGIcon("subject", "•"),
    });
    marker.addTo(map);
  }

  return map;
};

const clearMap = (map: L.Map | null) => {
  map?.off();
  map?.remove();
};

type Props = {
  lat: number;
  lng: number;
  zoomLevel?: number;
  showMarker?: boolean;
  satellite?: boolean;
  rounded?: boolean;
  zoomPosition?: ControlPosition;
};

export const SimpleMap = ({
  lat,
  lng,
  zoomLevel,
  showMarker,
  satellite,
  rounded = false,
  zoomPosition,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let map: L.Map | null = null;
    if (ref.current) {
      map = createMap(ref.current, lat, lng, zoomLevel, showMarker, satellite, zoomPosition);
    }
    return () => clearMap(map);
  }, [ref, lat, lng, zoomLevel, showMarker, satellite, zoomPosition]);

  return <div className={`z-0 h-full w-full ${rounded && "rounded"}`} ref={ref} />;
};