import { AskAbout, AskAboutWithAgent } from "components/proformas";
import { MarketGraph } from "components/proformas/";
import { useUserFromShareToken, useUserSession } from "state";
// import { useCurrentStates, useSiteConfig } from "state";
import { MarketDataEntryType, useMarket } from "state/market";
import { ParcelAgentType, useParcelAgent, useProforma } from "state/proformas";

const fields: (keyof MarketDataEntryType)[] = [
  "n_new_listings",
  "n_sales",
  "n_price_reductions",
  "n_canceled_listings",
  "median_days_on_market",
  "sale_to_list_price_ratio",
];

const sumarizeMarketData = (data: MarketDataEntryType[], period: number) => {
  const totals: any = {};

  fields.forEach((field) => {
    totals[field] = 0;
  });

  period = period === 0 ? data.length : period;
  let firstMonth = data.length - period;
  firstMonth = firstMonth < 0 ? 0 : firstMonth;
  for (let i = firstMonth; i < data.length; i++) {
    fields.forEach((field) => {
      if (data[i][field] !== null) {
        totals[field] += data[i][field];
      }
    });
  }
  totals["years"] = Math.floor(period / 12);
  totals["median_days_on_market"] /= period;
  totals["sale_to_list_price_ratio"] /= period;

  return totals;
};

const getYearToYearDeltas = (data: MarketDataEntryType[]) => {
  const deltas: Record<string, number> = {};
  fields.forEach((field) => {
    const current = data[data.length - 1][field];
    const previousYear = data[data.length - 13][field];
    if (current && previousYear && current !== 0 && previousYear !== 0) {
      deltas[`delta_` + field] = (100 * (Number(current) - Number(previousYear))) / Number(previousYear);
    }
  });

  return deltas;
};

export const Markets = ({ agent }: { agent: any }) => {
  const { proforma } = useProforma();
  const isNWMLS = proforma.listing?.listing_source === "NWMLS";
  const currentUser = useUserSession();
  const { sharingUser, sharedByAgent } = useUserFromShareToken(proforma);
  let parcelAgent = useParcelAgent(proforma.parcel_id);
  if (currentUser.isAgent) parcelAgent = currentUser.session as ParcelAgentType;

  const data = useMarket(proforma.parcel_id);
  const dataPlace = "city";
  const dataPeriod = 13;

  let dataPresent = false;
  if (data) {
    if (data.city && data.city.length > 0) dataPresent = true;
    if (data.neighborhood && data.neighborhood.length > 0) dataPresent = true;
    if (data.zip_code && data.zip_code.length > 0) dataPresent = true;
  }

  if (!data || !dataPresent) {
    return null;
  }

  if ((data[dataPlace] || []).length < 13) {
    return null;
  }

  const totals = sumarizeMarketData(data[dataPlace] || [], 13);
  const yearToYearDeltas = getYearToYearDeltas(data[dataPlace] || []);
  const summary = { ...totals, ...yearToYearDeltas };

  return (
    <div
      className="align-center flex w-full max-w-7xl flex-col items-center justify-center bg-white px-0 pb-8 pt-10 sm:py-[5.5rem]"
      id="markets"
    >
      <div className="flex w-full max-w-7xl flex-row items-start justify-center align-top xl:gap-10">
        <div className="flex w-full max-w-7xl flex-col items-center justify-center px-4 text-center md:w-2/3 md:px-0">
          <div className="w-full p-4 pt-0">
            {proforma.parcel?.city ? (
              <div className="text-center text-3xl font-bold">{proforma.parcel?.city} Insights</div>
            ) : (
              <div className="text-center text-3xl font-bold">Market Insights</div>
            )}

            {proforma.listing?.listing_source && (
              <div className="text-pretty pt-2 text-center">
                Aggregated from {proforma.listing?.listing_source} by Lotside
              </div>
            )}
          </div>
          <div className="mt-4 flex w-full max-w-7xl flex-wrap items-center justify-center gap-6 px-0 text-left md:gap-8">
            <MarketGraph
              marketData={data}
              valueName="n_new_listings"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="New Listings"
            />

            <MarketGraph
              marketData={data}
              valueName="n_sales"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="Sales"
            />

            {/* <MarketGraph
              marketData={data}
              valueName="n_price_reductions"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="Price Reductions"
            /> */}

            {/* <MarketGraph
              marketData={data}
              valueName="n_canceled_listings"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="Canceled Listings"
            /> */}

            <MarketGraph
              marketData={data}
              valueName="median_days_on_market"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="Median Days on Market"
              isAverage={true}
            />

            <MarketGraph
              marketData={data}
              valueName="sale_to_list_price_ratio"
              dataset={dataPlace}
              period={dataPeriod}
              totals={summary}
              title="Sale/List Price Ratio"
              isAverage={true}
            />
          </div>
        </div>
        <div className="sticky top-16 mb-8 mt-2 hidden justify-center px-4 md:flex md:w-1/3 xl:px-0">
          {parcelAgent && !isNWMLS ? (
            <AskAboutWithAgent
              title="Ask about this area"
              agent={sharedByAgent ? sharingUser : parcelAgent}
              currentUserIsAgent={currentUser.isAgent}
            />
          ) : (
            <AskAbout title="Ask about this area" />
          )}
        </div>
      </div>
    </div>
  );
};
