import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { FiltersSavedSearches } from "components/browse";
import { Fragment } from "react";

export const SavedSearchesMenu = () => {
  return (
    <Popover as="div" className="relative ml-2 mr-1 flex w-full justify-end">
      {({ open }) => (
        <>
          <Popover.Button className="text-md flex items-center justify-between rounded-md border border-black bg-black py-2 pl-3 pr-2 text-left text-sm font-medium">
            <span className="flex items-center whitespace-nowrap text-white">Save</span>
            {open ? (
              <ChevronUpIcon className={`ml-2 h-4 w-4 text-white`} />
            ) : (
              <ChevronDownIcon className={`ml-2 h-4 w-4 text-white`} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute right-0 top-10 z-30 mt-2 w-80 origin-top-left rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-96">
              <FiltersSavedSearches />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
