import { InformationCircleIcon } from "@heroicons/react/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { SimpleMap } from "components";
import { ArrowUturnLeftIcon } from "components/icons";
import { StreetView } from "components/proformas/details";
import { PhotosModal, PhotosModalMobile, SatelliteInfoModal } from "components/proformas/modals";
import { cn } from "helpers";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { atom, useRecoilState } from "recoil";
import { useProforma } from "state/proformas";

export const setShowPhotosModalIdxAtom = atom<undefined | number>({
  key: "setShowPhotosModalIdxAtom",
  default: undefined,
});

export const Photos = () => {
  const { proforma } = useProforma();
  const [isStreetView, setIsStreetView] = useState(false);
  const [showPhotosModalIdx, setShowPhotosModalIdx] = useRecoilState(setShowPhotosModalIdxAtom);
  const [showSatelliteInfoModal, setShowSatelliteInfoModal] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);
  const isPhotos = proforma.photos.length !== 0;

  const PhotosModalComponent = isMobile ? PhotosModalMobile : PhotosModal;

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 480;
  };

  const parcel = proforma.parcel;
  const geographyData: any = [];
  if (parcel.elevation != null) {
    geographyData.push({
      name: "Elevation",
      value: parcel.elevation + " Meters",
    });
  }

  if (parcel.slope != null) {
    geographyData.push({
      name: "Slope",
      value: parcel.slope + " Degrees",
    });
  }

  if (parcel.faces != null) {
    geographyData.push({
      name: "Property Direction",
      value: parcel.faces,
    });
  }

  if (parcel.closest_body_of_water != null) {
    geographyData.push({
      name: "Closest Body of Water",
      value: parcel.closest_body_of_water,
    });
  }

  if (parcel.closest_body_of_water != null) {
    geographyData.push({
      name: "Distance to Water",
      value: parcel.shortest_distance_to_water + " Meters",
    });
  }

  if (parcel.topological_position_index != null) {
    geographyData.push({
      name: "Topological Position Index",
      value: parcel.topological_position_index,
    });
  }

  return (
    <div className="group relative z-0 w-full overflow-auto bg-[#f7faff]">
      {!isScrolling && proforma.photos.length > 1 && !isMobile && (
        <>
          {scrollContainerRef.current && scrollContainerRef.current.scrollLeft > 10 && (
            <ChevronLeftIcon
              className="absolute left-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
              onClick={slideLeft}
            />
          )}
          {scrollContainerRef.current &&
            scrollContainerRef.current.scrollLeft <=
              scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth - 1 && (
              <ChevronRightIcon
                className="absolute right-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                onClick={slideRight}
              />
            )}
        </>
      )}
      <div
        className="flex w-full snap-x snap-mandatory overflow-x-auto scroll-smooth"
        ref={scrollContainerRef}
        onScrollCapture={(e) => {
          proforma.photos.length > 1 && !isMobile && handleScroll();
        }}
      >

        {/* Carthographic Map */}
        {!isMobile && (
          <div className="relative mx-2 block shrink-0 snap-start snap-normal">
            <div className="h-[15rem] w-[calc(15rem*1.15)] cursor-pointer object-cover font-medium sm:h-[24rem] sm:w-[calc(24rem*1.15)]">
              <SimpleMap
                lat={proforma.parcel.latitude}
                lng={proforma.parcel.longitude}
                showMarker={!proforma.parcel.most_recent_listing.dont_show_map_link}
                rounded
                zoomPosition={isPhotos ? "bottomright" : "bottomleft"}
              />
            </div>
          </div>
        )}

        {/* Sattelite Map */}
        <div className={isStreetView ? "hidden" : "relative mx-2 block shrink-0 snap-start snap-normal"}>
          <div className="h-[15rem] w-[calc(15rem*1.15)] cursor-pointer bg-[#f7faff] object-cover font-medium sm:h-[24rem] sm:w-[calc(24rem*1.15)]">
            <SimpleMap
              lat={proforma.parcel.latitude}
              lng={proforma.parcel.longitude}
              zoomLevel={19}
              showMarker={!proforma.parcel.most_recent_listing.dont_show_map_link}
              satellite
              rounded
              zoomPosition={isMobile ? "bottomleft" : isPhotos ? "bottomright" : "bottomleft"}
            />
          </div>
          {/* Sattelite Map controls */}
          {!isMobile && isPhotos && (
            <>
              <div
                className="absolute bottom-8 left-4 z-10 mx-auto flex w-fit cursor-pointer flex-row items-center border border-black bg-white p-1 px-2 text-[10px] font-bold"
                onClick={() => setIsStreetView(true)}
              >
                <ArrowUturnLeftIcon className="size-4 pr-1" />
                <div className="mt-[1px]">STREET VIEW</div>
              </div>
              <div
                className="absolute left-6 top-4 z-10 flex w-fit cursor-pointer flex-row"
                onClick={() => setShowSatelliteInfoModal(true)}
              >
                <InformationCircleIcon className="size-5 rounded-full bg-white" />
              </div>
            </>
          )}
        </div>

        {/* StreetView Map */}
        <div className="w-full">
          {(!isMobile || (isMobile && !isPhotos)) && (
            <div
              className={cn(
                "hidden",
                (isStreetView || !isPhotos) &&
                  "relative mx-2 block shrink-0 cursor-pointer snap-start snap-normal",
                !isMobile &&
                  !isPhotos &&
                  "relative mx-2 block w-full shrink-0 cursor-pointer snap-start snap-normal",
              )}
            >
              <StreetView
                otherClasses="bg-[#f7faff] object-cover font-medium"
                parcel={proforma.parcel}
                titles={!proforma.parcel.most_recent_listing.dont_show_address}
                sizeClasses={cn(
                  "w-[calc(15rem*1.15)] h-[15rem] sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]",
                  !isMobile && !isPhotos && "w-full h-[15rem] sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]",
                )}
                rounded="rounded"
              />
              {/* StreetView Map controls */}
              {!isMobile && isPhotos && (
                <div
                  className="absolute bottom-[26px] left-4 z-10 mx-auto flex w-fit cursor-pointer flex-row items-center border border-black bg-white p-1 px-2 text-[10px] font-bold"
                  onClick={() => setIsStreetView(false)}
                >
                  <ArrowUturnLeftIcon className="size-4 pr-1" />
                  <div className="mt-[1px]">SATELLITE VIEW</div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Property photos */}
        {proforma.photos.map((photo: any, idx: number) => (
          <div
            key={photo._id}
            className={`${proforma.photos.length === 1 && "w-full"} shrink-0 snap-start snap-normal px-2`}
          >
            <LazyLoadImage
              className={`${
                proforma.photos.length === 1 && "w-full"
              } h-60 min-w-[calc(15rem*1.15)] shrink-0 cursor-pointer rounded object-cover sm:h-96`}
              onClick={() => setShowPhotosModalIdx(idx)}
              src={photo.photo_url}
              alt={`Sale: ${photo.sale_id} Parcel: ${photo.parcel_id}`}
            />
          </div>
        ))}
        {showPhotosModalIdx !== undefined && (
          <PhotosModalComponent
            photos={proforma.photos}
            isOpen={showPhotosModalIdx !== undefined}
            closeModal={() => setShowPhotosModalIdx(undefined)}
            showIdx={showPhotosModalIdx}
          />
        )}
      </div>

      {showSatelliteInfoModal && (
        <SatelliteInfoModal
          isOpen={showSatelliteInfoModal}
          closeModal={() => setShowSatelliteInfoModal(false)}
          data={geographyData}
        />
      )}
    </div>
  );
};
