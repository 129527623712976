import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { ISearchParamLocations } from "state/browse";
import { MarketDataEntryType } from "state/market";
import useSWR from "swr";

export type IdNameType = {
  id: number;
  name: string;
};

export type MarketResearchAvailabilityType = {
  city: IdNameType[];
  county: IdNameType[];
  counties_for_market: IdNameType[];
  neighborhood: IdNameType[];
  metro_area: IdNameType[];
  zip_code: string[];
};

export type MarketResearchType = MarketDataEntryType[];

export type MarketPerformanceType = {
  market: string;
  pct_growth: number;
  structure_type: string;
  type: string;
  browse_page_path: string;
};

export type MarketSearchPerformanceType = {
  bottom_performers: MarketPerformanceType[];
  top_performers: MarketPerformanceType[];
};

export const useMarketResearchAvailability = (region: string) => {
  const url = appConfig.apiEndpoint.marketDataAvailability.replace(":region", region);
  const { data, error } = useSWR<MarketResearchAvailabilityType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error
    ? { city: [], county: [], counties_for_market: [], neighborhood: [], metro_area: [], zip_code: [] }
    : data;
};

export const useMarketResearchTopPerformers = (region: string) => {
  const url = appConfig.apiEndpoint.marketDataTopPerformers.replace(":region", region);
  const { data, error } = useSWR<MarketPerformanceType[]>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error ? [] : data;
};

export const useMarketDataSearchPerformers = (locations: ISearchParamLocations) => {
  let apiSearch = "";
  locations?.neighborhood.length > 0 && (apiSearch += "neighborhood=" + locations.neighborhood);
  locations?.city.length > 0 && (apiSearch += "&city=" + locations.city);
  locations?.county.length > 0 && (apiSearch += "&county=" + locations.county);
  locations?.metro_area.length > 0 && (apiSearch += "&metro_area=" + locations.metro_area);
  locations?.zip_code.length > 0 && (apiSearch += "&zip_code=" + locations.zip_code);
  const url = appConfig.apiEndpoint.marketDataSearchPerformers + "?" + apiSearch;
  const { data, error } = useSWR<MarketSearchPerformanceType>(
    apiSearch !== "" ? url : null,
    fetcherWithCredentials,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );
  const emptyResult = { top_performers: [], bottom_performers: [] };
  return !data || error ? emptyResult : data;
};

export const useMarketResearchBottomPerformers = (region: string) => {
  const url = appConfig.apiEndpoint.marketDataBottomPerformers.replace(":region", region);
  const { data, error } = useSWR<MarketPerformanceType[]>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error ? [] : data;
};

export const fetchMarketResearchData = (locType: string, locId: number, structureType: string) => {
  const url = appConfig.apiEndpoint.marketData
    .replace(":loc_type", locType)
    .replace(":loc_id", locId.toString())
    .replace(":structure_type", structureType);
  return fetcherWithCredentials(url);
};

export const fetchLocationPriceData = (locType: string, locId: number) => {
  const url = appConfig.apiEndpoint.locationPriceData
    .replace(":loc_type", locType)
    .replace(":loc_id", locId.toString());
  return fetcherWithCredentials(url);
};
