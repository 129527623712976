import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";


export type SiteConfigType = {
  pellego_states?: number[];
  brokerage_states?: number[];
  partner_states?: number[];
};

export const useSiteConfig = () => {
  const url = appConfig.apiEndpoint.siteConfig;
  const { data, error } = useSWR<SiteConfigType>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error ? {} : data;
};