import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  RentalCompModal,
  rentalCompModalShowIdxAtom,
  RentalCompPropertyCard,
  RentalCompSubjectPropertyCard,
} from "components/proformas/comparables/";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useProforma } from "state/proformas";
import { filteredCompStats, RentalComparableType } from "state/proformas/comparables";

type Props = {
  comparables: RentalComparableType[];
};

const isRentalCompsViewAdjustEmpty = (comparables: RentalComparableType[]) => {
  return comparables.every(
    (comp) => !comp.rental_cost_diffs.view_type || comp.rental_cost_diffs.view_type === "",
  );
};
export const RentalCompProperties = ({ comparables }: Props) => {
  const { proforma } = useProforma();
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 480;
  };

  const rentalCompModalShowIdx = useRecoilValue(rentalCompModalShowIdxAtom);
  const compStats = filteredCompStats(proforma);
  const isRentalCompsViewAdjustmentEmpty = isRentalCompsViewAdjustEmpty(comparables);

  return (
    <div className="mt-6 sm:basis-2/4">
      <div className="flex w-full flex-row">
        {/*  rental comps card  */}
        <RentalCompSubjectPropertyCard
          compStats={compStats}
          isRentalCompsViewAdjustEmpty={isRentalCompsViewAdjustmentEmpty}
        />

        <div
          className="group relative flex w-screen overflow-x-auto"
          onScrollCapture={() => {
            comparables.length > 1 && handleScroll();
          }}
        >
          {!isScrolling && comparables.length > 1 && (
            <>
              {scrollContainerRef.current && scrollContainerRef.current.scrollLeft > 0 && (
                <ChevronLeftIcon
                  className="invisible absolute left-1 top-60 z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                  onClick={slideLeft}
                />
              )}
              {scrollContainerRef.current &&
                scrollContainerRef.current.scrollLeft <=
                  scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth - 1 && (
                  <ChevronRightIcon
                    className="invisible absolute right-1 top-60 z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60 group-hover:visible"
                    onClick={slideRight}
                  />
                )}
            </>
          )}
          <div
            ref={scrollContainerRef}
            className="relative flex w-full snap-mandatory snap-normal gap-4 overflow-x-auto scroll-smooth"
          >
            {" "}
            {comparables.map((comp) => (
              <RentalCompPropertyCard
                key={comp.parcel._id}
                comparable={comp}
                compStats={compStats}
                isRentalCompsViewAdjustEmpty={isRentalCompsViewAdjustmentEmpty}
              />
            ))}
            {/*  additional comps card to balance spacing on scroll */}
            <div className="flex snap-start snap-normal flex-row first:pl-4">
              <div className="w-2 whitespace-nowrap p-3 pr-0 sm:p-4"></div>
            </div>
          </div>
        </div>
      </div>

      {rentalCompModalShowIdx !== null && (
        <RentalCompModal
          isOpen={rentalCompModalShowIdx !== null}
          comparables={comparables}
          compStats={compStats}
        />
      )}
    </div>
  );
};
