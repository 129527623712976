import { ContactForm } from "components/agents";
import { useParcelAgent } from "state/proformas";

type Props = {
  parcelId: number;
};

export const Agents = ({ parcelId }: Props) => {
  const agent = useParcelAgent(parcelId);

  if (!agent) return null;
  var brokerage = "Pellego, Inc.";
  if (agent.email === "emmy@pellego.com") {
    brokerage = "EXIT Strategy Realty";
  }
  if (agent.email === "neil@pellego.com") {
    brokerage = "Realty One Group Prosper";
  }
  if (agent.email === "bobthomas@pellego.com") {
    brokerage = "Premier Property Group";
  }
  if (agent.email === "gideon+matt@pellego.com") {
    brokerage = "OneSource Real Estate";
  }
  if (agent.email === "gideon+darryl@pellego.com") {
    brokerage = "OneSource Real Estate";
  }
  if (agent.email === "wes@pellego.com") {
    brokerage = "Your Castle Real Estate";
  }

  return (
    <div
      className="align-center  mb-20 flex w-full flex-col items-center justify-center bg-gray-50 px-0 pt-14 pb-0"
      id="markets"
    >
      <div
        className="align-center mb-20 flex w-full max-w-5xl flex-col items-center justify-center bg-gray-50 px-0 pt-14 pb-0"
        id="markets"
      >
        <div className="mb-1 text-center text-3xl font-medium">Ask about this property</div>
        <div className="mb-8 text-center text-xl font-light">
          Our agents get investors because they are investors
        </div>
        <div className="flex w-full flex-col px-6 sm:flex-row ">
          <div className="basis-1/1 w-full sm:basis-1/3">
            <div className="my-2 flex h-60 w-60 rounded-md bg-gray-200 align-top">
              <img src={agent.avatar} alt={`${agent.name_first} ${agent.name_last}`} />
            </div>
            <div className="mt-4 mb-2 text-xl font-bold">
              {agent.agent_page_url ? (
                <a href={`//${agent.agent_page_url}`} target="_blank" rel="noreferrer">
                  {agent.name_first} {agent.name_last}
                </a>
              ) : (
                <div> {agent.name_first} {agent.name_last} </div>
              )}
            </div>
            <div className="text-md w-[90%] font-normal">Real Estate Agent</div>
            <div className="text-md mb-4 w-[90%] font-normal">{brokerage}</div>
          </div>
          <div className="basis-1/1 w-full sm:basis-2/3">
            <ContactForm
              toEmail={agent.email}
              messagePlceholder="Learn more about this property"
              submitButtonLabel="Submit Your Message"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
