import { appConfig, WASHINGTON_STATE_ID } from "config";
import { fetcherWithCredentials } from "helpers";
import useSWR from "swr";

export const useUpdateDate = (state_id: number | undefined) => {
  const url = appConfig.apiEndpoint.parcelUpdateDate.replace(
    ":state_id",
    (state_id || WASHINGTON_STATE_ID).toString(),
  );
  const { data, error } = useSWR<string>(url, fetcherWithCredentials, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return !data || error ? "" : data ;
};
