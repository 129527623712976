import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { useState } from "react";
import { CustomStructureType, useCustomStructure } from "state/proformas";
import { processRentalComparables, RentalComparableType, rentalCompFiltersMinMaxAttrs, rentalCompsFiltersType, useRentalComps } from "state/proformas/comparables";


const buildMoreComparablesParams = (
  filters: rentalCompsFiltersType,
  customStructure: CustomStructureType,
  proforma: any
) => {
  const moreCompsParams = {} as any;

  moreCompsParams.filter = [];
  rentalCompFiltersMinMaxAttrs.forEach((attr) => {
    const minVal = filters[`min_${attr.key}` as keyof rentalCompsFiltersType];
    if (minVal) {
      moreCompsParams.filter.push(`${attr.section}_${attr.key} >= ${minVal}`);
    }
    const maxVal = filters[`max_${attr.key}` as keyof rentalCompsFiltersType];
    if (maxVal) {
      moreCompsParams.filter.push(`${attr.section}_${attr.key} <= ${maxVal}`);
    }
  });

  // moreCompsParams.listing_statuses = filters.listing_statuses;
  // moreCompsParams.sale_types = filters.sale_types;
  moreCompsParams.structure_types = filters.structure_types;
  moreCompsParams.max_distance = filters.distance;
  moreCompsParams.structure = customStructure;
  moreCompsParams.return_limit = 25;

  return moreCompsParams;
};

export const useMoreRentalComparables = (proforma: any) => {
  const { customStructure } = useCustomStructure(proforma);
  const { setRentalComparables } = useRentalComps();
  const [loading, setLoading] = useState<boolean>(false);
  const [numMoreComps, setNumMoreComps] = useState<number | null>(null);

  const searchMoreRentalComps = (newFilters: any) => {
    const params = buildMoreComparablesParams(newFilters, customStructure, proforma);
    setLoading(true);
    setNumMoreComps(null);
    fetcherWithCredentials(
      appConfig.apiEndpoint.moreRentalComparables.replace(':parcel_id', proforma.parcel_id.toString()),
      "PUT", params
    )
      .then((comparables: RentalComparableType[]) => {
        setNumMoreComps(comparables.length);
        if (comparables.length > 0) {
          const rentalComps = processRentalComparables(comparables);
          setRentalComparables(rentalComps);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    loading,
    searchMoreRentalComps,
    numMoreComps,
  }
}