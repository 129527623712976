import { appConfig } from "config";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { useProforma, proformaAtom, financialsAtom, customStructureAtom } from "state/proformas";
import { salesComparablesAtom, rentalComparablesAtom } from "state/proformas/comparables";
import { fetcherWithCredentials } from "helpers";
import { cloneDeep } from "lodash";

export const saveProformaDataSelector = selector({
  key: "saveProformaDataSelector",
  get: ({ get }) => {
    const proforma = get(proformaAtom);
    const financials = get(financialsAtom);
    const customStructure = get(customStructureAtom);
    const salesComps = get(salesComparablesAtom);
    const rentalComps = get(rentalComparablesAtom);

    const saveData: any = {
      parcel_id: proforma.parcel_id,
      calcsheet: financials?.calcsheet,
      comparables: salesComps,
      rental_comparables: rentalComps,
      custom_structure: customStructure,
      name: proforma.name,
      notes: proforma.notes,
      proforma_type: proforma.proforma_type,
      labels: proforma.labels,
      sale_id: proforma.sale_id,
      structure_id: proforma.structure_id,
    }
    if (proforma.is_mine) {
      saveData._id = proforma._id;
    }
    return saveData;
  },

});

const reducePayload = (proformaData: any) => {
  const data = cloneDeep(proformaData);

  data.calcsheet.loans.forEach((loan: any) => {
    Object.keys(loan).forEach((key: string) => {
      if (key[0] === "_") {
        delete loan[key];
      }
    });
  });

  data.calcsheet.costs.forEach((cost: any) => {
    Object.keys(cost).forEach((key: string) => {
      if (key[0] === "_") {
        delete cost[key];
      }
    });
  });

  data.calcsheet.rental.forEach((rental: any) => {
    Object.keys(rental).forEach((key: string) => {
      if (key[0] === "_") {
        delete rental[key];
      }
    });
  });

  data.comparables.forEach((comp: any) => {
    delete comp.parcel;
    delete comp._idx;
  });

  data.rental_comparables.forEach((comp: any) => {
    delete comp.parcel;
    delete comp.photos;
    delete comp._idx;
    delete comp.status;
  });

  return data;
}

const autoSaveProformaAtom = atom({
  key: "autoSaveProformaAtom",
  default: false,
});

export const useSaveProforma = () => {
  const saveProformaData = useRecoilValue(saveProformaDataSelector);
  const { proforma, setProforma } = useProforma();
  const [ autoSaveProforma, setAutoSaveProforma ] = useRecoilState(autoSaveProformaAtom);

  const saveProforma = async () => {
    let url = appConfig.apiEndpoint.proformas;
    if (proforma.is_mine) {
      url += proforma._id;
    }
    await fetcherWithCredentials(url, proforma.is_mine ? "POST" : "PUT", reducePayload(saveProformaData))
      .then((res) => {
        if (res._id !== proforma._id) {
          setProforma({ ...proforma, _id: res._id, is_mine: true });
        }
      });
  }

  return {
    proforma,
    saveProforma,
    autoSaveProforma,
    setAutoSaveProforma,
    triggerAutoSaveProforma: () => { setAutoSaveProforma(true) },
  };
}