import { useMarketResearchBottomPerformers } from "state/market";
import NumberFormat from "react-number-format";
import { marketStructureTypes, newSearchParams } from "components/markets";
import { ArrowRightIcon, PlusIcon } from "@heroicons/react/outline";

type Props = {
  region: string;
  withAdd?: boolean;
};

export const WorstPerformingMarkets = ({ region, withAdd }: Props) => {
  const performances = useMarketResearchBottomPerformers(region);

  return (
    <div>
      <div className="flex w-full flex-col gap-4 pb-4 text-left sm:flex-row ">
        <div className="mb-4 w-full rounded-lg">
          <div>
            <div className="text-md text-left font-bold">Worst Performing Markets</div>
            <div className="pt-0 pb-3 text-sm font-light text-gray-700">In the last 12 months</div>
            <table className="w-full table-auto p-0 text-left text-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Location</th>
                  <th className="py-1 text-right">Growth</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {performances.map((performance, idx) => (
                  <tr key={idx} className="border-b">
                    <td className="items-center py-2 text-left font-light">
                      {withAdd && (
                        <a
                          href={newSearchParams(performance.browse_page_path)}
                          className="cursor-pointer text-black hover:text-blue-500"
                        >
                          <PlusIcon className="mr-2 h-4 w-3 text-black hover:text-blue-500" />
                        </a>
                      )}
                    </td>
                    <td className="py-2 align-top font-light">
                      <a
                        href={performance.browse_page_path}
                        className="py-2 pb-0.5 align-top font-light font-medium hover:text-blue-500 "
                      >
                        {performance.market}&nbsp;
                        {marketStructureTypes[
                          performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                        ] !== "All" && (
                          <>
                            {
                              marketStructureTypes[
                                performance.structure_type.toLowerCase() as keyof typeof marketStructureTypes
                              ]
                            }
                          </>
                        )}
                      </a>
                    </td>

                    <td className="py-2 text-right align-top font-light">
                      <NumberFormat
                        value={performance.pct_growth}
                        displayType="text"
                        suffix="%"
                        decimalScale={1}
                      />
                    </td>

                    <td className="items-center py-2 text-right font-light">
                      <div className="flex flex-row ">
                        <div>
                          <a
                            href={performance.browse_page_path}
                            className="cursor-pointer text-blue-400"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ArrowRightIcon className="ml-2 h-4 w-3 text-black hover:text-blue-500" />
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
