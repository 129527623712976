import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useUserActions } from "actions";

import { Alert } from "components";
import { LabeledInput, SubmitButton } from "components/forms";

export const PasswordReset = () => {
  const userActions = useUserActions();
  const { token } = useParams<"token">();

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required."),
    password: Yup.string().required("Password is required.")
      .min(6, "Password must be at least 6 characters."),
    password_confirm: Yup.string().required("Confirm Password is required.")
      .oneOf([Yup.ref("password")], "Passwords must match."),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const onSubmit = (userData: any) => userActions.resetPassword(token || "", userData);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div className="mt-20 max-w-md mx-auto px-6 pb-4 ">
      <h3 className="text-3xl">Reset Password</h3>
      <Alert />
      <div className="mt-4 max-w-md">
        <div className="grid grid-cols-1 gap-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabeledInput type="email" label="Confirm Email" {...register("email")} errMsg={errors.email?.message} />
            <LabeledInput
              type="password"
              label="New Password"
              {...register("password")}
              errMsg={errors.password?.message}
            />
            <LabeledInput
              type="password"
              label="Confirm Password"
              {...register("password_confirm")}
              errMsg={errors.password_confirm?.message}
            />
            <SubmitButton isSubmitting={isSubmitting}>Save Password</SubmitButton>
          </form>
        </div>
      </div>
    </div>
  );
}
