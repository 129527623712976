import { RentalGraph } from "components/proformas/financials/rentaltab/";
import { MathServiceClass, useFinancials } from "state/proformas";

export const RentalGraphs = () => {
  const { financials } = useFinancials();

  if (!financials) return null;

  const mathService = new MathServiceClass(financials);

  return (
    <div className="w-full flex flex-row flex-wrap">
      <div className="w-full sm:basis-1/2">
        <RentalGraph graphType="equity" name="Net Equity" mathService={mathService} />
        <div className="text-xs basis-1/1 flex flex-wrap ">
          <div className="text-xs basis-1/4 whitespace-nowrap "><span className="w-3 h-3 whitespace-nowrap bg-[#d8476f] mr-2">&nbsp; &nbsp; </span>Cash In</div>
          <div className="text-xs basis-1/4 whitespace-nowrap px-1"><span className="w-3 h-3 whitespace-nowrap bg-[#1976d2] mr-2">&nbsp; &nbsp; </span>Selling Costs</div>
          <div className="text-xs basis-1/4 whitespace-nowrap px-1"><span className="w-3 h-3 whitespace-nowrap bg-[#64B5F6] mr-2">&nbsp; &nbsp; </span>Loan Balance</div>
          <div className="text-xs basis-1/4 whitespace-nowrap text-left"><span className="w-3 h-3 whitespace-nowrap bg-[#9C27B0] mr-2">&nbsp; &nbsp; </span>Net Equity</div>
        </div>
      </div>
      <div className="w-full sm:basis-1/2">
        <RentalGraph graphType="monthlycashflow" name="Cash Flow" mathService={mathService} />
        <div className="text-xs basis-1/1 flex whitespace-nowrap text-center ">
          <div className="text-xs basis-1/2 whitespace-nowrap"><span className="w-3 h-3 whitespace-nowrap bg-[#1976d2] mr-2">&nbsp; &nbsp; </span>Loan Payment</div>
          <div className="text-xs basis-1/2 whitespace-nowrap"><span className="w-3 h-3 whitespace-nowrap bg-[#64B5F6] mr-2">&nbsp; &nbsp; </span>Monthly Costs</div>
          <div className="text-xs basis-1/2 whitespace-nowrap"><span className="w-3 h-3 whitespace-nowrap bg-[#9C27B0] mr-2">&nbsp; &nbsp; </span>Profit / Loss</div>
        </div>
      </div>
      <div className="w-full sm:basis-1/2">
        <RentalGraph graphType="netprofit" name="Total Profit" mathService={mathService} />
        <div className="text-xs basis-1/1 flex text-center">
          <div className="text-xs basis-1/2"><span className="w-3 h-3 whitespace-nowrap bg-[#64B5F6] mr-2">&nbsp; &nbsp; </span>Cumulative Cash Flow</div>
          <div className="text-xs basis-1/2"><span className="w-3 h-3 whitespace-nowrap bg-[#9C27B0] mr-2">&nbsp; &nbsp; </span>Net Equity</div>
        </div>
      </div>
      <div className="w-full sm:basis-1/2">
        <RentalGraph graphType="rates" name="Rates of Return" mathService={mathService} />

        <div className="text-xs basis-1/1 flex justify-start text-center">
          <div className="text-xs basis-1/2"><span className="w-3 h-3 whitespace-nowrap bg-[#64B5F6] mr-2">&nbsp; &nbsp; </span>IRR</div>
          <div className="text-xs basis-1/2"><span className="w-3 h-3 whitespace-nowrap bg-[#9C27B0] mr-2">&nbsp; &nbsp; </span>Compound Annual Growth</div>
        </div>
      </div>
    </div>
  )
}
