import { Nav, Footer } from "components";
import { SearchBox } from "components/SearchBox";

export const PropertyNotFound = () => {
  return (


    <div id="main-content" className="flex-1 relative h-full overflow-y-scroll">
      <Nav />
      <div className="flex flex-col h-screen items-center justify-center w-full text-center bg-no-repeat bg-cover bg-[url('https://cdn.davinci.pellego.com/static/images/home_bg.jpg')]">
        <div className="text-sm mb-8 text-gray-600 uppercase font-light">404 Error</div>
        <div className="text-3xl sm:text-5xl font-extralight pb-4 max-w-lg text-gray-600 mb-4">
          Nothing to see here
        </div>
        <div className="text-lg text-gray-600 max-w-md font-extralight">
          We are missing critical structure details for this parcel. Try searching for a new location below:
        </div>
        <div className="flex max-w-3xl my-2 sm:my-4 justify-center items-center h-24 w-full text-left text-md leading-10 ">
          <div className="w-full flex items-center max-w-md px-4">
            <SearchBox />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
