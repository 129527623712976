import { useSetRecoilState } from "recoil";
import { useFinancials } from "state/proformas";
import NumberFormat from "react-number-format";
import {
  CustomizeArvModal,
  ShowCustomizeArvModalAtom,
} from "components/proformas/financials/rentaltab/";


export const LongTermAssumption = () => {
  const { financials, updateCalcSheet } = useFinancials();
  const setShowCustomizeArvModal = useSetRecoilState(ShowCustomizeArvModalAtom);

  if (!financials) return null;

  const propValue = financials?.strategy_rental === "financed_brrr" ?
    financials.calcsheet.resale_value :
    financials.calcsheet.purchase_price + financials.calcsheet.rehab_costs_hold;

  const propAppr = financials.calcsheet.property_appreciation * 100;

  const onUpdatePropAppr = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ''));
    updateCalcSheet({ property_appreciation: val / 100 });
  }

  const rentAppr = financials.calcsheet.rent_appreciation * 100;

  const onUpdateRentAppr = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ''));
    updateCalcSheet({ rent_appreciation: val / 100 });
  }

  const onUpdateRentPeriod = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ''));
    updateCalcSheet({ rent_period: val });
  }

  const onUpdateHoldingTime = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d]+/g, ''));
    updateCalcSheet({ holding_time: val });
  }

  return (
    <div className="w-full flex flex-col gap-2 sm:gap-8 sm:flex-row pt-4">
      <div className="sm:pr-4 w-full">

        <div className="flex flex-row justify-items-stretch gap-2 text-sm mb-2 w-full">
          <div className="w-full whitespace-nowrap basis-3/5">
            <div className="">Initial Property Value</div>

            <div className="text-xs text-gray-400">
              {financials?.strategy_rental === "financed_brrr" && (
                <>after repair value</>
              )}
              {financials?.strategy_rental === "financed_hold" && (
                <>purchase price + initial rehab</>
              )}
            </div>

          </div>
          <div
            className="w-full basis-2/5 p-1 mb-1 items-center text-sm text-right text-blue-700 cursor-pointer bg-stone-200 border outline-0 border-[#E4C442]"
            onClick={() => setShowCustomizeArvModal(true)}
          >
            <NumberFormat
              className="text-blue-700 cursor-pointer"
              value={propValue}
              thousandSeparator={true}
              decimalScale={0}
              prefix="$ "
              displayType="text"
            />
          </div>
        </div>

        <div className="flex flex-row justify-items-stretch gap-2 text-sm mb-2">
          <div className="whitespace-nowrap basis-3/5">
            <div>Property Appreciation</div>
            <div className="text-xs text-gray-400">annual rate</div>
          </div>
          <div className="w-full basis-2/5 leading-9">
            <NumberFormat
              className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
              value={propAppr}
              thousandSeparator={true}
              decimalScale={1}
              suffix=" %"
              onBlur={onUpdatePropAppr}
              onKeyUp={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  onUpdatePropAppr(e);
                }
              }}
            />
          </div>
        </div>

        <div className="flex flex-row justify-items-stretch gap-2 text-sm mb-2">
          <div className="whitespace-nowrap basis-3/5">
            <div>Rent/Cost Increase</div>
            <div className="text-xs text-gray-400">effective annual rate</div>
          </div>
          <div className="w-full basis-2/5 leading-9">
            <NumberFormat
              className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
              value={rentAppr}
              thousandSeparator={true}
              decimalScale={1}
              suffix=" %"
              onBlur={onUpdateRentAppr}
              onKeyUp={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  onUpdateRentAppr(e);
                }
              }}
            />
          </div>
        </div>

        <div className="flex flex-row justify-items-stretch gap-2 text-sm mb-2">
          <div className="whitespace-nowrap basis-3/5">
            <div>Rent/Cost Period</div>
            <div className="text-xs text-gray-400">increase every x years</div>
          </div>
          <div className="flex  basis-2/5  w-full">
            <div className="text-center w-full">
              <NumberFormat
                className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                value={financials.calcsheet.rent_period}
                thousandSeparator={true}
                decimalScale={1}
                onBlur={onUpdateRentPeriod}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    onUpdateRentPeriod(e);
                  }
                }}
              />
            </div>
            <div className="w-1/2 text-left pl-2">years</div>
          </div>
        </div>

        <div className="flex flex-row justify-items-stretch gap-2 text-sm">
          <div className="whitespace-nowrap w-3/5">
            <div>Holding Time</div>
            <div className="text-xs text-gray-400">sell after x years</div>
          </div>
          <div className="flex basis-2/5 w-full">
            <div className="text-center w-full">
              <NumberFormat
                className="w-full h-5 p-3 text-sm text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                value={financials.calcsheet.holding_time}
                thousandSeparator={true}
                decimalScale={0}
                onBlur={onUpdateHoldingTime}
                onKeyUp={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    onUpdateHoldingTime(e);
                  }
                }}
              />
            </div>
            <div className="w-1/2 text-left pl-2">years</div>
          </div>
        </div>

      </div>

      <CustomizeArvModal />

    </div>
  )
}