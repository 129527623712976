import { WASHINGTON_STATE_ID } from "config";
import { TypeAheadItemType } from "helpers";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCurrentStates, UserSession, useUserSession } from "state";
import {
  ISearchParamLocations,
  ISearchParams,
  useLocationsApi,
  useLocationSearch,
  useSearchApiParams,
  useSearchApiResult,
} from "state/browse";

export const buildLocationsFromUrl = (urlParams: URLSearchParams, locationsApi: any): any => {
  let stateId: number | null = null;
  const stateIds: number[] = [];
  const locations: ISearchParamLocations = {
    state: [],
    city: [],
    county: [],
    neighborhood: [],
    school_district: [],
    zip_code: [],
    metro_area: [],
  };
  const locationSearchItems: TypeAheadItemType[] = [];

  ["state", "county", "city", "zip_code", "neighborhood", "metro_area"].forEach((locationType) => {
    const paramIdValues = urlParams.getAll(locationType);
    if (paramIdValues.length > 0) {
      locations[locationType as keyof ISearchParamLocations] = paramIdValues.map((id) => parseInt(id)).sort((a, b) => a - b);
      if (locationType === "state") {
        stateId = parseInt(paramIdValues[0]);
        stateIds.push(stateId);
      } else if (locationType !== "zip_code" && !locationsApi.loading) {
        paramIdValues.forEach((paramIdValue) => {
          const locationData = locationsApi.data[locationType][paramIdValue];
          if (!locationData) return;
          if (locationData?.state_id && !stateId) {
            stateId = parseInt(locationData.state_id);
          }
          stateIds.push(Number(locationData?.state_id));
          locationSearchItems.push({
            type: locationType as TypeAheadItemType["type"],
            name: locationData.name,
            state_id: Number(stateId),
            id: Number(paramIdValue),
            county_id: locationData.county_id ? Number(locationData.county_id) : undefined,
            city_id: locationData.city_id ? Number(locationData.city_id) : undefined,
          });
        });
      } else if (locationType === "zip_code") {
        paramIdValues.forEach((paramIdValue) => {
          locationSearchItems.push({
            type: "zip_code",
            id: paramIdValue,
            name: `ZIP: ${paramIdValue}`,
            state_id: Number(stateId),
          });
        });
      }
    }
  });
  return [stateId, stateIds, locations, locationSearchItems];
};

const defaultStateId = (currentUser: UserSession): number => {
  return currentUser.session?.default_region || WASHINGTON_STATE_ID;
};

const buildSearchParams = (locations: ISearchParamLocations): ISearchParams => {
  const searchParams: ISearchParams = {
    locations: locations,
  };
  return searchParams;
};

export const useBrowse = () => {
  const currentUser = useUserSession();
  const searchApiParams = useSearchApiParams();
  const searchApiResult = useSearchApiResult();
  const [urlParams] = useSearchParams();
  const locationsApi = useLocationsApi(urlParams);
  const { setCurrentStates } = useCurrentStates();

  const [stateIdFromUrl, stateIds, locationsFromUrl, locationSearchItemsFromUrl] = buildLocationsFromUrl(
    urlParams,
    locationsApi,
  );

  const { locationSearchItems, setLocationSearchItems } = useLocationSearch();
  const stateId = stateIdFromUrl || defaultStateId(currentUser);
  const searchParams = buildSearchParams(locationsFromUrl);

  useEffect(() => {
    if (!locationsApi.loading && locationsApi.data) {
      searchApiParams.update(searchParams);
      setCurrentStates(stateIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsApi.loading, locationsApi.data]);

  useEffect(() => {
    if (!isEqual(locationSearchItemsFromUrl, locationSearchItems)) {
      setLocationSearchItems(locationSearchItemsFromUrl);
    }
  }, [locationSearchItemsFromUrl, locationSearchItems, setLocationSearchItems]);

  return {
    stateId: stateId,
    loading: locationsApi.loading || searchApiResult.loading,
    locationSearchItems: locationSearchItemsFromUrl,
    emptyResult: searchApiResult.deals?.length === 0,
  };
};
