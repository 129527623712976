import AnnounceKit from "announcekit-react";
import { isMobile } from "react-device-detect";

type AnnounceWidgetType = {
  visible?: boolean;
  bottom?: string;
  right?: string;
};

export const AnnounceWidget = ({ visible = true, bottom = "20px", right = "20px" }: AnnounceWidgetType) => {
  return (
    !isMobile && visible ? (
      <AnnounceKit
        widget="https://announcekit.co/widgets/v2/3Gzuus"
        floatWidget={true}
        trackLocationChanges={true}
        widgetStyle={{
          bottom: bottom,
          right: right,
          zIndex: 30,
        }}
      />
    ):(<></>)
  );
};
