import { Dot } from "components/icons";
import { SavedProperties } from "components/saved";
import { useEffect, useState } from "react";
import { useSavedPropertiesList } from "state/proformas";


export type Status = "all" | "active" | "pending" | "offMarket";

type Props = {
  handleScroll: () => void;
};

export const Saved = ({ handleScroll }: Props) => {
  const { loading, total_properties, properties } = useSavedPropertiesList();

  const [status, setStatus] = useState<Status>("all");

  const activeProperties = properties?.filter(
    (property: any) => property.listing.listing_status === "Active",
  );
  const pendingProperties = properties?.filter(
    (property: any) => property.listing.listing_status === "Pending",
  );
  const offMarketProperties = properties?.filter(
    (property: any) =>
      property.listing.listing_status === "Sold" || property.listing.listing_status === "Canceled",
  );

  const [filteredProperties, setFilteredProperties] = useState<any>(properties);

  useEffect(() => {
    const getFilteredProperties = (status: Status) => {
      switch (status) {
        case "all":
          return properties;
        case "active":
          return activeProperties?.length > 0 ? activeProperties : properties;
        case "pending":
          return pendingProperties?.length > 0 ? pendingProperties : properties;
        case "offMarket":
          return offMarketProperties?.length > 0 ? offMarketProperties : properties;
        default:
          return properties;
      }
    };
    setFilteredProperties(getFilteredProperties(status));
    //eslint-disable-next-line
  }, [properties, status]);

  return (
    <div className="mt-4 w-full max-w-5xl">
      {!loading && total_properties === 0 && (
        <div className="mx-auto mb-4 max-w-7xl text-pretty text-center text-[22px] font-extralight">
          Save properties to see them here
        </div>
      )}
      <div className="sm:mt-8 flex min-h-screen w-full flex-col sm:flex-row">
        <div className="flex w-full shrink-0 flex-row mb-6 sm:mb-0 items-center justify-center gap-2 p-2 text-sm sm:w-40 sm:flex-col sm:items-start sm:justify-start">
          <div
            className="cursor-pointer"
            onClick={() => {
              setStatus("all");
            }}
          >
            All<span className="hidden sm:inline"> Saved</span> ({total_properties})
          </div>
          <div
            className={`flex ${activeProperties?.length > 0 && "cursor-pointer"} flex-row items-center gap-1 text-xs sm:gap-2`}
            onClick={() => {
              activeProperties?.length > 0 && setStatus("active");
            }}
          >
            <Dot className="size-2 text-[#34fd0f]" />
            <span>
              Active<span className="hidden sm:inline"> Listing</span> ({activeProperties?.length})
            </span>
          </div>
          <div
            className={`flex ${pendingProperties?.length > 0 && "cursor-pointer"} flex-row items-center gap-1 text-xs sm:gap-2`}
            onClick={() => {
              pendingProperties.length > 0 && setStatus("pending");
            }}
          >
            <Dot className="size-2 text-[#fce5cb]" />
            Pending ({pendingProperties?.length})
          </div>
          <div
            className={`flex ${offMarketProperties?.length > 0 && "cursor-pointer"} flex-row items-center gap-1 text-xs sm:gap-2`}
            onClick={() => {
              offMarketProperties?.length > 0 && setStatus("offMarket");
            }}
          >
            <Dot className="size-2 text-[#999999]" />
            Off Market ({offMarketProperties?.length})
          </div>
        </div>
        <div className="relative flex w-full flex-col px-2">
          <SavedProperties
            loading={loading}
            filteredProperties={filteredProperties}
            status={status}
            setStatus={setStatus}
            handleScroll={handleScroll}
          />
        </div>
      </div>
    </div>
  );
};