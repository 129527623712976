import { useAlerts } from "state";
import { jsonParse } from "helpers";

export { useFetchWrapper };

function useFetchWrapper() {
  const alertActions = useAlerts();

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };

  function request(method: string) {
    return (url: string, body: object | null = null) => {
      const requestOptions: RequestInit = { method };
      requestOptions.credentials = "include";
      if (body) {
        requestOptions.headers = { "Content-Type": "application/json" };
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(url, requestOptions)
        .then(handleResponse)
        .catch((error) => {
          alertActions.error(error);
          return Promise.reject(error);
        });
    };
  }

  // helper functions

  function handleResponse(response: Response) {
    return response.text().then((text) => {
      const data = jsonParse(text);

      if (!response.ok) {
        const error = (data && (data.message || data.msg)) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }
}
