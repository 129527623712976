import { FlipCard, FlipCashIn, FlipCosts } from "components/proformas/financials/fliptab/";
import { useRef } from "react";

type Props = {
  setSelectedTab: CallableFunction;
};

export const FlipTab = ({ setSelectedTab }: Props) => {
  const carryingTimeRef = useRef<HTMLInputElement>(null);

  const handleCarryingTimeClick = () => {
    if (carryingTimeRef && carryingTimeRef.current) {
      carryingTimeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      carryingTimeRef.current.focus();
    }
  };
  return (
    <div className="flex w-full flex-col justify-center">
      <div className="mb-6 flex flex-col items-center justify-center text-center text-sm text-gray-500 sm:mb-10 sm:text-base">
        Edit the assumptions in yellow boxes. Input profit to calculate an offer price. Pre-set assumptions
        are computer generated by Lotside.
      </div>
      <div className="flex w-full flex-col gap-2 sm:flex-row sm:gap-8">
        <div className="basis-1/1 sm:basis-1/3 sm:pr-4">
          <FlipCashIn handleCarryingTimeClick={handleCarryingTimeClick} />
        </div>
        <div className="basis-1/1 sm:basis-1/3 sm:pr-4">
          <FlipCosts />
        </div>
        <div className="basis-1/1 order-first mb-8 mt-4 sm:order-last sm:mt-0 sm:basis-1/3">
          <FlipCard setSelectedTab={setSelectedTab} ref={carryingTimeRef} />
        </div>
      </div>
    </div>
  );
};
