import { mdiHistory } from "@mdi/js";
import { Icon } from "@mdi/react";
import { formatPropertyName } from "helpers";
import { Link } from "react-router-dom";

type Props = {
  search: any;
  resetStatus: CallableFunction;
};

export const RecentSearchResultItem = ({ search, resetStatus }: Props) => {
  return (
    <Link to={search.parameters.url} target="_self" onClick={() => resetStatus(true)}>
      <div className="flex flex-row items-center">
        <Icon className="mr-2 inline-block size-3.5" path={mdiHistory} />
        {search.parameters?.url.includes("address" || "listing-number") &&
          (search.parameters?.propertyData?.showAddress ? (
            <div className="truncate">
              {formatPropertyName(search.parameters?.propertyData?.name)},{" "}
              {search.parameters?.propertyData?.city_for_address ||
                search.parameters?.propertyData?.county + " County"}
              , {search.parameters?.propertyData?.state_abbreviation}{" "}
              {search.parameters?.propertyData?.zip_code}
            </div>
          ) : (
            <>Address Hidden by Owner</>
          ))}
        {search.parameters?.url.includes("browse") &&
          (search.parameters?.browseData?.pageTitle
            ? search.parameters?.browseData?.pageTitle
            : "(Unknown Search)")}
      </div>
    </Link>
  );
};
