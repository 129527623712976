import { useEffect, useState } from "react";
import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { ProformaType } from "state/proformas/";

const createShareUrl = (proforma: ProformaType) => {
  const data = { calcsheet_id: proforma._id, parcel_id: proforma.parcel_id };
  return fetcherWithCredentials(appConfig.apiEndpoint.proformaShare, "POST", data);
}

export const useShare = (proforma: ProformaType) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    if (proforma.is_mine) {
      createShareUrl(proforma)
        .then(url => {
          setUrl(url);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [proforma]);

  return {
    loading,
    error: loading === false && !url,
    url,
  }
};
