import { useEffect, useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { locationSearchItemsAtom, useFilters } from "state/browse";
import { fetchAllMarketResearchData } from "pages";
import { MarketResearchGraph, MarketComparableType } from "components/markets";
import { TypeAheadItemType, titleCase } from "helpers";
import { Tab } from "@headlessui/react";

const getMarketComparables = (
  filtersStructureTypes: string[],
  locationSearchItems: TypeAheadItemType[],
): MarketComparableType[] => {
  let structureTypes = filtersStructureTypes
    .filter((item) => item !== "Mobile")
    .map((item) => item.toLowerCase());
  if (structureTypes?.length === 0) {
    structureTypes = ["all"];
  }

  const marketComparable: MarketComparableType[] = [];
  structureTypes?.forEach((structureType) => {
    locationSearchItems?.forEach((item) =>
      marketComparable.push({
        id: `${item.type}/${item.id}/${structureType}`,
        loc_type: item.type,
        loc_id: Number(item.id),
        loc_name: titleCase(item.name),
        structureType: structureType,
      }),
    );
  });
  return marketComparable;
};

type Props = {
  stateId: number;
};

export const BrowseInsights = ({ stateId }: Props) => {
  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const [comparableItems, setComparableItems] = useState<MarketComparableType[]>([]);
  const { filters } = useFilters();

  const marketComparable = useMemo(
    () => getMarketComparables(filters.structure_type || [], locationSearchItems),
    [filters.structure_type, locationSearchItems],
  );

  useEffect(() => {
    fetchAllMarketResearchData(marketComparable).then((marketData) => {
      const newComparableItems = marketData.map((data, index) => ({
        ...marketComparable[index],
        data,
      }));
      setComparableItems(newComparableItems);
    });
  }, [marketComparable]);

  return (
    <>
      <div className="mt-4 w-full px-0">
        <MarketResearchGraph
          marketResearchData={comparableItems}
          valueName="median_value_per_sqft"
          title="Price per Square Foot"
        />
      </div>

      {comparableItems.length > 0 && (
        <Tab.Group>
          <Tab.List className="mt-2 flex w-full justify-start space-x-0 ">
            <Tab
              className={({ selected }) =>
                selected
                  ? "sm:text-md text-md w-full border-l border-r border-t py-4 px-2 text-center font-medium leading-5 sm:py-6"
                  : "sm:text-md text-md  w-full border-b py-4 px-2 text-center font-light leading-5 text-gray-600 sm:py-6"
              }
            >
              Volume
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? "sm:text-md text-md w-full border-l border-r border-t py-4 px-2 text-center  font-medium leading-5 sm:py-6  "
                  : "sm:text-md text-md w-full border-b  py-4 px-2 pl-4 text-center font-light leading-5 tracking-tighter text-gray-600 sm:py-6"
              }
            >
              Activity
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-8 w-full px-0">
            <Tab.Panel>
              <div className="my-2 mb-4 ">
                <MarketResearchGraph
                  marketResearchData={comparableItems}
                  valueName="n_new_listings"
                  title="Listings"
                />
              </div>

              <div className="my-2 mb-4 ">
                <MarketResearchGraph marketResearchData={comparableItems} valueName="n_sales" title="Sales" />
              </div>

            </Tab.Panel>

            <Tab.Panel>

              <div className="basis-1/1 mb-4 sm:basis-1/2">
                <MarketResearchGraph
                  marketResearchData={comparableItems}
                  valueName="sale_to_list_price_ratio"
                  title="Sale/List price ratio"
                />
              </div>

              <div className="mb-4 ">
                <MarketResearchGraph
                  marketResearchData={comparableItems}
                  valueName="median_days_on_market"
                  title="Median Days on Market"
                  updated={true}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </>
  );
};
