import { stateIdToStateData } from "config";
import { cn, formatPropertyName } from "helpers";
import TimeAgo from "react-timeago";


type Props = {
  property: any;
};

export const PhotosListingDisclosure = ({ property }: Props) => {
  const withLogoImage = property?.listing_source === "RMLS" || property?.listing_source === "SABOR";
  const imageLogo = property?.listing_source === "RMLS" ? "rmls_logo.jpg" : "sabor_logo.png";
  const isSABOR = property?.listing_source === "SABOR";

  const propertyAddress = (
    <>
      {formatPropertyName(property.name)},{" "}
      {property.location?.includes(",")
        ? property.location?.split(",")[0]
        : property.location?.includes("(")
          ? property.location?.split(" (")[0]
          : property.location || ""}
      ,{" "}
      {stateIdToStateData[property?.state_id as keyof typeof stateIdToStateData].code ||
        property?.county + " County"}{" "}
      {property?.zip_code}
    </>
  );

  return (
    <div className="absolute z-10 w-full bg-gradient-to-t from-black/50 to-transparent pb-2 pl-3 pr-2.5 pt-3 text-white">
      <div className="flex flex-row items-center justify-between">
        <div className={cn("flex flex-col", withLogoImage ? "w-56" : "w-full")}>
          <div className="text-xs">
            {property?.listingEvent}&nbsp;
            <TimeAgo
              date={new Date(property?.listingEventDate)}
              live={false}
              formatter={(value, unit, suffix) => {
                return `${value}${unit === "month" ? "mo" : unit.slice(0, 1)} ${suffix}`;
              }}
            />
            ,&nbsp;
            {property.days_on_market} DOM
          </div>
          {withLogoImage && (
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
              {isSABOR ? propertyAddress : <>Listing provided by {property.listing_company}</>}
            </div>
          )}
          {!withLogoImage && (
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[8px] tracking-tight">
              Listing provided by {property.listing_source}
              {property.listing_company && " and " + property.listing_company}
            </div>
          )}
        </div>
        {withLogoImage && (
          <div className="ml-2 content-center justify-center rounded-sm bg-white">
            <img
              className="h-[28px] w-14 object-scale-down p-[3px]"
              src={`https://cdn.davinci.pellego.com/static/images/${imageLogo}`}
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};