import { StreetView } from "components/proformas/details";
import { cn, formatPropertyName } from "helpers";
import { Link } from "react-router-dom";
import { NewSaveSearchType } from "state/proformas";

type Props = {
  search: NewSaveSearchType;
};

export const RecentSearchPropertyCard = ({ search }: Props) => {
  return (
    <Link to={search.parameters.url} target="_blank">
      <div
        className={cn(
          "h-76 mb-4 mr-4 flex w-80 max-w-full shrink-0 snap-start snap-normal flex-col whitespace-nowrap rounded-sm border bg-white p-4 shadow-lg shadow-x-2 shadow-y-2 hover:border-blue-500",
        )}
      >
        <div className="relative aspect-video w-full flex-shrink-0">
          {search.parameters?.propertyData?.thumbnail_url ===
          "https://cdn.davinci.pellego.com/static/images/core/missing.png" ? (
            <div>
              <StreetView
                otherClasses="flex cursor-pointer items-end"
                sizeClasses=" h-[160.88px] w-full"
                parcel={search.parameters?.propertyData?.parcel}
                titles={false}
                rounded="rounded-t-sm"
              />
            </div>
          ) : (
            <div
              className="bg-[background-size: 100% 100%;] flex h-full w-full items-end rounded-t-sm bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url('${search.parameters?.propertyData?.thumbnail_url}')` }}
            />
          )}
        </div>

        <div className="h-fit w-full items-center truncate bg-white pt-4 text-sm font-bold text-gray-700">
          {search.parameters?.propertyData?.showAddress ? (
            <>
              {formatPropertyName(search.parameters?.propertyData?.name)},{" "}
              {search.parameters?.propertyData?.city_for_address ||
                search.parameters?.propertyData?.county + " County"}
              <span className="mr-2 hidden lg:inline">
                , {search.parameters?.propertyData?.state_abbreviation}{" "}
                {search.parameters?.propertyData?.zip_code}
              </span>
            </>
          ) : (
            <>Address Hidden by Owner</>
          )}
        </div>
      </div>
    </Link>
  );
};
