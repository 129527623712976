import { FlipCostGroup } from "components/proformas/financials/fliptab/";

export const FlipCosts = () => {

  return (
    <div>
      <FlipCostGroup costGroup="acquisition" costGroupName="Buying Costs" />
      <FlipCostGroup costGroup="carrying" costGroupName="Carrying Costs" />
      <FlipCostGroup costGroup="selling" costGroupName="Selling Costs" />
    </div>
  )
}