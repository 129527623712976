import React from "react";
import { structureTypeOptions } from "state/browse";
import { StructureTypeButton } from "components/browse";

type Props = {
  structureTypeValues: any[];
  handleClick: CallableFunction;
  handleReset?: CallableFunction;
};

export const StructureFilters = ({ structureTypeValues, handleClick, handleReset }: Props) => {
  return (
    <>
      <div className="mb-2 flex text-xs font-bold text-gray-700">
        <div className="mr-2 text-sm font-bold">Structure</div>
      </div>
      <div className="flex flex-row flex-wrap gap-2 py-2">
        {structureTypeOptions.map((structureTypeOption) => (
          <StructureTypeButton
            key={`st-${structureTypeOption.value}`}
            type={structureTypeOption}
            active={structureTypeValues?.includes(structureTypeOption.value)}
            onClick={handleClick}
          />
        ))}
      </div>

      {handleReset && (
        <div className="mr-2 flex">
          <button
            onClick={() => handleReset()}
            className={`${
              structureTypeValues.length === 0 ? "hidden" : ""
            } rounded-xs mt-4 h-8 px-3 text-sm text-gray-500 hover:bg-gray-200`}
          >
            Reset
          </button>
        </div>
      )}
    </>
  );
};
