import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";

export type RegisterWholesaleContactType = {
  full_name: string;
  phone_number: string;
  email: string;
  address?: string;
  message?: string;
  has_agent_licence?: boolean;
  has_deals_to_post?: boolean;
  is_pre_approved?: boolean;
};

export const useRegisterWholesaleContact = () => {
  const registerWholesaleContact = (data: RegisterWholesaleContactType) => {
    return fetcherWithCredentials(appConfig.apiEndpoint.wholesaleContact, "POST", data);
  };

  return { registerWholesaleContact };
};
