import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";


export type RegisterRestrictedInfoContactType = {
  full_name: string;
  phone_number: string;
  email: string;
  message?: string;
  has_agent_license?: boolean;
  is_pre_approved?: boolean;
  domain: string;
  "g-recaptcha-response": string;
};

export const useRegisterRestrictedInfoContact = () => {
  const registerRestrictedInfoContact = (data: RegisterRestrictedInfoContactType) => {
    return fetcherWithCredentials(appConfig.apiEndpoint.restrictedInfoContact, "POST", data);
  };

  return { registerRestrictedInfoContact };
};