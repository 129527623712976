import React, { useEffect, useRef } from "react";
import { atom, useSetRecoilState } from "recoil";
import { useSelectedProperty } from "state";
import { LeafletMapService, mapBoundsAtom, mapDrawnPolygonsAtom } from "./LeafletMapService";
import "leaflet/dist/leaflet.css";
import { Spinner } from "components";
import { useRecoilValue } from "recoil";
import { useMultiPolygons, useUserSession } from "state";
import { filteredSearchResultsSelector, useMapRequirements } from "state/browse";
import "state/useMultiPolygons";

export const highlightHandlerAtom = atom<CallableFunction | null>({
  key: "highlightHandlerAtom",
  default: null,
});

type Props = {
  emptyResult: boolean;
};

export const Map = ({ emptyResult }: Props) => {
  const filteredSearchResults = useRecoilValue(filteredSearchResultsSelector);
  const ref = useRef<HTMLDivElement>(null);
  const mapService = useRef<LeafletMapService | null>(null);
  const { setSelectedProperty } = useSelectedProperty();
  const setHighlightHandler = useSetRecoilState(highlightHandlerAtom);
  const setMapBounds = useSetRecoilState(mapBoundsAtom);
  const setMapDrawnPolygons = useSetRecoilState(mapDrawnPolygonsAtom);
  const isClient = useUserSession().isClient;
  const mapRequirements = useMapRequirements(emptyResult);
  const setMultiPolygons = useMultiPolygons();

  useEffect(() => {
    if (!ref.current || mapRequirements.loading) return;

    mapService.current = new LeafletMapService(
      setSelectedProperty,
      setMapBounds,
      setMapDrawnPolygons,
      emptyResult, // with emptyResult, we show only the appreciation layer
      isClient,
      mapRequirements,
    );
    mapService.current.createMap(ref.current);
    setHighlightHandler(() => mapService.current?.highlightHandler.bind(mapService.current));

    return () => mapService.current?.clearMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, mapRequirements.loading]);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.updateProperties(filteredSearchResults);
    }
  }, [filteredSearchResults]);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.setMultiPolygons = setMultiPolygons;
    }
  }, [setMultiPolygons]);

  return (
    <div ref={ref} className="h-full w-full">
      {mapRequirements.loading && (
        <div className="grid h-full w-full place-items-center">
          <span className="flex items-center rounded-3xl bg-[#61a28d] py-2 pl-8 pr-4 text-center text-white">
            <Spinner /> Loading map...
          </span>
        </div>
      )}
    </div>
  );
};
