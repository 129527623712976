import { FilterPriceDropdownMenu } from "components/browse";
import { Control, Controller, FieldValues, UseFormGetValues, UseFormSetValue } from "react-hook-form";


const options = [
  0,
  100_000,
  200_000,
  300_000,
  400_000,
  500_000,
  600_000,
  700_000,
  800_000,
  900_000,
  1_000_000,
  1_250_000,
  1_500_000,
  1_750_000,
  2_000_000,
  2_250_000,
  2_500_000,
  2_750_000,
  3_000_000,
  3_250_000,
  3_500_000,
  3_750_000,
  4_000_000,
  4_250_000,
  4_500_000,
  4_750_000,
  5_000_000,
  6_000_000,
  7_000_000,
  8_000_000,
  9_000_000,
  10_000_000,
  Infinity,
];

type Props = {
  label: string;
  variable: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  handleChange: (e: any) => void;
  control: Control<FieldValues, unknown>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
};

export const MinMaxPriceDropdowns = ({ label, variable, handleChange, control, setValue, getValues }: Props) => {
  const commonClasses =
    "flex w-full h-9 text-right border-dotted border-gray-400 items-center border px-2 py-4";

  return (
    <div className="mt-2 flex w-full flex-row items-center justify-between gap-1 sm:gap-4">
      <div className="max-w-1/3 relative flex w-1/3 whitespace-nowrap">{label}</div>
      <div className="max-w-2/3 flex w-2/3 flex-row gap-1 sm:gap-4">
        <div className="flex w-full">
          <Controller
            control={control}
            name={`min_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterPriceDropdownMenu
                className={commonClasses}
                placeholder={"Min"}
                label={label}
                value={value}
                options={
                  getValues(`max_${variable}`)
                    ? options.filter((o) => o < getValues(`max_${variable}`))
                    : options
                }
                onChange={(min_value: any) => {
                  onChange(min_value);
                  setValue(name, min_value);
                  handleChange(min_value);
                }}
              />
            )}
          />
        </div>
        <div className="py-1 text-gray-400">-</div>
        <div className="flex w-full">
          <Controller
            control={control}
            name={`max_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterPriceDropdownMenu
                className={commonClasses}
                placeholder={"Max"}
                label={label}
                value={value}
                options={
                  getValues(`min_${variable}`)
                    ? options.filter((o) => o > getValues(`min_${variable}`))
                    : options
                }
                onChange={(max_value: any) => {
                  onChange(max_value);
                  setValue(name, max_value);
                  handleChange(max_value);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};