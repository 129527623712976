import { WithSignIn } from "components";
import { Fragment } from "react";
import { useUserSession } from "state";
import { useProforma } from "state/proformas";

const agentFields = [
  {
    name: "listing_agent_phone",
    title: "Agent Phone:",
    whenSignedIn: true,
  },
  {
    name: "listing_office_email",
    title: "Office Email:",
    whenSignedIn: false,
  },
  {
    name: "listing_office_phone",
    title: "Office Phone:",
    whenSignedIn: false,
  },
  {
    name: "selling_commission",
    title: "Selling Commission:",
    whenSignedIn: false,
  },
];

const agentOnlyAttrs = [
  // { key: "agent_listing_remarks", name: "Agent Listing Remarks" },
  {
    key: "showing_requirements",
    name: "Showing Requirements",
    on: "sale",
    whenSignedIn: false,
  },
  { key: "occupant_type", name: "Occupant Type", on: "sale", whenSignedIn: false },
  { key: "occupant_name", name: "Occupant Name", on: "sale", whenSignedIn: false },
  {
    key: "owner1_name",
    name: "Owner Name",
    on: "parcel",
    saleKey: "owner_name",
    whenSignedIn: false,
  },
  { key: "owner2_name", name: "Second Owner Name", on: "parcel", whenSignedIn: false },
  {
    key: "owner_address",
    name: "Owner Address",
    on: "parcel",
    saleKey: "owner_city_state",
    whenSignedIn: false,
  },
];

export const PrivateRemarks = () => {
  const { proforma } = useProforma();
  const mostRecentListing = proforma.parcel.most_recent_listing;
  const currentUser = useUserSession();

  if (!mostRecentListing) {
    return null;
  }

  const anyAttrToShow = agentOnlyAttrs.some((attr) => mostRecentListing[attr.key]);
  if (!anyAttrToShow && !mostRecentListing["agent_listing_remarks"]) return null;

  return (
    <div className="text-md mt-4 pt-4">
      <div className="pb-1 text-xs font-bold uppercase text-green-900">Private Remarks (Agents Only)</div>
      <div className="text-md">
        <WithSignIn asAgent={true} text="Sign in with an agent account to view this information">
          <span className="text-md mt-4 font-light text-gray-700">
            {mostRecentListing.agent_listing_remarks}
          </span>
        </WithSignIn>
        <div className="pt-4 text-sm text-gray-700">
          <ul>
            {agentFields.map((field) => (
              <Fragment key={field.name}>
                {(currentUser.isAgent || field.whenSignedIn) && mostRecentListing[field.name] && (
                  <li className="text-md font-light" key={field.name}>
                    <span className="font-bold">{field.title} </span>
                    {field.name === "selling_commission" ? (
                      mostRecentListing[field.name]+"%"
                    ) : (
                      <WithSignIn asAgent={true}>{mostRecentListing[field.name]}</WithSignIn>
                    )}
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </div>

      <div className={`text-sm text-gray-700`}>
        <ul>
          {agentOnlyAttrs.map((attr) => (
            <Fragment key={attr.key}>
              {(currentUser.isAgent || attr.whenSignedIn) && (
                <li key={attr.key}>
                  <strong>{attr.name}: </strong>
                  <WithSignIn asAgent={true}>
                    {attr.key === "showing_requirements" && mostRecentListing.showing_requirements ? (
                      <>{mostRecentListing.showing_requirements.replace(/[{}"]/g, "").replace(/,+/g, ", ")}</>
                    ) : attr.on === "parcel" ? (
                      proforma.parcel[attr.key] || mostRecentListing[attr.saleKey || ""]
                    ) : (
                      <>{mostRecentListing[attr.key]}</>
                    )}
                  </WithSignIn>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};
