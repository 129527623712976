import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { useRecoilValueLoadable, selectorFamily } from "recoil";

const loansSelector = selectorFamily({
  key: 'loansSelector',
  get: (parcelId: number | undefined) => async () => {
    if (!parcelId) return;
    const response = await fetcherWithCredentials(
      appConfig.apiEndpoint.parcelLoans.replace(':parcel_id', parcelId.toString())
    );
    return response;
  },
});


export const useLoans = (parcelId: number | undefined) => {
  const loadable = useRecoilValueLoadable(loansSelector(parcelId));

  return {
    loans: loadable.contents,
    loading: loadable.state === 'loading',
    error: loadable.state === 'hasError',
  };
};
