import { useEffect, useRef, memo } from "react";
import { CompMapService } from "components/proformas/comparables/";
import { useRentalComps } from "state/proformas/comparables";
import { useProforma } from "state/proformas";
import { atom, useSetRecoilState } from "recoil";

export const rentalComphighlightHandlerAtom = atom<CallableFunction | null>({
    key: "rentalComphighlightHandlerAtom",
    default: null,
});

export const RentalCompMap = memo(() => {
    const { proforma } = useProforma();
    const { rentalComparables } = useRentalComps();
    const setHighlightHandler = useSetRecoilState(rentalComphighlightHandlerAtom);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            const compMapService = new CompMapService(rentalComparables, proforma);
            compMapService.createMap(ref.current);
            setHighlightHandler(() => compMapService.highlightHandler.bind(compMapService));
        }
        return () => setHighlightHandler(null);
    }, [ref, rentalComparables, proforma, setHighlightHandler]);

    return (
        <div ref={ref} className="w-full h-full" />
    );
});
