import { ClipboardCopyIcon, ShareIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper, Spinner } from "components";
import { ExportModal } from "components/proformas/modals";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactGA from "react-ga4";
import { useUserSession } from "state";
import { useProforma, useShare } from "state/proformas";

type Props = {
  isOpen: boolean;
  closeModal: any;
};

export const ShareModal = ({ isOpen, closeModal }: Props) => {
  const { proforma } = useProforma();
  const share = useShare(proforma);
  const [copied, setCopied] = useState(false);
  const currentUser = useUserSession();
  const [showExportModal, setShowExportModal] = useState(false);

  const onCopyToClipboard = () => {
    setCopied(true);

    ReactGA.event({
      category: "Proforma",
      action: "Share Analysis",
      label: proforma._id.toString(),
    });
  };

  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      {!showExportModal ? (
        <div className="inline-block h-full min-h-52 w-full max-w-sm transform rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
          <header className="flex w-full flex-row items-center">
            <div className="text-md flex basis-4/5 items-center pl-9 font-medium text-gray-700">
              <ShareIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
              Share your analysis
            </div>
            <div className="justify-right basis-1/5 p-4 pr-4 text-right">
              <button
                type="button"
                className="rounded-md pb-1 pl-1 text-sm font-medium text-gray-400 hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={closeModal}
              >
                <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
              </button>
            </div>
          </header>

          <main className="flex h-full flex-1 flex-grow flex-col bg-gray-50 p-8">
            {proforma?.is_mine && (
              <>
                {share.loading && (
                  <div className="flex flex-row items-center">
                    <Spinner className="mr-3 size-5 text-black" />
                    <div>Loading...</div>
                  </div>
                )}
                {share.url && (
                  <>
                    This link will show your comps and numbers for seven days. If you are an agent, it will
                    showcase you as the agent.
                    <br />
                    <br />
                    <span className="break-all">{share.url}</span>
                    <CopyToClipboard text={share.url} onCopy={onCopyToClipboard}>
                      <button className="mt-4 flex items-center justify-center rounded-sm bg-black px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-black sm:h-10 sm:px-8">
                        <ClipboardCopyIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
                        Copy to clipboard
                      </button>
                    </CopyToClipboard>
                    {copied && <div className="p-4 text-center text-green-500">Copied!</div>}
                    <div className="mt-8 flex flex-col items-center text-sm leading-3">
                      <div>Want to share this as a PDF?</div>
                      <div
                        onClick={() => currentUser.isLogged && setShowExportModal(true)}
                        className="flex cursor-pointer px-4 py-2 text-sm text-blue-700 hover:text-blue-900"
                      >
                        Export Spreadsheet
                      </div>
                      <div>Formatted as an 8.5x11" documnent</div>
                    </div>
                  </>
                )}
              </>
            )}
            {!proforma.is_mine && (
              <>
                Please make sure you are logged in and have already saved this property in order to share the
                analysis.
              </>
            )}
          </main>
        </div>
      ) : (
        <ExportModal closeModal={closeModal} />
      )}
    </ModalWrapper>
  );
};
