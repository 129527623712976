import { mdiAutorenew, mdiClose, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { WithSignIn } from "components";
import {
  Labels,
  RecentSearchResultItem,
  SavedSearchResultItem,
  SearchResultItem,
} from "components/SearchBox";
import { cn, typeAheadApi, TypeAheadItemType } from "helpers";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-storage-complete";
import { useUserSession } from "state";
import { ISearchParamLocations, useLocationSearch, useSearchApiParams } from "state/browse";
import { SaveSearchType, useSavedSearchList } from "state/proformas";
import { useDebounce, useOnClickOutside } from "usehooks-ts";

const buildLocationsFromSearchBoxOption = (items: TypeAheadItemType[]): [ISearchParamLocations, any] => {
  const locationSearchItems: TypeAheadItemType[] = [];
  const locations: ISearchParamLocations = {
    state: [],
    city: [],
    county: [],
    neighborhood: [],
    school_district: [],
    zip_code: [],
    metro_area: [],
  };

  items.forEach((item: TypeAheadItemType) => {
    locations[item.type as keyof ISearchParamLocations].push(Number(item.id));
    locationSearchItems.push(item);
  });

  return [locations, locationSearchItems];
};

const browseURL = (locations: ISearchParamLocations) => {
  const nameValue: string[] = [];

  Object.entries(locations).forEach(([key, values]) => {
    values.forEach((value: any) => {
      nameValue.push(`${key}=${value}`);
    });
  });

  return "/browse?" + nameValue.join("&");
};

export const SearchBox = () => {
  const [inputText, setInputText] = useState("");
  const debouncedText = useDebounce(inputText, 700);
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [showInputPanel, setShowInputPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
  const { locationSearchItems, setLocationSearchItems } = useLocationSearch();
  const searchApiParams = useSearchApiParams();
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();
  const isPropertyPage = location.pathname.includes("/proformas/");
  const currentUser = useUserSession();
  const isLogged = currentUser.isLogged;
  const [lsSearches] = useLocalStorage<any[]>("searches", [], {
    prefix: "lotside",
  });
  const recentSearches = lsSearches?.filter((lsSearch) => lsSearch.parameters.isRecent === true);
  const { savedSearchFilters: savedSearches } = useSavedSearchList(currentUser?.session?.user_id);

  const currentStateId = locationSearchItems[0]?.state_id || null;

  useEffect(() => {
    if (location.pathname !== "/browse" && locationSearchItems.length > 0) {
      setLocationSearchItems([]);
    }
  }, [location, locationSearchItems.length, setLocationSearchItems]);

  useEffect(() => {
    if (debouncedText.length > 1) {
      setLoading(true);
      typeAheadApi(debouncedText)
        .then((result) => {
          setTypeaheadResult(result);
          const notParcelResults = result.filter((item) => item.type !== "parcel");
          const parcelResults = result.filter((item) => item.type === "parcel");
          setTypeaheadResult([...notParcelResults, ...parcelResults]);
        })
        .finally(() => setLoading(false));
    }
  }, [debouncedText]);

  const firstNonParcelItem = typeaheadResult.find((item) => item.type !== "parcel");
  const firstParcelItem = typeaheadResult.find((item) => item.type === "parcel");

  const resetStatus = (closePanel: boolean = true) => {
    setInputText("");
    setTypeaheadResult([]);
    setSelectedItem(undefined);
    if (closePanel) {
      setShowInputPanel(false);
    }
  };

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus(false);
    }
  };

  const updateLocationsAndNavigation = (items: TypeAheadItemType[]) => {
    const [locations, newLocationSearchItems] = buildLocationsFromSearchBoxOption(items);

    searchApiParams.update({
      locations: locations,
    });

    setLocationSearchItems(newLocationSearchItems);
    navigate(browseURL(locations));
  };

  const selectItem = (item: TypeAheadItemType, add: boolean) => {
    resetStatus();

    if (item.type === "parcel") {
      setLocationSearchItems([]);
      navigate("/proformas/address/" + item.address_for_url?.replace(/ /g, "-") + "?source=lookup");
      return;
    }

    if (locationSearchItems.some((location: TypeAheadItemType) => location.id === item.id)) {
      return; // already added
    }

    const items = [...locationSearchItems, item];
    updateLocationsAndNavigation(items);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        resetStatus();
        break;
      case "ArrowUp":
        e.preventDefault();
        if (typeaheadResult.length > 0 && selectedItem !== undefined && selectedItem > 0) {
          setSelectedItem(selectedItem - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextIdx = selectedItem == null ? 0 : selectedItem + 1;
        if (typeaheadResult.length > 0 && nextIdx < typeaheadResult.length) {
          setSelectedItem(nextIdx);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (selectedItem != null) {
          selectItem(
            typeaheadResult[selectedItem],
            currentStateId === typeaheadResult[selectedItem].state_id,
          );
        }
        break;
    }
  };

  const onRemoveItem = (itemId: any) => {
    resetStatus();
    const newLocationSearchItems = locationSearchItems.filter((i) => i.id !== itemId);
    updateLocationsAndNavigation(newLocationSearchItems);
  };

  return (
    <div className={cn("relative w-full", !isMobile ? "mr-1" : "mr-3", isPropertyPage && "mr-0")}>
      <div
        className={cn("relative w-full pr-2 text-base text-black sm:pr-0")}
        onKeyDown={handleKeyDown}
        ref={ref}
      >
        <div
          className="flex h-10 w-full justify-between rounded-full border bg-gray-50"
          onClick={() => setShowInputPanel(true)}
        >
          <Labels locations={locationSearchItems} compact onRemoveItem={onRemoveItem} />
          <div className="right-0 w-10 flex-none cursor-pointer rounded-r-full bg-gray-50 p-2.5">
            <Icon path={mdiMagnify} className="size-5" />
          </div>
        </div>

        {showInputPanel && (
          <div
            className={cn(
              isMobile
                ? "fixed left-1 top-1 w-[calc(100%-8px)] pb-2"
                : "absolute top-0 w-full min-w-[290px] rounded-xl",
              "z-[10000] items-center border bg-[#eef6fe] pt-2.5",
            )}
          >
            <div className="mx-2">
              <Labels locations={locationSearchItems} onRemoveItem={onRemoveItem} />
            </div>
            <div className={cn("m-4 mt-2", typeaheadResult.length === 0 && isMobile && "mb-2")}>
              <input
                type="text"
                className="h-9 w-full border border-gray-200 focus:border-gray-200"
                value={inputText}
                onChange={onInputTextChange}
                autoComplete="off"
                placeholder={
                  locationSearchItems.length === 0
                    ? isMobile
                      ? ""
                      : "Enter an address, city, region, neighborhood or zip code"
                    : "Enter another location"
                }
                autoFocus
              />
            </div>
            <button
              className="absolute -top-0.5 right-2 ml-1 p-2 font-bold"
              onClick={() => resetStatus(true)}
            >
              {!loading && <Icon path={mdiClose} className="inline size-5 rounded-lg text-gray-500" />}
              {loading && <Icon path={mdiAutorenew} className="mt-1 h-5 w-5 text-blue-600" spin />}
            </button>

            {typeaheadResult.length === 0 && (
              <div
                className={cn(
                  "w-full items-center overflow-y-scroll px-2",
                  isMobile ? "h-[calc(100vh-120px)] border-t-2 px-4" : "h-[calc(100vh-134px)]",
                )}
              >
                <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>
                  Recent Searches
                </div>
                {recentSearches?.slice(0, 3).map((search) => (
                  <div className="p-1.5 pl-3 hover:bg-black hover:text-white" key={search.parameters?.url}>
                    <RecentSearchResultItem search={search} resetStatus={resetStatus} />
                  </div>
                ))}
                <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Saved Searches</div>
                {isLogged ? (
                  <>
                    {savedSearches?.map((search: SaveSearchType) => (
                      <div
                        className="p-1.5 pl-3 hover:bg-black hover:text-white"
                        key={search.parameters?.url}
                      >
                        <SavedSearchResultItem search={search} resetStatus={resetStatus} />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex flex-row p-2 pb-1 pl-3">
                    <span className="text-blue-700">
                      <WithSignIn useChildren>Log in</WithSignIn>
                    </span>
                    <span className="text-[#131618]">&nbsp;to see your saved searches.</span>
                  </div>
                )}
              </div>
            )}
            {typeaheadResult.length > 0 && (
              <div
                className={cn(
                  "size-full items-center overflow-y-scroll px-2",
                  isMobile ? "h-[calc(100vh-120px)] border-t-2 px-4" : "h-[calc(100vh-134px)]",
                )}
              >
                {typeaheadResult.map((item, idx) => (
                  <div key={item.id || item.parcel_id || idx}>
                    {item === firstNonParcelItem && (
                      <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Regions</div>
                    )}
                    {item === firstParcelItem && (
                      <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>
                        Addresses
                      </div>
                    )}
                    <SearchResultItem
                      item={item}
                      onSelection={selectItem}
                      selected={selectedItem === idx}
                      currentStateId={currentStateId}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
