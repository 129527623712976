import { mdiMapOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Link } from "react-router-dom";

type Props = {
  search: any;
  resetStatus: CallableFunction;
};

export const SavedSearchResultItem = ({ search, resetStatus }: Props) => {
  return (
    <Link
      to={search.parameters?.url}
      key={search.name + search.id}
      target="_self"
      onClick={() => resetStatus(true)}
    >
      <div className="flex flex-row items-center">
        <Icon className="mr-2 inline-block size-3.5" path={mdiMapOutline} />
        <span className="truncate">{search.name}</span>
      </div>
    </Link>
  );
};
