import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";


type Props = {
  label: string;
  locationId: string | number | undefined;
  onRemove: CallableFunction;
  compact?: boolean;
  allowRemove?: boolean;
};

export const Label = ({ label, locationId, onRemove, compact, allowRemove }: Props) => {
  return (
    <div
      className={`ml-1 flex h-8 cursor-pointer items-center whitespace-nowrap rounded-full bg-black px-3 text-sm text-white  ${
        compact ? "max-w-[120px]" : "mb-2 sm:max-w-[250px]"
      }`}
    >
      <span className="truncate">{label}</span>
      {locationId && allowRemove && (
        <span onClick={(e) => {
          e.stopPropagation();
          onRemove(locationId);
        }} className="flex items-center">
          <Icon className="ml-1 inline h-4 w-4 rounded-lg text-[#dbf0e9] hover:bg-gray-50 hover:text-black" path={mdiClose} />
        </span>
      )}
    </div>
  );
};