import { PencilAltIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalWrapper } from "components";
import { SubmitButton } from "components/forms";
import { buildProformasAddressUrlNewConstruction } from "helpers";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Select from "react-select";
import { atom } from "recoil";
import { useUserSession } from "state";
import { useCustomStructure, useFinancials, useProforma, useSaveProforma } from "state/proformas";
import {
  buildSalesCompsFilters,
  processSalesComparables,
  useSalesComps,
  useSalesCompsFilters,
} from "state/proformas/comparables";
import * as Yup from "yup";

export const showStructureModalAtom = atom({
  key: "showStructureModalAtom",
  default: false,
});

const numberNullable = Yup.number()
  .nullable(true)
  .transform((val) => (val === Number(val) ? val : null));
const notUsedRightNow = Yup.number().nullable(true).default(null);

// Fields transformation rules
const validationSchema = Yup.object().shape({
  bathrooms: numberNullable,
  bedrooms: numberNullable,
  floors: notUsedRightNow,
  parking_count: numberNullable,
  square_feet_basement_finished: notUsedRightNow,
  square_feet_basement_unfinished: numberNullable,
  square_feet_finished: numberNullable,
  square_feet_garage: numberNullable,
  view_type: Yup.array().of(Yup.string()).default([]),
  year_built: notUsedRightNow,
});

const attributes = [
  { key: "bedrooms", name: "Bedrooms" },
  { key: "bathrooms", name: "Bathrooms" },
  { key: "square_feet_finished", name: "Finished Sqft" },
  { key: "square_feet_basement_unfinished", name: "Unfinished Basement Sqft" },
  { key: "parking_count", name: "Covered Parking" },
  { key: "square_feet_garage", name: "Garage Sqft" },
];

const viewTypeOptions = [
  "Bay",
  "City",
  "Golf Course",
  "Lake",
  "Mountain",
  "Ocean",
  "Park",
  "Partial",
  "River",
  "See Remarks",
  "Ski Area",
  "Skyline",
  "Small Water",
  "Sound",
  "Territorial",
];

type Props = {
  isOpen: boolean;
  closeModal: any;
};

type viewTypeValuesType = {
  value: string;
  label: string;
};

export const StructureModal = ({ isOpen, closeModal }: Props) => {
  const { proforma } = useProforma();
  const currentUser = useUserSession();
  const { updateCalcSheet } = useFinancials();
  const { createCustomStructure, customStructure, setCustomStructure } = useCustomStructure(proforma);
  const { setSalesComparables } = useSalesComps();
  const { setSalesCompFilters } = useSalesCompsFilters();
  const { triggerAutoSaveProforma } = useSaveProforma();
  const currentStructure = { ...proforma.structure };
  currentStructure.parking_count = proforma.parcel.parking_count;
  currentStructure.view_type = proforma.parcel.view_type
    ? proforma.parcel.view_type.map((vt: string) => vt.replace(/[{}]/g, "")).join(", ")
    : "";

  const customStructureDefaults = { ...customStructure };
  delete customStructureDefaults.view_type;
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: customStructureDefaults,
  };
  const { register, getValues, handleSubmit, formState } = useForm(formOptions);
  const { isSubmitting } = formState;

  const [inputValues, SetInputValues] = useState<any>(customStructureDefaults);
  const [viewTypeValues, SetViewTypeValues] = useState<viewTypeValuesType[]>(
    customStructure.view_type?.map((vt: string) => ({ value: vt, label: vt })) || [],
  );
  const [apiResponse, setApiResponse] = useState<any>(null);

  let hasInputData = viewTypeValues && viewTypeValues.length > 0;
  if (!hasInputData) {
    attributes.forEach((attr: any) => {
      if (inputValues[attr.key]) hasInputData = true;
    });
  }

  const handleInputChange = () => {
    const newValues = validationSchema.cast(getValues());
    SetInputValues(newValues);
  };

  const onSubmit = () => {
    const newValues = { ...inputValues };
    newValues.view_type = viewTypeValues.map((vt: any) => vt.value);
    if (newValues.view_type.length === 0) {
      newValues.view_type = null;
    }

    return createCustomStructure(newValues).then((data) => {
      setApiResponse(data);
    });
  };

  const saveCustomStructure = (saveProperty: boolean = true) => {
    setCustomStructure(apiResponse.structure);
    updateCalcSheet({ resale_value: apiResponse.resale_value });
    const salesComps = processSalesComparables(apiResponse.comparables);
    setSalesComparables(salesComps);
    setSalesCompFilters(buildSalesCompsFilters(salesComps, proforma.parcel._characteristics.structure_type));
    saveProperty && triggerAutoSaveProforma();

    ReactGA.event({
      category: "Proforma",
      action: "Edit Structure",
      label: proforma._id.toString(),
    });
  };

  useEffect(
    () => {
      if (currentStructure.view_type && currentStructure.view_type.length > 0 && !customStructure.view_type) {
        const initialViewTypeValues: string[] = Array.from(
          currentStructure.view_type.replace("Mountain(s)", "Mountain").split(", "),
        );
        SetViewTypeValues(initialViewTypeValues.map((vt: string) => ({ value: vt, label: vt })));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (customStructure.view_type && customStructure.view_type?.length > 0) {
      SetViewTypeValues(customStructure.view_type.map((vt: string) => ({ value: vt, label: vt })));
    }
  }, [customStructure.view_type]);
  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block h-full max-h-fit w-full max-w-xl transform overflow-hidden overflow-y-scroll rounded-md px-0 text-left align-middle shadow-xl transition-all">
        <div className="flex max-h-[90%] w-full justify-center">
          <div className="w-full max-w-5xl rounded-md bg-white shadow-lg">
            <div className="flex h-full flex-col">
              <header className="flex w-full flex-row items-center">
                <div className="text-md flex basis-4/5 items-center pl-9 font-medium text-gray-700">
                  <PencilAltIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
                  Edit property structure
                </div>
                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-gray-400 hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <form onSubmit={handleSubmit(onSubmit)}>
                <main className="flex flex-grow flex-col bg-gray-50 p-8 pt-4">
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Existing</th>
                        <th></th>
                        <th>Improved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attributes.map(({ key, name }, idx: number) => (
                        <tr key={key} className="border-b border-gray-200 text-sm">
                          <td className="w-64 pl-1 font-bold">{name}</td>
                          <td className="py-3">
                            <NumberFormat
                              value={currentStructure[key]}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={currentStructure[key] % 1 !== 0 ? 2 : 0}
                            />
                          </td>
                          <td className="w-16 py-3 text-gray-400">
                            {inputValues[key] && (
                              <NumberFormat
                                value={
                                  (100 * (inputValues[key] - currentStructure[key])) / currentStructure[key]
                                }
                                displayType="text"
                                suffix="%"
                                decimalScale={0}
                              />
                            )}
                          </td>
                          <td className="w-16 py-2">
                            <input
                              type="text"
                              className="w-16 rounded-md border border-gray-300 bg-transparent text-right text-sm leading-4 outline-0 focus:border-indigo-300 focus:ring-0 focus:ring-indigo-200 focus:ring-opacity-50"
                              {...register(key, { onChange: handleInputChange })}
                              disabled={isSubmitting || apiResponse !== null}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table className="m-0 table-auto p-0">
                    <tbody>
                      <tr className="align-top">
                        <td className="w-32 pl-1 pt-4 text-sm font-bold">View Type(s)</td>
                        <td className="text-sm">
                          <div className="py-1"></div>
                          <Select
                            isMulti
                            name="view_types"
                            value={viewTypeValues}
                            options={viewTypeOptions.map((vt: any) => ({
                              value: vt,
                              label: vt,
                            }))}
                            className="bg-border-0"
                            classNamePrefix="select"
                            placeholder="Add view..."
                            onChange={(selected: any) => SetViewTypeValues(selected)}
                            isClearable={false}
                            isDisabled={isSubmitting || apiResponse !== null}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </main>

                <footer className="flex flex-col bg-gray-50 pl-[33px] p-8 justify-center text-center font-bold sm:flex-row">
                  {apiResponse === null && (
                    <div className="flex w-full justify-between">
                      <div className="my-5 flex w-fit flex-col text-start font-normal">
                        <div className="w-full">Maximize the Lot</div>
                        <Link
                          to={buildProformasAddressUrlNewConstruction(proforma.parcel.full_address)}
                          target="_blank"
                          className="w-full font-bold text-blue-700"
                        >
                          Rebuild Structure
                        </Link>
                      </div>
                      <div className="w-full sm:w-fit">
                        <SubmitButton
                          buttonClassName="bg-black text-white text-[15px] px-5 hover:bg-black"
                          isSubmitting={isSubmitting}
                          disabled={!hasInputData}
                        >
                          SAVE EDITS
                        </SubmitButton>
                      </div>
                    </div>
                  )}
                  {apiResponse && (
                    <div className="flex flex-col text-md font-medium text-gray-700">
                      <span className="text-sm">
                        Lotside has estimated a new ARV according to your structure changes:
                      </span>
                      <div className="text-md mt-8 font-bold text-black">
                        After Repair Value:
                        <span className="m-4 rounded-md bg-gray-200 p-2">
                          <NumberFormat
                            value={proforma.calcsheet.pellego_arv}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={0}
                          />
                        </span>
                        →
                        <span className="m-4 rounded-md bg-green-400 p-2">
                          <NumberFormat
                            value={apiResponse.resale_value}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={0}
                          />
                        </span>
                      </div>
                      <div className="mt-8 flex w-full flex-col justify-around sm:flex-row">
                        <button
                          className="mb-4 cursor-pointer whitespace-nowrap rounded-sm border border-blue-400 bg-white px-4 py-2 text-center text-sm text-blue-600 shadow-sm hover:bg-blue-400 hover:text-white"
                          type="button"
                          onClick={closeModal}
                        >
                          Keep existing
                        </button>
                        <button
                          className="mb-4 cursor-pointer whitespace-nowrap rounded-sm border border-blue-400 bg-white px-4 py-2 text-center text-sm text-blue-600 shadow-sm hover:bg-blue-400 hover:text-white"
                          type="button"
                          onClick={() => setApiResponse(null)}
                        >
                          Re-edit structure
                        </button>
                        <button
                          className="mb-4 cursor-pointer whitespace-nowrap rounded-sm bg-black px-4 py-2 text-center text-sm text-white shadow-sm hover:bg-black"
                          type="button"
                          onClick={() => {
                            saveCustomStructure(currentUser.isLogged);
                            closeModal();
                          }}
                        >
                          {currentUser.isLogged ? "Save new ARV & Comps" : "Use new ARV & Comps"}
                        </button>
                      </div>
                    </div>
                  )}
                </footer>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
