import { atom, useRecoilState } from "recoil";

type SignInSignUpModalStatusType = "signIn" | "signUp" | "passwordReset" | "nonMLSSignUp" | null;

const showSignInModalSignUpAtom = atom<SignInSignUpModalStatusType>({
  key: "showSignInModalSignUpAtom",
  default: null,
});

export const useSignInSignUpModal = () => {
  const [showSignInSignUpModal, setShowSignInSignUpModal] = useRecoilState(showSignInModalSignUpAtom);

  return {
    openSignInModal: () => setShowSignInSignUpModal("signIn"),
    openSignUpModal: () => setShowSignInSignUpModal("signUp"),
    openNonMLSSignUpModal: () => setShowSignInSignUpModal("nonMLSSignUp"),
    openPasswordResetModal: () => setShowSignInSignUpModal("passwordReset"),
    closeSignInSignUpModal: () => setShowSignInSignUpModal(null),
    showSignInModal: showSignInSignUpModal === "signIn",
    showSignUpModal: showSignInSignUpModal === "signUp",
    showNonMLSSignUpModal: showSignInSignUpModal === "nonMLSSignUp",
    showPasswordResetModal: showSignInSignUpModal === "passwordReset",
    showSignInSignUpModal: showSignInSignUpModal !== null,
  };
};
