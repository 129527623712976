import { useState } from "react";
import { ModalWrapper } from "components";
import { XIcon } from "@heroicons/react/solid";
import { atom, useRecoilState } from "recoil";
import NumberFormat from "react-number-format";
import { useFinancials } from "state/proformas";

export const ShowCustomizeArvModalAtom = atom<boolean>({
  key: "ShowCustomizeArvModalAtom",
  default: false,
});

export const CustomizeArvModal = () => {
  const { setResaleValue } = useFinancials();
  const [showCustomizeArvModal, setShowCustomizeArvModal] = useRecoilState(ShowCustomizeArvModalAtom);
  const [customArvValue, setCustomArvValue] = useState<undefined | number>(undefined);

  const onCustomArvValueChange = (values: any, evInfo: any) => {
    if (evInfo.source === 'event' && (values.floatValue || values.floatValue === 0)) {
      setCustomArvValue(values.floatValue);
    }
  }

  const closeModal = () => {
    setCustomArvValue(undefined);
    setShowCustomizeArvModal(false);
  }

  const closeSaveArv = () => {
    if (customArvValue) {
      setResaleValue(customArvValue);
    }
    closeModal();
  }

  return (
    <ModalWrapper isOpen={!!showCustomizeArvModal} closeModal={closeModal}>
      <div className="inline-block w-full max-w-sm p-2 overflow-hidden text-left align-middle transition-all transform bg-white border border-gray-200 shadow-xl rounded-lg">
        <button className="float-right outline-none" onClick={closeModal}>
          <XIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
        </button>

        <div className="mt-4 p-3">
          <div className="w-full text-center font-medium text-xl mb-4">
            Customize ARV
          </div>

          <div className="w-full flex text-sm mb-4">
            <div className="w-1/2">
              Enter Custom ARV:
            </div>
            <div className="w-1/2">
              <NumberFormat
                className="w-full h-5 p-3 text-right bg-[#F6EDC6] border outline-0 border-[#E4C442] focus:ring-0 placeholder:text-gray-400 focus:border-blue-400"
                value={customArvValue}
                thousandSeparator={true}
                decimalScale={0}
                prefix="$ "
                onValueChange={onCustomArvValueChange}
              />
            </div>
          </div>
        </div>
        <hr className="mb-2" />
        <div className="text-center">
          <button className=" hover:text-green-700 text-gray-400 py-1 px-2 m-1"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="hidden sm:inline-flex items-center sm:h-10 px-4 sm:px-8 py-2 font-normal text-sm  rounded-sm text-white bg-[#61a28d] hover:bg-[#5bbc9c] shadow-sm transition ease-in-out duration-150"
            onClick={closeSaveArv}
          >
            Save
          </button>
        </div>

      </div>
    </ModalWrapper>
  );
}