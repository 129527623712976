import { useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

const visibleSectionAtom = atom({
  key: "visibleSection",
  default: {
    photos: 0,
    remarks: 0,
    comps: 0,
    details: 0,
    financials: 0,
  },
});

export const useVisibleSection = () => {
  const visibleSection = useRecoilValue(visibleSectionAtom);

  let max = -1, active = "";

  Object.entries(visibleSection).forEach(([key, val]) => {
    if (val > max) {
      max = val;
      active = key === "photos" || key === "remarks" ? "overview" : key;
    }
  });

  return active;
}

export const useSetVisibleSection = (element: any, sectionName: string) => {
  const setVisibleSection = useSetRecoilState(visibleSectionAtom);

  useEffect(() => {
    const currentElement = element?.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisibleSection((prev) => {
          return { ...prev, [sectionName]: entry.intersectionRatio };
        });
      }, { threshold: [0, 0.3, 0.6, 0.9, 1] }
    );

    currentElement && observer.observe(currentElement);

    return () => observer.unobserve(currentElement);

  }, [element, sectionName, setVisibleSection]);

};