import { WithSignIn } from "components";
import { AllFiltersSavedSearch, getNewUrlParamsStrings } from "components/browse";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  locationSearchItemsAtom,
  searchResultsFiltersAtom,
} from "state/browse";
import { SaveSearchType, useSavedSearchList } from "state/proformas";

export const AllFiltersSavedSearches = () => {
  const filters = useRecoilValue(searchResultsFiltersAtom);
  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const currentUser = useUserSession();
  const { loading, savedSearchFilters, deleteSavedSearch, updateSavedSearch } = useSavedSearchList(
    currentUser?.session?.user_id,
  );

  const buildSavedFiltesParameters = () => {
    return getNewUrlParamsStrings(locationSearchItems, filters);
  };

  return (
    <div className="w-full max-w-md leading-7 pb-6">
      <WithSignIn asButton={false} text="Login to manage your saved searches">
        <>
          <div className="pb-2 font-bold">Saved Searches</div>
          {loading && "Loading..."}
          <ul className="w-full h-fit max-h-64 overflow-y-auto overflow-x-hidden">
            {savedSearchFilters?.length === 0 && (
              <li className="text-xs italic">You don’t have any saved searches</li>
            )}

            {!loading &&
              savedSearchFilters
                .filter((search: SaveSearchType) => !search.parameters.isRecent)
                .map((filter: SaveSearchType) => (
                  <AllFiltersSavedSearch
                    key={filter.id}
                    filter={filter}
                    onDeleteSavedSearch={deleteSavedSearch}
                    onUpdateSavedSearch={updateSavedSearch}
                    buildSavedFiltesParameters={buildSavedFiltesParameters}
                  />
                ))}
          </ul>
        </>
      </WithSignIn>
    </div>
  );
};
