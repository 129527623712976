
import React from "react";
import { XIcon } from '@heroicons/react/solid'
import { ModalWrapper, WithSignIn } from "components";
import { ParcelHistoryEntryType } from "state/proformas";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { formatDateShort } from "helpers";
import NumberFormat from "react-number-format";

type Props = {
  history: ParcelHistoryEntryType;
  isOpen: boolean;
  closeModal: any;
};

export const SaleHistoryPhotosModal = ({ history, isOpen, closeModal }: Props) => {

  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block w-full h-full">

        <div className="inline-block w-full h-full px-0 max-w-5xl text-left align-middle transition-all transform rounded-md">

          <div className="flex justify-center w-full overflow-hidden" >

            <div className="w-full max-w-5xl rounded-md shadow-lg h-full bg-gray-50">
              <div className="flex flex-row w-full align-top sm:align-middle bg-white items-center border-b border-gray-200">
                <div className="w-full sm:basis-1/2 sm:p-4 text-md font-medium ml-4 flex-col sm:flex-row flex items-center">
                  <div className="w-full py-0">
                    Historical Listing
                  </div>
                </div>
                <div className="w-full basis-1/2 sm:px-4 py-2 sm:py-4 justify-right text-right">
                  <button
                    type="button"
                    className="ml-2 mr-4 pl-1 pb-1 text-sm font-medium text-gray-400 rounded-md hover:bg-gray-400 hover:text-white hover:border-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}>
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="flex flex-col my-0">

                <div className="flex flex-col sm:flex-row w-full px-0 pb-4">
                  <div className="relative overflow-auto w-screen">
                    <div className="relative w-full flex gap-4 snap-mandatory overflow-x-auto styled-scrollbars">
                      {history.photos?.map((photo: any) => (
                        <div key={photo._id} className="snap-normal snap-center shrink-0 first:pl-4 last:pr-8 min-w-[320px]">
                          <LazyLoadImage
                            className="shrink-0 w-100 h-60 sm:w-160 sm:h-96 object-cover rounded-xs shadow-xl bg-white"
                            src={photo.photo_url}
                            alt="Property Img"
                          />
                        </div>
                      ))}
                    </div>
                  </div >
                </div>

                <div className="flex flex-col sm:flex-row w-full px-4 sm:px-8 pb-8 gap-8 mt-2">
                  <div className="basis-1/1 sm:basis-1/3 w-full text-sm px-4">

                    <div className="font-bold text-xs uppercase flex items-center text-green-900 pb-2">Listing Details</div>

                    <div className="font-medium text-lg md:text-lg"><NumberFormat value={history.price} displayType="text" thousandSeparator={true} prefix="$ " decimalScale={0} /></div>

                    <div className="pb-4 border-b mb-4">{history.event} {formatDateShort(history.date)}</div>

                    <table className="text-xs align-text-top">
                      <tbody>
                        {history.sale_type && (
                          <tr>
                            <td className="align-text-top"><strong>Sale Type:</strong></td>
                            <td className="align-text-top">{history.sale_type}</td>
                          </tr>
                        )}
                        {history.sale_deed && (
                          <tr>
                            <td className="align-text-top"><strong>Sale Deed:</strong></td>
                            <td className="align-text-top">{history.sale_deed}</td>
                          </tr>
                        )}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                        </tr>

                        {history.listing_agent && (
                          <tr>
                            <td className="align-text-top"><strong>Listing Agent: &nbsp;</strong></td>
                            <td className="align-text-top">{history.listing_agent}</td>
                          </tr>
                        )}
                        {history.listing_company && (
                          <tr>
                            <td className="align-text-top"><strong>Listing Company: &nbsp;</strong></td>
                            <td className="align-text-top">{history.listing_company}</td>
                          </tr>
                        )}

                        {history.selling_agent && (
                          <tr>
                            <td className="align-text-top"><strong>Selling Agent: &nbsp;</strong></td>
                            <td className="align-text-top">{history.selling_agent}</td>
                          </tr>
                        )}

                        {history.selling_company && (
                          <tr>
                            <td className="align-text-top"><strong>Selling Company: &nbsp;</strong></td>
                            <td className="align-text-top">{history.selling_company}</td>
                          </tr>
                        )}

                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                        </tr>

                        {history.buyer && (
                          <tr>
                            <td className="align-text-top"><strong>Buyer{history.buyer2 && " 1"}:</strong></td>
                            <td className="align-text-top">{history.buyer}</td>
                          </tr>
                        )}

                        {history.buyer2 && (
                          <tr>
                            <td className="align-text-top"><strong>Buyer 2:</strong></td>
                            <td className="align-text-top">{history.buyer2}</td>
                          </tr>
                        )}

                        {history.seller && (
                          <tr>
                            <td className="align-text-top"><strong>Seller{history.seller2 && " 1"}:</strong></td>
                            <td className="align-text-top">{history.seller}</td>
                          </tr>
                        )}

                        {history.seller2 && (
                          <tr>
                            <td className="align-text-top"><strong>Seller 2:</strong></td>
                            <td className="align-text-top">{history.seller2}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                  </div>

                  <div className="basis-1/1 sm:basis-2/3 w-full text-sm">

                    {history.listing_remarks && (
                      <div>
                        <div className="font-bold text-xs uppercase flex items-center text-green-900">
                          Historical Listing remarks
                        </div>
                        <div className="mt-2 text-sm font-light">
                          {history.listing_remarks}
                        </div>
                      </div>
                    )}

                    {history.agent_listing_remarks && (
                      <>
                        <div className="pt-4 font-bold text-xs uppercase flex items-center text-green-900 mb-2">
                          Historical Agent Listing Remarks
                        </div>
                        <WithSignIn asAgent={true} text="Sign in with an agent account to view this information">
                          <div>
                            <div className="font-light text-sm">
                              {history.agent_listing_remarks}
                            </div>
                          </div>
                        </WithSignIn>
                      </>
                    )}

                    {history.listing_events && history.listing_events.length > 0 && history.listing_events[0].event && (
                      <div className="mt-4">
                        <div className="font-bold text-xs text-green-900 uppercase py-1">
                          Sale History
                        </div>
                        {history.listing_events.map((event: any, idx) => (
                          <React.Fragment key={idx}>
                            {event.event && (
                              <div className='flex w-full py-2 border-b border-gray-200 text-sm whitespace-nowrap'>
                                <div className="basis-1/3 sm:basis-1/3 font-medium flex align-middle items-center">
                                  {event.event === "PriceDate" ? "Price Change" : event.event}
                                </div>
                                <div className="basis-1/3 sm:basis-1/3 text-right">
                                  {formatDateShort(event.date)}
                                </div>
                                <div className="hidden sm:block sm:basis-1/3 text-right">
                                  {event.price && (
                                    <NumberFormat value={event.price} displayType="text" thousandSeparator={true} prefix="$ " decimalScale={0} />
                                  )}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </ModalWrapper >
  );
}