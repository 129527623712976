import { atom, useRecoilState } from "recoil";
import { SalesComparableType } from "state/proformas/comparables";

export const listingStatuses = ['Sold', 'Pending', 'Active'];
export const saleTypes = ['Standard', 'Bank Owned', 'Short Sale'];
export const structureTypes = ['House', 'Manufactured Home', 'Condominium', 'Townhouse', 'Multifamily'];

export type salesCompsFiltersType = {
  listing_statuses: string[];
  sale_types: string[];
  structure_types: string[];
  flip: boolean;
  only_included: boolean;
  only_sytyle: boolean;
  min_bedrooms: number;
  max_bedrooms: number;
  min_bathrooms: number;
  max_bathrooms: number;
  min_square_feet_finished: number;
  max_square_feet_finished: number;
  min_lot_square_feet: number;
  max_lot_square_feet: number;
  min_parking_count: number;
  max_parking_count: number;
  min_year_built: number;
  max_year_built: number;
  min_sale_price: number;
  max_sale_price: number;
  min_price_per_square_foot: number;
  max_price_per_square_foot: number;
  distance: number;
  months: number;
};

export const compFiltersMinMaxAttrs = [
  { label: "Bedrooms", key: "bedrooms", section: "structure" },
  { label: "Bathrooms", key: "bathrooms", section: "structure" },
  { label: "Finished Sqft", key: "square_feet_finished", section: "structure" },
  { label: "Lot Size Sqft", key: "lot_square_feet", section: "parcel" },
  { label: "Parking", key: "parking_count", section: "parcel" },
  { label: "Year Built", key: "year_built", section: "structure" },
  { label: "Sale Price", key: "sale_price", section: "sale" },
  { label: "Price per Sqft", key: "price_per_square_foot", section: "sale" },
];

export const buildSalesCompsFilters = (comparables: SalesComparableType[], subjectStructureType: string) => {
  const filters: any = {
    listing_statuses: [...listingStatuses],
    sale_types: ["Standard"],
    structure_types: structureTypes.filter((st) => (st === subjectStructureType)),
    flip: false,
    only_included: false,
    only_sytyle: false,
    distance: 0,
    months: 0,
  };

  const today = (new Date()).getTime();

  comparables.forEach((comp) => {
    compFiltersMinMaxAttrs.forEach((attr) => {
      if (attr.key !== "parking_count") { // parking_count is always empty in the old app
        const compValue = comp.parcel._characteristics[attr.key];
        const minKey = `min_${attr.key}`;
        if (filters[minKey] === undefined || filters[minKey] > compValue) {
          filters[minKey] = compValue;
        }
        const maxKey = `max_${attr.key}`;
        if (filters[maxKey] === undefined || filters[maxKey] < compValue) {
          filters[maxKey] = compValue;
        }
      }
    });

    if (filters.distance < comp.distance) {
      // Pad the distance to defeat server-side rounding of included comps
      const distance = Math.floor((comp.distance + 0.01) * 100) / 100;
      filters.distance = distance;
    }

    const date = new Date(comp.parcel._characteristics.last_date);
    const months = Math.ceil((today - date.getTime()) / (30 * 24 * 3600 * 1000));
    if (filters.months < months) {
      filters.months = months;
    }
  });

  if (filters.months > 12) filters.months = 12;

  return filters;
};

export const salesCompsFiltersAtom = atom<salesCompsFiltersType | {}>({
  key: "salesCompsFiltersAtom",
  default: {},
});

export const salesCompsFiltersBackupAtom = atom<salesCompsFiltersType | {}>({
  key: "salesCompsFiltersBackupAtom",
  default: {},
});

export const useSalesCompsFilters = () => {
  const [salesCompFilters, setSalesCompFilters] = useRecoilState(salesCompsFiltersAtom);
  const [salesCompFiltersBackup, setSalesCompFiltersBackup] = useRecoilState(salesCompsFiltersBackupAtom);

  return {
    salesCompFilters,
    setSalesCompFilters,
    salesCompFiltersBackup,
    setSalesCompFiltersBackup,
  }

}
