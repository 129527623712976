import { LoanDetails } from "components/proformas/financials/fliptab/";
import { isMobile } from "react-device-detect";
import { useFinancials } from "state/proformas";

type Props = {
  handleCarryingTimeClick: CallableFunction;
};

export const FlipCashIn = ({ handleCarryingTimeClick }: Props) => {
  const { financials } = useFinancials();

  const loans = financials?.calcsheet.loans.filter(
    (l) => l._type === "carry" && l.name.includes("hardmoney"),
  );

  return (
    <div>
      {!isMobile && (
        <div className="text-md flex justify-items-stretch gap-2 leading-9">
          <div className="w-full whitespace-nowrap font-medium">Interest-Only Financing</div>
        </div>
      )}
      {loans?.map((loan) => (
        <LoanDetails key={loan.name} loan={loan} handleCarryingTimeClick={handleCarryingTimeClick} />
      ))}
    </div>
  );
};
