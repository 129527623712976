import { getRelativeDate } from "helpers";
import { Chart } from "react-google-charts";
import {
  CalcSheetType,
  RentalCompAppreciationType,
  useFinancials,
  useProforma,
  useRentalCompAppreciation,
} from "state/proformas";
import { RentalComparableType } from "state/proformas/comparables";

class RentalCompsGraphClass {
  validGraph: boolean = false;
  comparables: RentalComparableType[];
  proforma: any;
  calcSheet: CalcSheetType | null;
  options: any;
  data: any[] = [];
  minRpsqft = 0;
  maxRpsqft = 0;
  numDataPoints = 0;
  formatters: any;

  constructor(
    data: RentalCompAppreciationType | undefined,
    comparables: RentalComparableType[],
    proforma: any,
    calcSheet: CalcSheetType | null,
  ) {
    this.comparables = comparables;
    this.proforma = proforma;
    this.calcSheet = calcSheet;
    if (!data) return;
    const sqFtPrices = data.appreciation || [];
    this.numDataPoints = sqFtPrices.length;

    this.minRpsqft = Math.min.apply(null, sqFtPrices);
    this.maxRpsqft = Math.max.apply(null, sqFtPrices);

    const rows = this.buildDataMatrix(sqFtPrices);
    const columns = this.buildGraph(
      this.proforma.parcel.zip_code.toString(),
      rows[0][0],
      rows[rows.length - 1][0],
    );
    this.data = [columns, ...rows];
  }

  addMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  buildDataMatrix(prices: number[]) {
    const matrix: any[][] = [];
    const sqFtPrices = [...prices];
    sqFtPrices.reverse();

    sqFtPrices.forEach((price, i) => {
      const point = [
        this.addMonths(new Date(), -i),
        Math.round(price * 100) / 100,
        "$" + Math.round(price * 100) / 100,
        null,
        null,
        null,
        null,
      ];
      matrix.push(point);
    });

    this.comparables.forEach((comp, i) => {
      const rpsqft =
        Math.round((comp.monthly_rent / comp.parcel._characteristics.square_feet_finished) * 100) / 100;
      const date = new Date(comp.lease_lease_date);
      const point = [
        date,
        null,
        null,
        rpsqft,
        "$" + rpsqft,
        String.fromCharCode("A".charCodeAt(0) + i),
        "point {fill-color: #70a08e; }",
      ];
      matrix.push(point);

      if (rpsqft < this.minRpsqft) {
        this.minRpsqft = rpsqft;
      }
      if (rpsqft > this.maxRpsqft) {
        this.maxRpsqft = rpsqft;
      }
    });

    const hold_rpsqft =
      Math.round(
        ((this.calcSheet?.monthly_rent_hold || 0) /
          this.proforma.parcel._characteristics.square_feet_finished) *
          100,
      ) / 100;

    matrix.push([
      this.addMonths(new Date(), 0),
      null,
      null,
      hold_rpsqft,
      "$" + hold_rpsqft,
      "Subject",
      "point {fill-color: #FFA500; }",
    ]);

    // Sort by date ascending
    matrix.sort((a, b) => {
      if (a[0].getTime() > b[0].getTime()) return 1;
      if (a[0].getTime() < b[0].getTime()) return -1;
      return 0;
    });

    // Set date/price in Comps data in order to smooth the graph
    let val: any = null,
      valStr: any = null,
      valDate: Date | null;
    matrix.forEach((row) => {
      if (row[1] !== null) {
        valDate = row[0];
        val = row[1];
        valStr = row[2];
      } else {
        if (valDate != null) row[0] = valDate;
        row[1] = val;
        row[2] = valStr;
      }
    });

    return matrix;
  }

  buildGraph(zipCode: string, firstDate: Date, lastDate: Date) {
    const max = this.maxRpsqft + 0.05;
    const min = 0;
    const xmin = getRelativeDate(-20, firstDate);
    const xmax = getRelativeDate(+20, lastDate);

    const ticks = [];
    const mult = 0.25;
    for (let i = 0; i <= Math.ceil((max - min) / mult); i++) {
      if (i !== 0) {
        ticks.push({ v: min + i * mult, f: ("$" + min + i * mult).replace(/\b0+/g, "") });
      }
    }

    this.options = {
      height: 250,
      chartArea: { left: "0", top: "5", right: "5", bottom: "60", width: "100%", height: "100%" },
      curveType: "function",
      focusTarget: "category",
      backgroundColor: "transparent",
      hAxis: {
        format: "MMM YY",
        viewWindow: {
          min: xmin,
          max: xmax,
          mode: "maximized",
        },
        gridlines: { color: "#eee" },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
      },
      legend: {
        position: "bottom",
        alignment: "center",
        textStyle: { color: "#858585", fontName: "Roboto", fontSize: "12" },
      },
      series: {
        0: { color: "#0E6BA0", type: "line", pointSize: "1" },
        1: { color: "#70a08e" },
      },
      seriesType: "scatter",
      vAxis: {
        textPosition: "in",
        ticks: ticks,
        viewWindow: {
          min: this.minRpsqft - 0.1,
          max: this.maxRpsqft + 0.1,
          mode: "maximized",
        },
        gridlines: { color: "#ebebeb", count: 4 },
        textStyle: { color: "#a7a7a7", fontSize: "12" },
      },
    };

    const columns = [
      { type: "date", label: "Month" },
      { type: "number", label: `Median Rent / SqFt in ${zipCode}` },
      { type: "string", role: "tooltip" },
      { type: "number", label: "Rent / Sqft for Comp" },
      { type: "string", role: "tooltip" },
      { type: "string", role: "annotation" },
      { type: "string", role: "style" },
    ];

    this.validGraph = true;

    return columns;
  }
}

type Props = {
  comparables: RentalComparableType[];
};

export const RentalCompGraph = ({ comparables }: Props) => {
  const { proforma } = useProforma();
  const { financials } = useFinancials();
  const { data, loading, error } = useRentalCompAppreciation(proforma.parcel_id);

  if (loading || error) return null;
  if (!data) return null;
  const rcChart = new RentalCompsGraphClass(data, comparables, proforma, financials?.calcsheet || null);

  if (!rcChart.validGraph) return null;

  let structureTypePlural = "Rental Properties ";
  let graphTitle;
  if (data && data.geo_attribute_for_appreciation === "neighborhood_id" && proforma.parcel.neighborhood) {
    graphTitle = structureTypePlural + " in " + proforma.parcel.neighborhood;
  } else if (data && data.geo_attribute_for_appreciation === "zip_code" && proforma.parcel.zip_code) {
    graphTitle = structureTypePlural + " in " + proforma.parcel.zip_code.toString();
  } else if (data && data.geo_attribute_for_appreciation === "city_id" && proforma.parcel.city) {
    graphTitle = structureTypePlural + " in " + proforma.parcel.city;
  } else {
    graphTitle = structureTypePlural + " in " + proforma.parcel.county + " County";
  }

  return (
    <div className="mb-4 w-full rounded-lg border border-stone-100 bg-stone-50 p-3 pb-1">
      <div className="pb-0 text-sm font-bold text-gray-900">{graphTitle}</div>
      <div className="py-1 text-sm font-light text-gray-700">Rent per Square Foot</div>
      <div className="pt-0">
        <Chart
          chartType="ComboChart"
          data={rcChart.data}
          options={rcChart.options}
          formatters={rcChart.formatters}
        />
      </div>
    </div>
  );
};
