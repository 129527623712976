import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { useEffect } from "react";
import { useRecoilValueLoadable, selectorFamily, atom, useRecoilState, useSetRecoilState } from "recoil";

type ParcelId = number;
type CalcSheetId = number;
export type SavedParcelIdsType = Record<ParcelId, CalcSheetId>;

export const savedDealsByParcelIdAtom = atom<SavedParcelIdsType>({
  key: "savedDealsByParcelIdAtom",
  default: {},
});


export const useSaveDeal = () => {
  const [savedDealsByParcelId, setSavedDealsByParcelId] = useRecoilState(savedDealsByParcelIdAtom);

  const isSavedDeal = (parcelId: number): boolean => {
    return !!savedDealsByParcelId[parcelId];
  }

  const saveDeal = async (proformaId: number, parcelId: number) => {
    const response = await fetcherWithCredentials(
      appConfig.apiEndpoint.proformaCopy.replace(':proforma_id', proformaId.toString()), "PUT"
    );
    if (typeof (response) === 'number') {
      setSavedDealsByParcelId({
        ...savedDealsByParcelId,
        [parcelId]: response,
      });
    }
  }

  const deleteSavedDeal = async (parcelId: number) => {
    const proformaId = savedDealsByParcelId[parcelId];
    await fetch(
      appConfig.apiEndpoint.proforma.replace(':proforma_id', proformaId.toString()),
      { method: "DELETE", credentials: "include" },
    ).then((response) => {
      if (response.ok) {
        const newSavedParcelIds = { ...savedDealsByParcelId };
        delete newSavedParcelIds[parcelId];
        setSavedDealsByParcelId(newSavedParcelIds);
      }
    });
  }

  return {
    isSavedDeal,
    saveDeal,
    deleteSavedDeal,
  }
}


const savedDealsAtom = selectorFamily({
  key: 'savedDealsAtom',
  get: (isUserLogged: boolean | undefined) => async () => {
    if (!isUserLogged) return {};
    return await fetcherWithCredentials(appConfig.apiEndpoint.proformaAll + "?parcel_ids=true")
  },

});

// useSavedDeals: if there is an active user sessions then it loads the current user's
// saved deals and stores them in the savedDealsByParcelIdAtom atom

export const useSavedDeals = (isUserLogged: boolean | undefined): void => {
  const loadable = useRecoilValueLoadable(savedDealsAtom(isUserLogged));
  const setSavedDealsByParcelId = useSetRecoilState(savedDealsByParcelIdAtom);

  useEffect(() => {
    if (loadable.state === "hasValue") {
      setSavedDealsByParcelId(loadable.contents);
    } else {
      setSavedDealsByParcelId({});
    }
  }, [loadable, setSavedDealsByParcelId]);

};
