import { useLayoutEffect, useRef, useState } from "react";

export const useWindowWidth = (widthChangePixels: number) => {
  const [width, setWidth] = useState(window.innerWidth);
  const widhtRef = useRef(width);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (Math.abs(window.innerWidth - widhtRef.current) > widthChangePixels) {
        setWidth(window.innerWidth);
        widhtRef.current = window.innerWidth;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [widthChangePixels]);
  return width;
};
