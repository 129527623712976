import { appConfig } from "config";
import { MultiPolygon } from "geojson";


const fetchPolygon = async (type: string, id: number): Promise<MultiPolygon> => {
  return fetch(
    appConfig.apiEndpoint.locationPolygon.replace(":type", type).replace(":id", id.toString()),
  ).then((res) => res.json());
};


export const fetchPolygons = (locations: any[]) => {
  return Promise.all(
    locations.map((location) => fetchPolygon(location.type, location.id))
  );
}