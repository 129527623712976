import { prettifyParcel } from "helpers";
import { atom, useRecoilState } from "recoil";
import { ComparableBaseType, rentalCompsFiltersAtom } from "state/proformas/comparables";


export type RentalComparableType = ComparableBaseType & {
  lease_lease_date: string;
  lease_type: string;
  listing_id: string;
  listing_type: string;
  monthly_rent: number;
  photos: any[];
  projected_rent: number;
  rental_cost_diffs: any;
  score: number;
};

export const rentalComparablesAtom = atom<RentalComparableType[]>({
  key: "rentalComparablesAtom",
  default: [],
});

const statusToRank: Record<string, number> = {
  suggested: 5,
  included: 4,
  excluded: 1,
};

const sortRentalComparables = (comparables: RentalComparableType[]) => {
  const sortedComps = [...comparables].sort((a, b) => {
    if (a.status === b.status) return a.score < b.score ? 1 : a.score >= b.score ? -1 : 0;
    const status_a = statusToRank[a.status] || 3;
    const status_b = statusToRank[b.status] || 3;
    return status_a < status_b ? 1 : status_a >= status_b ? -1 : 0;
  });
  return sortedComps;
};

export const processRentalComparables = (comparables: RentalComparableType[]) => {
  const sortedComps = sortRentalComparables(comparables);

  const prettifiedComps = sortedComps.map((comp, idx) => {
    return {
      ...comp,
      parcel: prettifyParcel(comp.parcel),
      _idx: idx,
      is_included: comp.status === "suggested" || comp.status === "included",
    };
  });

  return prettifiedComps;
};

export const useRentalComps = () => {
  const [rentalComparables, setRentalComparables] = useRecoilState(rentalComparablesAtom);
  const [rentalCompsFilters, setRentalCompsFilters] = useRecoilState(rentalCompsFiltersAtom);

  return {
    rentalComparables,
    setRentalComparables,
    rentalCompsFilters,
    setRentalCompsFilters,
  };
};