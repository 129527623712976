import { isEqual } from "lodash";

type urlEndLocationsType = {
    state: any[];
    county: any[];
    city: any[];
    neighborhood: any[];
    school_district: any[];
    zip_code: any[];
    metro_area: any[];
    geoId: any | null
  }

export const getUrlLocations = (url: string) => {

  let urlEnd = url
  if (url.includes("?")) {
    urlEnd = urlEnd.split("?")[1];
  }

  const urlParams = new URLSearchParams(urlEnd);
  const urlEndLocations: urlEndLocationsType = {
    state: [],
    city: [],
    county: [],
    neighborhood: [],
    school_district: [],
    zip_code: [],
    metro_area: [],
    geoId: null,
  };

  ["state", "county", "city", "zip_code", "neighborhood", "metro_area", "geoId"].forEach((locationType) => {
    const paramIdValues = urlParams.getAll(locationType);
    if (paramIdValues.length > 0) {
      urlEndLocations[locationType as keyof urlEndLocationsType] = paramIdValues.map((id) => parseInt(id)).sort((a, b) => a - b);
    }
  });

  return urlEndLocations;
}

const urlWithoutLocations = (url: string) => {
  const urlWithoutLocations = url.replace(/state=[0-9,]*&?/g, "").replace(/county=[0-9,]*&?/g, "").replace(/city=[0-9,]*&?/g, "").replace(/neighborhood=[0-9,]*&?/g, "").replace(/school_district=[0-9,]*&?/g, "").replace(/zip_code=[0-9,]*&?/g, "").replace(/metro_area=[0-9,]*&?/g, "").replace(/geoId=[0-9,]*&?/g, "").replace(/&$/, "")

  return urlWithoutLocations;
}

export const compareUrlLocations = (url1: string, url2: string): boolean => {

  const url1Locations = getUrlLocations(url1);
  const url2Locations = getUrlLocations(url2);

  if (url1.includes("/browse") && url2.includes("/browse")) {
    if (isEqual(url1Locations, url2Locations)) {
      return true;
    }
  } else {
    if (url1 === url2) {
      return true;
    }
  }

  return false;
}

export const compareUrls = (url1: string, url2: string): boolean => {
  if (url1 === url2) {
    return true;
  }

  const url1Locations = getUrlLocations(url1);
  const url2Locations = getUrlLocations(url2);

  const url1End = url1.includes("?") ? urlWithoutLocations(url1).split("?")[1] : ""
  const url2End = url2.includes("?") ? urlWithoutLocations(url2).split("?")[1] : ""

  if (isEqual(url1Locations, url2Locations) && (url1End === url2End)) {
    return true;
  }

  return false;
}

