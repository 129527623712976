import { Spinner, WithSignIn } from "components";
import { FiltersSavedSearch } from "components/browse";
import { stateIdToStateData } from "config";
import { formatCurrency } from "helpers";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  filtersList,
  filtersTexts,
  IFilters,
  listingTypeOptions,
  locationSearchItemsAtom,
  searchResultsFiltersAtom,
  structureTypeOptions,
} from "state/browse";
import { NewSaveSearchType, SaveSearchType, useSavedSearchList } from "state/proformas";

export const getNewUrlParamsStrings = (locations: any[], filters: IFilters): string[] => {
  //create textual params to save, for chips & email

  const result: string[] = [];

  locations.forEach((location: any, idx: number) => {
    if (idx === 0 && location.state_id) {
      result.push(stateIdToStateData[location.state_id as keyof typeof stateIdToStateData].name);
    }
    if (location.label) {
      result.push(location.label);
    }
  });

  filtersList.forEach((flItem) => {
    const filterName = flItem.name;
    if (
      filters[filterName] !== null &&
      typeof filters[filterName] !== "undefined" &&
      (filters[filterName] === 0 || filters[filterName] !== "") &&
      filtersTexts[filterName]
    ) {
      if (Array.isArray(filters[filterName])) {
        (filters[filterName] as string[]).forEach((value) => {
          const item = filtersTexts[filterName];
          //just filter selection if it does NOT match the defaults
          switch (filterName) {
            case "listing_type":
              if (!isEqual(filters.listing_type, listingTypeOptions)) {
                result.push(item.replace(":value", value));
              }
              break;
            case "structure_type":
              if (
                !isEqual(
                  filters.structure_type,
                  structureTypeOptions.map((st) => st.value),
                )
              ) {
                result.push(item.replace(":value", value));
              }
              break;
          }
        });
      } else {
        let item = filtersTexts[filterName];
        let value = filters[filterName];
        // Currency
        if (item.includes(" $")) {
          value = formatCurrency(value as number, 0);
          result.push(item.replace("$:value", value));
        } else {
          result.push(item.replace(":value", value as string));
        }
      }
    }
  });
  return result;
};

export const FiltersSavedSearches = () => {
  const filters = useRecoilValue(searchResultsFiltersAtom);
  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const location = useLocation();
  const isHome = location.pathname === "/";
  const currentUser = useUserSession();
  const [saving, setSaving] = useState<boolean>(false);
  const [newName, setNewName] = useState<string | null>(null);
  const { loading, savedSearchFilters, deleteSavedSearch, updateSavedSearch, createSavedSearch } =
    useSavedSearchList(currentUser?.session?.user_id);

  const buildSavedFiltesParameters = () => {
    return getNewUrlParamsStrings(locationSearchItems, filters);
  };

  const onCreateNewSavedSearch = () => {
    const name = (newName || "").trim();
    if (name.length === 0) return;

    const newFilter: NewSaveSearchType = {
      name: name,
      active: false,
      parameters: {
        texts: buildSavedFiltesParameters(),
        url: location.pathname + location.search,
      },
    };

    setSaving(true);

    createSavedSearch(newFilter).finally(() => {
      setNewName(null);
      setSaving(false);
    });

    ReactGA.event({
      category: "Search",
      action: "Save from Browse",
      label: name,
    });
  };

  return (
    <div className="w-full max-w-md">
      <div className="w-full p-2 leading-7">
        {!isHome && <div className="mb-4 text-sm font-bold">Save Your Search</div>}
        <WithSignIn asButton={false} text="Login to manage your saved searches">
          <>
            {!isHome && (
              <div className="mt-2 flex flex-row">
                <input
                  type="text"
                  className="w-full border border-black text-sm"
                  placeholder="Name your search"
                  value={newName || ""}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <button
                  className="rounded-xs whitespace-nowrap bg-black px-4 py-2 text-sm text-white hover:bg-black"
                  onClick={() => {
                    onCreateNewSavedSearch();
                  }}
                  disabled={!(newName || "").trim()}
                >
                  {saving ? <Spinner className="h-5 w-5 text-white" /> : <>Save</>}
                </button>
              </div>
            )}

            {!isHome && <div className="mt-6 border-t pt-4 font-bold">Saved Searches</div>}
            {loading && "Loading..."}
            <ul className="w-full h-[calc(100vh-320px)] overflow-y-auto">
              {savedSearchFilters?.length === 0 && (
                <li className="text-xs">You don't have any saved search yet</li>
              )}

              {!loading &&
                savedSearchFilters
                  .filter((search: SaveSearchType) => !search.parameters.isRecent)
                  .map((filter: SaveSearchType) => (
                    <FiltersSavedSearch
                      key={filter.id}
                      filter={filter}
                      onDeleteSavedSearch={deleteSavedSearch}
                      onUpdateSavedSearch={updateSavedSearch}
                      buildSavedFiltesParameters={buildSavedFiltesParameters}
                    />
                  ))}
            </ul>
          </>
        </WithSignIn>
      </div>
    </div>
  );
};
