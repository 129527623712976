import {
  mapBoundsAtom,
  mapDrawnPolygonsAtom,
  propertiesInBounds,
  propertiesInPolygons,
} from "components/browse";
import { dateFromDaysAgo, formatCurrencyK1, updateUrlParams } from "helpers";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useUserSession } from "state";
import { searchResultsAtom, SearchResultType } from "state/browse";

export type StructureType = "House" | "Mobile" | "Condominium" | "Multifamily" | "Townhouse";
type ListingType = "Standard" | "Bank Owned" | "Short Sale";
type ListingEventType = "New Listing" | "Price Change" | "Back on Market";

export interface IFilters {
  assumable_loan: boolean | null;
  hide_unknown_equity: boolean | null;
  listing_event: ListingEventType[] | null;
  listing_remarks: string[] | null;
  listing_type: ListingType[] | null;
  max_bathrooms: number | null;
  max_bedrooms: number | null;
  max_days_on_market: number | null;
  max_equity: number | null;
  max_gross_gain: number | null;
  max_gross_income: number | null;
  max_listing_price: number | null;
  max_lot_square_feet: number | null;
  max_square_feet_finished: number | null;
  max_unfinished_basement: number | null;
  max_updated: number | string | null;
  max_year_built: number | null;
  min_bathrooms: number | null;
  min_bedrooms: number | null;
  min_days_on_market: number | null;
  min_equity: number | null;
  min_gross_gain: number | null;
  min_gross_income: number | null;
  min_listing_price: number | null;
  min_lot_square_feet: number | null;
  min_square_feet_finished: number | null;
  min_unfinished_basement: number | null;
  min_updated: number | string | null;
  min_year_built: number | null;
  structure_type: StructureType[] | null;
}

const hasRemarks = (property: SearchResultType, remarks: string[] | null): boolean => {
  if (!remarks || remarks.length === 0) return true;
  if (!property.listing_remarks) return true;
  const propertyRemarks = property.listing_remarks.toLowerCase();
  return remarks.some((remark) => propertyRemarks.includes(remark));
};

const shouldKeepProperty = (property: SearchResultType, filters: IFilters, skipPrice: boolean): boolean => {
  // Structure
  if (
    filters.structure_type &&
    filters.structure_type.length > 0 &&
    !filters.structure_type.includes(property.structureType as StructureType)
  )
    return false;

  // List Price
  if (!skipPrice && filters.min_listing_price != null && property.listingPrice < filters.min_listing_price)
    return false;
  if (!skipPrice && filters.max_listing_price != null && property.listingPrice > filters.max_listing_price)
    return false;

  // Days Since Update
  if (filters.min_updated != null && property.listingEventDate.substring(0, 10) > filters.min_updated)
    return false;
  if (filters.max_updated != null && property.listingEventDate.substring(0, 10) < filters.max_updated)
    return false;

  // Days on Market
  if (filters.min_days_on_market != null && property.days_on_market < filters.min_days_on_market)
    return false;
  if (filters.max_days_on_market != null && property.days_on_market > filters.max_days_on_market)
    return false;

  // Finished Sqft
  if (filters.min_square_feet_finished != null && property.sqft < filters.min_square_feet_finished)
    return false;
  if (filters.max_square_feet_finished != null && property.sqft > filters.max_square_feet_finished)
    return false;

  // Unfinished Sqft
  if (filters.min_unfinished_basement != null && property.sqftUnfinished < filters.min_unfinished_basement)
    return false;
  if (filters.max_unfinished_basement != null && property.sqftUnfinished > filters.max_unfinished_basement)
    return false;

  // Lot Sqft
  if (filters.min_lot_square_feet != null && property.lot_square_feet < filters.min_lot_square_feet)
    return false;
  if (filters.max_lot_square_feet != null && property.lot_square_feet > filters.max_lot_square_feet)
    return false;

  // Year Built
  if (property.year_built) {
    if (filters.min_year_built != null && property.year_built < filters.min_year_built) return false;
    if (filters.max_year_built != null && property.year_built > filters.max_year_built) return false;
  }

  // Bedrooms
  if (filters.min_bedrooms != null && property.beds < filters.min_bedrooms) return false;
  if (filters.max_bedrooms != null && property.beds > filters.max_bedrooms) return false;

  // Bathrooms
  if (filters.min_bathrooms != null && property.baths < filters.min_bathrooms) return false;
  if (filters.max_bathrooms != null && property.baths > filters.max_bathrooms) return false;

  // Operation/Listing Type
  if (filters.listing_type && !filters.listing_type.includes(property.listingType as ListingType))
    return false;

  // Listing Remrks
  if (!hasRemarks(property, filters.listing_remarks)) return false;

  // Listing Event
  if (filters.listing_event) {
    const listingEvent = property.listingEvent;
    if (listingEvent === "Listed" && !filters.listing_event.includes("New Listing")) return false;
    if (listingEvent === "Back on Market" && !filters.listing_event.includes("Back on Market")) return false;
    if (listingEvent === "Relisted" && !filters.listing_event.includes("Back on Market")) return false;
    if (listingEvent === "Price Decrease" && !filters.listing_event.includes("Price Change")) return false;
    if (listingEvent === "Price Increase" && !filters.listing_event.includes("Price Change")) return false;
  }

  // Gross Gain
  const propertyGrossGain = (100 * (property.pellegoArv - property.listingPrice)) / property.listingPrice;
  if (filters.min_gross_gain != null && propertyGrossGain < filters.min_gross_gain) return false;
  if (filters.max_gross_gain != null && propertyGrossGain > filters.max_gross_gain) return false;

  // Gross Income
  const propertyGrossIncome = 100 * (property.monthly_rent_hold / property.piti - 1);
  if (filters.min_gross_income != null && propertyGrossIncome < filters.min_gross_income) return false;
  if (filters.max_gross_income != null && propertyGrossIncome > filters.max_gross_income) return false;

  // Equity
  if (property.equity !== null) {
    const propertyEquity =
      100 *
      (property.equity /
        (property.pellegoArv < property.listingPrice ? property.pellegoArv : property.listingPrice));
    if (filters.min_equity != null && propertyEquity < filters.min_equity) return false;
    if (filters.max_equity != null && propertyEquity > filters.max_equity) return false;
  }

  // Assumable Loan
  if (filters.assumable_loan && filters.assumable_loan !== property.assumable_loan) return false;

  // Hide Unknown Equity
  if (filters.hide_unknown_equity && !property.equity) return false;

  return true;
};

const adaptFilters = (filters: IFilters): IFilters => {
  const modifiedFilters: IFilters = { ...filters };

  if (filters.min_updated != null)
    modifiedFilters.min_updated = dateFromDaysAgo(filters.min_updated as number);
  if (filters.max_updated != null)
    modifiedFilters.max_updated = dateFromDaysAgo(filters.max_updated as number);

  return modifiedFilters;
};

const filterSearchResult = (
  searchResults: SearchResultType[],
  filters: IFilters,
  skipPrice = false,
  restrictNoAVM = true,
): SearchResultType[] => {
  const modifiedFilters = adaptFilters(filters);
  return searchResults.filter((property) => shouldKeepProperty(property, modifiedFilters, skipPrice));
};

export const searchResultsFiltersAtom = atom<IFilters>({
  key: "searchResultsFiltersAtom",
  default: {} as IFilters,
});

export const restrictNoAVMAtom = atom<boolean>({
  key: "restrictNoAVMAtom",
  default: true,
});

export const filteredSearchResultsSelector = selector({
  key: "filteredSearchResultsSelector",
  get: ({ get }) => {
    const searchResults = get(searchResultsAtom);
    const filters = get(searchResultsFiltersAtom);
    const bounds = get(mapBoundsAtom);
    const drawnPolygons = get(mapDrawnPolygonsAtom);
    const filtered = filterSearchResult(searchResults, filters);
    const inBounds = propertiesInBounds(filtered, bounds);
    const inPolygons = propertiesInPolygons(inBounds, drawnPolygons);

    let result = inPolygons;
    const restrictNoAVM = get(restrictNoAVMAtom);
    if (restrictNoAVM) {
      result = inPolygons.filter((property) => !property.no_avm_display);
    }

    return result;
  },
});

export const filteredSearchResultsNoAVMCountSelector = selector({
  key: "filteredSearchResultsNoAVMCountSelector",
  get: ({ get }) => {
    const searchResults = get(searchResultsAtom);
    const filters = get(searchResultsFiltersAtom);
    const bounds = get(mapBoundsAtom);
    const drawnPolygons = get(mapDrawnPolygonsAtom);
    const filtered = filterSearchResult(searchResults, filters);
    const inBounds = propertiesInBounds(filtered, bounds);
    const inPolygons = propertiesInPolygons(inBounds, drawnPolygons);

    let count = 0;
    const restrictNoAVM = get(restrictNoAVMAtom);
    if (restrictNoAVM) {
      count = inPolygons.filter((property) => property.no_avm_display).length;
    }

    return count;
  },
});

export const filteredSearchResultsSelectorSkipPrice = selector({
  key: "filteredSearchResultsSelectorSkipPrice",
  get: ({ get }) => {
    const searchResults = get(searchResultsAtom);
    const filters = get(searchResultsFiltersAtom);
    return filterSearchResult(searchResults, filters, true);
  },
});

export const EQUIVALENT_STRUCT_TYPES = [
  "Duplex",
  "Triplex",
  "Fourplex",
  "Multifamily",
  "MultiFamily",
  "Multi family",
  "Multi Family",
  "Multi-Family",
  "Quadruplex",
  "Quadplex",
  "Fiveplex",
];

export type StructureTypeOption = {
  name: string;
  value: string;
};

export const structureTypeOptions: StructureTypeOption[] = [
  { name: "Houses", value: "House" },
  { name: "Mobiles", value: "Mobile" },
  { name: "Condos", value: "Condominium" },
  { name: "Multi", value: "Multifamily" },
  { name: "Town", value: "Townhouse" },
];

export const listingTypeOptions: ListingType[] = ["Standard", "Bank Owned", "Short Sale"];
export const listingEventOptions: ListingEventType[] = ["New Listing", "Back on Market", "Price Change"];

interface IFiltersParams {
  name: keyof IFilters;
  type: "array" | "number" | "boolean" | "string";
  default: any;
}

export const filtersList: IFiltersParams[] = [
  { name: "assumable_loan", type: "boolean", default: false },
  { name: "hide_unknown_equity", type: "boolean", default: false },
  { name: "listing_event", type: "array", default: listingEventOptions },
  { name: "listing_remarks", type: "array", default: null },
  { name: "listing_type", type: "array", default: listingTypeOptions },
  { name: "max_bathrooms", type: "number", default: null },
  { name: "max_bedrooms", type: "number", default: null },
  { name: "max_days_on_market", type: "number", default: null },
  { name: "max_equity", type: "number", default: null },
  { name: "max_gross_gain", type: "number", default: null },
  { name: "max_gross_income", type: "number", default: null },
  { name: "max_listing_price", type: "number", default: null },
  { name: "max_lot_square_feet", type: "number", default: null },
  { name: "max_square_feet_finished", type: "number", default: null },
  { name: "max_unfinished_basement", type: "number", default: null },
  { name: "max_updated", type: "number", default: null },
  { name: "max_year_built", type: "number", default: null },
  { name: "min_bathrooms", type: "number", default: null },
  { name: "min_bedrooms", type: "number", default: null },
  { name: "min_days_on_market", type: "number", default: null },
  { name: "min_equity", type: "number", default: null },
  { name: "min_gross_gain", type: "number", default: null },
  { name: "min_gross_income", type: "number", default: null },
  { name: "min_listing_price", type: "number", default: null },
  { name: "min_lot_square_feet", type: "number", default: null },
  { name: "min_square_feet_finished", type: "number", default: null },
  { name: "min_unfinished_basement", type: "number", default: null },
  { name: "min_updated", type: "number", default: null },
  { name: "min_year_built", type: "number", default: null },
  { name: "structure_type", type: "array", default: [] },
];

export const filtersTexts: Record<keyof IFilters, string> = {
  assumable_loan: "Assumable Loan :value",
  hide_unknown_equity: "Hide Unknown Equity :value",
  listing_event: "Listing Event :value",
  listing_remarks: "Listing Remarks :value",
  listing_type: "Listing Type :value",
  max_bathrooms: "Max Bathrooms :value",
  max_bedrooms: "Max Bedrooms :value",
  max_days_on_market: "Max Days on Market :value",
  max_equity: "Max Equity :value%",
  max_gross_gain: "Max Gross Gain :value%",
  max_gross_income: "Max Income :value%",
  max_listing_price: "Max Price $:value",
  max_lot_square_feet: "Max Lot Sqft :value",
  max_square_feet_finished: "Max Finished Sqft :value",
  max_unfinished_basement: "Max Unfinished Sqft :value",
  max_updated: "Max Days since last update :value",
  max_year_built: "Max Year Built :value",
  min_bathrooms: "Min Bathrooms :value",
  min_bedrooms: "Min Bedrooms :value",
  min_days_on_market: "Min Days on Market :value",
  min_equity: "Min Equity :value%",
  min_gross_gain: "Min Gross Gain :value%",
  min_gross_income: "Min Income :value%",
  min_listing_price: "Min Price $:value",
  min_lot_square_feet: "Min Lot Sqft :value",
  min_square_feet_finished: "Min Finished Sqft :value",
  min_unfinished_basement: "Min Unfinished Sqft :value",
  min_updated: "Min Days since last update :value",
  min_year_built: "Min Year Built :value",
  structure_type: "Structure Type :value",
};

const paramToBool = (urlParams: URLSearchParams, paramName: string, defaultValue: boolean) => {
  const paramValue = urlParams.get(paramName);
  // The only presence of a boolean param (value === '') is considered true
  if (paramValue === "true" || paramValue === "") return true;
  if (paramValue === "false") return false;

  return defaultValue;
};

const paramToNum = (urlParams: URLSearchParams, paramName: string, defaultValue: number | null) => {
  const paramValue = urlParams.get(paramName);
  if (paramValue || paramValue === "0") return Number(paramValue);

  return defaultValue;
};

const paramToArray = (urlParams: URLSearchParams, paramName: string, defaultValue: any[] | null) => {
  const paramValue = urlParams.getAll(paramName);
  if (paramValue && paramValue.length > 0) return paramValue;

  return defaultValue;
};

const paramsToFilters = (params: URLSearchParams): any => {
  const filters = {} as IFilters;

  filtersList.forEach((filter) => {
    switch (filter.type) {
      case "array":
        filters[filter.name] = paramToArray(params, filter.name, filter.default) as never;
        break;
      case "number":
        filters[filter.name] = paramToNum(params, filter.name, filter.default) as never;
        break;
      case "boolean":
        filters[filter.name] = paramToBool(params, filter.name, filter.default) as never;
        break;
      case "string":
        filters[filter.name] = (params.get(filter.name) as never) || filter.default;
        break;
    }
  });
  return filters;
};

export const numActiveFiltersSelector = selector({
  key: "numActiveFiltersSelector",
  get: ({ get }) => {
    const filters = get(searchResultsFiltersAtom);
    let numActiveFilters = 0;

    filtersList.forEach((filter) => {
      // if filter is min/max, only increment one time if any or both are active
      if (filter.name.startsWith("min_")) {
        if (!isEqual(filters[filter.name], filter.default)) {
          numActiveFilters++;
        }
      } else if (filter.name.startsWith("max_")) {
        const minName = filter.name.replace("max_", "min_");
        if (
          isEqual(filters[minName as keyof IFilters], filter.default) &&
          !isEqual(filters[filter.name], filter.default)
        ) {
          numActiveFilters++;
        }
      } else {
        if (!isEqual(filters[filter.name], filter.default)) {
          numActiveFilters++;
        }
      }
    });
    return numActiveFilters;
  },
});

export const resetedFilterValues = () => {
  const resetedFilters = {} as IFilters;

  filtersList.forEach((filter) => {
    resetedFilters[filter.name] = filter.default;
  });
  return resetedFilters;
};

const nonDefaultValue = (filter: IFilters, filterName: string) => {
  const defaultValue = filtersList.find((filter) => filter.name === filterName)?.default;
  if (filter[filterName as keyof IFilters] !== defaultValue) {
    return filter[filterName as keyof IFilters];
  } else {
    return null;
  }
};

export const buildBrowseTitleFromUrl = (url: string, city: string = "") => {
  if (url.includes("?")) {
    url = url.split("?")[1];
  }
  const urlParams = new URLSearchParams(url);
  const filters = paramsToFilters(urlParams);
  const texts = [];
  if (Object.keys(filters).length === 0) return city;

  let value = nonDefaultValue(filters, "min_gross_gain");
  if (value !== null) {
    texts.push(`${value}%+ Gain`);
  }
  value = nonDefaultValue(filters, "min_gross_income");
  if (value !== null) {
    texts.push(`${value}%+ Income`);
  }
  value = nonDefaultValue(filters, "min_equity");
  if (value !== null) {
    texts.push(`${value}%${Number(value) < 100 ? "+" : ""} Equity`);
  }
  if (nonDefaultValue(filters, "assumable_loan")) {
    texts.push("Assumable Loan");
  }
  let resultText = texts.join(", ");
  resultText += resultText.length > 0 ? ` ${city} ` : `${city} `;

  const priceMin = nonDefaultValue(filters, "min_listing_price");
  const priceMax = nonDefaultValue(filters, "max_listing_price");
  if (priceMin && priceMax) {
    resultText += `${formatCurrencyK1(priceMin as number)}-${formatCurrencyK1(priceMax as number)}`;
  } else if (priceMin) {
    resultText += `${formatCurrencyK1(priceMin as number)}+`;
  } else if (priceMax) {
    resultText += `<${formatCurrencyK1(priceMax as number)}`;
  }

  return resultText;
};

export const useFilters = () => {
  const [filters, setFilters] = useRecoilState(searchResultsFiltersAtom);
  const searchResults = useRecoilValue(searchResultsAtom);
  const setRestrictNoAVM = useSetRecoilState(restrictNoAVMAtom);
  const filteredSearchResults = useRecoilValue(filteredSearchResultsSelector);
  const numActiveFilters = useRecoilValue(numActiveFiltersSelector);
  const [urlParams, setUrlParams] = useSearchParams();
  const currentUser = useUserSession();

  useEffect(() => {
    const filtersFromParams = paramsToFilters(urlParams);
    if (isEqual(filtersFromParams, filters)) return;
    setFilters(filtersFromParams);
  }, [urlParams, setFilters, filters]);

  const filterOutNoAVM =
    (filters.min_gross_gain !== null ||
      filters.max_gross_gain !== null ||
      filters.min_gross_income !== null ||
      filters.max_gross_income !== null) &&
    !currentUser.isAgent &&
    !currentUser.isClient;

  useEffect(() => {
    // Properties that have the no_avm_display === true should not show up
    // when Gross Gain or Income filters are active
    setRestrictNoAVM(filterOutNoAVM);
  }, [filterOutNoAVM, setRestrictNoAVM]);

  const updateFilters = (newFilters: any) => {
    updateUrlParams(urlParams, newFilters, setUrlParams);
  };

  return {
    filters,
    setFilters: updateFilters,
    numSearchResults: searchResults.length,
    numFilteredResults: filteredSearchResults.length,
    urlParams,
    setUrlParams,
    numActiveFilters,
  };
};
