import { AnnounceWidget, LoadingPhrases, NavBrowse } from "components";
import {
  AppreciationsMap,
  BrowseMap,
  Filters,
  mapBoundsAtom,
  NonMLSPanel,
  ResultsPanel,
} from "components/browse";
import { titleCase } from "helpers";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useSetRecoilState } from "recoil";
import { useUserSession } from "state";
import { buildBrowseTitleFromUrl, useBrowse, useLocationSearch, useSort } from "state/browse";
import { useSavedDeals, useSaveDeal } from "state/proformas";

const onlyFavoritesResults = (properties: any[], isSavedDeal: (parcelId: number) => boolean) => {
  return properties.filter((property) => isSavedDeal(property.parcel_id));
};

export const Browse = () => {
  const { loading, emptyResult, stateId } = useBrowse();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const currentUser = useUserSession();
  useSavedDeals(currentUser.isLogged);
  const { isSavedDeal } = useSaveDeal();
  const { sortedSearchResults } = useSort();
  const searchResults = onlyFavorites
    ? onlyFavoritesResults(sortedSearchResults, isSavedDeal)
    : sortedSearchResults;
  const { locationSearchItems } = useLocationSearch();
  const pageTitle = buildBrowseTitleFromUrl(
    window.location.pathname + window.location.search,
    titleCase(locationSearchItems[0]?.name),
  );
  const setMapBounds = useSetRecoilState(mapBoundsAtom);

  useEffect(() => {
    if (!isMobile && loading) {
      setMapBounds(undefined);
    }
  }, [loading, setMapBounds]);

  const loadingPhrases = ["Pulling Listings", "Estimating ARVs", "Estimating Rents", "Populating Data"];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div id="main-frame" className="grid h-screen w-screen place-items-center">
        <div id="top-navbar" className="fixed left-0 top-0 z-40 flex h-16 w-full flex-row justify-between">
          <NavBrowse loading={loading} emptyResult={emptyResult} searchResults={searchResults} />
          {!isMobile && <Filters filteredProperties={searchResults} />}
        </div>
        <div id="main-content" className="h-screen w-screen place-items-center">
          {loading ? (
            <LoadingPhrases phrases={loadingPhrases} />
          ) : (
            <>
              <div className="flex h-screen w-full flex-row">
                {!isMobile && (
                  <div id="map-section" className="z-10 mt-16 h-[calc(100vh-4rem)] w-full grow">
                    {emptyResult ? <AppreciationsMap /> : <BrowseMap />}
                  </div>
                )}
                <div
                  id="results-section"
                  className={`mt-16 flex h-full shrink-0 overflow-y-auto ${isMobile ? "w-full" : "w-[calc(318px+2rem)] xl:w-[calc(636px+3rem)] 3xl:w-[calc(954px+4rem)]"}`}
                >
                  {emptyResult ? (
                    <>
                      <NonMLSPanel stateId={stateId} />
                      <AnnounceWidget />
                    </>
                  ) : (
                    <ResultsPanel
                      loading={loading}
                      stateId={stateId}
                      searchResults={searchResults}
                      emptyResult={emptyResult}
                      onlyFavorites={onlyFavorites}
                      setOnlyFavorites={setOnlyFavorites}
                      sortedSearchResults={sortedSearchResults}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
