import { atom, useRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import { SalesComparableType } from "state/proformas/comparables";
import {
  CalcSheetType,
  calculateLoans,
  isCostInGroup,
  getFoundationAmount,
  costByName,
  calcBudgets,
} from "state/proformas";
import { calculatePurchaseFromProfit } from "state/proformas";

export type FinancialsType = {
  carry: {
    profit: number;
    cashin: number;
  },
  rental: {
    cashin: number;
    cashin_initial: number;
    budget: number;
    monthlyCosts: number;
    monthlyLoanPmt: number;
    monthlyRent: number;
    monthlyProfit: number;
    capRate: number;
    vacancyRate: number;
  },
  costgroups: {
    acquisition: any[];
    acquisitionHold: any[];
    carrying: any[];
    selling: any[];
    rentalBrrr: any[];
    rentalHold: any[];
  };
  costgroupTotals: {
    acquisition: number;
    acquisitionHold: number;
    carrying: number;
    selling: number;
    sellingHold: number;
    rentalBrrr: number;
    rentalHold: number;
    operatingBrrr: number;
    operatingHold: number;
  };
  loans: any;
  strategy_carry: string;
  strategy_rental: string;
  calcsheet: CalcSheetType;
  square_feet_finished: number;
  is_mine: boolean;
}

const getVacancyRate = (costgroups: any[]): number => {
  let vacancyRate = 0;

  costgroups.forEach(cost => {
    if (cost.name === "vacancy_hold" || cost.name === "vacancy_brrr") {
      vacancyRate = cost.multiplier;
    }
  });
  return vacancyRate;
}

export class FinancialsClass {
  data: FinancialsType;

  constructor(existingData: FinancialsType | null, proforma: any | null) {
    this.data = existingData || {
      carry: {
        profit: 0,
        cashin: 0,
      },
      rental: {
        cashin: 0,
        cashin_initial: 0,
        budget: 0,
        monthlyCosts: 0,
        monthlyLoanPmt: 0,
        monthlyRent: 0,
        monthlyProfit: 0,
        capRate: 0,
        vacancyRate: 0,
      },
      costgroups: {
        acquisition: [],
        acquisitionHold: [],
        carrying: [],
        selling: [],
        rentalBrrr: [],
        rentalHold: [],
      },
      costgroupTotals: {
        acquisition: 0,
        acquisitionHold: 0,
        carrying: 0,
        selling: 0,
        sellingHold: 0,
        rentalBrrr: 0,
        rentalHold: 0,
        operatingBrrr: 0,
        operatingHold: 0,
      },
      loans: {
        pp: undefined,
        rehab: undefined,
        brrr: undefined,
        hold: undefined,
        cash: {
          _monthly_pmt: 0,
          _principal_amt: 0,
          amount: 0,
          interest: 0,
        }
      },
      strategy_carry: '',
      strategy_rental: '',
      calcsheet: cloneDeep(proforma.calcsheet),
      square_feet_finished: proforma.structure.square_feet_finished,
      is_mine: proforma.is_mine,
    };

    if (this.data.calcsheet.is_brrr !== true) {
      this.data.calcsheet.is_brrr = false;
    }

    this.setLoans();

    if (!existingData) {
      this.data.calcsheet.original_resale_value = this.data.calcsheet.resale_value;

      if (this.data.calcsheet.include_appreciation) {
        this.addTheAppreciation();
      }

      if (!this.data.is_mine) {
        this.setProformaStrategy(['brrr_cash', 'flip_financed', 'hold_financed']);
      }
    }

    this.calculateAll();
  }

  setLoans() {
    this.data.calcsheet.loans.forEach((loan: any) => {
      this.data.loans[loan.name.split('_')[0]] = loan;
    });
  }

  calculateAll() {
    // this.updateProformaStrategy();
    calculateLoans(this.data.calcsheet);
    this.initializeCostgroups();
    this.updateProformaStrategy();
    this.calculateCostgroups('costs');
    this.calculateCostgroups('rental');
    this.calculateCostgroupTotals();
    this.calculateProformaProfit();
    this.calculateRentalData();
  }

  calculateCost(cost: any) {
    const foundationAmount = getFoundationAmount(cost.foundation, this.data);
    // for fixed, the multiplier will be one
    cost.amount = cost.base + (cost.multiplier * foundationAmount);

    // If the amount is annual, divide by 12 to get monthly
    if (cost._compound_rate === 'annually') {
      cost.amount /= 12;
    }

    // If we are in the CARRYING COSTS costgroup, multiply the amount
    // by the carrying time
    if (cost._costgroup === 'carrying') {
      cost.amount *= this.data.calcsheet.carrying_time;
    }

    // Round it out
    cost.amount = Math.round(cost.amount);

    return cost;
  }

  /**
   * Method to update the foundation amounts for all costs of a particular
   * type (e.g. 'costs', 'rental')
   * @param {string} type One of 'cost' or 'rental', the array of costs
  */
  setFoundationAmounts(type: 'costs' | 'rental') {
    const costsObj = this.data.calcsheet[type];

    costsObj.forEach((cost: any) => {
      cost._foundation_amt = getFoundationAmount(cost.foundation, this.data);
      if (!cost.foundation) {
        cost.multiplier = 1;
      }
    });

    return costsObj;
  }

  calculateCostgroups(costtype: 'costs' | 'rental') {
    // Update the foundations for all the costs in the requested array
    // And get the array back so we can iterate over it
    const costs = this.setFoundationAmounts(costtype);

    // calculate subcost totals for each cost in the array
    costs.forEach((cost: any) => this.calculateCost(cost));
  }

  calculateCostgroupTotals() {
    for (const [groupname, costarray] of Object.entries(this.data.costgroups)) {
      this.data.costgroupTotals[groupname as keyof typeof this.data.costgroupTotals] =
        costarray.reduce((prev, curr: any) => prev + curr.amount, 0);
    }
    this.addLoanCosts();
    this.calculateOperatingCosts();
  }

  addLoanCosts() {
    let acqcostshold = this.data.costgroupTotals.acquisitionHold;
    let acqcosts = this.data.costgroupTotals.acquisition;
    let carrycosts = this.data.costgroupTotals.carrying;
    let holdcosts = this.data.costgroupTotals.rentalHold;
    let brrrcosts = this.data.costgroupTotals.rentalBrrr;

    let rcosts = 0;

    this.data.calcsheet.loans.forEach((loan: any) => {
      if (loan.is_enabled === true) {
        switch (loan._type) {
          case 'carry':
            //TODO: will this change if the origination is financed?
            acqcosts += loan._origination_amt;
            const ccosts = loan._monthly_pmt * this.data.calcsheet.carrying_time;
            carrycosts += ccosts;
            break;
          case 'rental':
            if (loan.name === 'hold_mortgage') {
              acqcostshold += loan._origination_amt;
            }
            (loan.name === 'brrr_mortgage') ? (rcosts = brrrcosts) : (rcosts = holdcosts);
            rcosts += loan._monthly_pmt;
            (loan.name === 'brrr_mortgage') ? (brrrcosts = rcosts) : (holdcosts = rcosts);
            break;
        }
      }
    });

    this.data.costgroupTotals.acquisition = Math.round(acqcosts);
    this.data.costgroupTotals.acquisitionHold = Math.round(acqcostshold);
    this.data.costgroupTotals.carrying = Math.round(carrycosts);
    this.data.costgroupTotals.rentalHold = Math.round(holdcosts);
    this.data.costgroupTotals.rentalBrrr = brrrcosts;
  }

  calculateOperatingCosts() {
    // operating costs = 12 * (property management + taxes + insurance + hoa dues + maintenance budget)

    const costsListBrrr = ["hoa_dues", "management_brrr", "taxes_brrr", "rental_insurance_brrr", "maintenance_brrr"];
    this.data.costgroupTotals.operatingBrrr = 0;
    this.data.costgroups.rentalBrrr.forEach(cost => {
      if (costsListBrrr.includes(cost.name)) {
        this.data.costgroupTotals.operatingBrrr += cost.amount;
      }
    })

    const costsListHold = ["hoa_dues", "management_hold", "taxes_hold", "rental_insurance_hold", "maintenance_hold"];
    this.data.costgroupTotals.operatingHold = 0;
    this.data.costgroups.rentalHold.forEach(cost => {
      if (costsListHold.includes(cost.name)) {
        this.data.costgroupTotals.operatingHold += cost.amount;
      }
    })
  }

  calculateProformaProfit() {
    const calcsheet = this.data.calcsheet;
    const ctotals = this.data.costgroupTotals;
    let resale_value = calcsheet.resale_value;
    if (calcsheet.my_arv)
      resale_value = calcsheet.my_arv;

    let carry_profit = 0;

    // for flip:
    carry_profit = resale_value - calcsheet.purchase_price -
      calcsheet.rehab_costs_flip - ctotals['acquisition'] -
      ctotals['carrying'] - ctotals['selling'];

    this.data.carry.profit = carry_profit;

    this.calculateProformaCashIn();
  }

  calculateProformaCashIn() {
    const cashAmounts = {
      brrr_mortgage: 0,
      hold_mortgage: 0,
      pp_hardmoney: 0,
      rehab_hardmoney: 0,
      other: 0,
      carry: 0,
    };

    this.data.calcsheet.loans.forEach((loan: any) => {

      if (loan.is_enabled) {
        // adding this in 10/10/16 per Gideon's updates
        // the Net Cash In for the BRRR is slightly different as it's
        // basically netting two strategies
        // origination will be taken care of below
        if (loan.name === 'brrr_mortgage') {
          cashAmounts[loan.name as keyof typeof cashAmounts] =
            (loan._foundation_amt - loan._dpay_amt);
        } else {
          cashAmounts[loan.name as keyof typeof cashAmounts] += loan._dpay_amt;

          // adding in costs of all other enabled loans
          // to add into the net cash in value
          cashAmounts.carry += loan._interest_amt + loan._origination_amt;
        }

        // BLACK MAGICK:
        if (loan._type === 'rental' &&
          typeof (loan.is_financed_origination) === 'undefined') {
          loan.is_financed_origination = true;
        }

        // Never count mortgage interest as cash in
        if (!loan.is_financed_interest && loan._type === 'carry') {
          cashAmounts[loan.name as keyof typeof cashAmounts] += loan._interest_amt;
        }
        if (!loan.is_financed_origination) {
          cashAmounts[loan.name as keyof typeof cashAmounts] += loan._origination_amt;
        }
      }
      else {
        cashAmounts[loan.name as keyof typeof cashAmounts] += loan._foundation_amt;
      }
    });

    let closing_costs_amt = 0;

    this.data.calcsheet.costs.forEach((cost: any) => {
      if (cost._costgroup === 'acquisition' ||
        cost._costgroup === 'carrying') {
        cashAmounts.other += cost.amount;
      }
      if (cost.name === 'title_and_escrow') {
        closing_costs_amt = cost.amount;
      }
    });

    this.data.carry.cashin = Math.round(cashAmounts.pp_hardmoney +
      cashAmounts.rehab_hardmoney + cashAmounts.other);

    if (!this.data.calcsheet.is_brrr) {
      // BUY AND HOLD - no financing = purchase + other
      if (this.data.strategy_rental === 'cash') {
        this.data.rental.cashin = Math.round(
          cashAmounts.hold_mortgage +
          this.data.calcsheet.rehab_costs_hold +
          cashAmounts.other +
          closing_costs_amt);
      }
      // BUY AND HOLD - with financing
      else {
        this.data.rental.cashin = Math.round(
          cashAmounts.hold_mortgage +
          this.data.calcsheet.rehab_costs_hold +
          closing_costs_amt);
      }
    }
    else {
      // BRRR: Cash in is just the cash in calculated above
      // rental.cashin = cashAmounts.brrr_mortgage;
      // BRRR: Cash in is the principal
      // less the pprice, rehab, acq, and carry
      this.data.rental.cashin =
        (this.data.calcsheet.purchase_price +
          this.data.calcsheet.rehab_costs_flip +
          cashAmounts.other) - cashAmounts.brrr_mortgage +
        cashAmounts['carry'];
    }

    // the INITIAL cash in for brrr is the flip cash in
    this.data.rental.cashin_initial = this.data.carry.cashin;
  }

  calculateHoldSellingCosts() {
    // Update the foundations for all the costs in the requested array
    // And get the array back so we can iterate over it
    const costs = this.setFoundationAmounts('costs');
    const sellingHold: number[] = [];

    // calculate subcost totals for each cost in the array
    costs.forEach((cost: any) => {
      // if we're doing a buy and hold, our selling costs will start out
      // with a different foundation
      if (cost._costgroup === 'selling') {
        if (cost.foundation === 'resale_value') {
          cost._hold_foundation = 'purchase_price';
          cost._hold_foundation_amt = this.data.calcsheet.purchase_price;
        }

        cost._hold_amount = cost.multiplier * cost._hold_foundation_amt;
        // Round it out
        cost._hold_amount = Math.round(cost._hold_amount);

        sellingHold.push(cost._hold_amount);
      }
    });

    this.data.costgroupTotals.sellingHold = sellingHold.reduce((a, b) => a + b, 0);
  }

  setProformaStrategy(strategies: string[]) {
    // Called with strategies: ['brrr_cash', 'flip_financed', 'hold_financed']
    strategies.forEach((s) => {
      const strat = s.split('_');
      if (strat[0] === 'flip') {
        this.data.loans.pp.is_enabled = strat[1] === 'financed';
        this.data.loans.rehab.is_enabled = this.data.loans.pp.is_enabled;
      }
      else {
        this.data.loans[strat[0]].is_enabled = strat[1] === 'financed';
      }

      if (strat[0] === 'hold' && strat[1] === 'financed') {
        this.data.loans.brrr.is_enabled = false;
      }
    });
  }

  updateProformaStrategy() {
    // Assume cash, unless otherwise
    this.data.strategy_carry = 'cash';
    this.data.strategy_rental = 'cash';

    let carryloans = 0;

    this.data.calcsheet.loans.forEach((loan: any) => {
      if (loan.is_enabled) {
        switch (loan.name) {
          case 'pp_hardmoney':
            this.data.strategy_carry = 'financed_pp';
            carryloans += 1;
            break;
          case 'rehab_hardmoney':
            this.data.strategy_carry = 'financed_rehab';
            carryloans += 1;
            break;
          case 'hold_mortgage':
            if (!this.data.calcsheet.is_brrr) {
              this.data.strategy_rental = 'financed_hold';
              this.calculateHoldSellingCosts();
            }
            break;
          case 'brrr_mortgage':
            // Only set the brrr strategy if this IS a brrr
            if (this.data.calcsheet.is_brrr) {
              this.data.strategy_rental = 'financed_brrr';
            }
            break;
        }
      }
    });

    // Double up?
    if (carryloans === 2) {
      this.data.strategy_carry = 'financed_both';
    }
  }

  addTheAppreciation() {
    const compound = Math.pow((1 + this.data.calcsheet.annualized_appreciation), (this.data.calcsheet.carrying_time / 12));
    this.data.calcsheet.resale_value = Math.round(
      this.data.calcsheet.resale_value * compound / 100) * 100;
  }

  initializeCostgroups() {
    this.data.costgroups = {
      acquisition: [],
      acquisitionHold: [],
      carrying: [],
      selling: [],
      rentalBrrr: [],
      rentalHold: [],
    };
    this.data.costgroupTotals = {
      acquisition: 0,
      acquisitionHold: 0,
      carrying: 0,
      selling: 0,
      sellingHold: 0,
      rentalBrrr: 0,
      rentalHold: 0,
      operatingBrrr: 0,
      operatingHold: 0,
    };
    this.organizeCostgroups(this.data.calcsheet.costs);
    this.organizeCostgroups(this.data.calcsheet.rental);
  }

  organizeCostgroups(listofcosts: any[]) {
    const costarrays = {
      acquisition: [
        'miscellaneous_acquisition',
        'title_and_escrow'
      ],
      acquisitionHold: [
        // 'miscellaneous_acquisition',
        // 'title_and_escrow',
        // 'closing_acquisition'
      ],
      carrying: [
        'hoa_dues',
        'insurance',
        'taxes',
        'utilities'
      ],
      selling: [
        'closing_selling',
        'commission_listing',
        'commission_selling',
        'excise'
      ],
      rentalHold: [
        'management_hold',
        'maintenance_hold',
        'rental_insurance_hold',
        'taxes_hold',
        'vacancy_hold',
      ],
      rentalBrrr: [
        'management_brrr',
        'maintenance_brrr',
        'rental_insurance_brrr',
        'taxes_brrr',
        'vacancy_brrr',
      ]
    };

    // make a lookup for costgroup name from costname
    const costgrouplookup: Record<string, string> = {};

    // loop through the above costgroups
    Object.entries(costarrays).forEach(([key, arr]) => {
      // loop through every cost name in that costgroup
      arr.forEach((cname) => {
        // create the lookup
        costgrouplookup[cname] = key;
      });
    });

    // loop through all the costs, from the proforma
    listofcosts.forEach((cost) => {
      // did we make a lookup for this cost's name?
      if (costgrouplookup[cost.name]) {
        cost._costgroup = costgrouplookup[cost.name];

        // add this cost to the group
        this.data.costgroups[costgrouplookup[cost.name] as keyof typeof this.data.costgroups].push(cost);

        // Costs that are multiplied by month?
        if (costgrouplookup[cost.name] !== 'acquisition' &&
          costgrouplookup[cost.name] !== 'selling') {
          cost._compound_rate = 'monthly';
        }

        // TODO: split cost name, or find shortened fn to move through
        // the costs instead of two switches

        // Throw this in here, since we don't have a better place to put
        // it. Costs have foundations that display differently depending
        // on how they're measured.
        switch (cost.name) {
          case 'insurance':
          case 'rental_insurance_hold':
          case 'rental_insurance_brrr':
            cost._compound_rate = 'annually';
            break;
          case 'maintenance':
          case 'maintenance_hold':
          case 'maintenance_brrr':
          case 'utilities':
            cost._foundation_type = 'cents';
            break;
          case 'taxes':
          case 'taxes_brrr':
          case 'taxes_hold':
            cost._foundation_type = 'tax';
            cost._compound_rate = 'annually';
            break;
          case 'miscellaneous_acquisition':
          case 'hoa_dues':
            cost._foundation_type = 'fixed';
            cost.is_fixed = true;
            break;
        }

        // this is also thrown in here to update the display order
        switch (cost.name) {
          case 'management_brrr':
          case 'management_hold':
          case 'excise':
          case 'title_and_escrow':
            cost._display_order = 1;
            break;
          case 'maintenance_hold':
          case 'maintenance_brrr':
          case 'commission_listing':
            cost._display_order = 2;
            break;
          case 'vacancy_brrr':
          case 'vacancy_hold':
          case 'commission_selling':
          case 'miscellaneous_acquisition':
            cost._display_order = 3;
            break;
          case 'taxes_brrr':
          case 'taxes_hold':
          case 'closing_selling':
            cost._display_order = 4;
            break;
          case 'rental_insurance_hold':
          case 'rental_insurance_brrr':
            cost._display_order = 5;
            break;
        }

        // setting this for the costs w/ an editable base - we'll use
        // this attribute to show the base (in case it might be zero)
        switch (cost.name) {
          case 'utilities':
          case 'title_and_escrow':
            cost._has_base = true;
            break;
          case 'closing_selling':
            cost._has_base = true;
            break;
        }

      }

    });
    this.attachCostToAdditionalGroups('hoa_dues', ['rentalHold', 'rentalBrrr']);
  }

  attachCostToAdditionalGroups(costName: string, addToGroupNames: string[]) {
    const cost = costByName(costName, this.data.costgroups);
    if (!cost) { return; }
    addToGroupNames.forEach((groupName) => {
      const costgroup = this.data.costgroups[groupName as keyof FinancialsType["costgroups"]];
      if (!isCostInGroup(cost.name, groupName, costgroup)) {
        const newCost = { ...cost, _costgroup: groupName, amount: cost.base };
        costgroup.push(newCost);
      }
    });
  }

  calculateCapRate() {
    const rstrat = this.data.strategy_rental.split("_")[1];
    const lstrat = this.data.strategy_rental;
    const rehab = rstrat === "hold" ?
      this.data.calcsheet.rehab_costs_hold :
      this.data.calcsheet.rehab_costs_flip;

    const numerater = 12 * (
      lstrat === "cash" ?
        this.data.rental.monthlyRent - this.data.rental.monthlyCosts :
        this.data.rental.monthlyRent - this.data.rental.monthlyCosts + this.data.rental.monthlyLoanPmt
    );
    const denominator = this.data.calcsheet.purchase_price + rehab;
    const result = (numerater / denominator) * 100;

    return parseFloat(result.toFixed(2));
  }

  calculateRentalData() {
    let loan;

    if (this.data.strategy_rental === "financed_hold") {
      this.data.rental.budget = calcBudgets(this.data.costgroups.rentalHold);
      this.data.rental.monthlyCosts = this.data.costgroupTotals.rentalHold;
      this.data.rental.monthlyRent = this.data.calcsheet.monthly_rent_hold;
      this.data.rental.vacancyRate = getVacancyRate(this.data.costgroups.rentalHold);
      loan = this.data.loans.hold;
    } else {
      this.data.rental.budget = calcBudgets(this.data.costgroups.rentalBrrr);
      this.data.rental.monthlyCosts = this.data.costgroupTotals.rentalBrrr;
      this.data.rental.monthlyRent = this.data.calcsheet.monthly_rent_brrr;
      this.data.rental.vacancyRate = getVacancyRate(this.data.costgroups.rentalBrrr);
      loan = this.data.loans.brrr;
    }
    this.data.rental.monthlyLoanPmt = loan.is_enabled ? loan._monthly_pmt : 0;
    this.data.rental.monthlyProfit = Math.round(this.data.rental.monthlyRent - this.data.rental.monthlyCosts);
    this.data.rental.capRate = this.calculateCapRate();
  }

}

const rebuildFinancialsForProfit = (financials: FinancialsType, profit: number) => {
  const pp = calculatePurchaseFromProfit(financials, profit, undefined);

  if (pp.target_pprice) {
    const calcSheet = cloneDeep(financials?.calcsheet);
    const newCalcSheet = { ...calcSheet, purchase_price: pp.target_pprice };
    const newFinancials = cloneDeep(financials) as FinancialsType;
    newFinancials.calcsheet = newCalcSheet;
    const financialsObj = new FinancialsClass(newFinancials, null);
    return financialsObj.data;
  }
  return financials;
}

// Updates the financials object with the new target profit until the desired profit
// is reached by means of successive approximations
export const getFinancialsForProfit = (financials: FinancialsType, profit: number) => {
  let newFinancials = financials;
  for (let i = 0; i < 5; i++) {
    newFinancials = rebuildFinancialsForProfit(newFinancials, profit);
    if (newFinancials?.carry.profit === profit) {
      return newFinancials;
    }
  }
  return newFinancials;
}

export const financialsAtom = atom<FinancialsType | null>({
  key: "financialsAtom",
  default: null,
});

export const useFinancials = () => {
  const [financials, setFinancials] = useRecoilState(financialsAtom);

  const setCalcSheet = (newCalcSheet: CalcSheetType) => {
    const newFinancials = cloneDeep(financials) as FinancialsType;
    newFinancials.calcsheet = newCalcSheet;
    const financialsObj = new FinancialsClass(newFinancials, null);
    setFinancials(financialsObj.data);
  };

  const updateCalcSheet = (fieldsToUpdate: any) => {
    const calcSheet = cloneDeep(financials?.calcsheet);
    setCalcSheet({ ...calcSheet, ...fieldsToUpdate });
  }

  const recalculateArv = (salesComparables: SalesComparableType[]) => {
    const calcSheet = financials?.calcsheet;

    let weightedTotal = 0, total = 0;
    const fieldsToUpdate: any = {};

    salesComparables.forEach((comp) => {
      if (comp.is_included) {
        weightedTotal += comp.score * comp.projected_arv;
        total += comp.score;
      }
    });
    let new_arv = Math.round(weightedTotal / total / 500) * 500;
    fieldsToUpdate.original_resale_value = new_arv;
    if (calcSheet?.include_appreciation) {
      new_arv = (new_arv *
        Math.pow((1 + calcSheet.annualized_appreciation),
          (calcSheet.carrying_time / 12)));
    }
    fieldsToUpdate.resale_value = new_arv;
    updateCalcSheet(fieldsToUpdate);
  }

  const updateLoan = (loan: any) => {
    const newLoans = financials?.calcsheet.loans.map((l: any) => {
      if (l.name === loan.name) {
        return loan;
      }
      return { ...l };
    });
    updateCalcSheet({ loans: newLoans });
  }

  const updateCost = (cost: any) => {
    const newCosts = financials?.calcsheet.costs.map((c: any) => {
      if (c.name === cost.name) {
        return cost;
      }
      return { ...c };
    });
    const newRental = financials?.calcsheet.rental.map((r: any) => {
      if (r.name === cost.name) {
        return cost;
      }
      return { ...r };
    });
    updateCalcSheet({ costs: newCosts, rental: newRental });
  }

  const setResaleValue = (newResaleValue: number) => {
    const calcSheet = financials?.calcsheet;
    const fieldsToUpdate: any = {};

    fieldsToUpdate.resale_value = newResaleValue;

    if (calcSheet?.include_appreciation) {
      const compound = Math.pow((1 + calcSheet.annualized_appreciation), (calcSheet.carrying_time / 12));
      fieldsToUpdate.original_resale_value = newResaleValue / compound;
    }
    updateCalcSheet(fieldsToUpdate);
  }

  const updateBRRRstatus = (newBRRRstatus: boolean) => {
    const newLoans = financials?.calcsheet.loans.map((l: any) => {
      if (l.name === "hold_mortgage") {
        return { ...l, is_enabled: !newBRRRstatus };
      }
      if (l.name === "brrr_mortgage") {
        return { ...l, is_enabled: newBRRRstatus };
      }
      return { ...l };
    });
    const fieldsToUpdate: any = {
      is_brrr: newBRRRstatus,
      loans: newLoans,
    };
    updateCalcSheet(fieldsToUpdate);
  }

  window.Pellego.financials = financials;

  return {
    financials,
    setFinancials,
    updateCalcSheet,
    recalculateArv,
    updateLoan,
    updateCost,
    setResaleValue,
    updateBRRRstatus,
  };
}