import { ClipboardCopyIcon, DownloadIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { Spinner } from "components";
import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactGA from "react-ga4";
import { useUserSession } from "state";
import { useProforma } from "state/proformas";

type Props = {
  closeModal: () => void;
};

export const ExportModal = ({ closeModal }: Props) => {
  const { proforma } = useProforma();
  const currentUser = useUserSession();
  const [loading, setLoading] = useState(false);
  const [generatedURL, setGeneratedURL] = useState("");
  const [copied, setCopied] = useState(false);

  const exportFormat = "sheet";
  const generateExport = () => {
    setLoading(true);

    const url = appConfig.apiEndpoint.exporterSheet
      .replace(":deal_id", proforma._id.toString())
      .replace(":format", exportFormat);

    fetcherWithCredentials(url)
      .then((res) => {
        setGeneratedURL(res);
      })
      .finally(() => {
        setLoading(false);
      });

    ReactGA.event({
      category: "Proforma",
      action: `Export ${exportFormat}`,
      label: proforma._id.toString(),
    });
  };

  useEffect(() => {
    generateExport();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="inline-block h-full w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 pb-8 text-left align-middle shadow-xl transition-all">
      <header className="flex w-full flex-row items-center">
        <div className="text-md flex basis-4/5 items-center pl-9 font-medium text-gray-700">
          <DownloadIcon className="mr-2 mt-1 w-6" aria-hidden="true" />
          Export your analysis
        </div>
        <div className="justify-right basis-1/5 p-4 pr-4 text-right">
          <button
            type="button"
            className="rounded-md pb-1 pl-1 text-sm font-medium text-gray-400 hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={closeModal}
          >
            <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
          </button>
        </div>
      </header>

      <main className="flex h-full flex-grow flex-col bg-gray-50 p-8 pt-4">
        {loading && (
          <div className="flex flex-row pt-2 items-center">
            <Spinner className="mr-3 size-5 text-black" />
            <div>Loading...</div>
          </div>
        )}
        {!loading && (
          <>
            {currentUser.isExporter && proforma.is_mine && generatedURL && (
              <div>
                Paste this link into your browser to open a Google Sheet with information. From Google Sheet,
                you can also download the file as a PDF.
                <div className="my-4 w-full truncate text-ellipsis text-xs">{generatedURL}</div>
                <CopyToClipboard text={generatedURL} onCopy={() => setCopied(true)}>
                  <div className="mt-4 flex">
                    <button className="flex items-center justify-center rounded-sm bg-black px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-black sm:h-10 sm:px-4">
                      <ClipboardCopyIcon className="mr-1 mt-1 w-5" aria-hidden="true" />
                      Copy to clipboard
                    </button>
                    <button
                      type="button"
                      className="items-center rounded-sm px-4 py-2 text-sm font-normal text-black sm:h-10 sm:px-8"
                      onClick={closeModal}
                    >
                      CLOSE
                    </button>
                  </div>
                </CopyToClipboard>
                {copied && <div className="p-4 text-center text-green-500">Copied!</div>}
              </div>
            )}
            {currentUser.isExporter && !proforma.is_mine && (
              <>
                Please make sure you are logged in and have already saved this property in order to share the
                analysis.
                <button
                  type="button"
                  className="mt-4 w-32 items-center rounded-sm bg-[#61a28d] px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#5bbc9c] sm:h-10 sm:px-8"
                  onClick={closeModal}
                >
                  CLOSE
                </button>
              </>
            )}
            {!currentUser.isExporter && (
              <div>
                <p className="mb-4">
                  This feature is available to registered buyers and real estate agents who hang their license
                  with Lotside.
                </p>
                <p className="my-6 text-blue-700">
                  <a href="https://www.lotside.com/plans/agents">Agent Plans</a>
                </p>
                <p className="mt-6 text-blue-700">
                  <a href="https://www.lotside.com/buyers">Buyer Plans</a>
                </p>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};
