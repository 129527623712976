import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { DetailsList } from "components/proformas/details";

type Props = {
  isOpen: boolean;
  closeModal: any;
  data: any;
};

export const SatelliteInfoModal = ({ isOpen, closeModal, data }: Props) => {
  return (
    <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block h-full max-h-fit w-full max-w-md transform overflow-hidden rounded-md px-0 text-left align-middle shadow-xl transition-all">
          <div className="w-full rounded-md bg-white shadow-lg">
            <div className="flex h-full flex-col">
              <header className="flex w-full flex-row items-center">
                <div className="text-md flex basis-4/5 items-center pl-10 font-medium text-gray-700">
                  GIS Data from US Geological Survey
                </div>
                <div className="justify-right basis-1/5 p-4 pr-4 text-right">
                  <button
                    type="button"
                    className="rounded-md pb-1 pl-1 text-sm font-medium text-gray-400 hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
                  </button>
                </div>
              </header>
              <main className="bg-gray-50 px-10">
                <div className="flex w-full flex-col py-2">
                  <DetailsList data={data} />
                </div>
              </main>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
